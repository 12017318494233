import React from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Checkbox from '@mui/material/Checkbox';
import { useTranslation } from 'react-i18next';
import { RenderCategoryCell, RenderTypeCell } from './RenderCell/RenderStatusCell';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { selectAllInjuryReportDrafts, selectInjuryUnitEntities } from '../../store/injuryReportsSlice';
import { RenderCopyCell } from './RenderCell';
import { formatDateTimeFromTimestamp } from '../../../../utils/format';
import { CustomPagination } from './CustomPagination';
import { REPORT_CATEGORY, REPORT_TYPES } from '../../constants';
import { AppIcon } from '../../../../components/Elements';
import { DataGridContainer } from '../../../../components/DataGrid/DataGridContainer';
import { selectDepartmentEntities } from '../../../departments/departmentsSlice';
import { renderProgress } from './RenderCell/RenderProgressCell';
import { IActiveFilter, IFilterControl } from '../../../../components/DataGrid/types';
import { RDataGrid } from '../../../../components/DataGrid/RDataGrid';

const CustomCheckbox = (props: any) => {
  return <Checkbox icon={<AppIcon iconName="clinical_notes" />} {...props} />;
};

type InjuryReportDraftsDataGridProps = {
  category?: string;
};

export const InjuryReportDraftsDataGrid = (props: InjuryReportDraftsDataGridProps) => {
  const { category: categoryFilter } = props;

  const { t, i18n } = useTranslation();

  const drafts = useAppSelector(selectAllInjuryReportDrafts);

  const units = useAppSelector(selectInjuryUnitEntities);
  const departments = useAppSelector(selectDepartmentEntities);

  const controls = React.useMemo(() => {
    let controlItems: IFilterControl[] = [];

    return controlItems;
  }, []);

  const [activeFilters, setActiveFilters] = React.useState<any[]>([]);

  const handleClearFilters = () => {
    setActiveFilters([]);
  };

  const handleRemoveFilter = (field: string) => {
    setActiveFilters((prevFilters) => {
      let newFilters = [...prevFilters];
      newFilters = newFilters.filter((filter) => filter.field !== field);
      return newFilters;
    });
  };
  const handleSetFilter = (newFilter: IActiveFilter) => {
    setActiveFilters((prevFilters) => {
      let newFilters = [...prevFilters];
      const filterIndex = newFilters.findIndex((filter) => filter.field === newFilter.field);
      if (filterIndex > -1) {
        newFilters[filterIndex] = newFilter;
      } else {
        newFilters.push(newFilter);
      }
      return newFilters;
    });
  };

  const rows = React.useMemo(() => {
    let filteredDrafts: any = [...drafts];
    if (categoryFilter) {
      filteredDrafts = filteredDrafts.filter((injuryReport: any) => injuryReport.category === categoryFilter);
    }

    const getProgressFromDraft = (draft: any) => {
      let progress = 0.1;
      let totalSections = 1;
      if (draft.type === REPORT_TYPES.ACCIDENT) {
        totalSections = draft.category === REPORT_CATEGORY.EMPLOYEE ? 6 : 5;
      }
      if (draft.type === REPORT_TYPES.ILLNESS) {
        totalSections = 5;
      }
      if (draft.type === REPORT_TYPES.MINOR_INJURY) {
        totalSections = 2;
      }
      const filledOutSectionsCount = Object.keys(draft.sections).length;
      progress = filledOutSectionsCount / totalSections;
      return progress;
    };

    return filteredDrafts.map((draft: any) => {
      return {
        id: draft.id,
        created_at_timestamp: draft.created_at_timestamp,
        name: draft.name,
        category: draft.category,
        type: draft.type,
        progress: getProgressFromDraft(draft)
      };
    });
  }, [drafts, departments, units]);

  const columns = React.useMemo(() => {
    const columnByField: { [field: string]: GridColDef } = {
      id: {
        field: 'id',
        headerName: 'ID',
        width: 120,
        renderCell: (params) => <RenderCopyCell {...params} />
      },
      name: {
        field: 'name',
        headerName: 'Name',
        flex: 2,
        minWidth: 240
        /* renderCell: (params) => RenderLinkCell(params) */
      },
      created_at_timestamp: {
        field: 'created_at_timestamp',
        headerName: 'Created at',
        minWidth: 160,
        flex: 1,
        valueFormatter: ({ value }) => formatDateTimeFromTimestamp(value)
      },
      category: {
        field: 'category',
        headerName: 'Category',
        minWidth: 130,
        flex: 1
        /* renderCell: (params) => <RenderCategoryCell(params) />, */
      },
      type: {
        field: 'type',
        headerName: 'Type',
        minWidth: 130,
        flex: 1
        /* renderCell: (params) => RenderTypeCell(params), */
        /* type: 'singleSelect',
        valueOptions: [REPORT_TYPES.ACCIDENT, REPORT_TYPES.ILLNESS, REPORT_TYPES.MINOR_INJURY] */
      },
      progress: {
        field: 'progress',
        headerName: 'Progress',
        minWidth: 160,
        flex: 1,
        renderCell: renderProgress
      }
    };

    let cols = ['id', 'name', 'category', 'type', 'created_at_timestamp', 'progress'];
    if (categoryFilter) {
      cols = cols.filter((col) => col !== 'category');
    }

    return cols.map((field) => columnByField[field]);
  }, [categoryFilter]);

  const dispatch = useAppDispatch();

  return (
    <RDataGrid
      items={rows}
      cols={columns}
      filters={{
        controls: controls,
        addedControls: [],
        active: activeFilters,
        handleSetFilter,
        handleRemoveFilter,
        handleResetFilterControls: handleClearFilters
      }}
    />
  );
};
