import { CONSTANTS } from "../common/constants";
import CookieStorage from "./CookieStorage";

export const getActiveUser = () => {
  const userAndOrg = JSON.parse(localStorage.getItem('ngStorage-current'));
  return userAndOrg?.user;
};

export const getHasHandlerReadClaim = () => {
  const user = getActiveUser();
  if (
    user.permissions &&
    user.permissions.claims &&
    user.permissions.claims.length
  ) {
    return user.permissions.claims.map(claim => claim.claim_id).indexOf('b4cb162c-2f5f-11ee-be56-0242ac120002') > -1 && user.role !== 'tenantadmin'; 
  }
  return false;
};

export const getSelectedOrgId = () => {
  const selectedOrg: any = CookieStorage.getItem(CONSTANTS.COOKIE_KEYS.SELECTED_ORG) || false;
  if (selectedOrg) {
    let parsedOrg = JSON.parse(selectedOrg);
    return parsedOrg?.org_id || undefined;
  }
  return undefined;
};

export const getSelectedOrg = () => {
  let selectedOrgHeader = '';
  const selectedOrg: any = CookieStorage.getItem(CONSTANTS.COOKIE_KEYS.SELECTED_ORG) || false;
  if (selectedOrg) {
    let parsedOrg = JSON.parse(selectedOrg);
    delete parsedOrg.org_name;
    delete parsedOrg.name;
    selectedOrgHeader = JSON.stringify(parsedOrg);
  }
  return selectedOrgHeader;
};
