import * as yup from 'yup';
import React from 'react';
import { FormSelect, FormTextField } from '../../../../../components/FormInputs';
import { WithFormMethods } from '../../../../../components/Forms';
import { useTranslation } from 'react-i18next';
import { TKB_INJURY } from '../../../constants';
import { useAppSelector } from '../../../../../store';
import { selectAllInjuryUnits, selectInjuryUnitEntities, selectInjuryUnitIds } from '../../../store/injuryReportsSlice';
import { selectAllDepartments, selectDepartmentEntities, selectDepartmentIds } from '../../../../departments/departmentsSlice';
import { UnitPaper } from '../UnitPaper';
import { getTranslationKeyForSectionFieldLabel, getTranslationKeyForSectionFieldPlaceholder } from '../../../helpers/dataMapping';
import { STUDENT_SECTION_NAMES } from '../../../constants/student';
import { FormAutocomplete } from '../../../../../components/FormInputs/FormAutocomplete';
import { FormSingleAutocomplete } from '../../../../../components/FormInputs/FormSingleAutocomplete';
import _, { filter } from 'lodash';
export const schoolSchema = yup.object({
  departmentId: yup.string().min(4, 'min 4').max(110, 'max 110'), // ID or name
  unitId: yup.string().min(2, 'min 2').max(112, 'max 112'), // ID or name
  linkToInsurance: yup.string().optional()
});

export type SchoolSection = yup.InferType<typeof schoolSchema>;

export const SchoolControls = (props: WithFormMethods<SchoolSection>) => {
  const { t } = useTranslation();
  const { methods } = props;
  const { control, watch, setValue } = methods;

  const departmentIds = useAppSelector(selectDepartmentIds);
  const departmentEntities = useAppSelector(selectDepartmentEntities);
  const departments = useAppSelector(selectAllDepartments);
  const units = useAppSelector(selectAllInjuryUnits);
  const unitEntities = useAppSelector(selectInjuryUnitEntities);

  const departmentId = watch('departmentId');

  const filteredDepartments = React.useMemo(() => {
    let unitDepartmentIds: string[] = [];
    for (let unit of units) {
      if (!unitDepartmentIds.includes(unit.department_id)) {
        unitDepartmentIds.push(unit.department_id);
      }
    }
    return departments.filter((department) => unitDepartmentIds.indexOf(department.id) === -1);
  }, [units]);

  const departmentFilteredUnits = React.useMemo(() => {
    if (departmentId) {
      return units.filter((unit) => unit.department_id === departmentId || !unit.department_id);
    }
    return [];
  }, [departmentId]);
  const unitId = watch('unitId');

  React.useEffect(() => {
    let validUnitIds = departmentFilteredUnits.map((unit) => unit.unit_id);
    if (!validUnitIds.includes(unitId)) {
      if (validUnitIds.length > 0) {
        setValue('unitId', validUnitIds[0]);
      } else {
        setValue('unitId', '');
      }
      
    }
  }, [departmentFilteredUnits]);

  const uniqDepartmentIds = React.useMemo(() => {
    return _.uniq([...departmentIds]);
  }, [departmentIds]);

  const initialTranslationPath = `${TKB_INJURY}.schoolPlaceTab`;
  
  const repeatedNames = React.useMemo(() => {
    let allNames: string[] = [], repeated: string[] = [];

    departments.forEach((department) => {
      if (allNames.includes(department.name)) {
        repeated.push(department.name); 
      } else {
        allNames.push(department.name);
      }
    });

    return repeated;
  }, [departments]);
  let departmentNames: any = _.mapValues(departmentEntities, (dep) => dep.name);
  let unitNames: any = _.mapValues(unitEntities, (unit) => unit.unit_name);
  return (
    <>
      <FormSingleAutocomplete
        control={control}
        name='departmentId'
        label={t(getTranslationKeyForSectionFieldLabel(STUDENT_SECTION_NAMES.SCHOOL, 'department'))}
        options={filteredDepartments.map((department, i) => department.id)}
        optionLabels={departmentNames}
      />
      {departmentFilteredUnits.length > 0 && (
        <FormSingleAutocomplete
          control={control}
          name='unitId'
          label={t(getTranslationKeyForSectionFieldLabel(STUDENT_SECTION_NAMES.SCHOOL, 'unit'))}
          options={departmentFilteredUnits.map((unit, i) => unit.unit_id)}
          optionLabels={unitNames}
        />
      )}
      {/* <FormSelect
        control={control}
        name="departmentId"
        label={t(getTranslationKeyForSectionFieldLabel(STUDENT_SECTION_NAMES.SCHOOL, 'department'))}
        options={departments.map((department, i) => ({
          value: department.id,
          label: department.name,
          depth: department.depth
        }))}
      /> */}
      {/* <FormSelect
        control={control}
        name="unitId"
        label={t(getTranslationKeyForSectionFieldLabel(STUDENT_SECTION_NAMES.SCHOOL, 'unit'))}
        options={departmentFilteredUnits.map((unit, i) => ({
          value: unit.unit_id,
          label: unit.unit_name
        }))}
      /> */}
      {unitId && <UnitPaper unitId={unitId} />}
      <FormTextField
        control={control}
        name="linkToInsurance"
        label={t(getTranslationKeyForSectionFieldLabel(STUDENT_SECTION_NAMES.SCHOOL, 'linkToInsurance'))}
        placeholder={t(getTranslationKeyForSectionFieldPlaceholder(STUDENT_SECTION_NAMES.SCHOOL, 'linkToInsurance'))}
      />
    </>
  );
};