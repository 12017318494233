import { Box, Button, CardContent, CardHeader, Chip, Divider, Drawer, IconButton, Link, List, Paper, Stack, Step, StepButton, StepLabel, Stepper, ToggleButton, Tooltip, Typography } from '@mui/material';
import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { InjuryReportsDataGrid } from '../components/InjuryReportsDataGrid/InjuryReportsDataGrid';
import { ContentHeader, ContentLayout, NewContentLayout } from '../../../components/Layout';
import { NamedTabPanel, TabPanel } from '../../../components/Elements/TabPanel';
import { Statistics } from '../components/Statistics/Statistics';
import AppMap from '../../sites/components/Map';
import { RootState, useAppDispatch, useAppSelector } from '../../../store';
import { initializeTestData, makeSelectInjuryReportById, selectInjuryHandlerReportIds, selectInjuryReportsTestDataInitialized, updateApprovedSections } from '../store/injuryReportsSlice';
import { AppIcon } from '../../../components/Elements';
import { Insights } from '../components/Statistics/Insights';
import FullScreenDialog from '../../../components/Elements/FullScreenDialog';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { DescriptionList, DescriptionListItem } from '../../../components/DescriptionList/DescriptionsGrid';
import { formatDateFromTimestamp } from '../../../utils/format';
import { Content } from '../../../components/Layout/Content';
import { CurrentInjuryReportContext } from './CurrentInjuryReportContext';
import { AppExpandableSection, ApprovableSectionList, BaseSectionList } from '../components/ReportDetails/ApprovableSection/ApprovableSection';
import { ActionPanel } from '../components/ReportDetails/ActionPanel/ActionPanel';
import AppStepper, { SimpleAppStepper } from '../../../components/Elements/AppStepper/AppStepper';
import { CASE_STATUS, TKB_INJURY } from '../constants';
import { AppIconButton } from '../../../components/Elements/AppIconButton';
import { SectionValuesListItem } from '../../../components/SectionValuesList/SectionValuesListItem';
import { CustomStepConnector, CustomStepIcon } from '../components/InjuryForm/InjuryWizard';
import { DashedDivider } from '../../../components/Elements/DashedDivider';
import RejectInjuryReportDialog from '../components/ReportDetails/CaseHandlingDialogs/RejectInjuryReportDialog';
import CloseCaseDialog from '../components/ReportDetails/CaseHandlingDialogs/CloseCaseDialog';
import { ReportMessages } from '../components/ReportDetails/CasePanels/Messages';
import ContentWrapper from '../../../components/Layout/ContentWrapper';
import { RightContainer } from '../../../components/Layout/RightContainer';
import { Header } from '../../../components/Layout/Header';
import { TabsContainer } from '../../../components/Layout/AppTabs';
import { CaseStatusColors } from '../constants/mappedProps';
import CloseCasePanel from '../components/ReportDetails/CloseCasePanel/CloseCasePanel';
import PrintAndArchiveFormsPanel from '../components/ReportDetails/CasePanels/PrintAndArchiveFormsPanel';
import { Actions } from '../components/ReportDetails/CasePanels/Actions';
import { ReportComments } from '../components/ReportDetails/CasePanels/Comments';
import Grid from '@mui/material/Unstable_Grid2';
import { HistoryPanel } from '../components/ReportDetails/CasePanels/History';
import { snackAlertOpened } from '../../global/controlsSlice';
import LinkNonconformityReportDialog from '../components/ReportDetails/CaseHandlingDialogs/LinkNonconformityReportDialog';
import { selectNonconformityReportEntities } from '../../nonconformity/nonconformitySlice';
import { useTranslation } from 'react-i18next';
import Badge from '@mui/material/Badge';
import { selectEmployeeById } from '../../employees/employeesSlice';
import { replace } from 'lodash';

const tabNames = {
  DETAILS: 'DETAILS',
  COMMENTS: 'COMMENTS',
  MESSAGES: 'MESSAGES',
  ACTIONS: 'ACTIONS',
  REJECTION_DETAILS: 'REJECTION_DETAILS',
  FORMS: 'FORMS',
  CLOSING_CASE: 'CLOSING_CASE'
};

export const pathToBackLabel: any = {
  '/injuries/employee/registrations': 'employeeRegistrations', // 'Employee: Registrations',
  '/injuries/employee/handling': 'employeeHandling',// 'Employee: Report handler',
  '/injuries/student/registrations': 'studentRegistrations',// 'Student: Registration',
  '/injuries/student/handling': 'studentHandling',// 'Student:  Report handler',
  '/injuries/all/registrations': 'allRegistrations',// 'All injuries: Registration',
  '/injuries/all/handling': 'allHandling',// 'All injuries: Report handler',
}

type InjuryReportReporterPageProps = {
  category?: string;
}

export const InjuryReportReporterPage = (props: InjuryReportReporterPageProps) => {
  const { category } = props;

  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const { t } = useTranslation();

  const injuryReportId = params.injuryReportId || '';

  const selectInjuryReportById = React.useMemo(makeSelectInjuryReportById, [injuryReportId]);
  const injuryReport = useAppSelector((state: RootState) => selectInjuryReportById(state, injuryReportId));

  const [approvedSections, setApprovedSections] = React.useState<string[]>([]);
  const [activeTab, setActiveTab] = React.useState('details');

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
  };

  React.useEffect(() => {
    setApprovedSections(injuryReport?.approved_sections || []);
  }, [injuryReport]);

  if (!injuryReport) {
    return <></>;
  }

  const isOngoing = injuryReport.status === CASE_STATUS.ONGOING;
  const isRejected = injuryReport.status === CASE_STATUS.REJECTED;
  const isClosed = injuryReport.status === CASE_STATUS.CLOSED;

  const getBackPath = () => {
    const backPath = location.pathname.split('/').slice(0, -1).join('/');
    return backPath;
  };

  const getBackHome = () => {
    const homePath = location.pathname.split('/').slice(0, 2).join('/');
    return homePath;
  };

  const handleBack = () => {
    navigate(getBackPath());
  };

  const reporterUser = useAppSelector((state: RootState) => selectEmployeeById(state, injuryReport.reporter_id));

  const handlingInjuryReportIds = useAppSelector(selectInjuryHandlerReportIds);

  const userCanHandle = React.useMemo(() => {
    return handlingInjuryReportIds.indexOf(injuryReport?.id) > -1;
  }, [handlingInjuryReportIds, injuryReport]);

  const handleSwitchToHandlerView = () => {
    let initialPath = '';
    if (category) {
      if (category === 'student') {
        initialPath = '/injuries/student/handling';
      }
      if (category === 'employee') {
        initialPath = '/injuries/employee/handling';
      }
    } else {
      initialPath = '/injuries/all/handling';
    }
    navigate(`${initialPath}/${injuryReport.id}`, { replace: true });
  };

  const handleCopyText = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <CurrentInjuryReportContext.Provider value={injuryReport}>
      <ContentWrapper
      >
        <ContentLayout
          maxWidth='lg'
          breadcrumbs={[
            {
              to: getBackHome(),
              name: t(`${TKB_INJURY}.home`),
            },
            {
              to: getBackPath(),
              name: t(`${TKB_INJURY}.breadcrumbs.${pathToBackLabel[getBackPath()]}`),
            }
          ]}
          title={`${injuryReport.name}`}
        >
          <ContentHeader
            breadcrumbs={[
              {
                to: getBackHome(),
                name: t(`${TKB_INJURY}.home`),
              },
              {
                to: getBackPath(),
                name: t(`${TKB_INJURY}.breadcrumbs.${pathToBackLabel[getBackPath()]}`),
              }
            ]}
            title={`${injuryReport.name}`}
            action={
              <Stack
                direction='row'
                gap={1.5}
              >
                {/* {userCanHandle && (
                  <Button
                    color='primary'
                    variant='outlined'
                    onClick={handleSwitchToHandlerView}
                  >
                    {t(`${TKB_INJURY}.switchToHandlerView`)}
                  </Button>
                )} */}
                {/* <Button
                  color='primary'
                  disabled={approvedSections === injuryReport.approved_sections}
                >
                  Get report
                </Button> */}
                
                
              </Stack>
              
            }
            onBack={handleBack}
            backLabel={pathToBackLabel[getBackPath()]}
          />
          <Box
            sx={{
              flex: 1,
              px: {
                xs: 2, 
                lg: 2.5,
                xl: 3
              },
              py: {
                xs: 2, 
                lg: 2.5,
                xl: 3
              },
            }}
          >
            <Box
              sx={{
                flex: 1,
                borderRadius: 1,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                display: 'flex', 
                flexDirection: 'column',
                bgcolor: 'background.paper'
              }}
            >
              <DescriptionList>
              <DescriptionListItem
                field={t('wif.injury.caseId')}
                valueComponent={
                  <Stack 
                    direction='row'
                    gap={0.75}
                    sx={{
                      '&:hover': {
                        '& .visible-on-hover': {
                          display: 'flex', 
                          alignItems: 'center',
                          justifyContent: 'center',
                        }
                      }
                    }}
                  >
                    <Typography 
                      variant='body1' 
                      sx={{ 
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {injuryReport.id}
                    </Typography>
                    <Box
                      className='visible-on-hover'
                      sx={{
                        display: 'none',
                        
                      }}
                    >
                      <Tooltip
                        title='Copy ID'
                        placement='bottom'
                        
                      >
                      <IconButton 
                        onClick={() => handleCopyText(injuryReport.id)}
                        size='small'
                        sx={{ p: 0 }}
                      >
                        <AppIcon iconName='content_copy' opticalSize={20} />
                      </IconButton>
                      </Tooltip>
                    </Box>
                    
                  </Stack>
                }
              />
                <DescriptionListItem 
                  field={t('wif.injury.status')}
                  value={t(`${TKB_INJURY}.${injuryReport.status}`)}
                />
                <DescriptionListItem 
                  field={t('wif.injury.type')}
                  value={t(`${TKB_INJURY}.reportType.${injuryReport.type}`)}
                />
                {!category && (
                  <DescriptionListItem 
                    field={t('wif.injury.category')}
                    value={t(`${TKB_INJURY}.reportCategory.${injuryReport.category}`)}
                  />
                )}
                <DescriptionListItem 
                  field={t('wif.injury.reportedDate')}
                  value={formatDateFromTimestamp(injuryReport.reported)}
                />
                <DescriptionListItem 
                  field={t('wif.injury.reportedBy')}
                  value={reporterUser ? reporterUser?.name : injuryReport.reporter_id}
                />
              </DescriptionList>             
              <Box sx={{ mt: 2 }} />
              
              <TabsContainer px={0}>
              <Tabs
                value={activeTab}
                onChange={handleChangeTab}
              >
                <Tab label={t(`${TKB_INJURY}.details`)} value='details' />
                <Tab label={t(`${TKB_INJURY}.messages`)}  value='messages' />
                {injuryReport.status === CASE_STATUS.REJECTED && 
                  <Tab 
                    value={tabNames.REJECTION_DETAILS}
                    label={t(`${TKB_INJURY}.rejectedInfo`)} 
                  />
                }
              </Tabs>
            </TabsContainer>
            <Box
              sx={{
                py: 2.5,
                ...(activeTab === 'messages' && {
                  py: 0,
                }),
                flex: 1,
              }}
            >
              <NamedTabPanel
                value={activeTab}
                name='details'
              >
                <BaseSectionList 
                  approvedSections={approvedSections}
                  shouldAllowEdit={injuryReport?.status === CASE_STATUS.ONGOING}
                />
              </NamedTabPanel>
              <NamedTabPanel
                value={activeTab}
                name='messages'
              >
                <ReportMessages />
              </NamedTabPanel>
              {injuryReport.status === CASE_STATUS.REJECTED && (
                <NamedTabPanel
                  value={activeTab}
                  name='rejection_details'
                >
                  <Typography variant='h5'>Rejection message</Typography>
                  {injuryReport.rejected_information && (
                    <Typography variant='body1'>{injuryReport.rejected_information}</Typography>
                  )}
                </NamedTabPanel>
              )}
            </Box>
          </Box>
          </Box>
        </ContentLayout>
      </ContentWrapper>
    </CurrentInjuryReportContext.Provider>
  );
}