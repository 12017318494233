import * as yup from 'yup';
import React from 'react';
import { FormDatePicker, FormSelect, FormTextField } from '../../../components/FormInputs';
import { useAppSelector } from '../../../store';
import { selectEmployeeIds, selectEmployees, selectEmployeesFetched } from '../../employees/employeesSlice';
import _, { set } from 'lodash';
import { FormAutocomplete } from '../../../components/FormInputs/FormAutocomplete';
import { useTranslation } from 'react-i18next';
import { FormSingleAutocomplete } from '../../../components/FormInputs/FormSingleAutocomplete';
import { Box } from '@mui/material';

type CreateOrUpdateActionFormControlsProps = {
  control: any;
}

export const actionReducesOptions = [
  'reduces_likelihood',
  'reduces_consequence',
  'reduces_likelihood_consequence'
];

export const CreateOrUpdateActionFormControls = (props: CreateOrUpdateActionFormControlsProps) => {
  const { control } = props;
  const { t } = useTranslation();

  const employees = useAppSelector(selectEmployees); // Replace with access controlled
  const employeeIds = useAppSelector(selectEmployeeIds); 
  const employeesFetched = useAppSelector(selectEmployeesFetched);

  let employeeNames: any = _.clone(employees);
  employeeNames = _.mapValues(employeeNames, (e) => e.name);

  const mappedUserNames = React.useMemo(() => {
    let allNames: string[] = [], repeated: string[] = [];

    employeeIds.forEach((employeeId) => {
      const employee = employees[employeeId];
      if (allNames.includes(employee.name)) {
        repeated.push(employee.name);
      } else {
        allNames.push(employee.name);
      }
    });

    let mappedNames: any = {};

    for (let id of employeeIds) {
      const employee = employees[id];
      if (repeated.includes(employee.name)) {
        mappedNames[id] = `${employee.name} (${employee.email})`;
      } else {
        mappedNames[id] = employee.name;
      }
    }
    return mappedNames;
  }, [employees]);

  return (
    <>
      <FormTextField
        name='text'
        label={t('wif.injury.action.fields.text.title')}
        control={control}
        /* placeholder={t('wif.injury.action.fields.text.placeholder')} */
        required
      />
      <FormTextField
        name='actionDescription'
        label={t('wif.injury.action.fields.actionDescription.title')}
        control={control}
        /* placeholder={t('wif.injury.action.fields.actionDescription.placeholder')} */
        minRows={3}
        maxRows={4}
        multiline
        required
      />
      <FormSelect 
        name='measureType'
        label={t('wif.injury.action.fields.measureType.title')}
        control={control}
        options={[
          { label: t('wif.injury.action.fields.measureType.options.reduces_likelyhood'), value: 'reduces_likelihood' },
          { label: t('wif.injury.action.fields.measureType.options.reduces_consequence'), value: 'reduces_consequence' },
          { label: t('wif.injury.action.fields.measureType.options.reduces_likelyhood_consequence'), value: 'reduces_likelihood_consequence' },
        ]}
      />
      {/* Need to add clear for dates */}
      <FormDatePicker
        name='deadline'
        label={t('wif.injury.action.fields.deadline.title')}
        control={control}
      />
      <Box sx={{ mt: -1.5 }} />
      <FormSingleAutocomplete
        name='assignee'
        label={t('wif.injury.action.fields.assignee.title')}
        control={control}
        options={employeeIds as string[]}
        optionLabels={mappedUserNames}
        loading={employeesFetched !== 'succeeded'}
      />
      <FormAutocomplete
        name='members'
        label={t('wif.injury.action.fields.members.title')}
        control={control}
        options={employeeIds as string[]}
        optionLabels={mappedUserNames}
        loading={employeesFetched !== 'succeeded'}
      />
    </>
  )
};