import { EmployeeSectionDetails } from './employee';
import { StudentSectionDetails } from './student';

export * as CASE_STATUS from './caseStatuses';
export * as REPORT_TYPES from './reportTypes';
export * as REPORT_CATEGORY from './reportCategories';
export * as LOG_TYPES from './logs';
export * from './mappedSchemas';
export * from './common';

export const AllSectionDetails: { [sectionName: string]: { title: string, description: string } } = {
  ...StudentSectionDetails, 
  ...EmployeeSectionDetails
};

// TRANSLATION KEY BASE
