import { Avatar, Button, Paper, Stack, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import * as React from 'react';
import { BOX_SHADOWS } from "../../../../../config/theme/boxShadows";
import { formatDateTimeFromTimestamp } from "../../../../../utils/format";
import { APP_COLORS } from "../../../../../config";
import { AppAction } from "../../../store/injuryReportsSlice";
import { AppIcon } from "../../../../../components/Elements";
import { AppIconButton } from "../../../../../components/Elements/AppIconButton";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useAppSelector } from "../../../../../store";
import { selectAllActions } from "../../../../actions/actionsSlice";
import { Action } from "../../../../actions/components/Action";
import { useCurrentInjuryReport } from "../../../routes/CurrentInjuryReportContext";
import CreateInjuryActionDialog from "../../CreateInjuryActionDialog";
import { useTranslation } from 'react-i18next';

type InjuryReportActionsProps = {
  injuryReport: any;
}

export function Actions(props: InjuryReportActionsProps) {
  const { injuryReport } = props; 
  const { t } = useTranslation();
  const [statusFilter, setStatusFilter] = React.useState('all');

  const handleChange = (event: SelectChangeEvent) => {
    setStatusFilter(event.target.value as string);
  };

  

  const sortedActions = React.useMemo(() => {
    const actions = injuryReport?.actions || [];
    let sorted = [...actions.filter((action => action?.deadline && action?.status !== 'completed'))];
    let remaining = [...actions.filter((action => !action?.deadline && action?.status !== 'completed'))];
    let completed = [...actions.filter((action => action?.status === 'completed'))];
    sorted.sort((a, b) => new Date(a.deadline).getTime() - new Date(b.deadline).getTime());
    remaining.sort((a, b) => a.created_at - b.created_at);
    completed.sort((a, b) => a.updated_at - b.updated_at);
    return [...sorted, ...remaining, ...completed]
  }, [injuryReport]);
  
  const [createOpen, setCreateOpen] = React.useState(false);

  const handleClose = () => {
    setCreateOpen(false);
  };

  const actionFilterOptions = React.useMemo(() => {
    
    const actions = injuryReport?.actions || [];
    let statuses = ['all'];
    const statusLabels = {
      ['all']: t('wif.injury.allStatuses'),
      ['pending']: t('wif.injury.statuses.pending'),
      ['completed']: t('wif.injury.statuses.completed'),
      ['drafts']: t('wif.injury.statuses.draft'),
    };
    for (let action of actions) {
      if (!statuses.includes(action.status)) {
        statuses.push(action.status);
      }
    }
    return statuses.map((status) => ({ value: status, label: statusLabels[status] }))
  }, [injuryReport]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2
      }}
    >
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
      >
        <FormControl>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={statusFilter}
            onChange={handleChange}
            size="small"
          >
            {actionFilterOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
            ))}
          </Select>
        </FormControl>
        {sortedActions.filter(action => action.status === statusFilter || statusFilter === 'all').length === 0 && (
          <Typography
            color='text.secondary'
          >
            {t('wif.injury.noActions')}
          </Typography>
        )}
        <Button
          color='primary'
          onClick={() => setCreateOpen(true)}
        >
          {t('wif.injury.addAction')}
        </Button>
      </Stack>
      {/* {sortedActions.filter(action => action.status === statusFilter || statusFilter === 'all').length === 0 && (
        
      )} */}
      {sortedActions.filter(action => (action.status === statusFilter || statusFilter === 'all') && !action.is_deleted).map((action) => (
        <Action
          key={action.action_id}
          action={action}
        />
      ))}
      <CreateInjuryActionDialog
        open={createOpen}
        onClose={handleClose}
      />
    </Box>
  );
}