import React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { AppIcon } from '../../../../components/Elements';
import {
  Card,
  CardContent,
  CardHeader,
  Drawer,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { APP_COLORS } from '../../../../config';
import { BOX_SHADOWS } from '../../../../config/theme/boxShadows';
import { WithChildren } from '../../../../types';

type LocationsControllerToolbarProps = WithChildren<{}>;

export const LocationsControllerToolbar = (props: LocationsControllerToolbarProps) => {
  const { children } = props;

  return (
    <Paper
      elevation={0}
      sx={{
        width: '100%',
        px: 1,
        py: 1,
        bgcolor: 'background.default',
        /* borderBottom: 1, 
        borderColor: 'divider', */
        overflow: 'visible',
        borderRadius: 0
      }}
    >
      {children}
    </Paper>
  );
};
