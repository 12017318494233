
const enTranslations = require("./assets/translations/en.json");
const nbTranslations = require("./assets/translations/nb.json");
const nnTranslations = require("./assets/translations/nn.json");
// Temporary solution
export const tempI18nResources = {
  en: {
    translation: {
      "wif" : {
        "injury" : enTranslations,
      },
    }
  },
  nb: {
    translation: {
      "wif" : {
        "injury" : nbTranslations,
      },
    }
  },
  no: {
    translation: {
      "wif" : {
        "injury" : nnTranslations
      }
    }
  }
};
