import { TextField, type TextFieldProps } from '@mui/material';
import React from 'react';
import type { Control, FieldPath } from 'react-hook-form';
import { Controller, useFormContext } from 'react-hook-form';
import type { FieldValues } from 'react-hook-form/dist/types';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useTranslation } from 'react-i18next';

import 'dayjs/locale/nb';

type Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = TextFieldProps & {
  control?: Control<TFieldValues, any>;
  name: TName;
  label?: string; 
  formatTemplate?: string;
  controllerProps?: any;
  timePickerProps?: any;
  textFieldProps?: any;
};

export function FormTimePicker<TFieldValues extends FieldValues>(props: Props<TFieldValues>) {
  const { 
    control = useFormContext<TFieldValues>().control, 
    name,
    label = 'Label', 
    formatTemplate = 'HH:MM', 
    controllerProps, 
    timePickerProps, 
    textFieldProps
  } = props;
  const { t } = useTranslation();
  const getTranslation = React.useCallback((key: string) => {
    let keyWithValues = key.split(' ');
    const translationKey = keyWithValues[0];
    if (translationKey === 'max' || translationKey === 'min' || translationKey === 'minmax') {
      return t(`wif.injury.errors.${translationKey}`, { i: keyWithValues[1] });
    } else if (translationKey === 'between') {
      return t(`wif.injury.errors.${translationKey}`, { min: keyWithValues[1], max: keyWithValues[2] });
    } 
    return t(`wif.injury.errors.${key}`);
  }, [t]);
  return (
    <Controller
      control={control}
      name={name}
      {...controllerProps}
      render={({ field, fieldState }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='nb'>
          <TimePicker
            {...timePickerProps}
            label={label}
            value={dayjs(field.value.length >= 4 ? `2022-04-01T${field.value}` : `2022-04-01T${field.value}:00`)}
            onChange={(newValue: any) => field.onChange(newValue?.format())}
            renderInput={(params: any) => 
              <TextField 
                {...textFieldProps}
                {...params} 
                sx={{ flex: 1 }}
                error={!!fieldState.error}
                helperText={fieldState?.error?.message ? getTranslation(fieldState.error.message) : null}
              />
            }
            sx={{ flex: 1 }}
          />
        </LocalizationProvider>
      )}
    />
  );
}