import * as yup from 'yup';
import { FormTextField } from '../../../../components/FormInputs';
import { FormRow } from '../../../../components/Forms';
import { useTranslation } from 'react-i18next';
import { getTranslationKeyForSectionFieldLabel, getTranslationKeyForSectionFieldPlaceholder } from '../../helpers/dataMapping';
import { STUDENT_SECTION_NAMES } from '../../constants/student';

const AccidentTypeOptions: string[] = [
  'A1 Impact/hit by object',
  'A2 Collision/collision',
  'A3 Overturned',
  'A4 Pinched/trapped',
  'A5 Fall',
  'A6 Stung/cut by a sharp/pointed object',
  'A7 Electrical voltage',
  'A8 High/low temperature',
  'A9 Chemicals',
  'A10 Explosion, explosion, fire',
  'A11 Threats of violence',
  'A12 Inflicted violent damage',
  'A13 Other',
];

const DamageNatureOptions: string[] = [
  'C1 Soft tissue injury without wound (crush injury)',
  'C2 Wound damage',
  'C3 Loss of body part',
  'C4 Sprain, contortion',
  'C5 Bone fracture',
  'C6 Heat damage',
  'C7 Cold injury',
  'C8 Etching',
  'C9 Acute poisoning',
  'C10 Psychological after-effects',
  'C11 Other',
];

const DamagedBodyPartOptions: string[] = [
  'D1 Head',
  'D2 Face',
  'D3 Eye, left',
  'D4 Eye, right',
  'D5 Ear, left',
  'D6 Ear, right',
  'D7 Teeth',
  'D8 Throat/neck',
  'D9 Back',
  'D10 Rib/shoulder blade',
  'D11 Chest/lungs/heart/esophagus',
  'D12 Stomach/pelvis/digestive organs/kidney/urinary tract',
  'D13 Shoulder, left',
  'D14 Shoulder, right',
  'D15 Arm/elbow, left',
  'D16 Arm/elbow, right',
  'D17 Wrist, left',
  'D18 Wrist, right',
  'D19 Hand, left',
  'D20 Hand, right',
  'D21 Fingers, left',
  'D22 Fingers, right',
  'D23 Hip, left',
  'D24 Right hip',
  'D25 Leg w/knee, left',
  'D26 Leg w/knee, right',
  'D27 Ankle, left',
  'D28 Ankle, right',
  'D29 Foot, left',
  'D30 Foot, right',
  'D31 Toe, left',
  'D32 Toe, right',
  'D33 Whole body (general poisoning, general cooling, etc.)',
];


export const baseInjuredPartySchema = yup.object().shape({
  /* name: yup.string().required().min(4, 'min 4').max(63, 'max 63'), */
  nationalIdentityNumber: yup.string().required('minmax 11').min(11, 'min 11').max(11, 'max 11').matches(/^\d+$/), // Should be 11 characters long
  citizenship: yup.string().required('between 4 86').min(4, 'min 4').max(86, 'max 86'), // Should be between 4 and 86 characters long'
  address: yup.string().required('between 4 42').min(4, 'min 4').max(42, 'max 42'),
  municipality: yup.string().required('between 4 43').min(4, 'min 4').max(43, 'max 43')
});

export type BaseInjuredPartySection = yup.InferType<typeof baseInjuredPartySchema>;

export const BaseInjuredPartyControls = (props: any) => {
  const { methods: { control } } = props;
  const { t } = useTranslation();
  
  return (
    <>
      <FormTextField
        control={control}
        name='nationalIdentityNumber'
        label={t(getTranslationKeyForSectionFieldLabel(STUDENT_SECTION_NAMES.STUDENT, 'nationalIdentityNumber'))}
        sx={{ maxWidth: '320px' }}
      />
      <FormTextField
        control={control}
        name='citizenship'
        label={t(getTranslationKeyForSectionFieldLabel(STUDENT_SECTION_NAMES.STUDENT, 'citizenship'))}
        /* placeholder={t(getTranslationKeyForSectionFieldPlaceholder(STUDENT_SECTION_NAMES.STUDENT, 'citizenship'))} */
        sx={{ maxWidth: '560px' }}
      />
      <FormRow>
        <FormTextField
          control={control}
          name='address'
          label={t(getTranslationKeyForSectionFieldLabel(STUDENT_SECTION_NAMES.STUDENT, 'address'))}
          /* placeholder='Boligveien 1' */
          sx={{ flex: 1 }}
        />
        <FormTextField
          control={control}
          name='municipality'
          label={t(getTranslationKeyForSectionFieldLabel(STUDENT_SECTION_NAMES.STUDENT, 'municipality'))}
          /* placeholder={t(getTranslationKeyForSectionFieldPlaceholder(STUDENT_SECTION_NAMES.STUDENT, 'municipality'))} */
          sx={{ flex: 1 }}
        />
      </FormRow>

    </>
  );
}
