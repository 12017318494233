import type { TextFieldProps } from '@mui/material';
import { Avatar, Box, Button, IconButton, Paper, TextField, Typography } from '@mui/material';
import React from 'react';
import type { Control, FieldPath } from 'react-hook-form';
import { Controller, useFormContext } from 'react-hook-form';
import type { FieldValues, UseFormWatch } from 'react-hook-form/dist/types';
import { AppIcon } from '../Elements';
import { useTheme, alpha } from '@mui/material/styles';
import { Stack } from '@mui/system';
import { formatBytes } from '../../utils/format';

type GeneralFilePreviewProps = {
  file: File;
  previewBgColor?: string;
  previewIconColor?: string;
  previewIconName?: string;
  type?: 'list'|'grid';
}

const GeneralFilePreview = (props: GeneralFilePreviewProps) => {
  const { file, type = 'list', previewBgColor = 'background.default', previewIconColor = 'text.secondary', previewIconName = 'note' } = props;

  return (
    <Box
      sx={{
        flex: 1,
      }}
    >
      <Stack
        direction={type === 'list' ? 'row' : 'column'}
        alignItems='center'
        gap={2}
      >
        <Avatar
          sx={{
            height: 60,
            width: 60,
            borderRadius: 1,
            bgcolor: previewBgColor,
            color: previewIconColor
          }}
        >
          <AppIcon
            iconName={previewIconName}
          />
        </Avatar>
        <Stack
          direction='column'
        >
          <Typography variant='body2'
            sx={{ 
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            fontWeight={500}>{file.name.length > 34 ? file.name.substring(0, 31).concat('...') : file.name}</Typography>
          {type === 'list' && (
            <>
              <Typography variant='body2' color='textVariant'>{file.type}</Typography>
              <Typography variant='body2' color='textVariant'>{formatBytes(file.size, 2)}</Typography>
            </>
          )}
        </Stack>
        
      </Stack>
    </Box>
  )
};

type FilePreviewProps = {
  file: File;
  attachmentUrl?: string;
  type?: 'list'|'grid';
}

const FilePreview = (props: FilePreviewProps) => {
  const { file, attachmentUrl, type = 'list' } = props;
  const theme = useTheme();

  if (file !== null && file?.type !== null) {
    const fileType = file.type;
    if (fileType.startsWith('image') && attachmentUrl) {
      return (
        <Box
          sx={{
            flex: 1,
          }}
        >
          <Stack
            direction={type === 'list' ? 'row' : 'column'}
            alignItems='center'
            gap={2}
          >
            <Paper
              variant='outlined'
              sx={{
                p: 0,
                overflow: 'hidden',
                height: 60,
                width: 60
              }}
            >
              <img 
                src={attachmentUrl}
                style={{
                  height: 60,
                  width: 60, 
                  objectFit: 'cover'
                }}
                alt={file.name}
              />
            </Paper>
            <Stack
              direction='column'
            >
              <Typography variant='body2' 
                sx={{ 
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
                fontWeight={500}>{file.name.length > 34 ? file.name.substring(0, 31).concat('...') : file.name}</Typography>
              {type === 'list' && (
                <>
                  <Typography variant='body2' color='textVariant'>{file.type}</Typography>
                  <Typography variant='body2' color='textVariant'>{formatBytes(file.size, 2)}</Typography>
                </>
              )}
              
            </Stack>
          </Stack>
        </Box>
        
      );
    } 
    if (fileType.endsWith('pdf')) {
      return (
        <GeneralFilePreview type={type} file={file} previewBgColor={alpha(theme.palette.error.main, 0.9)} previewIconColor='#fff' previewIconName='picture_as_pdf' />
      )
    }
    return (
      <GeneralFilePreview type={type} file={file} />
    )
  }
};

type Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
  control?: Control<TFieldValues, any>;
  watch?: UseFormWatch<TFieldValues>;
  name: TName;
  label?: string;
  index?: number;
  type?: 'list'|'grid';
  outlinedBtn?: boolean;
  controllerProps?: any;
};

export function FormFileInput<TFieldValues extends FieldValues>(props: Props<TFieldValues>) {
  const { 
    control = useFormContext<TFieldValues>().control, 
    watch = useFormContext<TFieldValues>().watch,
    name, 
    label = 'Upload file',
    index,
    type = 'list',
    outlinedBtn = false,
    controllerProps, 
    ...rest 
  } = props;

  const [filePreviewUrl, setFilePreviewUrl] = React.useState<string | null>(null);

  const file = watch(name);

  React.useEffect(() => {
    
    if (file) {
      console.log(`file.${index}: ${file}`);
      const url = URL.createObjectURL(file);
      console.log(`url.${index}: ${url}`);
      setFilePreviewUrl(URL.createObjectURL(file));
    } else {
      setFilePreviewUrl(null);
    }
  }, [file]);

  return (
    
    <Controller
      control={control}
      name={name}
      {...controllerProps}
      render={({ field, fieldState: { error } }) => (
        <Box
          sx={{
            display: 'flex',
            flexDirection: type === 'list' ? 'row' : 'column', 
            alignItems: 'start', 
            ...(type === 'list' ? {
              flex: 1, 
            } : {
              width: '60px', 
            }),
            cursor: 'pointer'
          }}
        >
          {file ? (
            <label 
              htmlFor={`form-file-selector${index ? `-${index}` : ''}`}
              style={{
                cursor: 'pointer', 
                flex: 1,
              }}
            >
              <Stack
                direction='row'
                alignItems='start'
                gap={2}
                justifyContent='space-between'
                sx={{
                  mr: 0.75
                }}
              >
                <FilePreview file={file} attachmentUrl={filePreviewUrl} />
                <a href={filePreviewUrl} download>
                  <IconButton 
                    edge='end'
                  >
                    <AppIcon iconName='download' />
                  </IconButton>  
                </a>
              </Stack>
              
            </label>
          ) : (
            <label
              htmlFor={`form-file-selector${index ? `-${index}` : ''}`}
              style={{
                cursor: 'pointer', 
                flex: 1,
              }}
            >
              <Stack
                direction='row'
                alignItems='center'
                gap={2}
              >
                 <Button
                  startIcon={<AppIcon iconName='upload' />}
                  component='label'
                  variant={outlinedBtn ? 'outlined' : 'contained'}
                  htmlFor={`form-file-selector${index ? `-${index}` : ''}`}
                >
                  {label}
                </Button>              
              </Stack>
            </label>
          )}
          
          <input
            type='file'
            id={`form-file-selector${index ? `-${index}` : ''}`}
            style={{ display: 'none' }}
            onChange={(e) => field.onChange(e.target.files[0])}
            hidden
          />
        </Box>
      )}
    />
    
    
  );
}