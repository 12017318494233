import React from 'react';
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { REPORT_CATEGORY, REPORT_TYPES } from '../../constants';
import { EMPLOYEE_SECTION_NAMES } from '../../constants/employee';
import { STUDENT_SECTION_NAMES } from '../../constants/student';
import { InjuryWizard } from './InjuryWizard';

export const InjuryFormWrapper = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  // TODO add type report category
  const [controlPanelOpen, setControlPanelOpen] = React.useState(true);
  const [category, setCategory] = React.useState<any>(REPORT_CATEGORY.STUDENT);
  const [type, setType] = React.useState<any>(REPORT_TYPES.ACCIDENT);

  const handleChangeCategory = (
    event: React.MouseEvent<HTMLElement>,
    newCategory: any | null // TODO add type report category
  ) => {
    if (newCategory !== null) {
      setCategory(newCategory);
    }
  };

  const handleChangeType = (
    event: React.MouseEvent<HTMLElement>,
    newType: any | null // TODO add type report type
  ) => {
    if (newType !== null) {
      setType(newType);
    }
  };

  const sectionNames = React.useMemo(() => {
    if (category === REPORT_CATEGORY.EMPLOYEE) {
      switch (type) {
        case REPORT_TYPES.ACCIDENT:
          return [
            EMPLOYEE_SECTION_NAMES.EMPLOYEE,
            EMPLOYEE_SECTION_NAMES.EMPLOYER,
            EMPLOYEE_SECTION_NAMES.ACCIDENT,
            EMPLOYEE_SECTION_NAMES.EMPLOYEE_ACCIDENT_TYPES,
            EMPLOYEE_SECTION_NAMES.FURTHER_INFORMATION
          ];
        case REPORT_TYPES.ILLNESS:
          return [
            EMPLOYEE_SECTION_NAMES.EMPLOYEE,
            EMPLOYEE_SECTION_NAMES.EMPLOYER,
            EMPLOYEE_SECTION_NAMES.ILLNESS,
            EMPLOYEE_SECTION_NAMES.FURTHER_INFORMATION
          ];
        case REPORT_TYPES.MINOR_INJURY:
          return [EMPLOYEE_SECTION_NAMES.MINOR_INJURY];

        default:
          return [EMPLOYEE_SECTION_NAMES.MINOR_INJURY];
      }
    }
    if (category === REPORT_CATEGORY.STUDENT) {
      switch (type) {
        case REPORT_TYPES.ACCIDENT:
          return [
            STUDENT_SECTION_NAMES.STUDENT,
            STUDENT_SECTION_NAMES.SCHOOL,
            STUDENT_SECTION_NAMES.ACCIDENT,
            STUDENT_SECTION_NAMES.FURTHER_INFORMATION
          ];
        case REPORT_TYPES.ILLNESS:
          return [
            STUDENT_SECTION_NAMES.STUDENT,
            STUDENT_SECTION_NAMES.SCHOOL,
            STUDENT_SECTION_NAMES.ILLNESS,
            STUDENT_SECTION_NAMES.FURTHER_INFORMATION
          ];
        case REPORT_TYPES.MINOR_INJURY:
          return [STUDENT_SECTION_NAMES.MINOR_INJURY];
        default:
          return [STUDENT_SECTION_NAMES.MINOR_INJURY];
      }
    }
    return [
      EMPLOYEE_SECTION_NAMES.EMPLOYEE,
      EMPLOYEE_SECTION_NAMES.EMPLOYER,
      EMPLOYEE_SECTION_NAMES.ACCIDENT,
      EMPLOYEE_SECTION_NAMES.FURTHER_INFORMATION
    ];
  }, [category, type]);

  const handleBack = () => {
    navigate('/injuries');
  };

  const handleClose = () => {};

  return (
    <React.Fragment>
      <Box
        sx={{
          bgcolor: 'background.paper',
          py: 2,
          px: {
            xs: 4,
            md: 8,
            lg: 24,
            xl: 28
          },
          width: '100%'
        }}
        /* breadcrumbs={['injuries']}
      breadcrumbLabels={{
        injuries: 'Injury reports'
      }} */
      >
        <Box
          sx={{
            py: 2,
            mb: 4
          }}
        >
          <Box
            /* variant='outlined' */
            sx={
              {
                /* px: 2,
              py: 1.5,
              mt: 2, */
                /* bgcolor: 'backgroundVariant', */
              }
            }
          >
            <Stack spacing={1.5} direction="row" alignItems="center">
              <ToggleButtonGroup
                value={category}
                exclusive
                color={theme.palette.mode === 'dark' ? 'standard' : 'primary'}
                onChange={handleChangeCategory}
                sx={{}}
              >
                <ToggleButton value={REPORT_CATEGORY.STUDENT} aria-label="student">
                  Student
                </ToggleButton>
                <ToggleButton value={REPORT_CATEGORY.EMPLOYEE} aria-label="employee">
                  Employee
                </ToggleButton>
              </ToggleButtonGroup>
              <ToggleButtonGroup
                value={type}
                exclusive
                onChange={handleChangeType}
                color={theme.palette.mode === 'dark' ? 'standard' : 'primary'}
                sx={{}}
              >
                <ToggleButton value={REPORT_TYPES.ACCIDENT} aria-label="accident">
                  Accident
                </ToggleButton>
                <ToggleButton value={REPORT_TYPES.ILLNESS} aria-label="illness">
                  Illness
                </ToggleButton>
                <ToggleButton value={REPORT_TYPES.MINOR_INJURY} aria-label="minor injury">
                  Minor injury
                </ToggleButton>
              </ToggleButtonGroup>
              <Box sx={{ flexGrow: 1 }} />
              {/* <Box>
              <IconButton
                edge='end'
                size='small'
                onClick={() => setControlPanelOpen(false)}
              >
                <AppIcon iconName='close' color='text.secondary' opticalSize={24} />
              </IconButton>
            </Box> */}
            </Stack>
          </Box>
        </Box>
        <InjuryWizard category={category} type={type} sectionNames={sectionNames} key={`${category}-${type}`} />
      </Box>

      {/* <Container
        maxWidth='lg'
        sx={{
          py: 4,
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
        }}
      >
        <Paper
          sx={{
            borderRadius: 0,
            width: '100%', 
            bgcolor: {
              xs: 'transparent',
              xl: 'background.paper',
            },
            boxShadow: {
              xs: 'none',
              xl: 1,
            },
            py: {
              xs: 0,
              xl: 4.5,
            },
            px: {
              xs: 0,
              xl: 5,
            }
          }}
        >
          <InjuryWizard
            sectionNames={sectionNames}
          />
        </Paper>
      </Container> */}
    </React.Fragment>
  );
};
