import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { AppDialogTitle } from '../../../../../components/Display/AppDialogTitle';
import { useAppDispatch } from '../../../../../store';
import { updateInjuryReport } from '../../../store/injuryReportsSlice';
import { snackAlertOpened } from '../../../../global/controlsSlice';
import { CASE_STATUS } from '../../../constants';
import { useTranslation } from 'react-i18next';

interface RejectInjuryReportDialogProps {
  open?: boolean;
  injuryReportId: string;
  onClose?: () => void;
}

export default function RejectInjuryReportDialog(props: RejectInjuryReportDialogProps) {
  const { open = true, injuryReportId, onClose } = props;
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const [rejectInfo, setRejectInfo] = useState<string>('');

  const handleInputChange = (event) => {
    setRejectInfo(event.target.value);
  };

  const handleRejectReport = async () => {
    const req = {
      reportId: injuryReportId,
      body: {
        status: CASE_STATUS.REJECTED,
        rejected_information: rejectInfo
      }
    };
    onClose();
    const resultAction = await dispatch(updateInjuryReport(req));
    if (updateInjuryReport.fulfilled.match(resultAction)) {
      dispatch(snackAlertOpened({ message: t('wif.injury.snackMessages.reportRejected.success'), severity: 'success' }));
    } else {
      if (resultAction.payload) {
        dispatch(snackAlertOpened({ message: t('wif.injury.snackMessages.genericError'), severity: 'error' }));
      } else {
        dispatch(snackAlertOpened({ message: t('wif.injury.snackMessages.errorWithMessage', { message: resultAction.error }), severity: 'error' }));
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <AppDialogTitle id="close-report-dialog-title" onClose={onClose}>
        {t('wif.injury.rejectReportDialog.title')}
      </AppDialogTitle>
      <DialogContent
        dividers
        sx={{
          width: 600
        }}
      >
        <TextField
          label={t('wif.injury.reasonForRejection')}
          multiline
          fullWidth
          rows={4}
          value={rejectInfo}
          onChange={handleInputChange}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="text" onClick={onClose}>
        {t('wif.injury.cancel')}
        </Button>
        <Button onClick={handleRejectReport} variant="contained" color="secondary">
          {t('wif.injury.reject')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
