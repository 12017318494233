import React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { AppDialogTitle } from '../../../../../components/Display/AppDialogTitle';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { UpdateInjuryCaseReq, updateInjuryCase, updateInjuryReport } from '../../../store/injuryReportsSlice';
import { loadingFinished, loadingStarted, snackAlertOpened } from '../../../../global/controlsSlice';
import { CASE_STATUS, REPORT_CATEGORY, REPORT_TYPES } from '../../../constants';
import { useCurrentInjuryReport } from '../../../routes/CurrentInjuryReportContext';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/nb';
import { selectAllEmployees, selectEmployees, selectEmployeesFetched } from '../../../../employees/employeesSlice';
import {
  SamDepartment,
  selectAllDepartments,
  selectDepartmentEntities
} from '../../../../departments/departmentsSlice';
import { uniq } from 'lodash';
import { STUDENT_SECTION_NAMES } from '../../../constants/student';
import { EMPLOYEE_SECTION_NAMES } from '../../../constants/employee';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@mui/material/Autocomplete';
interface AssigneeDialogProps {
  open?: boolean;
  onClose?: () => void;
}

export default function AssigneeDialog(props: AssigneeDialogProps) {
  const { open = true, onClose } = props;
  const { t } = useTranslation();

  const injuryReport = useCurrentInjuryReport();

  const dispatch = useAppDispatch();

  const [assignee, setAssignee] = React.useState<string|null>(null);

  const departments = useAppSelector(selectAllDepartments);
  const departmentEntities = useAppSelector(selectDepartmentEntities);
  const allEmployees = useAppSelector(selectAllEmployees);
  const employees = useAppSelector(selectEmployees);
  const employeesFetched = useAppSelector(selectEmployeesFetched);

  const departmentId = React.useMemo(() => {
    let sectionWithUnitAndDepartmentName = '';
    let departmentId = undefined;

    if (injuryReport.category === REPORT_CATEGORY.STUDENT) {
      if (injuryReport.type === REPORT_TYPES.MINOR_INJURY) {
        sectionWithUnitAndDepartmentName = STUDENT_SECTION_NAMES.MINOR_INJURY;
      } else {
        sectionWithUnitAndDepartmentName = STUDENT_SECTION_NAMES.SCHOOL;
      }
    } else if (injuryReport.category === REPORT_CATEGORY.EMPLOYEE) {
      if (injuryReport.type === REPORT_TYPES.MINOR_INJURY) {
        sectionWithUnitAndDepartmentName = EMPLOYEE_SECTION_NAMES.MINOR_INJURY;
      } else {
        sectionWithUnitAndDepartmentName = EMPLOYEE_SECTION_NAMES.EMPLOYER;
      }
    }
    if (injuryReport?.sections[sectionWithUnitAndDepartmentName]?.departmentId) {
      departmentId = injuryReport.sections[sectionWithUnitAndDepartmentName].departmentId;
    }

    if (departmentId !== undefined) {
      return departmentId;
    }
    return undefined;
  }, [injuryReport]);

  const injuryHandlerIds = React.useMemo(() => {
    let ids: string[] = [];

    // const tenantAdminUserIds = allEmployees
    //   ?.filter((employee) => employee.role === 'tenantadmin')
    //   .map((user) => user.id);
    // ids.push(...tenantAdminUserIds);

    const department = departmentEntities[departmentId];

    if (department?.injury_dept_leaders && department?.injury_dept_leaders?.length > 0) {
      ids.push(...department.injury_dept_leaders);
    }

    if (injuryReport?.category === 'student') {
      if (department?.injury_student_users?.length > 0) {
        ids.push(...department.injury_student_users);
      }
    } else if (injuryReport?.category === 'employee') {
      if (department?.injury_employee_users?.length > 0) {
        ids.push(...department.injury_employee_users);
      }
    }

    ids = uniq(ids);
    return ids;
  }, [departments, employees, departmentId]);

  const employeesWithSameName = React.useMemo(() => {
    let allNames: string[] = [];
    let repeatedNames: string[] = [];
    for (let id of injuryHandlerIds) {
      if (employees[id]?.name) {
        if (allNames.indexOf(employees[id]?.name) !== -1) {
          repeatedNames.push(employees[id]?.name);
        } else {
          allNames.push(employees[id]?.name);
        }
      }
      
    }
    return repeatedNames;
  }, [injuryHandlerIds]);

  const handleChange = (event: SelectChangeEvent) => {
    setAssignee(event.target.value as string);
  };

  
  const handleUpdateDueDate = async () => {
    if (assignee && assignee?.length > 1) {
      const req: UpdateInjuryCaseReq = {
        reportId: injuryReport.id,
        body: {
          assignee_id: assignee
        }
      };
      /* dispatch(loadingStarted()); */
      const resultAction = await dispatch(updateInjuryCase(req));
      if (updateInjuryCase.fulfilled.match(resultAction)) {
        /* dispatch(loadingFinished()); */
        onClose();
        dispatch(snackAlertOpened({ message: t('wif.injury.snackMessages.assigneeUpdated.success'), severity: 'success' }));
      } else {
        if (resultAction.payload) {
          /* dispatch(loadingFinished()); */
          dispatch(snackAlertOpened({ message: t('wif.injury.snackMessages.genericError'), severity: 'error' }));
        } else {
          /* dispatch(loadingFinished()); */
          dispatch(snackAlertOpened({ message: t('wif.injury.snackMessages.errorWithMessage', { message: resultAction.error }), severity: 'error' }));
        }
      }
    }
    
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <AppDialogTitle id="close-report-dialog-title" onClose={onClose}>
        {t('wif.injury.setReportAssigneeDialog.title')}
      </AppDialogTitle>
      <DialogContent
        dividers
        sx={{
          width: 600,
          border: 'none',
        }}
      >
        <Autocomplete
          options={injuryHandlerIds}
          getOptionLabel={(id) => {
            let label = employees?.[id]?.name || id;
            if (employeesWithSameName.indexOf(label) !== -1) {
              label = `${label} (${employees?.[id]?.email})`;
            }
            return label;
          }}
          onChange={(e: any, newValue: string|null) => setAssignee(newValue)}
          loading={employeesFetched !== 'succeeded'}
          value={employeesFetched === 'succeeded' ? assignee : null}
          renderInput={(params) => <TextField {...params} label={t('wif.injury.user')} />}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('wif.injury.cancel')}</Button>
        <Button onClick={handleUpdateDueDate} variant="contained" color="primary" disabled={!assignee || assignee?.length < 1}>
          {t('wif.injury.save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
