import * as yup from 'yup';
import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { FormRow, WithFormMethods } from '../../../../../components/Forms';
import {
  FormDatePicker,
  FormRadioGroup,
  FormSelectWithSearch,
  FormTextField
} from '../../../../../components/FormInputs';
import InputAdornment from '@mui/material/InputAdornment';
import { AppIcon } from '../../../../../components/Elements';
import { nokCurrencyFormatter } from '../../../../../utils/format';
import { FormSectionSubHeader } from '../../../../../components/FormInputs/FormSectionSubheader';
import { useAppSelector } from '../../../../../store';
import { selectAllEmployees, selectEmployeesFetched } from '../../../../employees/employeesSlice';
import { BaseInjuredPartyControls, baseInjuredPartySchema } from '../BaseInjuredPartySection';
import { GENERIC_VALIDATION_MSG } from '../../../constants/mappedInputLabels';
import { TKB_INJURY } from '../../../constants';
import { useTranslation } from 'react-i18next';
import { getTranslationKeyForSectionFieldLabel, getTranslationKeyForSectionFieldPlaceholder } from '../../../helpers/dataMapping';
import { EMPLOYEE_SECTION_NAMES } from '../../../constants/employee';

export const COMPETENCE_LEVEL_OPTIONS = [
  'Primary school',
  '1-3 years at upper secondary school level',
  '1-3 years of education beyond upper secondary school level',
  'University/college education with a duration of 4 years or more'
];

export const NATURE_OF_EMPLOYMENT_OPTIONS = ['Employee (Employment)', 'Self-employed', 'Freelancer'];

export const EMPLOYEE_INJURY_THREE_OPTIONS = ['option1', 'option2', 'option3'];

export const EMPLOYEE_INJURY_FOUR_OPTIONS = ['option1', 'option2', 'option3', 'option4'];

export const EMPLOYE_INJURY_YES_NO_OPTIONS = ['option1', 'option2'];

export const injuredEmployeeSchema = baseInjuredPartySchema.shape({
  employeeId: yup.string().required(GENERIC_VALIDATION_MSG),
  phoneNumber: yup.string().required('Should be between 4 and 16 characters long').min(4, 'min 4').max(16, 'max 16'),
  bankAccountNumber: yup.string().optional().max(12, 'max 12'),
  profession: yup.string().required('Should be between 2 and 86 characters long').min(2, 'min 2').max(86, 'max 86'),
  occupation: yup.string().required('Should be between 2 and 86 characters long').min(2, 'min 2').max(86, 'max 86'),
  employmentStartDate: yup.string().required('Should be at least 4 characters long').min(4, 'min 4'),
  employmentEndDate: yup.string(),
  assumedAnnualIncomeForClaimYear: yup.number().required('Should be up to 100000000').min(0, 'min 0').max(100000000, 'max 100000000'),
  competenceLevel: yup.string().required(GENERIC_VALIDATION_MSG),
  natureOfEmployment: yup.string().required(GENERIC_VALIDATION_MSG),
  voluntaryOccupationalInjuryInsurance: yup.string().when('natureOfEmployment', {
    is: (val) => val === 'option2' || val === 'option3',
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.notRequired()
  })
});

export type InjuredEmployeeSection = yup.InferType<typeof injuredEmployeeSchema>;

export const InjuredEmployeeControls = (props: WithFormMethods<InjuredEmployeeSection>) => {
  const { t } = useTranslation();
  const { methods } = props;
  const { control, watch, setValue } = methods;

  const employees = useAppSelector(selectAllEmployees);
  const injuryEmployeesFetched: string = useAppSelector(selectEmployeesFetched);

  const competenceLevelOptions = {
    option1: t(`${TKB_INJURY}.employee.employeePersonTab.competenceLevel.option1`),
    option2: t(`${TKB_INJURY}.employee.employeePersonTab.competenceLevel.option2`),
    option3: t(`${TKB_INJURY}.employee.employeePersonTab.competenceLevel.option3`),
    option4: t(`${TKB_INJURY}.employee.employeePersonTab.competenceLevel.option4`)
  };

  const natureOfWorkingOptions = {
    option1: t(`${TKB_INJURY}.employee.employeePersonTab.natureOfWorking.option1`),
    option2: t(`${TKB_INJURY}.employee.employeePersonTab.natureOfWorking.option2`),
    option3: t(`${TKB_INJURY}.employee.employeePersonTab.natureOfWorking.option3`)
  };

  const yesNoOptions = {
    option1: t(`${TKB_INJURY}.yesNoOptions.yes`),
    option2: t(`${TKB_INJURY}.yesNoOptions.no`)
  };

  const natureOfEmployment = watch('natureOfEmployment');

  return (
    <>
      <FormSelectWithSearch
        control={control}
        name="employeeId"
        label={t(getTranslationKeyForSectionFieldLabel(EMPLOYEE_SECTION_NAMES.EMPLOYEE, 'employeeId'))}
        options={employees.map((employee) => ({
          value: employee.id,
          label: employee?.name
        }))}
        loading={injuryEmployeesFetched !== 'succeeded'}
        employees={employees}
      />

      <BaseInjuredPartyControls methods={methods} />
      <FormTextField
        control={control}
        name="phoneNumber"
        label={t(getTranslationKeyForSectionFieldLabel(EMPLOYEE_SECTION_NAMES.EMPLOYEE, 'phoneNumber'))}
        sx={{ width: '400px' }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <AppIcon iconName="call" />
            </InputAdornment>
          )
        }}
      />
      <FormTextField
        control={control}
        name="bankAccountNumber"
        label={t(getTranslationKeyForSectionFieldLabel(EMPLOYEE_SECTION_NAMES.EMPLOYEE, 'bankAccountNumber'))}
        
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <AppIcon iconName="account_balance" />
            </InputAdornment>
          )
        }}
        sx={{ width: '400px' }}
      />
      <FormSectionSubHeader>Employment</FormSectionSubHeader>
      <FormTextField
        control={control}
        name="profession"
        label={t(getTranslationKeyForSectionFieldLabel(EMPLOYEE_SECTION_NAMES.EMPLOYEE, 'profession'))}
      />
      <FormTextField
        control={control}
        name="occupation"
        label={t(getTranslationKeyForSectionFieldLabel(EMPLOYEE_SECTION_NAMES.EMPLOYEE, 'occupation'))}
      />
      <FormRow>
        <FormDatePicker control={control} name="employmentStartDate" label={t(getTranslationKeyForSectionFieldLabel(EMPLOYEE_SECTION_NAMES.EMPLOYEE, 'employmentStartDate'))} />
        <FormDatePicker control={control} name="employmentEndDate" label={t(getTranslationKeyForSectionFieldLabel(EMPLOYEE_SECTION_NAMES.EMPLOYEE, 'employmentEndDate'))} />
      </FormRow>
      <Controller
        name="assumedAnnualIncomeForClaimYear"
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            color={'primary'}
            label={t(getTranslationKeyForSectionFieldLabel(EMPLOYEE_SECTION_NAMES.EMPLOYEE, 'assumedAnnualIncomeForClaimYear'))}
            placeholder="500 000"
            InputProps={{
              startAdornment: <InputAdornment position="start">kr</InputAdornment>
            }}
            sx={{ width: '400px' }}
            required={!!fieldState.error}
            error={!!fieldState.error}
            helperText={fieldState.error ? fieldState.error.message : null}
            {...field}
            value={field.value ? nokCurrencyFormatter.format(field.value) : undefined}
            onChange={(e) => field.onChange(parseInt(e.target.value.replace(/[^0-9]/g, '')))}
          />
        )}
      />

      {/* <Controller
        name='employmentPercentage'
        control={control}
        render={({ field, fieldState  }) => (
          <TextField 
            color={'primary'}
            label={t(getTranslationKeyForSectionFieldLabel(EMPLOYEE_SECTION_NAMES.EMPLOYEE, 'workTimeArrangements'))}
            sx={{ width: '240px' }}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            required={!!fieldState.error}
            error={!!fieldState.error}
            helperText={fieldState.error ? fieldState.error.message : null}
            {...field} 
            value={field.value ? field.value.toString() : undefined}
            onChange={(e) => field.onChange(parseInt(e.target.value.replace(/[^0-9]/g, '')))}
          />
        )}
      /> */}

      <FormRadioGroup
        control={control}
        name="competenceLevel"
        label={t(getTranslationKeyForSectionFieldLabel(EMPLOYEE_SECTION_NAMES.EMPLOYEE, 'competenceLevel'))}
        options={EMPLOYEE_INJURY_FOUR_OPTIONS}
        mappedLabels={competenceLevelOptions}
        direction="column"
      />
      <FormRadioGroup
        control={control}
        name="natureOfEmployment"
        label={t(getTranslationKeyForSectionFieldLabel(EMPLOYEE_SECTION_NAMES.EMPLOYEE, 'natureOfEmployment'))}
        options={EMPLOYEE_INJURY_THREE_OPTIONS}
        mappedLabels={natureOfWorkingOptions}
        direction="column"
      />
      {['option2', 'option3'].includes(natureOfEmployment) && (
        <FormRadioGroup
          control={control}
          name="voluntaryOccupationalInjuryInsurance"
          label={t(getTranslationKeyForSectionFieldLabel(EMPLOYEE_SECTION_NAMES.EMPLOYEE, 'voluntaryOccupationalInjuryInsurance'))}
          options={EMPLOYE_INJURY_YES_NO_OPTIONS}
          mappedLabels={yesNoOptions}
        />
      )}
      
    </>
  );
};
