import { Avatar, Button, IconButton, Paper, Stack, TextField, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { generateAvatarColor } from "../../utils/color";
import { AppIcon } from "./AppIcon";


type AppUserAvatarProps = {
  username?: string;
  size?: 'small'|'medium'|'large';
}

export const AppUserAvatar = (props: AppUserAvatarProps) => {
  const { username, size = 'medium' } = props; 

  return (
    <Tooltip
      title={username}
      placement='top'
    >
      <Avatar
        sx={{

          ...(username ? {
            bgcolor: (theme) => generateAvatarColor(username, theme.palette.mode === 'dark'),
          } : {
            bgcolor: 'textVariant'
          }),
          
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          ...(size === 'small' && {
            height: 24, 
            width: 24, 
            fontSize: 'body2.fontSize'
          }),
          ...(size === 'medium' && {
            height: 32,
            width: 32,
          }),
          ...(size === 'large' && {
            height: 40, 
            width: 40
          }),
        }}
      >
        {username ? username?.charAt(0)?.toUpperCase() : <AppIcon iconName='person' />}
      </Avatar>
    </Tooltip>
    
  )
}