import {
  createAsyncThunk, createSelector, createSlice, PayloadAction, createEntityAdapter, EntityState
} from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { REPORT_TYPES } from '../injuries/constants';
import { Grodem, KongsvingerUngdomsskole, Marikollen } from './constants/testSites';

export interface NewCoordinates {
  lat: number;
  lng: number;
}

export interface NewSite {
  id: string;
  department_id?: string;
  account_id?: string;
  name: string;
  coordinates: NewCoordinates;
  address?: string;
  type: string;
};

const sitesAdapter = createEntityAdapter<NewSite>({
});

export interface NewArea {
  id: string;
  account_id?: string;
  site: string; 
  name: string;
  coordinates: NewCoordinates;
  areaCoordinates?: number[][];
}

const areasAdapter = createEntityAdapter<NewArea>({
});

export type Site = {
  domain: string; 
  name: string;
  'lat': number; 
  'lng': number; 
  'areaCoordinates'?: number[][];
};

export type SitesCollection = {
  ids: string[];
  sites: {
    [id: string]: Site;
  };
}

export type LocationsState = {
  sites: EntityState<NewSite>;
  areas: EntityState<NewArea>;
  initialCoordinates: {
    lat: number;
    lng: number;
  };
  currentSiteId?: string;
  currentAreaId?: string;
}

const testSites: NewSite[] = [
  {
    id: '1', 
    name: 'Grodem',
    coordinates: {
      lat: 59.01009,
      lng: 5.65333,
    },
    
    address: 'Fjordsolveien 8',
    type: 'school',
  },
  {
    id: '2', 
    name: 'Kongsvinger ungdomsskole',
    coordinates: {
      lat: 60.1914,
      lng: 12.01479,
    },
   
    address: 'Markensvegen 20',
    type: 'school',
  },
  {
    id: '3', 
    name: 'Marikollen',
    coordinates: {
      lat: 60.19127,
      lng: 11.97191,
    },
    
    address: 'Trygve Stokkes veg 57',
    type: 'school',
  },
]

const initialState: LocationsState = {
  sites: sitesAdapter.getInitialState(),
  areas: areasAdapter.getInitialState(),
  initialCoordinates: {
    lat: 59.01007,
    lng: 5.65323
  },
}

type CreateAreaPayload = {
  site_id: string;
  name: string;
  lat: number;
  lng: number;
  areaCoordinates?: number[][];
}

type CreateSitePayload = {
  name: string;
  coordinates: any;
  address?: string; 
  type?: string;
}

export const locationsSlice = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    initLocationTestData: (state) => {
      sitesAdapter.setAll(state.sites, testSites);
      areasAdapter.setAll(state.areas, [...Grodem, ...KongsvingerUngdomsskole, ...Marikollen]);
    },
    siteClicked: (state, action: PayloadAction<string>) => {
      if (state.currentSiteId === action.payload) {
        state.currentSiteId = undefined;
      } else {
        state.currentSiteId = action.payload;
      }
    },
    siteSelected: (state, action: PayloadAction<string>) => {
      state.currentSiteId = action.payload;
    },
    siteDeselected: (state) => {
      state.currentSiteId = undefined;
    },
    areaClicked: (state, action: PayloadAction<string>) => {
      if (state.currentAreaId === action.payload) {
        state.currentAreaId = undefined;
      } else {
        state.currentAreaId = action.payload;
      }
    },
    siteCreated: (state, action: PayloadAction<CreateSitePayload>) => {
      const id = `${state.sites.ids.length + 1}`;
      const site: any = {
        id: id,
        ...action.payload
      };
      sitesAdapter.addOne(state.sites, site);
    },
    areaCreated: (state, action: PayloadAction<CreateAreaPayload>) => {
      const id = `${action.payload.site_id}/${action.payload.name}`;
      const area: any = {
        id: id,
        ...action.payload,
      };
      areasAdapter.addOne(state.areas, area);
    },
  },
  extraReducers: (builder) => {
  }
});

export const {
  initLocationTestData,
  siteSelected, 
  siteDeselected,
  siteClicked,
  areaClicked: areaClicked,
  areaCreated,
  siteCreated
} = locationsSlice.actions;

export default locationsSlice.reducer;

export const {
  selectIds: selectSiteIds,
  selectEntities: selectSites,
  selectAll: selectAllSites,
  selectTotal: selectSitesTotal,
  selectById: selectSiteById,
} = sitesAdapter.getSelectors<RootState>((state) => state.locations.sites);

export const {
  selectIds: selectAreaIds,
  selectEntities: selectAreas,
  selectAll: selectAllAreas,
  selectTotal: selectAreasTotal,
  selectById: selectAreaById,
} = areasAdapter.getSelectors<RootState>((state) => state.locations.areas);

export const selectInitialCoordinates = (state: RootState) => state.locations.initialCoordinates;
export const selectCurrentSiteId = (state: RootState) => state.locations.currentSiteId;
export const selectCurrentAreaId = (state: RootState) => state.locations.currentAreaId;

const getId = (_: any, id: string) => id;
const getStrKey = (_: any, key: string) => key;

export const selectSitesInBaseCount = createSelector(
  [selectSiteIds, selectAreaIds],
  (siteIds, areaIds: any) => {
    let areasInSiteCount: any = {};
    for (let siteId of siteIds) {
      areasInSiteCount[siteId] = areaIds.filter(areaId => areaId.split('/')[0] === siteId).length;
    }
    return areasInSiteCount;
  }
);

export const makeSelectAreasBySiteId = () => {
  return createSelector(
    [selectAllAreas, getId],
    (areas, siteId) => areas.filter(area => area.site === siteId)
  );
};

export const selectSiteFeatures = createSelector(
  [selectAllSites],
  (sites) => sites.map((site: NewSite) => ({
    type: 'Feature', 
    properties: {
      id: site.id,
      iconImage: 'pin-2',
      name: site.name,
      type: 'pin',
      
    },
    geometry: {
      coordinates: [site.coordinates.lng, site.coordinates.lat],
      type: 'Point',
    },
    id: site.id
  } as GeoJSON.Feature<GeoJSON.Point>))
);

export const selectAreaFeatures = createSelector(
  [selectAllAreas, selectCurrentAreaId], 
  (areas, current) => areas.map((area: NewArea) => ({
    type: 'Feature', 
    properties: {
      id: area.id,
      iconImage: 'pin-0',
      showIcon: area.areaCoordinates ? 0 : 1,
      label: area.name,
      type: 'pin',
      current: current === area.id ? 1 : 0,
      reportsCount: {
        'total': 0,
        [REPORT_TYPES.ACCIDENT]: 0,
        [REPORT_TYPES.ILLNESS]: 0,
        [REPORT_TYPES.MINOR_INJURY]: 0,
      },
      reportsCountStatus: {
        'total': 1,
        [REPORT_TYPES.ACCIDENT]: 1,
        [REPORT_TYPES.ILLNESS]: 1,
        [REPORT_TYPES.MINOR_INJURY]: 1,
      },
    },
    geometry: {
      coordinates: [area.coordinates.lng, area.coordinates.lat],
      type: 'Point',
    },
    id: `${area.site}/${area.name}`,
  } as GeoJSON.Feature<GeoJSON.Point>))
);

export const selectSiteOutlineFeatures = createSelector(
  [selectAllAreas, selectCurrentAreaId],
  (areas, current) => areas.filter((area: NewArea) => area.areaCoordinates !== undefined).map((area: NewArea) => ({
    type: 'Feature',
    properties: {
      id: `${area.site}/${area.name}-outline`,
      siteId: `${area.site}/${area.name}`,
      type: 'outline',
      current: current === `${area.site}/${area.name}` ? 1 : 0,
      reportsCount: {
        'total': 0,
        [REPORT_TYPES.ACCIDENT]: 0,
        [REPORT_TYPES.ILLNESS]: 0,
        [REPORT_TYPES.MINOR_INJURY]: 0,
      },
      reportsCountStatus: {
        'total': 1,
        [REPORT_TYPES.ACCIDENT]: 1,
        [REPORT_TYPES.ILLNESS]: 1,
        [REPORT_TYPES.MINOR_INJURY]: 1,
      },
    },
    geometry: {
      coordinates: [area.areaCoordinates],
      type: 'Polygon',
    },
    id: `${area.site}/${area.name}-outline`,
  } as GeoJSON.Feature<GeoJSON.Polygon>))
);