import React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import { Avatar, Box, Divider, IconButton, ListItem, ListItemText, Stack, Typography } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import { AppIcon } from '../../../../components/Elements';
import { RootState, useAppDispatch, useAppSelector } from '../../../../store';
import {
  NewSite,
  areaClicked,
  makeSelectAreasBySiteId,
  selectAllSites,
  selectCurrentSiteId,
  selectSites
} from '../../../locations/locationsSlice';
import { makeSelectInjuryReportsByAreaId, selectAllInjuryReports } from '../../../injuries/store/injuryReportsSlice';
import { REPORT_TYPES } from '../../../injuries/constants';
import { ReportTypeIconNames } from '../../../injuries/constants/mappedProps';
import { InjuryReportCase } from '../../../injuries/types';
import { APP_COLORS } from '../../../../config';
import { createTheme, ThemeProvider, styled, ThemeOptions, alpha } from '@mui/material/styles';

export const testBasesMappedNames: any = {
  Grodem: 'Grødem skole',
  'Kongsvinger ungdomsskole': 'Kongsvinger ungdomsskole',
  Marikollen: 'Marikollen skole'
};

type BaseLocationListItemProps = {
  site: NewSite;
  onClick: () => void;
};

const BaseLocationListItem = (props: BaseLocationListItemProps) => {
  const { site, onClick } = props;

  const currentSiteId = useAppSelector(selectCurrentSiteId);

  const selectAreasBySiteId = React.useMemo(makeSelectAreasBySiteId, []);
  const areas = useAppSelector((state: RootState) => selectAreasBySiteId(state, site.id));

  const areaIds = areas.map((area: any) => area.id);

  const injuryReports = useAppSelector(selectAllInjuryReports);

  const filteredInjuryReports = React.useMemo(() => {
    return injuryReports.filter((report: any) => {
      return areaIds.indexOf(report.sitePath) !== -1;
    });
  }, [site, injuryReports, areaIds]);

  const accidentsCount = filteredInjuryReports.filter((report: any) => report.type === REPORT_TYPES.ACCIDENT).length;
  const illnessCount = filteredInjuryReports.filter((report: any) => report.type === REPORT_TYPES.ILLNESS).length;
  const minorInjuryCount = filteredInjuryReports.filter(
    (report: any) => report.type === REPORT_TYPES.MINOR_INJURY
  ).length;

  return (
    <ListItem
      selected={site.id === currentSiteId}
      sx={{
        p: 2,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '&.Mui-selected': {
          backgroundColor: alpha(APP_COLORS.secondary, 0.12)
        }
      }}
      button
      onClick={onClick}
      /* secondaryAction={
        <IconButton
          edge="end"
        >
          <AppIcon iconName='more_horiz' />
        </IconButton>
      } */
    >
      {/* <ListItemIcon
      
      >
        <AppIcon iconName={'school'} opticalSize={24} color={'text.secondary'} sx={{ ml: -0.5 }} />
      </ListItemIcon> */}
      <Stack direction="column" sx={{ flex: 1 }}>
        <Typography variant="h5" sx={{ lineHeight: '20px' }}>
          {site.name}
        </Typography>
        <Box
          sx={{
            mt: 0.25,
            color: 'text.secondary',
            typography: 'body2',
            display: 'flex',
            flexDirection: 'row',
            gap: 0.75,
            alignItems: 'center'
          }}
        >
          {`School`}
          <Box
            sx={{
              color: 'text.disabled',
              fontSize: 'body2.fontSize'
            }}
          >
            •
          </Box>
          {site.address}
        </Box>
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          sx={{
            mt: 1
          }}
        >
          <Typography variant="body1" sx={{ flexGrow: 1, color: 'text.primary' }}>
            {`${areas.length} sites`}
          </Typography>
          <Stack direction="row" alignItems="center" spacing={0.75}>
            <AppIcon
              iconName={ReportTypeIconNames[REPORT_TYPES.ACCIDENT]}
              opticalSize={18}
              color={'textVariant'}
              sx={{ ml: -0.5 }}
            />
            <Typography variant="body2" color="text.secondary">
              {accidentsCount}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={0.75}>
            <AppIcon
              iconName={ReportTypeIconNames[REPORT_TYPES.ILLNESS]}
              opticalSize={18}
              color={'textVariant'}
              sx={{ ml: -0.5 }}
            />
            <Typography variant="body2" color="text.secondary">
              {illnessCount}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={0.75}>
            <AppIcon
              iconName={ReportTypeIconNames[REPORT_TYPES.MINOR_INJURY]}
              opticalSize={18}
              color={'textVariant'}
              sx={{ ml: -0.5 }}
            />
            <Typography variant="body2" color="text.secondary">
              {minorInjuryCount}
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      {/* <Avatar
        sx={{
          height: 48,
          width: 48,
          borderRadius: 1,
          bgcolor: 'backgroundVariant',
          color: 'text.primary',
        }}
      >
        <AppIcon iconName={'school'} />
      </Avatar> */}
      {/* <ListItemText 
        primary={baseLocation.name}
        secondary={`${sites.length} sites`}
      /> */}
    </ListItem>
  );
};

export const BaseLocationList = () => {
  const sites = useAppSelector(selectAllSites);
  const siteEntities = useAppSelector(selectSites);

  const dispatch = useAppDispatch();

  const handleClick = (siteId: string) => {
    dispatch(areaClicked(siteId));
  };

  return (
    <List
      disablePadding
      sx={{
        px: 0,
        py: 1
      }}
    >
      {sites.map((site, i: number) => (
        <React.Fragment key={site.id}>
          <BaseLocationListItem site={site} onClick={() => handleClick(site.id)} />
          {i < sites.length - 1 && <Divider />}
        </React.Fragment>
      ))}
    </List>
  );
};
