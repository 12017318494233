import React from 'react';
import Paper from '@mui/material/Paper';
import { useAppSelector } from '../../../../store';
import { selectInjuryUnitEntities } from '../../store/injuryReportsSlice';
import { DescriptionList, DescriptionListItem } from '../../../../components/DescriptionList/DescriptionsGrid';
import { useTranslation } from 'react-i18next';

type UnitPaperProps = {
  unitId: string;
};

export const UnitPaper = (props: UnitPaperProps) => {
  const { unitId } = props;
  const { t } = useTranslation();

  const units = useAppSelector(selectInjuryUnitEntities);

  return (
    <>
      {units[unitId] && (
        <Paper
          variant="outlined"
          sx={{
            p: 3,
            bgcolor: 'background.default'
          }}
        >
          <DescriptionList>
          {units[unitId].unit_name && <DescriptionListItem field={t('wif.injury.schoolPlaceTab.unit.unitName')} value={units[unitId]?.unit_name || 'None'} />}
            <DescriptionListItem field={t('wif.injury.schoolPlaceTab.unit.organizationNo')} value={units[unitId]?.organization_number || 'None'} />
            <DescriptionListItem field={t('wif.injury.schoolPlaceTab.unit.address')} value={units[unitId]?.address || 'Undefined'} />
            <DescriptionListItem field={t('wif.injury.schoolPlaceTab.unit.municipality')} value={units[unitId].municipality} />
            <DescriptionListItem field={t('wif.injury.schoolPlaceTab.unit.phone')} value={units[unitId].phone} />
            {units[unitId].insurance_company_name && <DescriptionListItem field={t('wif.injury.schoolPlaceTab.unit.insuranceCompanyName')} value={units[unitId].insurance_company_name} />}
            
          </DescriptionList>
        </Paper>
      )}
    </>
  );
};
