import React from 'react';
import Collapse from '@mui/material/Collapse';
import _ from 'lodash';
import Typography from '@mui/material/Typography';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  Switch
} from '@mui/material';
import { InferType } from 'yup';
import { AppIcon } from '../../../../../components/Elements';
import { WithChildren } from '../../../../../types';
import { useCurrentInjuryReport } from '../../../routes/CurrentInjuryReportContext';
import { mappedInputLabels } from '../../../constants/mappedInputLabels';
import { AllSectionDetails, REPORT_CATEGORY, REPORT_TYPES, SCHEMA_BY_SECTION_NAME, TKB_INJURY } from '../../../constants';
import { AppIconButton } from '../../../../../components/Elements/AppIconButton';
import { InjuryForm } from '../../InjuryForm/InjuryForm';
import { SectionControlsMapper } from '../../InjuryForm/SectionControlsMapper';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import {
  selectEmployeeInjuryTagEntities,
  selectInjuryUnitEntities,
  selectStudentInjuryTagEntities,
  updateInjuryReport
} from '../../../store/injuryReportsSlice';
import { useTranslation } from 'react-i18next';
import { getTableValidSection, getTranslationKeyForSectionFieldLabel } from '../../../helpers/dataMapping';
import { snackAlertOpened } from '../../../../global/controlsSlice';
import { selectDepartmentEntities } from '../../../../departments/departmentsSlice';
import { STUDENT_SECTION_NAMES } from '../../../constants/student';
import { EMPLOYEE_SECTION_NAMES } from '../../../constants/employee';
import { nokCurrencyFormatter } from '../../../../../utils/format';
import { FORM_OF_SALARY_LABELS, WORK_TIME_ARRANGEMENTS_LABELS } from '../../FormSections/employee';
import { selectNonconformityReportEntities } from '../../../../nonconformity/nonconformitySlice';
import { v4 as uuidv4 } from 'uuid';
import { LoadingButtonContainer } from '../../../../../components/Elements/LoadingButtonContainer';
import { selectEmployees } from '../../../../employees/employeesSlice';

type InjurySectionListItemProps = {
  field: string;
  value: any;
  sectionName: string;
};

export const InjurySectionListItem = (props: InjurySectionListItemProps) => {
  const { field: key, value, sectionName } = props;
  const { t } = useTranslation();

  const employeeTags = useAppSelector(selectEmployeeInjuryTagEntities);
  const studentTags = useAppSelector(selectStudentInjuryTagEntities);
  const departments = useAppSelector(selectDepartmentEntities);
  const units = useAppSelector(selectInjuryUnitEntities);
  const ncReports = useAppSelector(selectNonconformityReportEntities);
  const users = useAppSelector(selectEmployees);

  const studentAccidentMapped = {
    '67604946-cf85-11ed-afa1-0242ac120002': t(`${TKB_INJURY}.accidentTypesOccurredTags.teachingLocation`),
    '751d94a8-cf85-11ed-afa1-0242ac120002': t(`${TKB_INJURY}.accidentTypesOccurredTags.duringTeaching`),
    '7c6476d2-cf85-11ed-afa1-0242ac120002': t(`${TKB_INJURY}.accidentTypesOccurredTags.sfo`),
    '8369f466-cf85-11ed-afa1-0242ac120002': t(`${TKB_INJURY}.accidentTypesOccurredTags.onTheWay`),
    '1d4b309e-cf87-11ed-afa1-0242ac120002': t(`${TKB_INJURY}.accidentTypesOccurredTags.other`)
  };

  const yesNoOptions = {
    option1: t(`${TKB_INJURY}.yesNoOptions.yes`),
    option2: t(`${TKB_INJURY}.yesNoOptions.no`)
  };

  const mappedSectionValueFuncs = React.useMemo(() => {
    return {
      employeeId: (val: string) => users[val]?.name || val,
      damageNature: (val: any[]) =>
        val
          .map((v) => `${v.id ? v.id : v} ` + t(`${TKB_INJURY}.employee.typeTab.natureOfDamage.${v.id ? v.id : v}`))
          .join('; '),
      damagedBodyPart: (val: any[]) =>
        val
          .map((v) => `${v.id ? v.id : v} ` + t(`${TKB_INJURY}.employee.typeTab.damagedBodyPart.${v.id ? v.id : v}`))
          .join('; '),
      accidentType: (val: any[]) =>
        val
          .map((v) => `${v.id ? v.id : v} ` + t(`${TKB_INJURY}.employee.typeTab.typeOfAccident.${v.id ? v.id : v}`))
          .join('; '),
      background: (val: any[]) =>
        val
          .map((v) => `${v.id ? v.id : v} ` + t(`${TKB_INJURY}.employee.typeTab.background.${v.id ? v.id : v}`))
          .join('; '),
      damageMode: (val: any[]) =>
        val
          .map((v) => `${v.id ? v.id : v} ` + t(`${TKB_INJURY}.employee.typeTab.damageMode.${v.id ? v.id : v}`))
          .join('; '),
      typeOfWorkplace: (val: any[]) =>
        val
          .map((v) => `${v.id ? v.id : v} ` + t(`${TKB_INJURY}.employee.typeTab.typeOfWorkplace.${v.id ? v.id : v}`))
          .join('; '),
      deviation: (val: any[]) =>
        val
          .map((v) => `${v.id ? v.id : v} ` + t(`${TKB_INJURY}.employee.typeTab.deviation.${v.id ? v.id : v}`))
          .join('; '),
      assumedAbsence: (val: any[]) =>
        val
          .map((v) => `${v.id ? v.id : v} ` + t(`${TKB_INJURY}.employee.typeTab.assumedAbsence.${v.id ? v.id : v}`))
          .join('; '),
      ncReportId: (val: string) => ncReports[val]?.name || val,
      durationOfImpact: (val: string[]) => val.join(', '),
      assumedAnnualIncomeForClaimYear: (val: number) => `kr ${nokCurrencyFormatter.format(val)}`,
      employmentPercentage: (val: number) => `${val} %`,
      competenceLevel: (val: string) => t(`${TKB_INJURY}.employee.employeePersonTab.competenceLevel.${val}`),
      natureOfEmployment: (val: string) => t(`${TKB_INJURY}.employee.employeePersonTab.natureOfWorking.${val}`),
      voluntaryOccupationalInjuryInsurance: (val: string) => yesNoOptions[val],
      workTimeArrangements: (val: string) => t(`${TKB_INJURY}.employee.accidentTab.arrangements.${val}`),
      accidentOccured: (val: string) =>
        sectionName === EMPLOYEE_SECTION_NAMES.ACCIDENT
          ? t(`${TKB_INJURY}.employee.accidentTab.accidentOccurred.${val}`)
          : sectionName === STUDENT_SECTION_NAMES.ACCIDENT
            ? studentAccidentMapped[val]
            : '',
      accidentHappenedAtMainAddress: (val: string) => t(`wif.injury.yesNoOptions.${val}`),
      formOfSalary: (val: any) => t(`${TKB_INJURY}.employee.accidentTab.formOfSalary.${val}`),
      atNormalWorkplace: (val: any) => t(`${TKB_INJURY}.yesNoOptions.${val}`),
      insideOutside: (val: any) => t(`${TKB_INJURY}.employee.accidentTab.whereAccidentOccurred.${val}`),
      onTheWayToWork: (val: any) => t(`${TKB_INJURY}.yesNoOptions.${val}`),
      onTheWayBetweenWork: (val: any) => t(`${TKB_INJURY}.yesNoOptions.${val}`),
      deathFromAccident: (val: any) => t(`${TKB_INJURY}.yesNoOptions.${val}`),
      necessaryTraining: (val: any) => t(`${TKB_INJURY}.yesNoOptions.${val}`),
      reportedToLIA: (val: any) => t(`${TKB_INJURY}.yesNoOptions.${val}`),
      departmentId: (val: string) => departments[val]?.name || val,
      unitId: (val: string) => units[val]?.unit_name || val,
      typesOfInjury: (val: any[]) =>
        sectionName === EMPLOYEE_SECTION_NAMES.MINOR_INJURY
          ? (val &&
            val
              .map((v: any) => employeeTags && employeeTags[v] && employeeTags[v]?.name)
              .map((v: any) =>
                t(`${TKB_INJURY}.typesOfInjury.${v}`).includes(TKB_INJURY) ? v : t(`${TKB_INJURY}.typesOfInjury.${v}`)
              )
              .join(', ')) ||
          ''
          : sectionName === STUDENT_SECTION_NAMES.MINOR_INJURY
            ? (val &&
              val
                .map((v: any) => studentTags && studentTags[v] && studentTags[v]?.name)
                .map((v: any) =>
                  t(`${TKB_INJURY}.typesOfInjury.${v}`).includes(TKB_INJURY) ? v : t(`${TKB_INJURY}.typesOfInjury.${v}`)
                )
                .join(', ')) ||
            ''
            : (val && val.join(', ')) || ''
    };
  }, [t, users]);

  return (
    <ListItem
      disableGutters
      sx={{
        pt: 0.5,
        pb: 0.25
      }}
    >
      <ListItemText
        sx={{
          '& .MuiListItemText-primary': {
            fontWeight: 500,
            color: 'text.primary',
            fontSize: 'body2.fontSize'
          },
          '& .MuiListItemText-secondary': {
            color: value ? 'text.secondary' : 'text.disabled',
            fontSize: 'body2.fontSize'
          }
        }}
        primary={t(getTranslationKeyForSectionFieldLabel(sectionName, key))}
        secondary={mappedSectionValueFuncs[key] ? mappedSectionValueFuncs[key](value) : value ? value : 'Undefined'}
      />
    </ListItem>
  );
};

type AppExpandableSectionProps = WithChildren<{
  title?: string;
  editing?: {
    handleClickEdit: () => void;
    active: boolean;
  };
  shouldExpand?: boolean;
  disabled?: boolean;
}>;

export const AppExpandableSection = (props: AppExpandableSectionProps) => {
  const { title = 'Title', editing, shouldExpand = true, children, disabled = false } = props;
  const { t } = useTranslation();

  const [expanded, setExpanded] = React.useState(shouldExpand);

  React.useEffect(() => {
    setExpanded(shouldExpand);
  }, [shouldExpand]);

  return (
    <Stack
      direction="column"
      sx={{
        overflow: 'visible'
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        gap={1}
        sx={{
          height: '32px',
          overflow: 'visible'
        }}
      >
        <Typography variant="h5" color={'text.primary'}>
          {title}
        </Typography>
        <Divider sx={{ flex: 1, mr: 1.75, ml: 1.75, display: { xs: 'none', sm: 'block' } }} />
        {editing && !disabled && (
          <Button
            variant="text"
            color="primary"
            size="small"
            sx={{ mr: 1.75 }}
            startIcon={<AppIcon iconName="edit" />}
            disabled={disabled}
            onClick={editing.handleClickEdit}
          >
            {t(`${TKB_INJURY}.edit`)}
          </Button>
        )}
        {(editing === undefined || !editing?.active) && (
          <IconButton
            onClick={() => setExpanded(!expanded)}
            size="small"
            edge="end"
            sx={{
              p: 0.25
            }}
          >
            <AppIcon
              iconName={expanded && !disabled ? 'expand_less' : 'expand_more'}
              color={'text.secondary'}
              opticalSize={24}
              sx={{ mb: '-1px' }}
            />
          </IconButton>
        )}
      </Stack>
      <Collapse
        in={!disabled && (editing?.active || expanded)}
        timeout="auto"
        unmountOnExit
        sx={{
          py: 0.25
        }}
      >
        {children}
      </Collapse>
    </Stack>
  );
};

type ApprovableSectionStatus = 'editing' | 'approved' | 'unapproved';

type ApprovableSectionProps = WithChildren<{
  title?: string;
  status?: ApprovableSectionStatus;
  shouldExpand?: boolean;
  onClickEdit?: () => void;
  onClickEditOff?: () => void;
  onChangeApprove?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}>;

export const ApprovableSection = (props: ApprovableSectionProps) => {
  const {
    title = 'Title',
    status,
    onClickEdit,
    onClickEditOff,
    onChangeApprove,
    shouldExpand = false,
    disabled = false,
    children
  } = props;
  const { t } = useTranslation();

  const editing = status === 'editing';
  const approved = status === 'approved';

  const [expanded, setExpanded] = React.useState(shouldExpand);

  React.useEffect(() => {
    setExpanded(shouldExpand);
  }, [shouldExpand]);

  React.useEffect(() => {
    if (editing) {
      setExpanded(true);
    }
  }, [editing]);

  React.useEffect(() => {
    if (disabled) {
      setExpanded(false);
    }
  }, [disabled]);

  const handleClickExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <Stack
      direction="column"
      sx={{
        overflow: 'visible'
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        gap={1}
        sx={{
          height: '32px',
          overflow: 'visible'
        }}
      >
        {/* <AppIcon
          weight={500}
          iconName={editing ? 'pending' : approved ? 'task_alt' : 'radio_button_unchecked'}
          color={editing ? 'primary.main' : approved ? 'success.main' : 'textVariant'}
          sx={{
            ml: -0.5,
          }}
        /> */}
        <Typography variant="h5" color={editing || approved ? 'text.primary' : 'text.secondary'}>
          {title}
        </Typography>
        <Divider sx={{ flex: 1, mr: 1.75, ml: 1.75, display: { xs: 'none', sm: 'block' } }} />

        {!editing && !disabled && !approved && (
          <Button
            variant="text"
            color="primary"
            size="small"
            sx={{ mr: 1.75 }}
            startIcon={<AppIcon iconName="edit" />}
            disabled={disabled}
            onClick={onClickEdit}
          >
            {t(`${TKB_INJURY}.edit`)}
          </Button>
        )}
        {editing && (
          <Button
            color="primary"
            variant="text"
            size="small"
            startIcon={<AppIcon iconName="edit_off" />}
            onClick={onClickEditOff}
          >
            {t(`${TKB_INJURY}.edit`)}
          </Button>
        )}
        {!editing && (
          <FormControlLabel
            componentsProps={{ typography: { variant: 'body1', sx: { mr: -0.5 } } }}
            control={
              <Switch
                checked={approved}
                onChange={onChangeApprove}
                size="small"
                sx={{ ...(approved && { ml: 1.5 }) }}
              />
            }
            label={t(`${TKB_INJURY}.approved`)}
            disabled={disabled}
          />
        )}
        {!editing && (
          <IconButton
            onClick={handleClickExpand}
            size="small"
            edge="end"
            sx={{
              p: 0.25,
              opacity: disabled ? 0.6 : 1
            }}
          >
            <AppIcon
              iconName={expanded && !disabled ? 'expand_less' : 'expand_more'}
              color={'text.secondary'}
              opticalSize={24}
              sx={{ mb: '-1px' }}
            />
          </IconButton>
        )}
      </Stack>
      <Collapse
        in={(editing || expanded) && !disabled}
        timeout="auto"
        unmountOnExit
        sx={{
          py: 0.25
        }}
      >
        {children}
      </Collapse>
    </Stack>
  );
};

const SectionForm = (props: { sectionName: string; handleSubmitData: any; formId: string; loading?: boolean }) => {
  const { sectionName, handleSubmitData, formId, loading = false } = props;
  const injuryReport = useCurrentInjuryReport();

  const FormSchema = SCHEMA_BY_SECTION_NAME[sectionName];

  const transformedData = React.useMemo(() => { }, [injuryReport.sections[sectionName]]);

  return (
    <InjuryForm<InferType<typeof FormSchema>, typeof FormSchema>
      schema={FormSchema}
      id={formId}
      onSubmit={props.handleSubmitData}
      data={injuryReport.sections[sectionName]}
      loading={loading}
    >
      {(methods) => <SectionControlsMapper methods={methods} sectionName={sectionName} />}
    </InjuryForm>
  );
};

type ApprovableSectionListProps = {
  approvedSections: string[];
  handleApproveSection: (sectionName: string, event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const ApprovableSectionList = (props: ApprovableSectionListProps) => {
  const { t } = useTranslation();
  const injuryReport = useCurrentInjuryReport();
  const dispatch = useAppDispatch();
  const departments = useAppSelector(selectDepartmentEntities);
  const units = useAppSelector(selectInjuryUnitEntities);
  const users = useAppSelector(selectEmployees);
  const employeeTags = useAppSelector(selectEmployeeInjuryTagEntities);
  const studentTags = useAppSelector(selectStudentInjuryTagEntities);
  const { approvedSections, handleApproveSection } = props;

  const getTableValidBody = (sectionName: string, body: any) => {
    let newBody = Object.assign({}, body);

    if (sectionName === STUDENT_SECTION_NAMES.SCHOOL) {
      const departmentId = body['school_place_information.department'];
      const unitId = body['school_place_information.unit'];
      if (departmentId) {
        newBody['school_place_information.department'] = Object.assign({}, departments[departmentId]);
      }
      if (unitId) {
        newBody['school_place_information.unit'] = Object.assign({}, units[unitId]);
      }
    }

    if (sectionName === EMPLOYEE_SECTION_NAMES.EMPLOYER) {
      const departmentId = body['employer_information.department'];
      const unitId = body['employer_information.unit'];
      if (departmentId) {
        newBody['employer_information.department'] = Object.assign({}, departments[departmentId]);
      }
      if (unitId) {
        newBody['employer_information.unit'] = Object.assign({}, units[unitId]);
      }
    }

    if (sectionName === EMPLOYEE_SECTION_NAMES.MINOR_INJURY) {
      const departmentId = body['minor_injury_information.department'];
      // const unitId = body['minor_injury_information.unit'];
      const typesOfInjury = body['minor_injury_information.typesOfInjury'];
      if (typesOfInjury) {
        newBody['minor_injury_information.typesOfInjury'] = typesOfInjury.map((type: string) => {
          return Object.assign({}, employeeTags[type]);
        });
      }
      if (departmentId) {
        newBody['minor_injury_information.department'] = Object.assign({}, departments[departmentId]);
      }
      // if (unitId) {
      //   newBody['minor_injury_information.unit'] = Object.assign({}, units[unitId]);
      // }
    }

    if (sectionName === STUDENT_SECTION_NAMES.MINOR_INJURY) {
      const departmentId = body['minor_injury_information.department'];
      // const unitId = body['minor_injury_information.unit'];
      const typesOfInjury = body['minor_injury_information.typesOfInjury'];
      if (typesOfInjury) {
        newBody['minor_injury_information.typesOfInjury'] = typesOfInjury.map((type: string) => {
          return Object.assign({}, studentTags[type]);
        });
      }
      if (departmentId) {
        newBody['minor_injury_information.department'] = Object.assign({}, departments[departmentId]);
      }
      // if (unitId) {
      //   newBody['minor_injury_information.unit'] = Object.assign({}, units[unitId]);
      // }
    }

    if (sectionName === STUDENT_SECTION_NAMES.ACCIDENT) {
      const studentAccidentMapped = {
        '67604946-cf85-11ed-afa1-0242ac120002': t(`${TKB_INJURY}.accidentTypesOccurredTags.teachingLocation`),
        '751d94a8-cf85-11ed-afa1-0242ac120002': t(`${TKB_INJURY}.accidentTypesOccurredTags.duringTeaching`),
        '7c6476d2-cf85-11ed-afa1-0242ac120002': t(`${TKB_INJURY}.accidentTypesOccurredTags.sfo`),
        '8369f466-cf85-11ed-afa1-0242ac120002': t(`${TKB_INJURY}.accidentTypesOccurredTags.onTheWay`),
        '1d4b309e-cf87-11ed-afa1-0242ac120002': t(`${TKB_INJURY}.accidentTypesOccurredTags.other`)
      };

      const accidentOccuredId = body['accident_information.accidentOccured'];
      if (accidentOccuredId) {
        newBody['accident_information.accidentOccured'] = Object.assign(
          {},
          { id: accidentOccuredId, name: studentAccidentMapped[accidentOccuredId] }
        );
      }
    }

    if (sectionName === EMPLOYEE_SECTION_NAMES.EMPLOYEE) {
      if (body['person_information.registeredEmployees']) {
        const employeeId = body['person_information.registeredEmployees'];
        const employee = (users && users[employeeId]) || {
          id: employeeId,
          name: 'Undefined'
        };

        newBody['person_information.registeredEmployees'] = employee;
      }
      const competenceLevelOptions = {
        option1: t(`${TKB_INJURY}.employee.employeePersonTab.competenceLevel.option1`),
        option2: t(`${TKB_INJURY}.employee.employeePersonTab.competenceLevel.option2`),
        option3: t(`${TKB_INJURY}.employee.employeePersonTab.competenceLevel.option3`),
        option4: t(`${TKB_INJURY}.employee.employeePersonTab.competenceLevel.option4`)
      };

      const natureOfWorkingOptions = {
        option1: t(`${TKB_INJURY}.employee.employeePersonTab.natureOfWorking.option1`),
        option2: t(`${TKB_INJURY}.employee.employeePersonTab.natureOfWorking.option2`),
        option3: t(`${TKB_INJURY}.employee.employeePersonTab.natureOfWorking.option3`)
      };

      const yesNoOptions = {
        option1: t(`${TKB_INJURY}.yesNoOptions.yes`),
        option2: t(`${TKB_INJURY}.yesNoOptions.no`)
      };

      const competenceLevelId = body['person_information.competenceLevel'];
      if (competenceLevelId) {
        newBody['person_information.competenceLevel'] = Object.assign(
          {},
          { id: competenceLevelId, name: competenceLevelOptions[competenceLevelId] }
        );
      }
      const natureOfEmploymentId = body['person_information.natureOfWorking'];
      if (natureOfEmploymentId) {
        newBody['person_information.natureOfWorking'] = Object.assign(
          {},
          { id: natureOfEmploymentId, name: natureOfWorkingOptions[natureOfEmploymentId] }
        );
      }
      const voluntaryOccupationalInjuryInsuranceId = body['person_information.voluntaryInsurance'];
      if (voluntaryOccupationalInjuryInsuranceId) {
        newBody['person_information.voluntaryInsurance'] = Object.assign(
          {},
          { id: voluntaryOccupationalInjuryInsuranceId, name: yesNoOptions[voluntaryOccupationalInjuryInsuranceId] }
        );
      }
    }

    if (sectionName === EMPLOYEE_SECTION_NAMES.ACCIDENT) {
      const arrangementsOptions = {
        option1: t(`${TKB_INJURY}.employee.accidentTab.arrangements.option1`),
        option2: t(`${TKB_INJURY}.employee.accidentTab.arrangements.option2`)
      };

      const accidentOccurredOptions = {
        option1: t(`${TKB_INJURY}.employee.accidentTab.accidentOccurred.option1`),
        option2: t(`${TKB_INJURY}.employee.accidentTab.accidentOccurred.option2`),
        option3: t(`${TKB_INJURY}.employee.accidentTab.accidentOccurred.option3`)
      };

      const formOfSalaryOptions = {
        option1: t(`${TKB_INJURY}.employee.accidentTab.formOfSalary.option1`),
        option2: t(`${TKB_INJURY}.employee.accidentTab.formOfSalary.option2`)
      };

      const whereAccidentOccurredOptions = {
        option1: t(`${TKB_INJURY}.employee.accidentTab.whereAccidentOccurred.option1`),
        option2: t(`${TKB_INJURY}.employee.accidentTab.whereAccidentOccurred.option2`)
      };

      const yesNoOptions = {
        yes: t(`${TKB_INJURY}.yesNoOptions.yes`),
        no: t(`${TKB_INJURY}.yesNoOptions.no`)
      };

      const arrangementOptionId = body['accident_information.arrangements'];
      if (arrangementOptionId) {
        newBody['accident_information.arrangements'] = Object.assign(
          {},
          { id: arrangementOptionId, name: arrangementsOptions[arrangementOptionId] }
        );
      }
      const accidentOccuredId = body['accident_information.accidentOccured'];
      if (accidentOccuredId) {
        newBody['accident_information.accidentOccured'] = Object.assign(
          {},
          { id: accidentOccuredId, name: accidentOccurredOptions[accidentOccuredId] }
        );
      }
      const formOfSalaryId = body['accident_information.formOfSalary'];
      if (formOfSalaryId) {
        newBody['accident_information.formOfSalary'] = Object.assign(
          {},
          { id: formOfSalaryId, name: formOfSalaryOptions[formOfSalaryId] }
        );
      }
      const workplaceId = body['accident_information.workplace'];
      if (workplaceId) {
        newBody['accident_information.workplace'] = Object.assign(
          {},
          { id: workplaceId, name: yesNoOptions[workplaceId] }
        );
      }
      const whereAccidentOccurredId = body['accident_information.whereAccidentOccurred'];
      if (whereAccidentOccurredId) {
        newBody['accident_information.whereAccidentOccurred'] = Object.assign(
          {},
          { id: whereAccidentOccurredId, name: whereAccidentOccurredOptions[whereAccidentOccurredId] }
        );
      }
      const onTheWayId = body['accident_information.onTheWay'];
      if (onTheWayId) {
        newBody['accident_information.onTheWay'] = Object.assign(
          {},
          { id: onTheWayId, name: yesNoOptions[onTheWayId] }
        );
      }
      const betweenWorkplacesId = body['accident_information.betweenWorkplaces'];
      if (betweenWorkplacesId) {
        newBody['accident_information.betweenWorkplaces'] = Object.assign(
          {},
          { id: betweenWorkplacesId, name: yesNoOptions[betweenWorkplacesId] }
        );
      }
      const deathFromInjuryId = body['accident_information.deathFromInjury'];
      if (deathFromInjuryId) {
        newBody['accident_information.deathFromInjury'] = Object.assign(
          {},
          { id: deathFromInjuryId, name: yesNoOptions[deathFromInjuryId] }
        );
      }
    }

    if (sectionName === STUDENT_SECTION_NAMES.ILLNESS || sectionName === EMPLOYEE_SECTION_NAMES.ILLNESS) {
      const durationImpact = body['illness_information.durationImpact'];
      if (durationImpact && durationImpact.length) {
        let durationImpactValues = [];
        _.each(durationImpact, (impactDate) => {
          durationImpactValues = [
            ...durationImpactValues,
            {
              id: uuidv4(),
              date: impactDate
            }
          ];
        });
        newBody['illness_information.durationImpact'] = durationImpactValues;
      }
    }

    return newBody;
  };

  const sectionNames = React.useMemo(() => {
    const sectionKeys = Object.keys(injuryReport.sections);
    let orderedSectionNames: string[] = [];

    const category = injuryReport.category;
    const reportType = injuryReport.type;

    const pushIfIncludes = (sectionName: string) => {
      if (sectionKeys.includes(sectionName)) {
        orderedSectionNames.push(sectionName);
      }
    };

    if (category === REPORT_CATEGORY.EMPLOYEE) {
      if (reportType === REPORT_TYPES.MINOR_INJURY) {
        pushIfIncludes(EMPLOYEE_SECTION_NAMES.MINOR_INJURY);
      } else if (reportType === REPORT_TYPES.ACCIDENT || reportType === REPORT_TYPES.ILLNESS) {
        pushIfIncludes(EMPLOYEE_SECTION_NAMES.EMPLOYEE);
        pushIfIncludes(EMPLOYEE_SECTION_NAMES.EMPLOYER);
        if (reportType === REPORT_TYPES.ACCIDENT) {
          pushIfIncludes(EMPLOYEE_SECTION_NAMES.ACCIDENT);
          pushIfIncludes(EMPLOYEE_SECTION_NAMES.EMPLOYEE_ACCIDENT_TYPES);
        } else if (reportType === REPORT_TYPES.ILLNESS) {
          pushIfIncludes(EMPLOYEE_SECTION_NAMES.ILLNESS);
        }
        pushIfIncludes(EMPLOYEE_SECTION_NAMES.FURTHER_INFORMATION);
      }
    } else if (category === REPORT_CATEGORY.STUDENT) {
      if (reportType === REPORT_TYPES.MINOR_INJURY) {
        pushIfIncludes(STUDENT_SECTION_NAMES.MINOR_INJURY);
      } else if (reportType === REPORT_TYPES.ACCIDENT || reportType === REPORT_TYPES.ILLNESS) {
        pushIfIncludes(STUDENT_SECTION_NAMES.STUDENT);
        pushIfIncludes(STUDENT_SECTION_NAMES.SCHOOL);
        if (reportType === REPORT_TYPES.ACCIDENT) {
          pushIfIncludes(STUDENT_SECTION_NAMES.ACCIDENT);
        } else if (reportType === REPORT_TYPES.ILLNESS) {
          pushIfIncludes(STUDENT_SECTION_NAMES.ILLNESS);
        }
        pushIfIncludes(STUDENT_SECTION_NAMES.FURTHER_INFORMATION);
      }
    }
    pushIfIncludes('summary');
    return orderedSectionNames;
  }, [injuryReport]);

  const getSectionKeys = (sectionName: string) => {
    return Object.keys(injuryReport.sections[sectionName]);
  };

  const [hasClickedEdit, setHasClickedEdit] = React.useState(false);
  const [editingSection, setEditingSection] = React.useState<any | undefined>();
  const [loading, setLoading] = React.useState(false);

  const handleClickEditSection = (sectionName: string) => {
    setEditingSection(sectionName);
    if (hasClickedEdit === false) {
      setHasClickedEdit(true);
    }
  };

  const handleClickEditOffSection = () => {
    setEditingSection(undefined);
  };

  const getShouldExpand = React.useCallback(
    (sectionName: string, index: number) => {
      if (!hasClickedEdit) {
        for (let i = 0; i < index; i++) {
          const curSectionName = sectionNames[i];
          if (!approvedSections.includes(curSectionName)) {
            return false;
          }
        }
        return !approvedSections.includes(sectionName);
      }
      return false;
    },
    [approvedSections]
  );

  const handleSaveUpdatedSection = async (sectionName: string, updatedSection: any) => {
    let filteredUpdates = {};
    for (let key in updatedSection) {
      const newValue = updatedSection[key];
      const originalValue = injuryReport.sections[sectionName][key];
      if (Array.isArray(originalValue)) {
        if (JSON.stringify(newValue) != JSON.stringify(originalValue)) {
          filteredUpdates[key] = updatedSection[key];
        }
      } else if (newValue != originalValue) {
        filteredUpdates[key] = updatedSection[key];
      }
    }
    let body = getTableValidSection(sectionName, filteredUpdates);
    body = getTableValidBody(sectionName, body);

    // Don't send request if there are no updated to form
    if (Object.keys(body).length === 0) {
      dispatch(snackAlertOpened({ message: t('wif.injury.snackMessages.reportUpdated.success'), severity: 'success' }));
      setEditingSection(undefined);
      return;
    }
    const req = {
      reportId: injuryReport.id,
      body
    };
    setLoading(true);
    const resultAction = await dispatch(updateInjuryReport(req));
    if (updateInjuryReport.fulfilled.match(resultAction)) {
      dispatch(snackAlertOpened({ message: t('wif.injury.snackMessages.reportUpdated.success'), severity: 'success' }));
      setEditingSection(undefined);
      setLoading(false);
    } else {
      if (resultAction.payload) {
        dispatch(snackAlertOpened({ message: t('wif.injury.snackMessages.genericError'), severity: 'error' }));
        setLoading(false);
      } else {
        dispatch(snackAlertOpened({ message: t('wif.injury.snackMessages.errorWithMessage', { message: resultAction.error }), severity: 'error' }));
        setLoading(false);
      }
    }
  };

  return (
    <Stack
      direction="column"
      gap={3}
      my={0.5}
      sx={{
        width: '100%',
        minWidth: 0
      }}
    >
      {sectionNames.map((sectionName, index) => (
        <ApprovableSection
          key={index}
          title={t(`wif.injury.sectionNames.${sectionName}`)}
          status={
            editingSection && editingSection === sectionName
              ? 'editing'
              : approvedSections.includes(sectionName)
                ? 'approved'
                : 'unapproved'
          }
          shouldExpand={getShouldExpand(sectionName, index)}
          onClickEdit={() => handleClickEditSection(sectionName)}
          onClickEditOff={handleClickEditOffSection}
          onChangeApprove={(event) => handleApproveSection(sectionName, event)}
          disabled={editingSection && editingSection !== sectionName}
        >
          {editingSection && editingSection === sectionName ? (
            <Stack
              mt={2.5}
              mb={2}
              gap={2}
              direction="column"
              sx={{
                '& .FormSectionSubHeader': {
                  typography: 'h6',
                  color: 'text.secondary',
                  mt: 1
                }
              }}
            >
              <SectionForm
                handleSubmitData={(updatedSection: any) => {
                  handleSaveUpdatedSection(sectionName, updatedSection);
                }}
                sectionName={sectionName}
                formId={`${injuryReport.id}-${sectionName}`}
                loading={loading}
              />
              <Stack direction="row" alignItems="center" justifyContent="end" gap={1}>
                <Button
                  onClick={() => setEditingSection(undefined)}
                  disabled={loading}
                  color={loading ? 'primary' : 'plain'}
                /* variant='text' */
                >
                  {t('wif.injury.cancel')}
                </Button>
                <LoadingButtonContainer loading={loading}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={loading}
                    form={`${injuryReport.id}-${sectionName}`}
                  >
                    {t('wif.injury.save')}
                  </Button>
                </LoadingButtonContainer>
              </Stack>
            </Stack>
          ) : (
            <React.Fragment>
              <List disablePadding>
                {Object.entries(injuryReport.sections[sectionName]).map(([key, value]: any, i) => (
                  <React.Fragment key={`${sectionName}-${key}`}>
                    {/* {i > 0 && !SUBHEADER_ABOVE_FIELD[key] && (
                      <DashedDivider />
                    )} */}
                    {/* {SUBHEADER_ABOVE_FIELD[key] && (
                      <ListSubheader
                        disableSticky
                        sx={{
                          p: 0,
                          color: 'text.secondary',
                          pt: 1.5
                        }}
                      >
                        {SUBHEADER_ABOVE_FIELD[key]}
                      </ListSubheader> 
                    )} */}

                    <InjurySectionListItem
                      field={key}
                      value={
                        key.replace(/\s+/g, '').toLowerCase() === 'employeeid' ? (value.id ? value.id : value) : value
                      }
                      sectionName={sectionName}
                    />
                  </React.Fragment>
                ))}
              </List>
            </React.Fragment>
          )}
        </ApprovableSection>
      ))}
    </Stack>
  );
};

type BaseSectionListProps = {
  approvedSections?: string[];
  shouldAllowEdit?: boolean;
};

export const BaseSectionList = (props: BaseSectionListProps) => {
  const { approvedSections = [], shouldAllowEdit = true } = props;
  const { t } = useTranslation();
  const injuryReport = useCurrentInjuryReport();
  const dispatch = useAppDispatch();
  const departments = useAppSelector(selectDepartmentEntities);
  const units = useAppSelector(selectInjuryUnitEntities);

  const employeeTags = useAppSelector(selectEmployeeInjuryTagEntities);
  const studentTags = useAppSelector(selectStudentInjuryTagEntities);

  const [editingSection, setEditingSection] = React.useState<any | undefined>();

  const sectionNames = React.useMemo(() => {
    const sectionKeys = Object.keys(injuryReport.sections);
    let orderedSectionNames: string[] = [];

    const category = injuryReport.category;
    const reportType = injuryReport.type;

    const pushIfIncludes = (sectionName: string) => {
      if (sectionKeys.includes(sectionName)) {
        orderedSectionNames.push(sectionName);
      }
    };

    if (category === REPORT_CATEGORY.EMPLOYEE) {
      if (reportType === REPORT_TYPES.MINOR_INJURY) {
        pushIfIncludes(EMPLOYEE_SECTION_NAMES.MINOR_INJURY);
      } else if (reportType === REPORT_TYPES.ACCIDENT || reportType === REPORT_TYPES.ILLNESS) {
        pushIfIncludes(EMPLOYEE_SECTION_NAMES.EMPLOYEE);
        pushIfIncludes(EMPLOYEE_SECTION_NAMES.EMPLOYER);
        if (reportType === REPORT_TYPES.ACCIDENT) {
          pushIfIncludes(EMPLOYEE_SECTION_NAMES.ACCIDENT);
          pushIfIncludes(EMPLOYEE_SECTION_NAMES.EMPLOYEE_ACCIDENT_TYPES);
        } else if (reportType === REPORT_TYPES.ILLNESS) {
          pushIfIncludes(EMPLOYEE_SECTION_NAMES.ILLNESS);
        }
        pushIfIncludes(EMPLOYEE_SECTION_NAMES.FURTHER_INFORMATION);
      }
    } else if (category === REPORT_CATEGORY.STUDENT) {
      if (reportType === REPORT_TYPES.MINOR_INJURY) {
        pushIfIncludes(STUDENT_SECTION_NAMES.MINOR_INJURY);
      } else if (reportType === REPORT_TYPES.ACCIDENT || reportType === REPORT_TYPES.ILLNESS) {
        pushIfIncludes(STUDENT_SECTION_NAMES.STUDENT);
        pushIfIncludes(STUDENT_SECTION_NAMES.SCHOOL);
        if (reportType === REPORT_TYPES.ACCIDENT) {
          pushIfIncludes(STUDENT_SECTION_NAMES.ACCIDENT);
        } else if (reportType === REPORT_TYPES.ILLNESS) {
          pushIfIncludes(STUDENT_SECTION_NAMES.ILLNESS);
        }
        pushIfIncludes(STUDENT_SECTION_NAMES.FURTHER_INFORMATION);
      }
    }
    return orderedSectionNames;
  }, [injuryReport]);

  const getShouldExpand = React.useCallback(
    (sectionName: string, index: number) => {
      for (let i = 0; i < index; i++) {
        const curSectionName = sectionNames[i];
        if (!approvedSections.includes(curSectionName)) {
          return false;
        }
      }
      return !approvedSections.includes(sectionName);
    },
    [approvedSections]
  );

  const handleEditSection = (sectionName: string) => {
    if (editingSection) {
      if (editingSection === sectionName) {
        setEditingSection(undefined);
      }
    } else {
      setEditingSection(sectionName);
    }
  };

  const getTableValidBody = (sectionName: string, body: any) => {
    let newBody = Object.assign({}, body);

    if (sectionName === STUDENT_SECTION_NAMES.SCHOOL) {
      const departmentId = body['school_place_information.department'];
      const unitId = body['school_place_information.unit'];
      if (departmentId) {
        newBody['school_place_information.department'] = Object.assign({}, departments[departmentId]);
      }
      if (unitId) {
        newBody['school_place_information.unit'] = Object.assign({}, units[unitId]);
      }
    }

    if (sectionName === EMPLOYEE_SECTION_NAMES.EMPLOYER) {
      const departmentId = body['employer_information.department'];
      const unitId = body['employer_information.unit'];
      if (departmentId) {
        newBody['employer_information.department'] = Object.assign({}, departments[departmentId]);
      }
      if (unitId) {
        newBody['employer_information.unit'] = Object.assign({}, units[unitId]);
      }
    }

    if (sectionName === EMPLOYEE_SECTION_NAMES.MINOR_INJURY) {
      const departmentId = body['minor_injury_information.department'];
      // const unitId = body['minor_injury_information.unit'];
      const typesOfInjury = body['minor_injury_information.typesOfInjury'];
      if (typesOfInjury) {
        newBody['minor_injury_information.typesOfInjury'] = typesOfInjury.map((type: string) => {
          return Object.assign({}, employeeTags[type]);
        });
      }
      if (departmentId) {
        newBody['minor_injury_information.department'] = Object.assign({}, departments[departmentId]);
      }
      // if (unitId) {
      //   newBody['minor_injury_information.unit'] = Object.assign({}, units[unitId]);
      // }
    }

    if (sectionName === STUDENT_SECTION_NAMES.MINOR_INJURY) {
      const departmentId = body['minor_injury_information.department'];
      // const unitId = body['minor_injury_information.unit'];
      const typesOfInjury = body['minor_injury_information.typesOfInjury'];
      if (typesOfInjury) {
        newBody['minor_injury_information.typesOfInjury'] = typesOfInjury.map((type: string) => {
          return Object.assign({}, studentTags[type]);
        });
      }
      if (departmentId) {
        newBody['minor_injury_information.department'] = Object.assign({}, departments[departmentId]);
      }
      // if (unitId) {
      //   newBody['minor_injury_information.unit'] = Object.assign({}, units[unitId]);
      // }
    }

    if (sectionName === STUDENT_SECTION_NAMES.ACCIDENT) {
      const studentAccidentMapped = {
        '67604946-cf85-11ed-afa1-0242ac120002': t(`${TKB_INJURY}.accidentTypesOccurredTags.teachingLocation`),
        '751d94a8-cf85-11ed-afa1-0242ac120002': t(`${TKB_INJURY}.accidentTypesOccurredTags.duringTeaching`),
        '7c6476d2-cf85-11ed-afa1-0242ac120002': t(`${TKB_INJURY}.accidentTypesOccurredTags.sfo`),
        '8369f466-cf85-11ed-afa1-0242ac120002': t(`${TKB_INJURY}.accidentTypesOccurredTags.onTheWay`),
        '1d4b309e-cf87-11ed-afa1-0242ac120002': t(`${TKB_INJURY}.accidentTypesOccurredTags.other`)
      };

      const accidentOccuredId = body['accident_information.accidentOccured'];
      if (accidentOccuredId) {
        newBody['accident_information.accidentOccured'] = Object.assign(
          {},
          { id: accidentOccuredId, name: studentAccidentMapped[accidentOccuredId] }
        );
      }
    }

    if (sectionName === EMPLOYEE_SECTION_NAMES.EMPLOYEE) {
      const competenceLevelOptions = {
        option1: t(`${TKB_INJURY}.employee.employeePersonTab.competenceLevel.option1`),
        option2: t(`${TKB_INJURY}.employee.employeePersonTab.competenceLevel.option2`),
        option3: t(`${TKB_INJURY}.employee.employeePersonTab.competenceLevel.option3`),
        option4: t(`${TKB_INJURY}.employee.employeePersonTab.competenceLevel.option4`)
      };

      const natureOfWorkingOptions = {
        option1: t(`${TKB_INJURY}.employee.employeePersonTab.natureOfWorking.option1`),
        option2: t(`${TKB_INJURY}.employee.employeePersonTab.natureOfWorking.option2`),
        option3: t(`${TKB_INJURY}.employee.employeePersonTab.natureOfWorking.option3`)
      };

      const yesNoOptions = {
        option1: t(`${TKB_INJURY}.yesNoOptions.yes`),
        option2: t(`${TKB_INJURY}.yesNoOptions.no`)
      };

      const competenceLevelId = body['person_information.competenceLevel'];
      if (competenceLevelId) {
        newBody['person_information.competenceLevel'] = Object.assign(
          {},
          { id: competenceLevelId, name: competenceLevelOptions[competenceLevelId] }
        );
      }
      const natureOfEmploymentId = body['person_information.natureOfWorking'];
      if (natureOfEmploymentId) {
        newBody['person_information.natureOfWorking'] = Object.assign(
          {},
          { id: natureOfEmploymentId, name: natureOfWorkingOptions[natureOfEmploymentId] }
        );
      }
      const voluntaryOccupationalInjuryInsuranceId = body['person_information.voluntaryInsurance'];
      if (voluntaryOccupationalInjuryInsuranceId) {
        newBody['person_information.voluntaryInsurance'] = Object.assign(
          {},
          { id: voluntaryOccupationalInjuryInsuranceId, name: yesNoOptions[voluntaryOccupationalInjuryInsuranceId] }
        );
      }
    }

    if (sectionName === EMPLOYEE_SECTION_NAMES.ACCIDENT) {
      const arrangementsOptions = {
        option1: t(`${TKB_INJURY}.employee.accidentTab.arrangements.option1`),
        option2: t(`${TKB_INJURY}.employee.accidentTab.arrangements.option2`)
      };

      const accidentOccurredOptions = {
        option1: t(`${TKB_INJURY}.employee.accidentTab.accidentOccurred.option1`),
        option2: t(`${TKB_INJURY}.employee.accidentTab.accidentOccurred.option2`),
        option3: t(`${TKB_INJURY}.employee.accidentTab.accidentOccurred.option3`)
      };

      const formOfSalaryOptions = {
        option1: t(`${TKB_INJURY}.employee.accidentTab.formOfSalary.option1`),
        option2: t(`${TKB_INJURY}.employee.accidentTab.formOfSalary.option2`)
      };

      const whereAccidentOccurredOptions = {
        option1: t(`${TKB_INJURY}.employee.accidentTab.whereAccidentOccurred.option1`),
        option2: t(`${TKB_INJURY}.employee.accidentTab.whereAccidentOccurred.option2`)
      };

      const yesNoOptions = {
        yes: t(`${TKB_INJURY}.yesNoOptions.yes`),
        no: t(`${TKB_INJURY}.yesNoOptions.no`)
      };

      const arrangementOptionId = body['accident_information.arrangements'];
      if (arrangementOptionId) {
        newBody['accident_information.arrangements'] = Object.assign(
          {},
          { id: arrangementOptionId, name: arrangementsOptions[arrangementOptionId] }
        );
      }
      const accidentOccuredId = body['accident_information.accidentOccured'];
      if (accidentOccuredId) {
        newBody['accident_information.accidentOccured'] = Object.assign(
          {},
          { id: accidentOccuredId, name: accidentOccurredOptions[accidentOccuredId] }
        );
      }
      const formOfSalaryId = body['accident_information.formOfSalary'];
      if (formOfSalaryId) {
        newBody['accident_information.formOfSalary'] = Object.assign(
          {},
          { id: formOfSalaryId, name: formOfSalaryOptions[formOfSalaryId] }
        );
      }
      const workplaceId = body['accident_information.workplace'];
      if (workplaceId) {
        newBody['accident_information.workplace'] = Object.assign(
          {},
          { id: workplaceId, name: yesNoOptions[workplaceId] }
        );
      }
      const whereAccidentOccurredId = body['accident_information.whereAccidentOccurred'];
      if (whereAccidentOccurredId) {
        newBody['accident_information.whereAccidentOccurred'] = Object.assign(
          {},
          { id: whereAccidentOccurredId, name: whereAccidentOccurredOptions[whereAccidentOccurredId] }
        );
      }
      const onTheWayId = body['accident_information.onTheWay'];
      if (onTheWayId) {
        newBody['accident_information.onTheWay'] = Object.assign(
          {},
          { id: onTheWayId, name: yesNoOptions[onTheWayId] }
        );
      }
      const betweenWorkplacesId = body['accident_information.betweenWorkplaces'];
      if (betweenWorkplacesId) {
        newBody['accident_information.betweenWorkplaces'] = Object.assign(
          {},
          { id: betweenWorkplacesId, name: yesNoOptions[betweenWorkplacesId] }
        );
      }
      const deathFromInjuryId = body['accident_information.deathFromInjury'];
      if (deathFromInjuryId) {
        newBody['accident_information.deathFromInjury'] = Object.assign(
          {},
          { id: deathFromInjuryId, name: yesNoOptions[deathFromInjuryId] }
        );
      }
    }

    if (sectionName === STUDENT_SECTION_NAMES.ILLNESS || sectionName === EMPLOYEE_SECTION_NAMES.ILLNESS) {
      const durationImpact = body['illness_information.durationImpact'];
      if (durationImpact && durationImpact.length) {
        let durationImpactValues = [];
        _.each(durationImpact, (impactDate) => {
          durationImpactValues = [
            ...durationImpactValues,
            {
              id: uuidv4(),
              date: impactDate
            }
          ];
        });
        newBody['illness_information.durationImpact'] = durationImpactValues;
      }
    }

    return newBody;
  };

  const handleSaveUpdatedSection = async (sectionName: string, updatedSection: any) => {
    let filteredUpdates = {};
    for (let key in updatedSection) {
      if (injuryReport.sections[sectionName][key] !== updatedSection[key]) {
        filteredUpdates[key] = updatedSection[key];
      }
    }
    let body = getTableValidSection(sectionName, filteredUpdates);
    body = getTableValidBody(sectionName, body);
    const req = {
      reportId: injuryReport.id,
      body
    };
    const resultAction = await dispatch(updateInjuryReport(req));
    if (updateInjuryReport.fulfilled.match(resultAction)) {
      dispatch(snackAlertOpened({ message: t('wif.injury.snackMessages.reportUpdated.success'), severity: 'success' }));
      setEditingSection(undefined);
    } else {
      if (resultAction.payload) {
        dispatch(snackAlertOpened({ message: t('wif.injury.snackMessages.genericError'), severity: 'error' }));
      } else {
        dispatch(snackAlertOpened({ message: t('wif.injury.snackMessages.errorWithMessage', { message: resultAction.error }), severity: 'error' }));
      }
    }
  };

  return (
    <Stack
      direction="column"
      gap={3}
      my={0.5}
      sx={{
        width: '100%',
        minWidth: 0
      }}
    >
      {sectionNames.map((sectionName, index) => (
        <AppExpandableSection
          shouldExpand={getShouldExpand(sectionName, index)}
          title={t(`wif.injury.sectionNames.${sectionName}`)}
          editing={
            approvedSections && !approvedSections.includes(sectionName) && shouldAllowEdit
              ? {
                active: editingSection === sectionName,
                handleClickEdit: () => handleEditSection(sectionName)
              }
              : undefined
          }
          disabled={editingSection && editingSection !== sectionName}
        >
          {editingSection && editingSection === sectionName ? (
            <Stack
              mt={2.5}
              mb={2}
              gap={2}
              direction="column"
              sx={{
                '& .FormSectionSubHeader': {
                  typography: 'h6',
                  color: 'text.secondary',
                  mt: 1
                }
              }}
            >
              <SectionForm
                handleSubmitData={(updatedSection: any) => {
                  handleSaveUpdatedSection(sectionName, updatedSection);
                }}
                sectionName={sectionName}
                formId={`${injuryReport.id}-${sectionName}`}
              />
              <Stack direction="row" alignItems="center" justifyContent="end" gap={1}>
                <Button onClick={() => setEditingSection(undefined)}>{t('wif.injury.cancel')}</Button>
                <Button variant="contained" color="primary" type="submit" form={`${injuryReport.id}-${sectionName}`}>
                  {t('wif.injury.save')}
                </Button>
              </Stack>
            </Stack>
          ) : (
            <List disablePadding>
              {Object.entries(injuryReport.sections[sectionName]).map(([key, value]: any, i) => (
                <React.Fragment key={`${sectionName}-${key}`}>
                  {/* {i > 0 && !SUBHEADER_ABOVE_FIELD[key] && (
                      <DashedDivider />
                    )} */}
                  {/* {SUBHEADER_ABOVE_FIELD[key] && (
                      <ListSubheader
                        disableSticky
                        sx={{
                          p: 0,
                          color: 'text.secondary',
                          pt: 1.5
                        }}
                      >
                        {SUBHEADER_ABOVE_FIELD[key]}
                      </ListSubheader> 
                    )} */}

                  <InjurySectionListItem
                    field={key}
                    value={
                      key.replace(/\s+/g, '').toLowerCase() === 'employeeid' ? (value.id ? value.id : value) : value
                    }
                    sectionName={sectionName}
                  />
                </React.Fragment>
              ))}
            </List>
          )}
        </AppExpandableSection>
      ))}
    </Stack>
  );
};
