import React, { useEffect } from 'react'
import * as yup from 'yup';
import { IAction } from '../../../actions/actionTypes';
import { Box, Button, Dialog, DialogActions, DialogContent, Stack, Typography } from '@mui/material';
import { AppDialogTitle } from '../../../../components/Display/AppDialogTitle';
import FormCheckbox from '../../../../components/FormInputs/FormCheckbox';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButtonContainer } from '../../../../components/Elements/LoadingButtonContainer';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { RootState } from '../../../../store/store';
import { UpdateInjuryReportRequest, makeSelectInjuryReportById, updateInjuryReport, uploadGeneralReport } from '../../store/injuryReportsSlice';
import { CASE_STATUS } from '../../constants';
import { AsyncThunkAction, Dispatch, AnyAction } from '@reduxjs/toolkit';
import { snackAlertOpened } from '../../../global/controlsSlice';
import { EMPLOYEE, STUDENT } from '../../constants/reportCategories';
import { MINOR_INJURY, ACCIDENT } from '../../constants/reportTypes';
import { GeneralReportblobUrl } from '../../components/InjuryDocument/InjuryDocumentDialog';
import { useTranslation } from 'react-i18next';

export const ArchiveConfirmationSchema = yup.object().shape({
    generalForm: yup.boolean().notRequired(),
    navForm: yup.boolean().notRequired(),
    labourInspectionAuthForm: yup.boolean().notRequired()
});

export type IArchiveConfirmation = yup.InferType<typeof ArchiveConfirmationSchema>;

interface ArchiveConfirmationDialogProps {
    open?: boolean;
    onClose: () => void;
}

interface ArchiveOptions {
    generalForm: Date;
    navForm: Date;
    labourInspectionAuthForm: Date;
}

export default function ArchiveConfirmation(props: ArchiveConfirmationDialogProps) {

    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState(false);
    const [isAnyCheckboxSelected, setAnyCheckboxSelected] = React.useState(false);
    const params = useParams();
    const { onClose, open = false } = props;
    const { handleSubmit, control, watch } = useForm<IArchiveConfirmation>({
        resolver: yupResolver(ArchiveConfirmationSchema)
    });
    const injuryReportId = params.injuryReportId || '';
    const selectInjuryReportById = React.useMemo(makeSelectInjuryReportById, []);
    const injuryReport = useAppSelector((state: RootState) =>
        selectInjuryReportById(state, injuryReportId)
    );

    const mapInjuryLabels = {
        generalForm: t('wif.injury.generalForm'),
        navForm: t('wif.injury.navForm'),
        labourInspectionAuthForm: t('wif.injury.laborForm')
    }

    const handleNotArchiveOnClick = async () => {
        const req = {
            reportId: injuryReportId,
            body: {
                status: CASE_STATUS.CLOSED
            }
        };
        setLoading(true);
        const resultAction = await dispatch(updateInjuryReport(req));
        if (updateInjuryReport.fulfilled.match(resultAction)) {
            dispatch(snackAlertOpened({ message: `Report completed!`, severity: 'success' }));
            setLoading(false);
        } else {
            if (resultAction.payload) {
                dispatch(snackAlertOpened({ message: `Error: ${JSON.stringify(resultAction.payload)}`, severity: 'error' }));
                setLoading(false);
            } else {
                dispatch(snackAlertOpened({ message: `Error: ${resultAction.error}`, severity: 'error' }));
                setLoading(false);
            }
        }
        onClose();
    }

    //when implement genaral report remove this method and uncomment below one
    const showArchiveOptions = () => {
        if (injuryReport.type === MINOR_INJURY) {
            return ["generalForm"]
        }
        switch (injuryReport.category) {
            case STUDENT:
                return ["generalForm", "navForm"]
            case EMPLOYEE:
                if (injuryReport.type === ACCIDENT) {
                    return ["generalForm", "navForm", "labourInspectionAuthForm"]
                }
                return ["generalForm", "navForm"]
            default:
                return []
        }
    }

    //const showArchiveOptions = () => {
    //    if (injuryReport.type === MINOR_INJURY) {
    //        return ["generalForm"]
    //    }
    //    switch (injuryReport.category) {
    //        case STUDENT:
    //            return ["generalForm", "navForm"]
    //        case EMPLOYEE:
    //            return ["generalForm", "navForm", "labourInspectionAuthForm"]
    //        default:
    //            return []
    //    }
    //}

    const watchCheckboxes = watch(["generalForm", "navForm", "labourInspectionAuthForm"]);

    useEffect(() => {
        const anySelected = Object.values(watchCheckboxes).some(val => val);
        setAnyCheckboxSelected(anySelected);
    }, [watchCheckboxes]);

    function isMyUnionType(value) {
        return value === "generalForm" || value === "navForm" || value == "labourInspectionAuthForm";
      }

    function blobToBase64(blob) {
        return new Promise((resolve, _) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve((reader.result as string).split(',')[1]);
            reader.readAsDataURL(blob);
        });
    }
    const onSubmit: SubmitHandler<IArchiveConfirmation> = async (data) => {
        const req = {
            reportId: injuryReportId,
            body: {
                status: CASE_STATUS.CLOSED,
                archive: {
                    generalForm: data.generalForm ? new Date() : null,
                    navForm: data.navForm ? new Date() : null,
                    labourInspectionAuthForm: data.labourInspectionAuthForm ? new Date() : null
                }
            }
        };
        setLoading(true);
        if (data.generalForm) {
            const blobUrl = GeneralReportblobUrl;
            const response = await fetch(blobUrl);
            const blobData = await response.blob();

            const base64Object =await blobToBase64(blobData);
            if (base64Object) {
                const req = {
                    body: {
                        content: base64Object,
                        reportId: injuryReportId
                    }
                };
                await uploadGeneralReport(req);
            }
        }
        const resultAction = await dispatch(updateInjuryReport(req));
        if (updateInjuryReport.fulfilled.match(resultAction)) {
            dispatch(snackAlertOpened({ message: `Report completed!`, severity: 'success' }));
            setLoading(false);
        } else {
            if (resultAction.payload) {
                dispatch(snackAlertOpened({ message: `Error: ${JSON.stringify(resultAction.payload)}`, severity: 'error' }));
                setLoading(false);
            } else {
                dispatch(snackAlertOpened({ message: `Error: ${resultAction.error}`, severity: 'error' }));
                setLoading(false);
            }
        }
        onClose();
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <AppDialogTitle id="archive-confirmation-dialog-title" onClose={onClose}>
                {t('wif.injury.ArchiveForms')}
            </AppDialogTitle>
            <DialogContent
                dividers
                sx={{
                    width: 600
                }}
            >
                <form id="archive-confirmation-form" onSubmit={handleSubmit(onSubmit)}>
                    <Stack direction="column" gap={0.05} width="100%">
                        <Typography variant={'body1'} color="textVariant" sx={{ pr: 1 }}>
                            {t('wif.injury.SelectWhatFormsYouWantToArchive')} 
                        </Typography>
                        {
                            showArchiveOptions().map((archiveOption) => (<FormCheckbox name={archiveOption as any} defaultValue={false} size={28} label={mapInjuryLabels[archiveOption]} control={control} />))
                        }
                        {/* <FormCheckbox name={'generalForm'} defaultValue={false} size={28} label='General form' control={control} />
                        <FormCheckbox name={'navForm'} defaultValue={false} size={28} label='Nav Form' control={control} />
                        <FormCheckbox name={'labourInspectionAuthForm'} defaultValue={false} size={28} label='Labor Inspection Authority form' control={control} /> */}
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions>
                <Button
                    variant='contained'
                    color='plain'
                    id='save-as-draft'
                    onClick={onClose}
                >
                    {t('wif.injury.Cancel')}
                </Button>
                <Box sx={{ flexGrow: 1 }} />
                <LoadingButtonContainer
                    loading={loading}
                >
                    <Button
                        variant='contained'
                        color='secondary'
                        disabled={loading}
                        onClick={() => handleNotArchiveOnClick()}
                    >
                        {t('wif.injury.DoNotArchive')}
                    </Button>
                </LoadingButtonContainer>
                <LoadingButtonContainer
                    loading={loading}
                >
                    <Button
                        type='submit'
                        variant='contained'
                        color='secondary'
                        disabled={loading || !isAnyCheckboxSelected}
                        form='archive-confirmation-form'
                        id='save'
                    >
                        {t('wif.injury.Archive')}
                    </Button>
                </LoadingButtonContainer>
            </DialogActions>
        </Dialog>
    )
}

