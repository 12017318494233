import { Dictionary } from "@reduxjs/toolkit";
import { AppEmployee } from "../../employees/employeesSlice";
import { CASE_STATUS, REPORT_CATEGORY, REPORT_TYPES } from "../constants";
import { EMPLOYEE_SECTION_NAMES as empSections } from "../constants/employee";
import { STUDENT_SECTION_NAMES as studSections } from "../constants/student";
import { STUDENT_SECTION_NAMES } from "../constants/student";
import { InjuryReportCase } from "../types";

const getSectionWithUnitAndDepartmentName = (injuryReport: InjuryReportCase) => {
  let sectionWithUnitAndDepartmentName = '';
  let departmentId = '';

  if (injuryReport.category === REPORT_CATEGORY.STUDENT) {
    if (injuryReport.type === REPORT_TYPES.MINOR_INJURY) {
      sectionWithUnitAndDepartmentName = studSections.MINOR_INJURY;
    } else {
      sectionWithUnitAndDepartmentName = studSections.SCHOOL;
    }
  } else if (injuryReport.category === REPORT_CATEGORY.EMPLOYEE) {
    if (injuryReport.type === REPORT_TYPES.MINOR_INJURY) {
      sectionWithUnitAndDepartmentName = empSections.MINOR_INJURY;
    } else {
      sectionWithUnitAndDepartmentName = empSections.EMPLOYER;
    }
  }
  /*  if (injuryReport?.sections[sectionWithUnitAndDepartmentName]?.unitId) {
    unitId = injuryReport.sections[sectionWithUnitAndDepartmentName].unitId;
  } */
  /* if (injuryReport?.sections[sectionWithUnitAndDepartmentName]?.departmentId) {
    departmentId = injuryReport.sections[sectionWithUnitAndDepartmentName].departmentId as string;
  } */

  return sectionWithUnitAndDepartmentName;
};

export const getInjuryReportDepartmentId = (injuryReport: InjuryReportCase) => {
  const containingSection = getSectionWithUnitAndDepartmentName(injuryReport);
  if (injuryReport?.sections[containingSection]?.departmentId) {
    return injuryReport.sections[containingSection].departmentId as string;
  }
  return undefined;
}

export const getInjuryReportUnitId = (injuryReport: InjuryReportCase) => {
  const containingSection = getSectionWithUnitAndDepartmentName(injuryReport);
  if (injuryReport?.sections[containingSection]?.unitId) {
    return injuryReport.sections[containingSection].unitId as string;
  }
  return undefined;
}

export const getInjuryReportSectionNames = (injuryReport: InjuryReportCase) => {
  const category = injuryReport.category;
  const type = injuryReport.type;

  if (category === 'employee') {
    if (type ===  'minorInjury') {
      return [
        empSections.MINOR_INJURY
      ]
    } else if (type === 'accident') {
      return [
        empSections.EMPLOYEE,
        empSections.EMPLOYER,
        empSections.ACCIDENT,
        empSections.EMPLOYEE_ACCIDENT_TYPES,
        empSections.FURTHER_INFORMATION
      ];
    } else if (type === 'illness') {
      return [
        empSections.EMPLOYEE,
        empSections.EMPLOYER,
        empSections.ILLNESS,
        empSections.FURTHER_INFORMATION
      ];
    }
  } else if (category === 'student') {
    if (type === 'minorInjury') {
      return [
        studSections.MINOR_INJURY
      ];
    } else if (type === 'accident') {
      return [
        studSections.STUDENT,
        studSections.SCHOOL,
        studSections.ACCIDENT,
        studSections.FURTHER_INFORMATION
      ];
    } else if (type === 'illness') {
      return [
        studSections.STUDENT,
        studSections.SCHOOL,
        studSections.ILLNESS,
        studSections.FURTHER_INFORMATION
      ];
    }
  }
  return [];
}

export const getTranslatedInjuryReportName = (report: InjuryReportCase, t: any) => {
  const translatedReportType = t(`wif.injury.reportType.${report.type}`);
  return `${translatedReportType}: ${report.name}`;
}
