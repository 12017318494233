import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { tempI18nResources } from './translations';

i18n
  .use(initReactI18next)
  .init({
    resources: tempI18nResources,
    lng: 'nb',
    debug: true,
    react: {
      useSuspense: false,
    },  
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });


export default i18n;