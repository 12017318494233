import { Button, Paper, Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import { useTheme } from '@mui/material/styles';
import { ChartContainer } from '../../../../components/Charts/ChartContainer';
import { RootState, useAppDispatch, useAppSelector } from '../../../../store';
import { CASE_STATUS, REPORT_CATEGORY, REPORT_TYPES, TKB_INJURY } from '../../constants';
import { InjuryReportsByMonthChart } from './InjuryReportsByMonthChart';
import React from 'react';
import { APP_COLORS } from '../../../../config';
import { BOX_SHADOWS } from '../../../../config/theme/boxShadows';
import { InjuryReportsByMonthBars } from './InjuryReportBars';
import { getCustomIbmCategorical } from '../../../../config/colors';
import { MostCommonValuesBar } from './InjuryReportTopValuesBar';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  makeSelectAccidentTypeCountByModule,
  makeSelectAssumedAbsenceCountByModule,
  makeSelectBackgroundCountByModule,
  makeSelectDamagedBodyPartCountByModule,
  makeSelectDamageModeCountByModule,
  makeSelectDamageNatureCountByModule,
  makeSelectDeviationCountByModule,
  makeSelectInjuryReportsByModule,
  makeSelectTypeOfWorkplaceCountByModule,
  selectAccidentTypeCount,
  selectAllInjuryReports,
  selectDamagedBodyPartCount,
  selectDamageNatureCount,
  selectDepartmentCountStatistics,
  selectInjuryUnitEntities,
  selectUnitCountStatistics
} from '../../store/injuryReportsSlice';
import { SDoughnut } from '../../../statistics/components/SDoughnut';
import { SIX_VAR, THREE_VAR } from '../../../../config/colors/dataVisPalettes';
import { useTranslation } from 'react-i18next';
import { InjuryModuleName } from '../../store/injuryControlsSlice';
import { AppIcon } from '../../../../components/Elements';
import _, { filter } from 'lodash';
import { selectDepartmentEntities } from '../../../departments/departmentsSlice';
import { EMPLOYEE_SECTION_NAMES } from '../../constants/employee';
import { STUDENT_SECTION_NAMES } from '../../constants/student';

type SingleCountItemProps = {
  count: number;
  title: string;
  sx?: any;
}

const SingleCountItem = ({ count, title, sx }: SingleCountItemProps) => {
  return (
    <Paper
      variant='outlined'
      sx={{ ...sx, pl: 3, border: 'none', bgcolor: 'transparent' }}
    >
      <Stack
        direction='column'

      >
        <Typography variant='h3' >
          {count}
        </Typography>
        <Typography variant='body2'>{title}</Typography>
      </Stack>
    </Paper>
  )
}

type InjuryInsightsProps = {
  module?: InjuryModuleName;
}

export const Insights = (props: InjuryInsightsProps) => {
  const { module = 'all' } = props;

  const [timePeriod, setTimePeriod] = React.useState('6M'); // 7D 1M 6M 1Y
  

  


  const selectInjuryReportsByModule = React.useMemo(makeSelectInjuryReportsByModule, [module]);
  const departments = useAppSelector(selectDepartmentEntities);
  const units = useAppSelector(selectInjuryUnitEntities);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const lg = useMediaQuery('(min-width:1200px)', { noSsr: true })
  

  /* const unitCountStatistics = useAppSelector(selectUnitCountStatistics);
  const departmentCountStatistics = useAppSelector(selectDepartmentCountStatistics); */
  const injuryReports = useAppSelector((state: RootState) => 
    selectInjuryReportsByModule(state, module)
  );

  const filteredReports = React.useMemo(() => {
    let reports = [...injuryReports.slice()];
    if (module === 'student' || module === 'employee') {
      reports = reports.filter((injuryReport) => injuryReport.category === module);
    }
    if (timePeriod === '7D') {
      reports = reports.filter((injuryReport) => injuryReport.reportedTimestamp >= new Date().getTime() - 7 * 24 * 60 * 60 * 1000);
    } else if (timePeriod === '1M') {
      reports = reports.filter((injuryReport) => injuryReport.reportedTimestamp >= new Date().getTime() - 30 * 24 * 60 * 60 * 1000);
    } else if (timePeriod === '6M') {
      reports = reports.filter((injuryReport) => injuryReport.reportedTimestamp >= new Date().getTime() - 6 * 30 * 24 * 60 * 60 * 1000);
    } else if (timePeriod === '1Y') {
      reports = reports.filter((injuryReport) => injuryReport.reportedTimestamp >= new Date().getTime() - 365 * 24 * 60 * 60 * 1000);
    }
    return reports;
  }, [timePeriod, injuryReports]);

  const selectDepartmentCountStatistics = () => {
    let reports = [...filteredReports.slice()];
    let count: any = {};
    let registered: string[] = [];
    for (let report of reports) {
      if (report.type === REPORT_TYPES.ACCIDENT || report.type === REPORT_TYPES.ILLNESS) {
        if (report.category === REPORT_CATEGORY.EMPLOYEE) {
          const departmentId = report.sections[EMPLOYEE_SECTION_NAMES.EMPLOYER]?.departmentId;
          if (departmentId) {
            if (!registered.includes(departmentId)) {
              registered.push(departmentId);
              count[departmentId] = 1;
            } else {
              count[departmentId]++;
            }
          }
        }
        if (report.category === REPORT_CATEGORY.STUDENT) {
          const departmentId = report.sections[STUDENT_SECTION_NAMES.SCHOOL]?.departmentId;
          if (departmentId) {
            if (!registered.includes(departmentId)) {
              registered.push(departmentId);
              count[departmentId] = 1;
            } else {
              count[departmentId]++;
            }
          }
        }
      }
    }

    return registered.map((registeredVal) => ({ value: departments[registeredVal]?.name || registeredVal, count: count[registeredVal] }));
  }

  const selectUnitCountStatistics = () => {
    let reports = [...filteredReports.slice()];
    let count: any = {};
    let registered: string[] = [];
    for (let report of reports) {
      if (report.type === REPORT_TYPES.ACCIDENT || report.type === REPORT_TYPES.ILLNESS) {
        if (report.category === REPORT_CATEGORY.EMPLOYEE) {
          const unitId = report.sections[EMPLOYEE_SECTION_NAMES.EMPLOYER]?.unitId;
        }
        if (report.category === REPORT_CATEGORY.STUDENT) {
          const unitId = report.sections[STUDENT_SECTION_NAMES.SCHOOL]?.unitId;
          if (unitId) {
            if (!registered.includes(unitId)) {
              registered.push(unitId);
              count[unitId] = 1;
            } else {
              count[unitId]++;
            }
          }
        }
      }
    }

    return registered
    .filter(registeredVal => units[registeredVal])
    .map((registeredVal) => ({ value: units[registeredVal]?.unit_name, count: count[registeredVal] }));
  }

  const selectTypeCount = (keyOfType: string) => {
    let reports = [...filteredReports.slice()];
    let count: any = {};
    let registered: string[] = [];
    for (let report of reports) {
      if (report.type === REPORT_TYPES.ACCIDENT) {
        if (report.category === REPORT_CATEGORY.EMPLOYEE) {
          const typeInfo = _.get(report, `sections.employeeTypes.${keyOfType}`, null);
          if (typeInfo) {
            for (let value of typeInfo) {
              if (!registered.includes(value)) {
                registered.push(value);
                count[value] = 1;
              } else {
                count[value]++;
              }
            }
          }
        } else if (report.category === REPORT_CATEGORY.STUDENT) {
          const typeInfo = _.get(report, `sections.studentAccident.${keyOfType}`, null);
          if (typeInfo) {
            for (let value of typeInfo) {
              if (!registered.includes(value)) {
                registered.push(value);
                count[value] = 1;
              } else {
                count[value]++;
              }
            }
          }
        }
      }
    }

    return registered.map((registeredVal) => ({ value: registeredVal, count: count[registeredVal] }));
  }

  const counts = React.useMemo(() => {
    let counts: any = {};
    counts.accidentTypeWithCount = selectTypeCount('typeOfAccident');
    counts.damagedBodyPartWithCount = selectTypeCount('damagedBodyPart');
    counts.backgroundWithCount = selectTypeCount('background');
    counts.damageNatureWithCount = selectTypeCount('natureOfDamage');
    counts.typeOfWorkplaceWithCount = selectTypeCount('typeOfWorkplace');
    counts.deviationWithCount = selectTypeCount('deviation');
    counts.assumedAbsenceWithCount = selectTypeCount('assumedAbsence');
    counts.damageModeWithCount = selectTypeCount('damageMode');
    counts.totalCount = filteredReports.length;
    counts.ongoingCount = filteredReports.filter((injuryReport) => injuryReport.status === CASE_STATUS.ONGOING).length;
    counts.closedCount = filteredReports.filter((injuryReport) => injuryReport.status === CASE_STATUS.CLOSED).length;
    counts.rejectedCount = filteredReports.filter((injuryReport) => injuryReport.status === CASE_STATUS.REJECTED).length;
    counts.accidentCount = filteredReports.filter((injuryReport) => injuryReport.type === REPORT_TYPES.ACCIDENT).length;
    counts.illnessCount = filteredReports.filter((injuryReport) => injuryReport.type === REPORT_TYPES.ILLNESS).length;
    counts.minorInjuryCount = filteredReports.filter((injuryReport) => injuryReport.type === REPORT_TYPES.MINOR_INJURY).length;
    counts.departmentCountStatistics = selectDepartmentCountStatistics();
    counts.unitCountStatistics = selectUnitCountStatistics();
    console.log(`counts: ${JSON.stringify(counts, null, 2)}`);
    return counts;
  }, [filteredReports, module]);

  
  const { 
    accidentTypeWithCount, 
    damageNatureWithCount, 
    damagedBodyPartWithCount, 
    backgroundWithCount, 
    deviationWithCount, 
    assumedAbsenceWithCount, 
    damageModeWithCount, 
    typeOfWorkplaceWithCount,
    totalCount,
    ongoingCount,
    closedCount,
    rejectedCount,
    accidentCount,
    illnessCount,
    minorInjuryCount,
    departmentCountStatistics,
    unitCountStatistics
  } = counts;

  const timePeriodMapping = {
    '7D': 'wif.injury.last7Days',
    '1M': 'wif.injury.lastMonth',
    '6M': 'wif.injury.last6Months',
    '1Y': 'wif.injury.lastYear',
  };

  const getNatureLabel = (s: any) => {
    return s + " " + t(`${TKB_INJURY}.employee.typeTab.natureOfDamage.${s}`);
  }

  const getDamagedBodyPartLabel = (s: any) => {
    return s + " " + t(`${TKB_INJURY}.employee.typeTab.damagedBodyPart.${s}`);
  }

  const getAccidentTypeLabel = React.useCallback((s: any) => {
    return s + " " + t(`${TKB_INJURY}.employee.typeTab.typeOfAccident.${s}`);
  }, [t]);

  const getTypeLabel = React.useCallback((s: any, keyOfType: string) => {
    return s + " " + t(`${TKB_INJURY}.employee.typeTab.${keyOfType}.${s}`);
  }, [t]);

  const getTranslatedMostCommonWithFieldLabel = React.useCallback((fieldKey: string) => {
    return t('wif.injury.stats.mostCommon') + ": " + t(`wif.injury.employee.typeTab.${fieldKey}.title`)
  }, [t]);

  const handleChangeTimePeriod = (event: React.MouseEvent<HTMLElement>, newTimePeriod: string | null) => {
    if (newTimePeriod !== null) {
      setTimePeriod(newTimePeriod);
    }
  };

  return (
    <Box
      sx={{
        flex: 1,
        py: {
          xs: 2.5,
        },
        px: {
          xs: 2.5,
          md: 3
        },
        bgcolor: (theme) => theme.palette.mode === 'light' ? APP_COLORS.light2 : theme.palette.background.paper,
        overflowY: 'auto',
      }}
      className='BackgroundVariant'
    >
      <Stack
        direction='row'
        spacing={1.5}
        alignItems='center'
      >
        <ToggleButtonGroup
          value={timePeriod}
          exclusive
          onChange={handleChangeTimePeriod}
          aria-label='View mode'
        >
          <ToggleButton value='7D' aria-label='1 DAY'>
            {t('wif.injury.stats.7D')}
          </ToggleButton>
          <ToggleButton value='1M' aria-label='1 DAY'>
            {t('wif.injury.stats.1M')}
          </ToggleButton>
          <ToggleButton value='6M' aria-label='1 DAY'>
            {t('wif.injury.stats.6M')}
          </ToggleButton>
          <ToggleButton value='1Y' aria-label='1 DAY'>
            {t('wif.injury.stats.1Y')}
          </ToggleButton>
          {/* <ToggleButton value='' aria-label='1 DAY'>
            <AppIcon iconName='date_range' />
          </ToggleButton> */}
        </ToggleButtonGroup>
        {/* <Button
          startIcon={<AppIcon iconName='tune' />}
        >
          Filters
        </Button> */}
      </Stack>
      
      <Paper
        sx={{
          borderRadius: 0,
          bgcolor: (theme) => theme.palette.mode === 'light' ? 'background.paper' : 'backgroundVariant',
          p: {
            xs: 2
          },
          py: 2.5,
          my: {
            xs: 2.5,
            md: 2
          },
        }}
      >
        <Grid
          container
          spacing={{
            xs: 1.5,
            md: 2
          }}
          rowSpacing={{
            xs: 2
          }}
          sx={{
            minHeight: 0,
            pb: {
              xs: 1,
              md: 0,
            }
          }}
        >
          <Grid
            xs={12}
            md={3}
          >
            <Paper
              sx={{
                bgcolor: theme.palette.mode === 'dark' ? APP_COLORS.dark3 : APP_COLORS.light3,
                boxShadow: 'none',
                borderRadius: 1,
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <Typography variant='h1' color='primary.main'>{totalCount}</Typography>
              <Typography variant='body1'>{t('wif.injury.stats.reportsTotal', { count: totalCount })}</Typography>
            </Paper>
          </Grid>
          <Grid
            container
            xs={12}
            md={9}
          >
            <Grid
              xs={6}
              md={4}
            >
              <SingleCountItem
                count={accidentCount}
                title={t('wif.injury.stats.accidentsReported', { count: accidentCount })}
              />
            </Grid>
            <Grid
              xs={6}
              md={4}
            >
              <SingleCountItem
                count={illnessCount}
                title={t('wif.injury.stats.illnessesReported', { count: illnessCount })}
              />
            </Grid>
            <Grid
              xs={6}
              md={4}
            >
              <SingleCountItem
                count={minorInjuryCount}
                title={t('wif.injury.stats.minorInjuriesReported', { count: minorInjuryCount })}
              />
            </Grid>
            <Grid
              xs={6}
              md={4}
            >
              <SingleCountItem
                count={ongoingCount}
                title={t('wif.injury.stats.ongoingCases', { count: ongoingCount })}
              />
            </Grid>
            <Grid
              xs={6}
              md={4}
            >
              <SingleCountItem
                count={closedCount}
                title={t('wif.injury.stats.closedCases', { count: closedCount })}
              />
            </Grid>
            <Grid
              xs={6}
              md={4}
            >
              <SingleCountItem
                count={rejectedCount}
                title={t('wif.injury.stats.rejectedCases', { count: rejectedCount })}
              />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <Grid
        container
        spacing={2}
        rowSpacing={2}
        sx={{
          minHeight: 0,
        }}
      >
        {module === 'all' && (
          <Grid xs={12} md={6} lg={4}>
            <ChartContainer
              /* size={lg ? 'large' : 'medium'} */
            >
              <SDoughnut
                title={t('wif.injury.stats.reportsByCategory')}
                subtitle={t(timePeriodMapping[timePeriod])}
                label={t('wif.injury.reports')}
                arcs={{
                  key: 'category',
                  values: [REPORT_CATEGORY.STUDENT, REPORT_CATEGORY.EMPLOYEE],
                  labels: [t('wif.injury.reportCategory.student'), t('wif.injury.reportCategory.employee')],
                  colors: getCustomIbmCategorical({ numberOfCategories: 2, palette: 1 })
                }}
                dataSource={filteredReports}
              />
            </ChartContainer>
          </Grid>
        )}
        
        <Grid xs={12} md={6} lg={module === 'all' ? 4 : 6}>
          <ChartContainer
           /*  size={lg ? 'large' : 'medium'} */
          >
            <SDoughnut
              title={t('wif.injury.stats.reportsByStatus')}
              subtitle={t(timePeriodMapping[timePeriod])}
              label={t('wif.injury.reports')}
              arcs={{
                key: 'status',
                labels: [t('wif.injury.statuses.ongoing'), t('wif.injury.statuses.rejected'), t('wif.injury.statuses.closed')],
                values: [CASE_STATUS.ONGOING, CASE_STATUS.REJECTED, CASE_STATUS.CLOSED],
                colors: [theme.palette.warning.main, theme.palette.error.main, theme.palette.success.main]
              }}
              dataSource={filteredReports}
            />
          </ChartContainer>
        </Grid>
        <Grid xs={12} md={6} lg={module === 'all' ? 4 : 6}>
          <ChartContainer
            /* size={lg ? 'large' : 'medium'} */
          >
            <SDoughnut
              title={t('wif.injury.stats.reportsByType')}
              subtitle={t(timePeriodMapping[timePeriod])}
              label={t('wif.injury.reports')}
              arcs={{
                key: 'type',
                labels: [t('wif.injury.reportType.accident'), t('wif.injury.reportType.minorInjury'), t('wif.injury.reportType.illness')],
                values: [REPORT_TYPES.ACCIDENT, REPORT_TYPES.MINOR_INJURY, REPORT_TYPES.ILLNESS],
                colors: getCustomIbmCategorical({ numberOfCategories: 3, palette: 2 })
              }}
              dataSource={filteredReports}
            />
          </ChartContainer>
        </Grid>
        {departmentCountStatistics && departmentCountStatistics.length > 1 && (
          <Grid xs={12} lg={6}>
            <MostCommonValuesBar
              title={t('wif.injury.stats.departmentsWithMostReports')}
              subtitle={t(timePeriodMapping[timePeriod])}
              color={theme.palette.primary.main}
              valuesWithCount={departmentCountStatistics}
            />
          </Grid>
        )}
        {unitCountStatistics && unitCountStatistics.length > 1 && (
          <Grid xs={12} lg={6}>
            <MostCommonValuesBar
              title={t('wif.injury.stats.unitsWithMostReports')}
              subtitle={t(timePeriodMapping[timePeriod])}
              color={theme.palette.warning.main}
              valuesWithCount={unitCountStatistics}
            />
          </Grid>
        )}
        {module === 'all' && (
        <Grid xs={12} lg={6}>
          <ChartContainer
          >
            <InjuryReportsByMonthChart
              /* areas */
              title={t('wif.injury.stats.reportsByCategory')}
              subtitle={t(timePeriodMapping[timePeriod])}
              lines={{
                field: 'category',
                values: [REPORT_CATEGORY.STUDENT, REPORT_CATEGORY.EMPLOYEE],
                labels: [t('wif.injury.reportCategory.student'), t('wif.injury.reportCategory.employee')],
                colors: getCustomIbmCategorical({ numberOfCategories: 2, palette: 1 })
              }}
            />
            {/* <MTimeLineChart 
              timestampKey='reportedTimestamp'
              lines={{
                key: 'type',
                values: [REPORT_TYPES.ACCIDENT, REPORT_TYPES.ILLNESS, REPORT_TYPES.MINOR_INJURY],
                colors: getCustomIbmCategorical({ numberOfCategories: 3, palette: 1 })
              }}
              rawData={timeFilteredReports}
            /> */}
          </ChartContainer>

        </Grid>
        )}
        {module === 'all' && (
          <Grid xs={12} lg={6}>
            <ChartContainer
            >
              <InjuryReportsByMonthBars
                
                
                title={t('wif.injury.stats.reportsByCategory')}
                subtitle={t(timePeriodMapping[timePeriod])}
                bars={{
                  field: 'category',
                  values: [REPORT_CATEGORY.STUDENT, REPORT_CATEGORY.EMPLOYEE],
                  labels: [t('wif.injury.reportCategory.student'), t('wif.injury.reportCategory.employee')],
                  colors: getCustomIbmCategorical({ numberOfCategories: 2, palette: 1 })
                }}
              />
              {/* <MTimeLineChart 
                timestampKey='reportedTimestamp'
                lines={{
                  key: 'type',
                  values: [REPORT_TYPES.ACCIDENT, REPORT_TYPES.ILLNESS, REPORT_TYPES.MINOR_INJURY],
                  colors: getCustomIbmCategorical({ numberOfCategories: 3, palette: 1 })
                }}
                rawData={timeFilteredReports}
              /> */}
            </ChartContainer>
          </Grid>
        )}
        <Grid xs={12} lg={6}>
          <ChartContainer>
            <InjuryReportsByMonthChart
              title={t('wif.injury.stats.reportsByStatus')}
              subtitle={t(timePeriodMapping[timePeriod])}
              lines={{
                field: 'status',
                labels: [t('wif.injury.statuses.ongoing'), t('wif.injury.statuses.rejected'), t('wif.injury.statuses.closed')],
                values: [CASE_STATUS.ONGOING, CASE_STATUS.REJECTED, CASE_STATUS.CLOSED],
                colors: [theme.palette.warning.main, theme.palette.error.main, theme.palette.success.main]
              }}
            />
          </ChartContainer>
        </Grid>
        <Grid xs={12} lg={6}>
          <ChartContainer>
            <InjuryReportsByMonthBars
              
              
              title={t('wif.injury.stats.reportsByStatus')}
              subtitle={t(timePeriodMapping[timePeriod])}
              bars={{
                field: 'status',
                labels: [t('wif.injury.statuses.ongoing'), t('wif.injury.statuses.rejected'), t('wif.injury.statuses.closed')],
                values: [CASE_STATUS.ONGOING, CASE_STATUS.REJECTED, CASE_STATUS.CLOSED],
                colors: [theme.palette.warning.main, theme.palette.error.main, theme.palette.success.main]
              }}
            />
          </ChartContainer>
        </Grid>
        
        
        <Grid xs={12} lg={6}>
          <ChartContainer>
            <InjuryReportsByMonthChart
              /* areas */
              title={t('wif.injury.stats.reportsByType')}
              subtitle={t(timePeriodMapping[timePeriod])}
              lines={{
                field: 'type',
                values: [REPORT_TYPES.ACCIDENT, REPORT_TYPES.ILLNESS, REPORT_TYPES.MINOR_INJURY],
                labels: [t('wif.injury.reportType.accident'), t('wif.injury.reportType.minorInjury'), t('wif.injury.reportType.illness')],
                colors: getCustomIbmCategorical({ numberOfCategories: 3, palette: 1 })
              }}
            />
          </ChartContainer>

        </Grid>
        <Grid xs={12} lg={6}>
          <ChartContainer>
            <InjuryReportsByMonthBars
              
              
              title={t('wif.injury.stats.reportsByType')}
              subtitle={t(timePeriodMapping[timePeriod])}
              bars={{
                field: 'type',
                values: [REPORT_TYPES.ACCIDENT, REPORT_TYPES.ILLNESS, REPORT_TYPES.MINOR_INJURY],
                labels: [t('wif.injury.reportType.accident'), t('wif.injury.reportType.minorInjury'), t('wif.injury.reportType.illness')],
                colors: getCustomIbmCategorical({ numberOfCategories: 3, palette: 1 })
              }}
            />
          </ChartContainer>
        </Grid>
        
        
      </Grid>

      <Typography variant='h3' sx={{ mt: 3.5, mb: 2 }}>{t('wif.injury.accident')}</Typography>
      <Grid
        container
        spacing={2}
        rowSpacing={2}
        sx={{
          minHeight: 0,
        }}
      >
        {accidentTypeWithCount && accidentTypeWithCount.length > 1 && (
          <Grid xs={12} lg={6}>
            <MostCommonValuesBar
              getValueLabel={(value: string) => getAccidentTypeLabel(value)}
              title={getTranslatedMostCommonWithFieldLabel('typeOfAccident')}
              color={theme.palette.primary.main}
              valuesWithCount={accidentTypeWithCount}
            />
          </Grid>
        )}
        {backgroundWithCount && backgroundWithCount.length > 1 && (
          <Grid xs={12} lg={6}>
            <MostCommonValuesBar
              getValueLabel={(value: string) => getTypeLabel(value, 'background')}
              title={getTranslatedMostCommonWithFieldLabel('background')}
              valuesWithCount={backgroundWithCount}
              color={theme.palette.error.main}
              /* colors={SIX_VAR[2]} */
            />
          </Grid>
        )}
        {damageNatureWithCount && damageNatureWithCount.length > 1 && (
          <Grid xs={12} lg={6}>
              <MostCommonValuesBar
                getValueLabel={(value: string) => getNatureLabel(value)}
                title={getTranslatedMostCommonWithFieldLabel('natureOfDamage')}
                valuesWithCount={damageNatureWithCount}
                color={theme.palette.success.main}
              />
          </Grid>
        )}
        {damagedBodyPartWithCount && damagedBodyPartWithCount.length > 1 && (
          <Grid xs={12} lg={6}>
            <MostCommonValuesBar
              getValueLabel={(value: string) => getDamagedBodyPartLabel(value)}
              title={getTranslatedMostCommonWithFieldLabel('damagedBodyPart')}
              valuesWithCount={damagedBodyPartWithCount}
              color={theme.palette.warning.main}
              /* colors={SIX_VAR[2]} */
            />
          </Grid>
        )}
        {damageModeWithCount && damageModeWithCount.length > 1 && (
          <Grid xs={12} lg={6}>
            <MostCommonValuesBar
              getValueLabel={(value: string) => getTypeLabel(value, 'damageMode')}
              title={getTranslatedMostCommonWithFieldLabel('damageMode')}
              valuesWithCount={damageModeWithCount}
              color={theme.palette.primary.main}
              /* colors={SIX_VAR[2]} */
            />
          </Grid>
        )}
        {typeOfWorkplaceWithCount && typeOfWorkplaceWithCount.length > 1 && (
          <Grid xs={12} lg={6}>
            <MostCommonValuesBar
              getValueLabel={(value: string) => getTypeLabel(value, 'typeOfWorkplace')}
              title={getTranslatedMostCommonWithFieldLabel('typeOfWorkplace')}
              valuesWithCount={typeOfWorkplaceWithCount}
              color={theme.palette.error.main}
              /* colors={SIX_VAR[2]} */
            />
          </Grid>
        )}
        {deviationWithCount && deviationWithCount.length > 1 && (
          <Grid xs={12} lg={6}>
            <MostCommonValuesBar
              getValueLabel={(value: string) => getTypeLabel(value, 'deviation')}
              title={getTranslatedMostCommonWithFieldLabel('deviation')}
              valuesWithCount={deviationWithCount}
              color={theme.palette.success.main}
              /* colors={SIX_VAR[2]} */
            />
          </Grid>
        )}
        {assumedAbsenceWithCount && assumedAbsenceWithCount.length > 1 && (
          <Grid xs={12} lg={6}>
            <MostCommonValuesBar
              getValueLabel={(value: string) => getTypeLabel(value, 'assumedAbsence')}
              title={getTranslatedMostCommonWithFieldLabel('assumedAbsence')}
              valuesWithCount={assumedAbsenceWithCount}
              color={theme.palette.warning.main}
              /* colors={SIX_VAR[2]} */
            />
          </Grid>
        )}
        
        
      </Grid>
    </Box>
  )
}