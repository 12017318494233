import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert';
import { useAppSelector } from '../../../store';
import { selectSnackAlert } from '../controlsSlice';
import { AlertTitle } from '@mui/material';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={2} ref={ref} variant="standard" {...props} />;
});

export default function AppSnackBar() {
  const [open, setOpen] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState('');
  const [alertSeverity, setAlertSeverity] = React.useState<AlertColor>('info');
  const [alertTitle, setAlertTitle] = React.useState('');

  const snackAlert = useAppSelector(selectSnackAlert);

  React.useEffect(() => {
    if (snackAlert) {
      setAlertMessage(snackAlert.message);
      setAlertSeverity(snackAlert.severity);
      if (snackAlert?.title) {
        setAlertTitle(snackAlert.title);
      } else {
        setAlertTitle('');
      }
      setOpen(true);
    }
  }, [snackAlert]);

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  if (alertSeverity === 'info') {
    return <Snackbar open={open} autoHideDuration={4000} onClose={handleClose} message={alertMessage} />;
  }

  return (
    <Snackbar
      open={open}
      autoHideDuration={4000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      sx={
        {
          /*  '& .MuiPaper-root': {
          boxShadow: BOX_SHADOWS.elevation1,
        } */
        }
      }
    >
      <Alert
        /* variant='filled' */
        onClose={handleClose}
        severity={alertSeverity}
        sx={{ width: '100%', pl: 2, borderLeft: 4, borderColor: `${alertSeverity}.main` }}
      >
        {alertTitle && <AlertTitle>{alertTitle}</AlertTitle>}
        {alertMessage}
      </Alert>
    </Snackbar>
  );
}
