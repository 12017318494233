import * as yup from 'yup';
import { FormDatePicker, FormRadioGroup, FormTextField, YES_NO_OPTIONS } from '../../../../components/FormInputs';
import { FormAutocomplete } from '../../../../components/FormInputs/FormAutocomplete';
import { formatDateFromTimestamp } from '../../../../utils/format';
import { GENERIC_VALIDATION_MSG } from '../../constants/mappedInputLabels';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { getTranslationKeyForSectionFieldLabel } from '../../helpers/dataMapping';
import { STUDENT_SECTION_NAMES } from '../../constants/student';
// !INCOMPLETE
export const baseIllnessSchema = yup.object({
  diseaseName: yup.string().required('Should be between 4 and 42 characters long').min(4, 'min 4').max(42, 'max 42'),
  detectionDate: yup.string().matches(/^[0-9]{2}\.[0-9]{2}\.[2][0-1][0-2][0-9]$/).required(GENERIC_VALIDATION_MSG),
  deathFromDisease: yup.string().oneOf(YES_NO_OPTIONS).required(GENERIC_VALIDATION_MSG),
  provokingInfluence: yup.string().required('Should be between 1 and 100 characters long').min(1, 'min 1').max(100, 'max 100'),
  // diseaseCause: yup.string().required().min(4, 'min 4').max(86, 'max 86'),
  durationOfImpact: yup.array().of(yup.string().matches(/^[0-9]{2}\.[2][0-1][0-2][0-9]$/)).required(GENERIC_VALIDATION_MSG), // !TODO
  workAroundImpact: yup.string().required('Should be between 4 and 86 characters long').min(4, 'min 4').max(86, 'max 86') 
});

export type BaseIllnessSection = yup.InferType<typeof baseIllnessSchema>;

const getDurationOfImpactOptionsAndLabels = (endDate: number) => {
  const currentDate = dayjs();
  const startDate = currentDate.subtract(5, 'year');

  let options = [];
  let optionLabels: any = {};

  let date = startDate;
  while (date.isBefore(currentDate)) {
    options = [...options, date.format('MM.YYYY')];
    optionLabels[date.format('MM.YYYY')] = `${date.format('MMM YYYY')}`;
    date = date.add(1, 'month');
  }

  return {
    options,
    optionLabels
  };
};

export const BaseIllnessControls = ({ methods: { control } }: any) => {
  const date = Date.now();
  const { t } = useTranslation();
  const durationOfImpactOptionsAndLabels = getDurationOfImpactOptionsAndLabels(date);

  return (
    <>
      <FormTextField
        control={control}
        name='diseaseName'
        label={t(getTranslationKeyForSectionFieldLabel(STUDENT_SECTION_NAMES.ILLNESS, 'diseaseName'))}
      />
      <FormDatePicker
        control={control}
        name='detectionDate'
        label={t(getTranslationKeyForSectionFieldLabel(STUDENT_SECTION_NAMES.ILLNESS, 'detectionDate'))}
      />
      <FormRadioGroup
        label={t(getTranslationKeyForSectionFieldLabel(STUDENT_SECTION_NAMES.ILLNESS, 'deathFromDisease'))}
        control={control}
        name='deathFromDisease' 
        options={YES_NO_OPTIONS}
      />
      <FormTextField
        control={control}
        name='provokingInfluence'
        label={t(getTranslationKeyForSectionFieldLabel(STUDENT_SECTION_NAMES.ILLNESS, 'provokingInfluence'))}
      />
      <FormAutocomplete
        control={control}
        selectionLimit={5}
        name='durationOfImpact'
        options={durationOfImpactOptionsAndLabels.options}
        optionLabels={durationOfImpactOptionsAndLabels.optionLabels}
        label={t(getTranslationKeyForSectionFieldLabel(STUDENT_SECTION_NAMES.ILLNESS, 'durationOfImpact'))}
      />
      <FormTextField
        control={control}
        name='workAroundImpact'
        label={t(getTranslationKeyForSectionFieldLabel(STUDENT_SECTION_NAMES.ILLNESS, 'workAroundImpact'))}
        multiline
        rows={3}
      />
    </>
  )
}
