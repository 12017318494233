
import React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import { Avatar, Box, Divider, IconButton, ListItem, ListItemText, Stack, Typography } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import { AppIcon } from '../../../../components/Elements';
import { RootState, useAppDispatch, useAppSelector } from '../../../../store';
import { NewArea, NewSite, areaClicked, makeSelectAreasBySiteId, selectAllAreas, selectCurrentAreaId, selectCurrentSiteId, siteClicked } from '../../../locations/locationsSlice';
import { makeSelectInjuryReportsByAreaId, selectAllInjuryReports } from '../../../injuries/store/injuryReportsSlice';
import { REPORT_TYPES } from '../../../injuries/constants';
import { ReportTypeIconNames } from '../../../injuries/constants/mappedProps';
import { InjuryReportCase } from '../../../injuries/types';
import { testBasesMappedNames } from '../BaseLocationList/BaseLocationList';

type SiteListItemProps = {
  area: NewArea;
}

const SiteListItem = (props: SiteListItemProps) => {
  const { area } = props;
  const dispatch = useAppDispatch();

  const currentAreaId = useAppSelector(selectCurrentAreaId);

  const selectInjuryReportsByAreaId = React.useMemo(makeSelectInjuryReportsByAreaId, []);
  const filteredInjuryReports = useAppSelector((state: RootState) =>
    selectInjuryReportsByAreaId(state, area.id)
  );

  const accidentsCount = filteredInjuryReports.filter((report: any) => report.type === REPORT_TYPES.ACCIDENT).length;
  const illnessCount = filteredInjuryReports.filter((report: any) => report.type === REPORT_TYPES.ILLNESS).length;
  const minorInjuryCount = filteredInjuryReports.filter((report: any) => report.type === REPORT_TYPES.MINOR_INJURY).length;

  const handleClick = (path: string) => {
    dispatch(areaClicked(area.id));
  };

  return (
    <ListItem
      selected={currentAreaId === area.id}
      sx={{
        borderColor: 'divider',
        p: 2,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
      button 
      onClick={() => handleClick(area.id)}
    >
      <Stack
        direction='column'
        sx={{ flex: 1 }}
      >
        <Typography variant='h5' sx={{ lineHeight: '20px' }}>{area.name}</Typography>
        <Box 
          sx={{ 
            mt: 0.25, 
            color: 'text.secondary',
            typography: 'body2',
            display: 'flex',
            flexDirection: 'row', 
            gap: 0.75, 
            alignItems: 'center',
          }}
        >
          {/* {`Site`}
          <Box 
            sx={{
              color: 'text.disabled',
              fontSize: 'body2.fontSize'
            }}
          >
            •
          </Box> */}
          {area.areaCoordinates ? 'Area' : 'Point'}
        </Box>
        <Stack
          direction='row'
          alignItems='center'
          spacing={2}
          sx={{
            mt: 1
          }}
        >
          <Stack
            direction='row'
            alignItems='center'
            spacing={0.75}
          >
            <AppIcon iconName={ReportTypeIconNames[REPORT_TYPES.ACCIDENT]} opticalSize={18} color={'text.secondary'} sx={{ ml: -0.5 }} />
            <Typography variant='body2' color='text.primary'>{accidentsCount}</Typography>
          </Stack>
          <Stack
            direction='row'
            alignItems='center'
            spacing={0.75}
          >
            <AppIcon iconName={ReportTypeIconNames[REPORT_TYPES.ILLNESS]} opticalSize={18} color={'text.secondary'} sx={{ ml: -0.5 }} />
            <Typography variant='body2' color='text.primary'>{illnessCount}</Typography>
          </Stack>
          <Stack
            direction='row'
            alignItems='center'
            spacing={0.75}
          >
            <AppIcon iconName={ReportTypeIconNames[REPORT_TYPES.MINOR_INJURY]} opticalSize={18} color={'text.secondary'} sx={{ ml: -0.5 }} />
            <Typography variant='body2' color='text.primary'>{minorInjuryCount}</Typography>
          </Stack>
        </Stack>
      </Stack>
      {/* <Avatar
        sx={{
          height: 48,
          width: 48,
          borderRadius: 1,
          bgcolor: 'backgroundVariant',
          color: 'text.primary',
        }}
      >
        <AppIcon iconName={'school'} />
      </Avatar> */}
      {/* <ListItemText 
        primary={baseLocation.name}
        secondary={`${sites.length} sites`}
      /> */}
    </ListItem>
  );
};

export const SiteList = () => {
  const dispatch = useAppDispatch();

  const areas = useAppSelector(selectAllAreas);

  const handleClick = (path: string) => {
    dispatch(siteClicked(path));
  };

  return (
    <List
      disablePadding
      sx={{
        px: 0,
        py: 0.5,
      }}
    >
      {areas.map((area, i: number) => (
        <React.Fragment key={area.id}>
          <SiteListItem
            area={area}
          />
          {i < areas.length - 1 && (
            <Divider />
          )}
        </React.Fragment>
      ))}
    </List>
  )
}