import React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTheme, alpha } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { TKB_INJURY } from '../../features/injuries/constants/common';

interface TopBarMenuProps {
  anchorEl: null | HTMLElement;
  handleClose: () => void;
  open: boolean;
}

export default function LanguageSelectorMenu(props: TopBarMenuProps) {
  const { anchorEl, handleClose, open = false } = props;
  const theme = useTheme();
  const { t, i18n } = useTranslation();

  const handleClickLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  return (
    <Menu
      PaperProps={{
        variant: theme.palette.mode === 'dark' ? 'outlined' : 'elevation'
      }}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      sx={{ mt: 2 }}
      id="top-bar-menu"
      open={open}
      onClose={handleClose}
    >
      <MenuItem onClick={() => handleClickLanguage('en')}>{t(`${TKB_INJURY}.english`)}</MenuItem>
      <MenuItem onClick={() => handleClickLanguage('nb')}>{t(`${TKB_INJURY}.norwegian`)}</MenuItem>
      <MenuItem onClick={() => handleClickLanguage('no')}>{t(`${TKB_INJURY}.newNorwegian`)}</MenuItem>
    </Menu>
  );
}
