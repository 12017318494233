import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { DataGrid, GridRowsProp,
  useGridApiContext,
  useGridSelector, 
  gridPageCountSelector,
  GridToolbarExport,
  gridPageSelector,
  GridFilterPanel,
  GridFilterModel, GridColDef, GridToolbarColumnsButton, GridToolbar, GridToolbarContainer, GridToolbarQuickFilter, GridToolbarFilterButton, gridQuickFilterValuesSelector, gridRowSelectionStateSelector } from '@mui/x-data-grid';
import { BOX_SHADOWS } from '../../config/theme/boxShadows';
import { AppIcon } from '../Elements';
import { useTranslation } from 'react-i18next';

type RDataGridSelection = {
  count?: number;
  actions?: { 
    label: string;
    onClick?: any;
    iconName?: string;
    color?: any;
    variant?: any;
  }[];
  itemName?: string;
}

export type RSelectedItemToolbarProps = {
  count?: number;
  actions?: { 
    label: string;
    onClick?: any;
    iconName?: string;
    color?: any;
    variant?: any;
  }[];
  itemName?: string;
}

export const RSelectedItemToolbar = (props: RSelectedItemToolbarProps) => {
  const { count, actions, itemName } = props;

  const { t } = useTranslation();
  return (
    <Stack
      position='absolute'
      bottom={-48}
      zIndex={3}
      direction='row'
      gap={1}
      alignItems='center'
      justifyContent={'space-between'}
      sx={{ 
        width: '100%', 
        height: 48, 
        bgcolor: (theme) => theme.palette.mode === 'light' ? 'background.default' : 'backgroundVariant',
        boxShadow: BOX_SHADOWS.elevation0,
        borderRadius: 1.5,
        px: 2.5,
      }}
    >

      <Typography variant='body2' sx={{ fontWeight: 500, mr: 0.5, color: 'text.primary' }}>{t('wif.injury.selectedWithItemNameAndCount', { itemName: props?.itemName, count: 1 })}</Typography>
      <Stack
        direction='row'
        gap={1.5}
      >
        {actions.map((action, index) => (
          <Button
            variant={action?.variant || 'text'}
            startIcon={action?.iconName ? <AppIcon iconName={action?.iconName} /> : undefined}
            color={action?.color as any || 'primary'}
            onClick={action.onClick}
            sx={{
              ...(action?.variant && action?.variant !== 'text' && {
                ml: 1
              }),
            }}
          >
            {actions.length <= 4 && action.label}
          </Button>
        ))}
        {/* {actions.map((action, index) => (
          <Button
            variant={'text'}
            startIcon={<AppIcon iconName={action.iconName} color={'text.secondary'} weight={500} />}
            sx={{
              color: 'text.primary',
              '& .MuiButton-startIcon': {
                mx: 0,

              },
              p: 'auto',
              height: '36px', 
              width: '36px',
              minWidth: 0,
              fontWeight: 500
            }}
          >
          </Button>
        ))} */}
        {/* <Button
          variant={'text'}
          startIcon={<AppIcon iconName={'download'} color={'text.secondary'} weight={500} />}
          sx={{
            color: 'text.primary',
            p: 'auto',
            height: '36px', 
            minWidth: 0,
            fontWeight: 500
          }}
        >
          Download
        </Button> */}
      </Stack>
    </Stack>
  )
}