import { Text, View } from '@react-pdf/renderer';
import { DocColors } from './InjuryDocument';

type ChapterHeaderProps = {
  title?: string;
};

export const DocChapterHeader = (props: ChapterHeaderProps) => {
  const { title } = props;

  return (
    <View
      style={{
        width: '100%',
        /* borderRadius: 4, */
        /* paddingHorizontal: 12,  */
        display: 'flex', 
        flexDirection: 'row', 
        gap: 14,
        paddingVertical: 10, 
        marginTop: 10,
        marginBottom: 6,
        color: DocColors.primary,
      }}
    >
      <Text
        style={{
          fontSize: 13,
          fontWeight: 700,
        }}
      >
        {title}
      </Text>
      <View style={{
          borderTop: `1px solid ${DocColors.divider}`,
          marginBottom: 'auto',
          marginTop: 'auto',
          flex: 1,
        }}
        />
    </View>
  )
}

type DocGridContainerProps = {
  children: any;
};

export const DocGridRow = (props: DocGridContainerProps) => {
  const { children } = props;
  return (
    <View
      style={{
        display: 'flex', 
        flexDirection: 'row',
        alignItems: 'flex-start', 
        width: '100%',
        paddingVertical: 10
      }}
    >
      {children}
    </View>
  );
}

type DocGridDescriptionItemProps = {
  field: string; 
  value: string; 
}

export const DocGridDescriptionItem = (props: DocGridDescriptionItemProps) => {
  const { field, value } = props;
  return (
    <View 
      style={{
        display: 'flex', 
        flexDirection: 'column',
        gap: 4,
        flex: 1,
      }}
    >
      <Text
        style={{
          fontSize: 10, 
          color: DocColors.text.primary, 
          fontWeight: 500,
          maxWidth: '90%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {field}
      </Text>
      <Text
        style={{
          fontSize: 10,
          color: DocColors.text.secondary, 
          ...(value.length < 1 && {
            color: DocColors.textVariant,
            opacity: 0.9,
          }),
          fontWeight: 400,
          maxWidth: '90%',
        }}
      >
        {value.length < 1 && 'None'}
        {value.length > 24 ? value.substring(0, 21).concat('...') : value}
      </Text>
    </View>
  );
}