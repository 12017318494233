import { Paper, Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import { useTheme } from '@mui/material/styles';
import { APP_COLORS } from '../../../../config';
import { useAppSelector } from '../../../../store';
import { selectAllInjuryActions, selectInjuryReports } from '../../store/injuryReportsSlice';
import { Action, WideAction } from '../../../actions/components/Action';
import React from 'react';
import { IAction } from '../../../actions/actionTypes';

type InjuryActionsProps = {
  category?: string;
}

export const InjuryActions = (props: InjuryActionsProps) => {
  const { category } = props;

  const injuryActions = useAppSelector(selectAllInjuryActions);
  const injuryReports = useAppSelector(selectInjuryReports);

  const filteredInjuryActions = React.useMemo(() => {
    if (category) {
      return injuryActions.filter((action: IAction) => {
        const injuryReport = injuryReports[action.report_id];
        if (injuryReport) {
          if (injuryReport.category === category) {
            return true;
          }
        }
      });
    }
    return injuryActions; 
  }, [category, injuryActions, injuryReports]);

  return (
    <Box
      sx={{
        flex: 1,
        py: {
          xs: 2.5,
          md: 3
        },
        px: {
          xs: 2.5,
          md: 3
        },
        width: '80%',
        overflowY: 'auto',
        display: 'flex', 
        flexDirection: 'column', 
        gap: 2.5
      }}
    >
      {filteredInjuryActions.map((action: any) => (
        <Action
          action={action}
          linkToInjuryReport={true}
        />
      ))}
    </Box>
  )
}