import React from 'react';
import { Page, Text, View, Document, StyleSheet, Font } from '@react-pdf/renderer';
import { useCurrentInjuryReport } from '../../routes/CurrentInjuryReportContext';
import { APP_COLORS } from '../../../../config';
import { DocColors } from './InjuryDocument';

type DocSpaceProps = {
  spacing: number;
}

export const DocSpace = (props: DocSpaceProps) => {
  const { spacing } = props;
  return (
    <View 
      style={{
        marginVertical: spacing * 4
      }}
    />
  );
}

type DocDividerProps = {
  color?: string; 
  verticalSpacing?: number;
  marginTop?: number; 
  marginBottom?: number;
}

export const DocDivider = (props: DocDividerProps) => {
  const { color = DocColors.divider, verticalSpacing = 0, marginTop = 0, marginBottom = 0 } = props;
  return (
    <View
      style={{
        borderBottom: `1px solid ${color}`,
        marginVertical: 4 * verticalSpacing,
        marginTop: 8 * marginTop,
        marginBottom: 8 * marginBottom,  
        width: '100%'
      }}
    />
  )
}