import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { WithChildren } from '../../types';

type ChartContainerProps = WithChildren<{
  size?: 'small' | 'medium' | 'large';
  sx?: any;
}>;

export const ChartContainer = (props: ChartContainerProps) => {
  const { size = 'medium', children, sx } = props;

  const isSmall = size === 'small';
  const isMedium = size === 'medium';
  const isLarge = size === 'large';

  return (
    <Paper
      elevation={1}
      sx={{
        px: 2.5,
        py: 2,
        pb: 2.5,
        borderRadius: 0,
        bgcolor: (theme) => theme.palette.mode === 'dark' ? 'backgroundVariant' : 'background.paper'
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: isSmall ? '220px' : isMedium ? '260px' : '320px', 
          ...sx
        }}
      >
        {children}
      </Box>
    </Paper>
    
  )
}