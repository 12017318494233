import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { GlobalHeader } from './GlobalHeader';
import { WithChildren } from '../../types';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Backdrop, CircularProgress, Collapse, IconButton, Paper, Typography, useTheme } from '@mui/material';
import { APP_COLORS, SIKRI_COLORS } from '../../config';
import { AppIcon } from '../Elements';
import _, { filter } from 'lodash';
import { BOX_SHADOWS } from '../../config/theme/boxShadows';
import { useAppDispatch, useAppSelector } from '../../store';
import { selectAppLoading, selectTestUserRole } from '../../features/global/controlsSlice';

import { Theme, CSSObject } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  fetchAllInjuryComments,
  fetchInjuryReportLogs,
  fetchInjuryReports,
  fetchInjuryTagsByCategory,
  fetchInjuryUnits,
  selectAllInjuryReportHandlers,
  selectAllInjuryReportRegistrations,
  selectAllInjuryReports,
  selectInjuryReportsFetched
} from '../../features/injuries/store/injuryReportsSlice';
import {
  fetchEmployeeUsers,
  fetchUsersByIds,
  selectEmployeesCursorHasNext,
  selectEmployeesNextCursor
} from '../../features/employees/employeesSlice';
import {
  fetchAllDepartments,
  selectAllDepartments,
  selectDepartmentsFetchedStatus
} from '../../features/departments/departmentsSlice';
import CircularLoader from '../Loader/circularLoader';
import { fetchReporterNonconformityReports } from '../../features/nonconformity/nonconformitySlice';
import AppSnackBar from '../../features/global/components/AppSnackBar';
import { REPORT_CATEGORY, TKB_INJURY } from '../../features/injuries/constants';
import { APP_LETTER_SPACING, APP_LINE_HEIGHTS } from '../../config/theme/fontStyles';
import { userRoles } from '../../config/constants';
import { useTranslation } from 'react-i18next';
import { FetchActionsQuery, getDefaultActionsQueryFilters } from '../../features/actions/actionTypes';
import { fetchActions, selectActionsFetched } from '../../features/actions/actionsSlice';
import { fetchInjuryAttachments } from '../../features/injuries/store/injuryReportThunks';
import { getActiveUser } from '../../utils/user';

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
});

const userClaimId = 'b4cb162c-2f5f-11ee-be56-0242ac120002';
const readerClaimId = 'b4cb162c-2f5f-11ee-be56-0242ac120002';

export const baseSidebarRoutes: any[] = [
  {
    name: 'Register injury',
    iconName: 'personal_injury',
    to: '#/'
  },
  {
    name: 'Injury reports',
    iconName: 'overview',
    to: '#/injuries'
  }
];

const drawerWidth = 240;

type SideNavCollapseItemProps = WithChildren<{
  name: string;
  iconName?: string;
  to?: string;
}>;

const SideNavCollapseItem = (props: SideNavCollapseItemProps) => {
  const { name, iconName, to, children } = props;
  const theme = useTheme();
  const light = theme.palette.mode === 'light';
  const [open, setOpen] = React.useState(false);

  const location = useLocation();

  React.useEffect(() => {
    if (location.pathname.indexOf(to) !== -1) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [location.pathname]);

  const renderLink = React.useMemo(
    () =>
      React.forwardRef<any, any>((props, ref) => (
        <NavLink
          ref={ref}
          {...props}
          className={({ isActive }) => `${props.className} ${isActive ? 'Mui-selected' : ''}`}
          to={to}
        />
      )),
    [to]
  );

  return (
    <Box>
      <ListItem
        onClick={() => setOpen(true)}
        component={renderLink}
        sx={{
          borderRadius: 2,
          px: 1.5,
          color: theme.palette.text.primary,
          '& .MuiListItemText-primary': {
            fontWeight: 400,
            fontSize: 'body2.fontSize',
            color: 'text.secondary'
          },
          '& .MuiListItemText-secondary': {
            fontWeight: 600,
            color: 'text.secondary',
            letterSpacing: APP_LETTER_SPACING.wide,
            fontSize: '13px',
            lineHeight: APP_LINE_HEIGHTS.snug
          },
          '&:hover': {
            bgcolor: light ? APP_COLORS.light2 : APP_COLORS.dark2
          },
          height: '36px',
          '& .MuiListItemIcon-root': {
            color: theme.palette.text.secondary,
            ml: -0.5
          },
          '&.Mui-selected': {
            bgcolor: 'transparent',
            '& .MuiListItemText-primary': {
              fontWeight: 500,
              fontSize: 'body2.fontSize',
              color: 'text.primary'
            },
            '& .MuiListItemIcon-root': {
              color: (theme) => (theme.palette.mode === 'light' ? 'primary.main' : 'secondary.main')
              /* color: 'text.secondary', */
              /* color: 'primary.main', */
              /* color: light ? 'primary.main' : 'text.secondary',  */
              /* color: light ? Colors.primary[700] : Colors.primary[300], */
            }
          }
        }}
      >
        {iconName && (
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: 0.25,
              color: 'textVariant'
            }}
          >
            <AppIcon opticalSize={20} iconName={iconName} color="inherit" weight={400} grade={-25} />
          </ListItemIcon>
        )}
        <ListItemText primary={name} sx={{}} />
        {/* <AppIcon 
          iconName={open ? 'expand_less' : 'expand_more'} 
          color={SIKRI_COLORS.indigo}
          opticalSize={20}
          weight={500}
          sx={{
          }}
        /> */}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          sx={{
            gap: 1,
            pl: 3.5
          }}
        >
          {children}
        </List>
      </Collapse>
    </Box>
  );
};

interface SideNavLinkItemProps {
  name?: string;
  iconName?: string;
  to?: string;
  disabled?: boolean;
  position?: 'first' | 'last';
  variant?: 'top-level' | 'child';
}

const SideNavItem = (props: SideNavLinkItemProps) => {
  const mediumScreen = useMediaQuery('(min-width:900px)');
  const location = useLocation();

  const { name, iconName, to, disabled, position, variant } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef<any, any>((props, ref) => (
        <NavLink
          ref={ref}
          {...props}
          className={({ isActive }) => `${props.className} ${isActive ? 'Mui-selected' : ''}`}
          to={to}
        />
      )),
    [to]
  );

  const theme = useTheme();
  const light = theme.palette.mode === 'light';

  if (!mediumScreen) {
    return (
      <ListItem
        component={renderLink}
        sx={{
          borderRadius: 2,
          minWidth: '0px!important',
          width: '40px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',

          color: theme.palette.text.primary,
          '& .MuiListItemText-primary': {
            fontWeight: 400,
            fontSize: 'body2.fontSize',
            color: 'text.secondary'
          },
          '&:hover': {
            bgcolor: light ? APP_COLORS.light2 : APP_COLORS.dark2
          },
          height: '40px',
          '& .MuiListItemIcon-root': {
            width: '24px',
            pl: 0,
            color: theme.palette.textVariant
          },
          '&.Mui-selected': {
            bgcolor: light ? APP_COLORS.light5 : APP_COLORS.dark2,
            '&:hover': {
              bgcolor: light ? APP_COLORS.light4 : APP_COLORS.dark2
            },
            '& .MuiListItemText-primary': {
              color: 'text.primary',
              fontWeight: 400
            },
            '& .MuiListItemIcon-root': {
              color: 'text.primary'
            },
            color: light ? 'primary.main' : APP_COLORS.darkTextPrimary,
            '& .material-symbols-outlined': {
              fontVariationSettings: '"FILL" 0, "wght" 500, "GRAD" 0, "opsz" 20!important'
            },
            boxShadow: BOX_SHADOWS.elevation0,
            '&::after': {
              left: '-1px',
              position: 'absolute',
              width: '3px',
              height: '20px',
              content: '""',
              backgroundColor: light ? theme.palette.primary.main : theme.palette.secondary.main,
              borderRadius: '6px'
            }
          }
        }}
      >
        <ListItemIcon
          sx={{
            color: 'textVariant',
            px: 0
          }}
        >
          <AppIcon opticalSize={24} iconName={iconName} color="inherit" weight={400} grade={-25} />
        </ListItemIcon>
      </ListItem>
    );
  }

  return (
    <ListItem
      button
      component={renderLink}
      sx={{
        borderRadius: 2,
        px: variant && variant === 'top-level' ? 1.5 : 'auto',
        color: theme.palette.text.primary,
        '& .MuiListItemText-primary': {
          fontWeight: 400,
          fontSize: 'body2.fontSize',
          color: 'text.secondary'
        },
        '&:hover': {
          bgcolor: light ? APP_COLORS.light2 : APP_COLORS.dark2
        },
        height: '36px',
        '& .MuiListItemIcon-root': {
          /* color: 'text.secondary', */
          color: theme.palette.text.secondary,
          ml: -0.5
        },
        ...(variant !== 'top-level' && {
          '&::after': {
            left: '-12px',
            /* borderRadius: '6px', */
            position: 'absolute',
            zIndex: 99,
            width: '3px',
            height: '36px',
            content: '""',
            ...(position === 'first' && {
              borderTopLeftRadius: '6px',
              borderTopRightRadius: '6px'
            }),
            ...(position === 'last' && {
              borderBottomLeftRadius: '6px',
              borderBottomRightRadius: '6px'
            }),
            backgroundColor: light ? theme.palette.divider : theme.palette.divider
            /* borderTopLeftRadius: '6px',
        borderBottomLeftRadius: '6px', */
          }
        }),

        '&.Mui-selected': {
          '&:hover': {
            bgcolor: light ? APP_COLORS.light5 : APP_COLORS.dark2
          },
          '& .MuiListItemText-primary': {
            /* color: 'primary.main', */
            fontWeight: 500
          },
          '& .MuiListItemIcon-root': {
            /* color: 'text.secondary', */
            /* color: 'primary.main', */
            /* color: light ? 'primary.main' : 'text.secondary',  */
            /* color: light ? Colors.primary[700] : Colors.primary[300], */
          },
          /* color: light ? 'primary.main' : APP_COLORS.darkTextPrimary, */
          '& .material-symbols-outlined': {
            fontVariationSettings: '"FILL" 1, "wght" 500, "GRAD" 0, "opsz" 20!important'
          },
          ...(variant !== 'top-level' && {
            '&::after': {
              left: '-12px',
              /* borderRadius: '6px', */
              borderRadius: '6px',
              position: 'absolute',
              zIndex: 99,
              width: '3px',
              height: '36px',
              content: '""',
              backgroundColor: light ? theme.palette.primary.main : theme.palette.secondary.main
              /* borderTopLeftRadius: '6px',
          borderBottomLeftRadius: '6px', */
            }
          }),

          /* bgcolor: 'transparent', */
          bgcolor: light ? SIKRI_COLORS.galaxyGreenSelected : SIKRI_COLORS.galaxyGreenSelectedDark
          /* boxShadow: BOX_SHADOWS.elevation0, */
        }
      }}
      /* secondaryAction={
    <IconButton
      edge='end'
      aria-label='Settings'
    >
      <NewTypeIcon opticalSize={22} iconName='settings' weight={400} grade={-25} sx={{ mt: '-04px' }} color={light ? Colors.ltheme.textPrimary : Colors.dtheme.textPrimary} />
    </IconButton>
  } */
    >
      {iconName && (
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: 0.25,
            color: 'textVariant'
          }}
        >
          <AppIcon opticalSize={20} iconName={iconName} color="inherit" weight={400} grade={-25} />
        </ListItemIcon>
      )}
      <ListItemText primary={name} sx={{}} />
    </ListItem>
  );
};

type adminModuleType = {
  critical: boolean;
  documentHandling: boolean;
  fall: boolean;
  injuryEmployee: boolean;
  injuryStudent: boolean;
  manuals: boolean;
  nonconformity: boolean;
  privacypolicy: boolean;
  scheduler: boolean;
  weblink: boolean;
};

export default function SideNavigation({ children }: WithChildren) {
  const { t, i18n } = useTranslation();
  const testUserRole = useAppSelector(selectTestUserRole);
  const [loading, setLoading] = useState<boolean>(false);
  const [adminModules, setAdminModules] = React.useState<adminModuleType>(undefined);
  const [userClaim, setUserClaim] = React.useState<boolean>(false);
  const [userRole, setUserRole] = React.useState<string>(userRoles.user);
  const [userCanHandle, setUserCanHandle] = React.useState<boolean>(false);

  React.useEffect(() => {
    const activeUser = getActiveUser();
    if (['en', 'nn'].indexOf(activeUser?.primary_locale) !== -1) {
      i18n.changeLanguage(activeUser.primary_locale);
    }
  }, []);

  const departments = useAppSelector(selectAllDepartments);

  const mediumScreen = useMediaQuery('(min-width:900px)');
  const sideBarRoutesForUser: any[] = [
    {
      name: t(`${TKB_INJURY}.sidebar.allInjuries`),
      iconName: 'folder_shared',
      type: 'collapse',
      to: '/injuries/all',
      children: [
        {
          name: t(`${TKB_INJURY}.registrations`),
          type: 'link',
          to: '/injuries/all/registrations',
          position: 'first'
        }
      ]
    },
    {
      name: t(`${TKB_INJURY}.studentInjuries`),
      iconName: 'school',
      type: 'collapse',
      to: '/injuries/student',
      children: [
        {
          name: t(`${TKB_INJURY}.registrations`),
          type: 'link',
          to: '/injuries/student/registrations',
          position: 'first'
        }
      ]
    },
    {
      name: t(`${TKB_INJURY}.employeeInjuries`),
      iconName: 'badge',
      type: 'collapse',
      to: '/injuries/employee',
      children: [
        {
          name: t(`${TKB_INJURY}.registrations`),
          type: 'link',
          to: '/injuries/employee/registrations',
          position: 'first'
        }
      ]
    }
  ];
  const sideBarRoutes: any = [
    {
      name: t(`${TKB_INJURY}.sidebar.allInjuries`),
      iconName: 'folder_shared',
      type: 'collapse',
      to: '/injuries/all',
      children: [
        {
          name: t(`${TKB_INJURY}.registrations`),
          type: 'link',
          to: '/injuries/all/registrations',
          position: 'first'
        },
        {
          name: t(`${TKB_INJURY}.reportHandler`),
          type: 'link',
          to: '/injuries/all/handling',
          position: 'last'
        }
      ]
    },
    {
      name: t(`${TKB_INJURY}.studentInjuries`),
      iconName: 'school',
      type: 'collapse',
      to: '/injuries/student',
      children: [
        {
          name: t(`${TKB_INJURY}.registrations`),
          type: 'link',
          to: '/injuries/student/registrations',
          position: 'first'
        },
        {
          name: t(`${TKB_INJURY}.reportHandler`),
          type: 'link',
          to: '/injuries/student/handling',
          position: 'last'
        }
      ]
    },
    {
      name: t(`${TKB_INJURY}.employeeInjuries`),
      iconName: 'badge',
      type: 'collapse',
      to: '/injuries/employee',
      children: [
        {
          name: t(`${TKB_INJURY}.registrations`),
          type: 'link',
          to: '/injuries/employee/registrations',
          position: 'first'
        },
        {
          name: t(`${TKB_INJURY}.reportHandler`),
          type: 'link',
          to: '/injuries/employee/handling',
          position: 'last'
        }
      ]
    }
    /* {
      name: 'Nonconformities',
      iconName: 'quick_reference',
      type: 'collapse',
      to: '/nonconformities',
      children: [
        {
          name: 'Registered NCRs',
          type: 'link',
          to: '/nonconformities/reports',
          position: 'first'
        },
      ]
    }, */
    /* {
      name: 'Falls',
      iconName: 'falling',
      type: 'collapse',
      to: '/falls',
      children: [
        {
          name: 'Fall reports',
          type: 'link',
          to: '/falls/reports',
          position: 'first'
        },
      ]
    }, */
    /* {
      name: 'Sites',
      iconName: 'pin_drop',
      type: 'link',
      to: '/sites'
    } */
    /* {
      name: 'Handling',
      iconName: 'school',
      to: '/injuries/student',
    }, */
    /* {
      name: 'Employee',
      iconName: 'badge',
      type: 'link', 
      to: '/injuries/employee',
    }, */
    /* {
      name: 'Register injury',
      iconName: 'personal_injury',
      to: '/',
    }, */

    /* {
      name: 'Dashboards',
      iconName: 'analytics',
      to: '/documents',
    }, */
  ];



  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const injuryReportsFetched = useAppSelector(selectInjuryReportsFetched);
  const injuryReports = useAppSelector(selectAllInjuryReports);
  const actionsFetched = useAppSelector(selectActionsFetched);
  const usersNextCursor = useAppSelector(selectEmployeesNextCursor);
  const usersCursorHasNext = useAppSelector(selectEmployeesCursorHasNext);

  if (useAppSelector(selectAppLoading)) setLoading(useAppSelector(selectAppLoading));
  const injuryHandlerReports = useAppSelector(selectAllInjuryReportHandlers);

  const sidebarItems = React.useMemo(() => {
    let items = [];
    if (userRole === userRoles.user && !userClaim && !injuryHandlerReports.length) items = sideBarRoutesForUser;
    else items = sideBarRoutes;

    if (adminModules) {
      items = items.filter((sidebarItem) => {
        if (!adminModules.injuryStudent && adminModules.injuryEmployee) return sidebarItem.to === '/injuries/employee';
        else if (!adminModules.injuryEmployee && adminModules.injuryStudent)
          return sidebarItem.to === '/injuries/student';
        else if (adminModules.injuryEmployee && adminModules.injuryStudent) return sidebarItem;
        else return [];
      });
    }
    return items;
  }, [userClaim, userRole, adminModules, injuryHandlerReports, t]);

  React.useEffect(() => {
    // Retrieve the item from local storage
    const ngStorageCurrent = localStorage.getItem('ngStorage-current');
    if (ngStorageCurrent) {
      const current = JSON.parse(ngStorageCurrent);
      //set permitted modules by super admin
      if (current && current.organization) setAdminModules(current.organization.adminModules);
      if (
        current &&
        current.user &&
        current.user.permissions &&
        current.user.permissions.claims &&
        current.user.permissions.claims.length
      )
        current.user.permissions.claims.map((claim) => {
          if (claim.claim_id === userClaimId) setUserClaim(true);
        });
      if (current && current.user && current.user.role) setUserRole(current.user.role);
    }
  }, []);

  const departmentsFetchedStatus = useAppSelector(selectDepartmentsFetchedStatus);

  React.useEffect(() => {
    if (injuryReportsFetched === 'idle') {
      /* dispatch(loadingStarted()); */
      dispatch(fetchInjuryReports());
      const injuryActionQueryFilters = getDefaultActionsQueryFilters();
      const actionsQuery: FetchActionsQuery = {
        startIndex: 0,
        count: 100,
        body: injuryActionQueryFilters
      };
      dispatch(fetchActions(actionsQuery));
      dispatch(fetchInjuryReportLogs());
      dispatch(fetchAllInjuryComments());
      dispatch(fetchInjuryTagsByCategory({ category: REPORT_CATEGORY.STUDENT }));
      dispatch(fetchInjuryTagsByCategory({ category: REPORT_CATEGORY.EMPLOYEE }));
      dispatch(fetchInjuryUnits());
      dispatch(fetchAllDepartments());
      dispatch(fetchReporterNonconformityReports());
      dispatch(fetchInjuryAttachments());
      /* let nonconformityActionsQueryFilters = getDefaultActionsQueryFilters();
      nonconformityActionsQueryFilters.injury = false;
      nonconformityActionsQueryFilters.nonconformity = true;
      const nonconformityActionsQuery: FetchActionsQuery = {
        startIndex: 0,
        count: 200,
        body: nonconformityActionsQueryFilters
      };
      dispatch(fetchActions(nonconformityActionsQuery)); */
    }
    if (injuryReportsFetched === 'succeeded') {
      let injuryReportUserIds: string[] = [];
      for (let report of injuryReports) {
        if (report?.assignee_id) {
          injuryReportUserIds.push(report?.assignee_id);
        } 
        if (report?.reporter_id) {
          injuryReportUserIds.push(report?.reporter_id);
        } 
      }
      const uniqReportUserIds = _.uniq(injuryReportUserIds);
      dispatch(fetchUsersByIds(uniqReportUserIds));
    }
  }, [injuryReportsFetched]);

  const asyncFunction = (time) => new Promise((resolve) => setTimeout(resolve, time));

  React.useEffect(() => {
    if (departmentsFetchedStatus === 'succeeded') {
      if (usersCursorHasNext) {
        dispatch(fetchEmployeeUsers({}));
        
      }
    }
  }, [departmentsFetchedStatus, usersCursorHasNext, usersNextCursor]);

  const backToSamsvar = () => {
    if (userRole === 'superadmin') {
      window.location.hash = `#/dashboardlist`;
      window.location.reload();
    } else {
      window.location.hash = `#/mainDashboard`;
      window.location.reload();
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        width: '100%',
        flexDirection: 'column'
        /* flex: 1,  */
      }}
    >
      <CssBaseline />
      <GlobalHeader />

      <Box
        sx={{
          flex: 1,
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          bgcolor: 'background.default'
        }}
      >
        <Drawer
          variant="permanent"
          PaperProps={{
            elevation: 0
          }}
          sx={{
            width: {
              xs: '72px',
              md: '256px'
            },
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: { xs: '72px', md: '256px' },
              border: 'none',
              boxSizing: 'border-box',
              bgcolor: 'transparent'
            }
          }}
        >
          <Toolbar sx={{ display: { xs: 'none', md: 'block' } }} /* sx={{ display: { xs: 'none', md: 'block' }} */ />
          <Box
            sx={{
              p: 1.5,
              display: 'flex',
              flex: 1,
              gap: 1.5,
              flexDirection: 'column'
            }}
          >
            <Paper
              sx={{
                flex: 1,
                py: 2.25
              }}
            >
              <Box
                sx={{
                  px: 2.5,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  width: '100%'
                }}
              >
                <Typography variant="h5">{t(`${TKB_INJURY}.injuryModule`)}</Typography>
                {/* <IconButton
                  edge='end'
                  size='small'
                  sx={{ 
                    minHeight: '0px!important',
                    height: '24px',
                    mr: -0.75,
                    width: '24px'
                  }}
                >
                  <AppIcon iconName='close' opticalSize={22} />
                </IconButton> */}
              </Box>
              <Box sx={{ overflow: 'auto', pt: 0.5 }}>
                <List
                  /* disablePadding */
                  sx={{
                    ...(mediumScreen
                      ? {
                          px: 1.25,
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 1
                        }
                      : {
                          p: 2,
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          gap: 1
                        })
                  }}
                >
                  {sidebarItems.map((item: any) => (
                    <React.Fragment key={item.name}>
                      {item.type === 'collapse' && (
                        <SideNavCollapseItem key={item.name} name={item.name} iconName={item.iconName} to={item.to}>
                          {item.children.map((item: any) =>
                            item.name === 'Registrations' ? (
                              <SideNavItem
                                key={item.name}
                                name={item.name}
                                iconName={item.iconName}
                                to={item.to}
                                position={item?.position}
                              />
                            ) : injuryHandlerReports.length ? (
                              <SideNavItem
                                key={item.name}
                                name={item.name}
                                iconName={item.iconName}
                                to={item.to}
                                position={item?.position}
                              />
                            ) : (
                              <React.Fragment></React.Fragment>
                            )
                          )}
                        </SideNavCollapseItem>
                      )}
                      {item.type === 'link' && (
                        <SideNavItem
                          key={item.name}
                          name={item.name}
                          iconName={item.iconName}
                          to={item.to}
                          variant="top-level"
                        />
                      )}
                    </React.Fragment>
                  ))}
                </List>
              </Box>
            </Paper>
          </Box>
        </Drawer>
        <Box
          sx={{
            ml: {
              xs: -2
            },
            pt: {
              xs: '16px',
              md: '64px'
            },
            height: '100%',
            overflowY: 'auto',
            display: 'flex',
            flex: 1
          }}
        >
          {injuryReportsFetched === 'succeeded' && actionsFetched === 'succeeded' ? (
            <React.Fragment>{children}</React.Fragment>
          ) : (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: '100%'
              }}
            >
              <CircularLoader />
            </Box>
          )}
          <AppSnackBar />
        </Box>
      </Box>
      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="secondary" />
      </Backdrop>
    </Box>
  );
}
