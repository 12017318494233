import React from 'react';

import { Box, Typography } from '@mui/material';
import { WithChildren } from '../../types';

interface DescriptionField {
  field?: string;
}

type DescriptionValue =
  | {
      value?: string;
      valueComponent?: never;
    }
  | {
      value?: never;
      valueComponent?: any;
    };

type DescriptionListItemProps = DescriptionField &
  DescriptionValue & {
    size?: 'small' | 'normal';
    disabledOrUndefined?: boolean;
  };

export const DescriptionListItem = (props: DescriptionListItemProps) => {
  const { field, value, valueComponent, size = 'medium', disabledOrUndefined = false } = props;
  return (
    <React.Fragment>
      <Typography variant={size === 'small' ? 'body2' : 'body1'} color="textVariant" sx={{ pr: 0.75 }}>
        {field}
      </Typography>
      {value && (
        <Typography
          variant={size === 'small' ? 'body2' : 'body1'}
          sx={{ color: disabledOrUndefined ? 'text.disabled' : 'text.primary' }}
        >
          {value}
        </Typography>
      )}
      {valueComponent && valueComponent}
    </React.Fragment>
  );
};

type DescriptionsGridProp = WithChildren<{
  gap?: number;
}>;

export const DescriptionList = (props: DescriptionsGridProp) => {
  const { children, gap = 1.75 } = props;

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        gap: gap
      }}
    >
      {children}
    </Box>
  );
};
