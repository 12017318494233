import { createContext, useContext } from 'react';
import { InjuryReportCase } from '../types';

export const CurrentInjuryReportContext = createContext<InjuryReportCase | null>(null);

export const useCurrentInjuryReport = () => {
  const currentInjuryReportContext = useContext(CurrentInjuryReportContext);

  if (!currentInjuryReportContext) {
    throw new Error(
      "useCurrentInjuryReport has to be used within <CurrentInjuryReportContext.Provider>"
    );
  }

  return currentInjuryReportContext;
}