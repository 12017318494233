
import './map.css';
import React from 'react';
import Map from 'react-map-gl';
import MapGL, { Layer, Marker, Popup, Source } from 'react-map-gl';
import { APP_COLORS } from '../../../config';
import { useAppDispatch, useAppSelector } from '../../../store';
import { selectInjuryPropertiesForSiteFeature } from '../../injuries/store/injuryReportsSlice';
import { selectCurrentAreaId, selectInitialCoordinates, selectAreaFeatures, selectSiteOutlineFeatures, selectSites, siteClicked, siteSelected, selectCurrentSiteId, selectSiteIds } from '../../locations/locationsSlice';
import { PopupCard, PopupCardProps } from './HoverPopup';
import { clusterCountLayer, clusterLayer, mainLayer, unclusteredPointLayer } from './layers';
import _ from 'lodash';
import { alpha } from '@mui/material/styles';
import { retrieveSiteCurrent, retrieveSiteStatus } from '../../locations/helpers/mapExpressions';
export const MAPBOX_TOKEN = 'pk.eyJ1IjoiZW1rZiIsImEiOiJja29ib2k5cjgzMWd6MnBscDlvb3d0eWViIn0.nFACTNsfD10_6ooqj_SH4A';
import { useTheme } from '@mui/material';

export const paletteMapStyle: any = {
  'light': 'mapbox://styles/emkf/clf8kdbr8001901mwpja2vrdi',
  'dark': 'mapbox://styles/emkf/clfbjsjjf002h01mx1jn4mdnf'
};

const mapStyles: any = {
  width: '100%',
  flex: 1,
  minHeight: 0,
}

type HoverInfo = {
  longitude: number;
  latitude: number;
  card: PopupCardProps;
}

export default function AppMap() {
  const mapRef = React.useRef<any>(null);
  const [markerCoordinates, setMarkerCoordinates] = React.useState<any>({ lat: 59.9139, lng: 10.7522 });
  const [hoverInfo, setHoverInfo] = React.useState<HoverInfo|undefined>();
  const [cursor, setCursor] = React.useState('auto');
  
  const theme = useTheme();

  const dispatch = useAppDispatch();

  const initialSiteCoordinates = useAppSelector(selectInitialCoordinates);
  const currentAreaId = useAppSelector(selectCurrentAreaId);
  const currentSiteId = useAppSelector(selectCurrentSiteId);

  const siteIds = useAppSelector(selectSiteIds);
  const areaFeatures = useAppSelector(selectAreaFeatures);
  const sites = useAppSelector(selectSites);

  const injuryReportSiteProperties = useAppSelector(selectInjuryPropertiesForSiteFeature);
  const areaOutlineFeatures = useAppSelector(selectSiteOutlineFeatures);

  const areaFeaturesWithStatusColors = React.useMemo(() => {
    console.log(`areaFeatures: ${JSON.stringify(areaFeatures)}`);
    return areaFeatures.map(areaFeature => {
      const id = areaFeature.properties?.id;
      let addedProperties = injuryReportSiteProperties[id] || {};
      return {
        ...areaFeature,
        properties: {
          ...areaFeature.properties,
          ...addedProperties,
        }
      } as GeoJSON.Feature<GeoJSON.Point>
    }/* ({
      ...siteFeature,
      properties: {
        ...siteFeature.properties,
        status: injuryReportSiteStatuses[siteFeature.properties?.id] || 0,
        iconImage: `pin-${injuryReportSiteStatuses[siteFeature.properties?.id] || 0}`,
      }
    } as GeoJSON.Feature<GeoJSON.Point>) */)
  }, 
  [areaFeatures, injuryReportSiteProperties]);

  const areaOutlinesFeaturesWithStatusColors = React.useMemo(() => {
    return areaOutlineFeatures.map(siteOutlineFeature => {
      const id = siteOutlineFeature.properties?.siteId;
      let addedProperties = injuryReportSiteProperties[id] || {};
      return {
        ...siteOutlineFeature,
        properties: {
          ...siteOutlineFeature.properties,
          ...injuryReportSiteProperties[id]
        }
      } as GeoJSON.Feature<GeoJSON.Polygon>
    })
  }, [areaOutlineFeatures, areaFeaturesWithStatusColors]);

  const mainFeatureCollection = React.useMemo(() => ({
    type: 'FeatureCollection', 
    features: [...areaFeaturesWithStatusColors],
  }) as GeoJSON.FeatureCollection<GeoJSON.Point>, [areaFeaturesWithStatusColors]);

  const outlineFeatureCollection = React.useMemo(() => ({
    type: 'FeatureCollection', 
    features: [...areaOutlinesFeaturesWithStatusColors],
  }) as GeoJSON.FeatureCollection<GeoJSON.Polygon>, [areaOutlinesFeaturesWithStatusColors]);

  React.useEffect(() => {
    console.log(`areaFeaturesWithStatusColors: ${JSON.stringify(areaFeaturesWithStatusColors)}`);
  }, [areaFeaturesWithStatusColors]);

  /* const retrieveSiteStatus: any = ['get', 'total', ['get', 'reportsCountStatus', ['properties']]];
  const retrieveSiteCurrent: any = ['get', 'current']; */
  const healthStatus1Count = ['==', retrieveSiteStatus, 1];
  const healthStatus2Count = ['==', retrieveSiteStatus, 2];
  const healthStatus3Count = ['==', retrieveSiteStatus, 3];

  const delayMapFeatureHoverEffect = React.useCallback(
    _.debounce((newHoverInfo: any) => {
      setHoverInfo(newHoverInfo)
    }, 
  200), []);

  const delayMapFeatureHoverEffectLonger = React.useCallback(
    _.debounce((newHoverInfo: any) => {
      setHoverInfo(newHoverInfo)
    }, 
  500), []);

  const delayResize = React.useCallback(
    _.debounce(() => {
      mapRef.current?.resize();
    }, 
  100), []);

  const delayFlyTo = React.useCallback(
    _.debounce((args: any) => {
      mapRef.current?.flyTo(args);
    }, 
  200), []);

  /* const onHover = React.useCallback((event: any) => {
    if (currentSite) {
      return;
    }
    const feature: any = event.features && event.features[0];
    if (feature) {

      let newHoverInfo: HoverInfo = {
        latitude: feature.geometry.coordinates[1],
        longitude: feature.geometry.coordinates[0],
        card: {
          label: feature.properties.label,
          accidents: {
            count: feature.properties.accidentCount,
            status: feature.properties.accidentStatus || 0,
          },
          illnesses: {
            count: feature.properties.illnessCount,
            status: feature.properties.illnessStatus || 0,
          },
          minorInjuries: {
            count: feature.properties.minorInjuryCount,
            status: feature.properties.minorInjuryStatus || 0,
          },
        }
      };
      setCursor('pointer');
      delayMapFeatureHoverEffect(newHoverInfo);
    } else {
      setCursor('auto');
      delayMapFeatureHoverEffect(undefined);
    }
  }, []); */
  const onHover = React.useCallback((event: any) => {
    const feature: any = event.features && event.features[0];
    if (feature) {

      let newHoverInfo: HoverInfo = {
        latitude: feature.geometry.coordinates[1],
        longitude: feature.geometry.coordinates[0],
        card: {
          label: feature.properties.label,
          reportsCount: JSON.parse(feature.properties.reportsCount) || 0,
          reportsCountStatus: JSON.parse(feature.properties.reportsCountStatus) || 0,
        }
      };
      setCursor('pointer');
      delayMapFeatureHoverEffect(newHoverInfo);
    } else {
      setCursor('auto');
      delayMapFeatureHoverEffect(undefined);
    }
  }, []);

  React.useEffect(() => {
    console.log(`mainFeatureCollection: ${JSON.stringify(mainFeatureCollection)}`);
  }, [mainFeatureCollection]);

  const handleClick = (event: any) => {
    const feature: any = event.features && event.features[0];
    if (feature) {
      if (feature.properties.id !== currentAreaId) {
        const site = siteIds[feature.properties.id];
        /* delayFlyTo({ center: [site.lng, site.lat], duration: 2000 }); */
        /* let newHoverInfo: HoverInfo = {
          latitude: feature.geometry.coordinates[1],
          longitude: feature.geometry.coordinates[0],
          card: {
            label: feature.properties.label,
            accidents: {
              count: feature.properties.accidentCount,
              status: feature.properties.accidentStatus || 0,
            },
            illnesses: {
              count: feature.properties.illnessCount,
              status: feature.properties.illnessStatus || 0,
            },
            minorInjuries: {
              count: feature.properties.minorInjuryCount,
              status: feature.properties.minorInjuryStatus || 0,
            },
          }
        };
        delayMapFeatureHoverEffectLonger(newHoverInfo); */
      }
      dispatch(siteClicked(feature.properties.id));
      delayResize();
    }
  }

  React.useEffect(() => {
    if (currentAreaId) {
      const site = siteIds[currentAreaId];
      delayFlyTo({ center: [site.lng, site.lat], zoom: 17, duration: 2000 });
    }
  }, [currentAreaId]);

  React.useEffect(() => {
    if (currentSiteId) {
      const currentBaseLocation = sites[currentSiteId];

      delayFlyTo({ center: [currentBaseLocation.coordinates.lng, currentBaseLocation.coordinates.lat], duration: 2000, zoom: 16 });
    }
  }, [currentSiteId]);

  
  const darkThemeTextOutline: any = [theme.palette.text.secondary, theme.palette.success.main, theme.palette.warning.main, theme.palette.error.main, theme.palette.text.secondary];
  
  const textForPalette = React.useMemo(() => {
    if (theme.palette.mode !== 'light') {
      return [
        'case',
        ['==', retrieveSiteCurrent, 1],
        theme.palette.primary.main,
        ['==', retrieveSiteStatus, 3],
        theme.palette.error.main,
        ['==', retrieveSiteStatus, 2],
        theme.palette.warning.main,
        ['==', retrieveSiteStatus, 1],
        theme.palette.success.main,
        theme.palette.text.secondary,
      ] as any
    }
    return [
      'case',
      ['==', retrieveSiteCurrent, 1],
      theme.palette.primary.main,
      ['==', retrieveSiteStatus, 3],
      theme.palette.error.main,
      ['==', retrieveSiteStatus, 2],
      theme.palette.warning.main,
      ['==', retrieveSiteStatus, 1],
      theme.palette.success.main,
      theme.palette.text.secondary,
    ] as any;
  }, [theme.palette.mode]);
  
  const outlineForPalette = React.useMemo(() => {
    if (theme.palette.mode === 'light') {
      return APP_COLORS.darkTextPrimary
    } 
    return APP_COLORS.textPrimary
  }, [theme.palette.mode]);

  return (
    <Map
      ref={mapRef}
      style={mapStyles}
      mapboxAccessToken={MAPBOX_TOKEN}
      mapStyle={paletteMapStyle[theme.palette.mode as any]}
      initialViewState={{
        latitude: initialSiteCoordinates.lat,
        longitude: initialSiteCoordinates.lng,
        zoom: 16
      }}
      interactiveLayerIds={['main-sites']}
      onMouseMove={onHover}
      cursor={cursor}
      onClick={handleClick}
    >
      <Source
        id="main"
        type="geojson"
        data={mainFeatureCollection}
        cluster={true}
        clusterProperties={{
          healthStatus1Count: ['+', ['case', healthStatus1Count, 1, 0]],
          healthStatus2Count: ['+', ['case', healthStatus2Count, 1, 0]],
          healthStatus3Count: ['+', ['case', healthStatus3Count, 1, 0]]
        }}
      >
        <Layer
          id="main-sites"
          type="symbol"
          layout={{
            ...mainLayer.layout,
            'icon-image': [
              'case',
              ['==', ['get', 'showIcon'], 0],
              '',
              ['==', ['get', 'current'], 1],
              'pin-4',
              ['concat', 'pin-', retrieveSiteStatus]
            ],
            'text-offset': [
              'case',
              ['==', ['get', 'showIcon'], 0],
              ['literal', [0, 0]],
              ['==', retrieveSiteCurrent, 1],
              ['literal', [0, 1.9]],
              ['literal', [0, 1.7]]
            ],
            'text-font': [
              'case',
              ['==', retrieveSiteCurrent, 1],
              ['literal', ['Inter Tight Bold']],
              ['literal', ['Inter Tight Medium']]
            ]
          }}
          paint={{
            'text-color': textForPalette,
            'text-halo-color': outlineForPalette,
            'text-halo-width': 1
          }}
          /* filter={['==', ['get', 'type'], 'pin']} */
        />
        <Layer {...clusterLayer} />
        <Layer {...clusterCountLayer} />
      </Source>
      <Source
        id="site-areas"
        type="geojson"
        data={outlineFeatureCollection}
        /* cluster={true} */
      >
        <Layer
          id="site-fill"
          beforeId="main-sites"
          type="fill"
          paint={{
            'fill-color': [
              'case',
              ['==', retrieveSiteCurrent, 1],
              alpha(theme.palette.primary.light, 0.16),
              ['==', retrieveSiteStatus, 3],
              alpha(theme.palette.error.light, 0.12), // '#ead9dd', // error over light3 16%
              ['==', retrieveSiteStatus, 2],
              alpha(theme.palette.warning.light, 0.12),
              ['==', retrieveSiteStatus, 1],
              alpha(theme.palette.success.light, 0.12),
              alpha(theme.palette.secondary.light, 0.8)
            ]
          }} /* 
          filter={['==', ['get', 'type'], 'outline']} */
        />
        <Layer
          id="site-outlines"
          beforeId="site-fill"
          type="line"
          paint={{
            'line-color': [
              'case',
              ['==', retrieveSiteCurrent, 1],
              theme.palette.secondary.main,
              ['==', retrieveSiteStatus, 3],
              theme.palette.error.main,
              ['==', retrieveSiteStatus, 2],
              theme.palette.warning.main,
              ['==', retrieveSiteStatus, 1],
              theme.palette.success.main,
              theme.palette.text.secondary
            ],
            'line-width': ['case', ['==', retrieveSiteCurrent, 1], 6, 3]
          }}
          /* filter={['==', ['get', 'type'], 'outline']} */
        />
      </Source>
      {hoverInfo && (
        <Popup
          latitude={hoverInfo.latitude}
          longitude={hoverInfo.longitude}
          closeButton={false}
          offset={[0, -10] as [number, number]}
        >
          {<PopupCard {...hoverInfo.card} />}
        </Popup>
      )}
    </Map>
  );
}