import {
  GridColDef,
  GridColumnVisibilityModel
} from '@mui/x-data-grid';
import React from 'react';
import { useAppSelector } from '../../../../store';
import { RDataGrid } from '../../../../components/DataGrid/RDataGrid';
import { IActiveFilter, IFilterControl } from '../../../../components/DataGrid/types';
import { selectAllAreas, selectAllSites, selectSites } from '../../../locations/locationsSlice';

const RenderSiteCell = (params: any) => {
  const sites = useAppSelector(selectSites);
  return (
    <>
      {sites[params.value].name}
    </>
  )
}

export type UnsavedInjuryReportsDataGridFilter = {
  field: any; 
  value?: string;
}


export type InjuryReportsFilter = {
  field: any; 
  getFieldValue?: (value: any) => any;
  operator?: string; // default to '==' operator
  // operator 
  value: string;
}



export const AreasDataGrid = () => {
  const sites = useAppSelector(selectAllSites);
  const areas = useAppSelector(selectAllAreas);
  const siteEntities = useAppSelector(selectSites);

  const controls = React.useMemo(() => {
    let controlItems: IFilterControl[] = [
      {
        field: 'variant',
        label: 'Variant',
        options: [
          { value: 'Area', label: 'Area' },
          { value: 'Point', label: 'Point' },
        ]
      },
      {
        field: 'site',
        label: 'Site',
        options: sites.map(site => ({ value: site.id, label: site.name }))
      },
    ];

    return controlItems;
  }, [sites, areas]);
  
  const [activeFilters, setActiveFilters] = React.useState<any[]>([]);

  const handleClearFilters = () => {
    setActiveFilters([]);
  }

  const handleRemoveFilter = (field: string) => {
    setActiveFilters(prevFilters => {
      let newFilters = [...prevFilters];
      newFilters = newFilters.filter(filter => filter.field !== field);
      return newFilters;
    });
  }
  const handleSetFilter = (newFilter: IActiveFilter) => {
    setActiveFilters(prevFilters => {
      let newFilters = [...prevFilters];
      const filterIndex = newFilters.findIndex(filter => filter.field === newFilter.field);
      if (filterIndex > -1) {
        newFilters[filterIndex] = newFilter;
      } else {
        newFilters.push(newFilter);
      }
      return newFilters;
    });
  }
  const items = React.useMemo(() => {
    return areas.map(area => (
      {
        id: area.id,
        name: area.name,
        coordinates: `${area.coordinates.lat}, ${area.coordinates.lng}`,
        variant: area.areaCoordinates ? 'Area' : 'Point',
        site: area.site,
      }
    ))
  }, [areas, sites]);

  const columns = React.useMemo(() => {
    
    const columnByField: { [field: string]: GridColDef } = {
      'site': {  
        field: 'site',
        headerName: 'Site',
        minWidth: 200,
        flex: 2,
        renderCell: RenderSiteCell,
      },
      'name': {  
        field: 'name',
        headerName: 'Name',
        minWidth: 200,
        flex: 2,
      },
      'coordinates': {
        field: 'coordinates',
        headerName: 'Coordinates',
        minWidth: 200,
        flex: 2,
      },
      'variant': {
        field: 'variant',
        headerName: 'Variant',
        minWidth: 160,
        flex: 1,
      },
    };
    
    let cols = ['name', 'coordinates', 'variant', 'site'];

    return cols.map((field) => columnByField[field]);
  }, []);

  const initialColumnVisibilityModel: GridColumnVisibilityModel = {
    'lat': false,
    'lng': false,
  };

  return (
    <RDataGrid
      items={items}
      cols={columns}
      filters={{
        controls: controls, 
        addedControls: [],
        active: activeFilters, 
        handleSetFilter, 
        handleRemoveFilter, 
        handleResetFilterControls: handleClearFilters,
      }}
      initialColumnVisibilityModel={initialColumnVisibilityModel}
    />
  )
}