import * as yup from 'yup';
import { BaseInjuredPartyControls, baseInjuredPartySchema } from '..';
import { WithFormMethods } from '../../../../../components/Forms';
import { FormTextField } from '../../../../../components/FormInputs/FormTextField';
import { useTranslation } from 'react-i18next';
import { getTranslationKeyForSectionFieldLabel, getTranslationKeyForSectionFieldPlaceholder } from '../../../helpers/dataMapping';
import { STUDENT_SECTION_NAMES } from '../../../constants/student';

const AccidentOccuredOptions = [
  'At teaching location',
  'During teaching hours', 
  'At SFO', 
  'Other'
];

export const injuredStudentSchema = baseInjuredPartySchema.shape({
  name: yup.string().required('between 4 63').min(4, 'min 4').max(63, 'max 63'),
});

export type InjuredStudentSection = yup.InferType<typeof injuredStudentSchema>;

export const InjuredStudentControls = (props: WithFormMethods<InjuredStudentSection>) => {
  const { t } = useTranslation();
  const { methods } = props;
  const { control } = methods;
  return (
    <>
      <FormTextField
        control={control}
        name='name' 
        label={t(getTranslationKeyForSectionFieldLabel(STUDENT_SECTION_NAMES.STUDENT, 'name'))}
      />
      <BaseInjuredPartyControls
        methods={methods}
      />
    </>
  );
}
