
import { IBM_DEFAULT_CATEGORICAL_LIGHT } from '../../config/colors';

export const getChartColorByIndex = (i: number) => {
  if (i < IBM_DEFAULT_CATEGORICAL_LIGHT.length) {
    return IBM_DEFAULT_CATEGORICAL_LIGHT[i];
  }
  return IBM_DEFAULT_CATEGORICAL_LIGHT[0];
}

type args = {
  theme: any,
  title?: string,
  subtitle?: string,
  t?: any,
  displayDataLabels?: boolean,
}

export const getDefaultChartPropsWithTitle = ({ theme, title, subtitle, t, displayDataLabels = false }: args) => {
  return {
    maintainAspectRatio: false,
    responsive: true,
    /* interaction: {
      mode: 'index',
      intersect: false,
    }, */
    scales: {
      x: {
        border: {
          display: false
        },
        grid: {
          display: false,
          color: theme.palette.divider,
          borderColor: theme.palette.divider,
        },
        ticks: {
          color: theme.palette.text.secondary,
          font: {
            family: theme.typography.body2.fontFamily,
            size: 13,
            lineHeight: theme.typography.body2.lineHeight,
          },
          precision: 0,
          callback: function(val, index) {
            return t ? t(`wif.injury.stats.months.${this.getLabelForValue(val)}`) : val
          }
        },
      },
      y: {
        
        grid: {
          color: theme.palette.divider,
          borderColor: theme.palette.divider,
          borderDash: [3, 3],
        },
        ticks: {
          callback: function (value) {
            // truncate the labels only in this axis
            const lbl = this.getLabelForValue(value as number);
            if (typeof lbl === 'string' && lbl.length > 20) {
              return `${lbl.substring(0, 20)}...`;
            }
            return lbl;
          },
          color: theme.palette.text.secondary,
          /* maxTicksLimit: 6, */
          font: {
            family: theme.typography.body2.fontFamily,
            size: 13,
            lineHeight: theme.typography.body2.lineHeight,
          },
          precision: 0,
        },
      }
    },
    plugins: {
      datalabels: {
        color: theme.palette.background.paper,
        display: displayDataLabels,
        font: {
          size: 14,
          family: 'Inter',
          weight: 500,
          opacity: 1,
        }
      },
      tooltip: {
        intersect: false
      },
      legend: {
        position: 'top' as const,
        align: 'end',
        labels: {
          boxWidth: 10,
          boxHeight: 10,
          /* usePointStyle: true, */
          /* useBorderRadius: true,
          borderRadius: 6, */
          usePointStyle: false,
          color: theme.palette.text.secondary,
          font: {
            size: 14,
            family: 'Inter',
            opacity: 1,
          }
        },
      },
      title: {
        padding: {
          bottom: -10,
        },
        display: true,
        align: 'start',
        color: theme.palette.text.primary,
        font: {
          family: 'Inter', 
          size: theme.typography.h4.fontSize, 
          weight: 600,
          lineHeight: theme.typography.h4.lineHeight,
        },
        text: title ? title : 'Title',
      },
      subtitle: {
        display: false,
        text: subtitle || 'Last year',
        align: 'start',
        color: theme.palette.text.secondary,
        font: {
          family: 'Inter', 
          size: theme.typography.body2.fontSize, 
          lineHeight: theme.typography.body2.fontSize,
        },
        padding: {
          top: 14
        }
      }
    },
  } as any
};

