export const asdf = 'asdf';
import React from 'react';
import { useTheme, alpha } from '@mui/material/styles';
import _ from 'lodash';
import { useAppSelector } from '../../../../store';
import { selectAllInjuryReports } from '../../store/injuryReportsSlice';
import { InjuryReportsFilter } from '../InjuryReportsDataGrid/InjuryReportsDataGrid';
import LineChart from '../../../../components/Charts/LineChart';
import { getChartColorByIndex, getDefaultChartPropsWithTitle } from '../../../../components/Charts/helpers';
import { useTranslation } from 'react-i18next';

export const monthLabels = [
  '1', '2', '3', '4', '5', '6',
  '7', '8', '9', '10', '11', '12',
];

// !TODO Move to helpers or common 
export const getInitialMonthLabelArrForYear = (lastMonth: number) => {
  let labels: string[] = [];
  let curMonth = lastMonth + 1;
  for (let i = 0; i < monthLabels.length; i++) {
    labels.push(monthLabels[curMonth]);
    if (curMonth < 11) {
      curMonth++;
    } else {
      curMonth = 0;
    }
  }
  return labels;
};

export const getInitialByMonthForYear = (lastMonth: number) => {
  const labels = getInitialMonthLabelArrForYear(lastMonth);
  let data: any = {};
  for (let label of labels) {
    data[label] = 0;
  }
  return data;
};

type InjuryReportsByMonthChartProps = {
  filters?: InjuryReportsFilter[];
  lines?: {
    field: string;
    values: string[];
    colors?: string[];
    labels?: string[];
  };
  areas?: boolean;
  title?: string;
  subtitle?: string;
};

export const InjuryReportsByMonthChart = (props: InjuryReportsByMonthChartProps) => {
  const {
    filters = [],
    lines = { field: 'type', values: ['Accident', 'Illness', 'Minor injury'] },
    areas = false,
    title = 'Title', 
    subtitle = 'Last year'
  } = props;
  const theme = useTheme() as any;
  const { t } = useTranslation();
  const injuryReports = useAppSelector(selectAllInjuryReports);

  const filteredReports = React.useMemo(() => {
    let reports = [...injuryReports];
    for (let filter of filters) {
      reports = reports.filter((report: any) => report[filter.field] === filter.value);
    }
    return reports;
  }, [filters, injuryReports]);

  const chartData = React.useMemo(() => {
    let datasets: any = [];
    let lineByMonth: any = {};
    let options = getDefaultChartPropsWithTitle({ theme, title, subtitle, t });
    options.plugins.subtitle.display = true;
    for (let line of lines.values) {
      lineByMonth[line] = getInitialByMonthForYear(new Date().getMonth());
    }

    for (let report of filteredReports) {
      const reportDate = new Date(report.reportedTimestamp);
      if (
        reportDate.getFullYear() === new Date().getFullYear() ||
        (reportDate.getFullYear() === new Date().getFullYear() - 1 && reportDate.getMonth() >= new Date().getMonth())
      ) {
        const month = reportDate.getMonth();
        const key = lines.field as any;
        if (lines.values.includes(report[key])) {
          lineByMonth[report[lines.field]][monthLabels[month]] += 1;
        }
      }
    }

    let sortedLines = lines.values.slice();

    if (areas) {
      sortedLines = sortedLines.sort(
        (a, b) =>
          Math.max(...(Object.values(lineByMonth[a]) as any)) - Math.max(...(Object.values(lineByMonth[b]) as any))
      );
    }

    const monthLabelForLastYear = getInitialMonthLabelArrForYear(new Date().getMonth());
    
    for (let i = 0; i < sortedLines.length; i++) {
      const line = sortedLines[i];
      let colorIndex = lines.values.indexOf(line);
      const color = lines.colors ? lines.colors[colorIndex] : getChartColorByIndex(colorIndex);
      if (areas) {
        console.log(`line: ${line} data: ${JSON.stringify(lineByMonth[line])}`);
      }
      let lineData: { x: any, y: any }[] = [];
      for (let monthLabel of monthLabelForLastYear) {
        lineData.push({ x: monthLabel, y: lineByMonth[line][monthLabel] });
      }
      datasets.push({
        data: lineData,
        borderColor: color,
        label: lines?.labels?.[lines.values.indexOf(line)] || line,
        pointBackgroundColor: color,
        pointRadius: 0,
        lineTension: 0.4,
        fill: areas && i > 0 ? '-1' : areas ? 'origin' : false,
        backgroundColor: areas ? alpha(color, 0.8) : color
      });
    }

    
   
    const data = {
      labels: monthLabelForLastYear,
      datasets: datasets
    };

    return {
      data,
      options
    };
  }, [filteredReports, t]);

  return <LineChart data={chartData.data} options={chartData.options} />;
};