import React from 'react';
import {
  DataGrid,
  GridColumnVisibilityModel,
  GridRowSelectionModel,
  GridColDef,
  GridRow,
  GridColumnHeaders,
  GridSortModel,
  GridEventListener
} from '@mui/x-data-grid';
import Checkbox from '@mui/material/Checkbox';
import { IActiveFilter, IFilterControl, IFilters } from './types';
import { DataGridContainer } from './DataGridContainer';
import { RDataGridToolbar } from './RDataGridToolbar';
import { RDataGridPagination } from './RDataGridPagination';
import LinearProgress from '@mui/material/LinearProgress';
import { useTranslation } from 'react-i18next';
import { TKB_INJURY } from '../../features/injuries/constants';
import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { RSelectedItemToolbarProps } from './RDataGridSelectedToolbar';

const StyledGridOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%'
}));

const CustomTextField = (props: any) => {
  return <TextField {...props} variant="outlined" size="small" />;
};

type RDataGridProps = {
  items: readonly any[];
  cols: GridColDef[];
  filters?: IFilters;
  addableControls?: {
    controls: IFilterControl[];
    handleAddFilterControl: (control: IFilterControl) => void;
  };
  initialColumnVisibilityModel?: GridColumnVisibilityModel;
  initialSortModel?: any;
  loading?: boolean;
  sortModel?: any;
  handleChangeSortModel?: (model: GridSortModel) => void;
  columnVisibilityModel?: GridColumnVisibilityModel;
  handleChangeColumnVisibilityModel?: (model: GridColumnVisibilityModel) => void;
  onRowClick?: GridEventListener<'rowClick'>;
  onRowDoubleClick?: GridEventListener<'rowDoubleClick'>;
  selected?: RSelectedItemToolbarProps;
};

export const RDataGrid = (props: RDataGridProps) => {
  const {
    items,
    cols,
    filters,
    addableControls,
    initialColumnVisibilityModel,
    initialSortModel = {
      field: 'reportedTimestamp',
      sort: 'desc'
    },
    loading,
    sortModel,
    handleChangeSortModel,
    columnVisibilityModel,
    handleChangeColumnVisibilityModel,
    onRowDoubleClick,
    onRowClick,
    selected
  } = props;

  const { t } = useTranslation();

  const activeFilters = filters?.active || [];

  const rows = React.useMemo(() => {
    let filteredItems: any = items.slice();
    for (const applied of activeFilters) {
      filteredItems = filteredItems.filter((item: any) => {
        if (item[applied.field] === undefined) return false;
        return item[applied.field] === applied.value;
      });
    }
    return filteredItems;
  }, [items, activeFilters]);

  const columns = React.useMemo(() => {
    /* let filteredColumns = cols.slice();
    const activeFilterFields = activeFilters.filter(applied => filters.controls.find(control => control.field === applied.field).removeCol).map(applied => applied.field);

    return filteredColumns.filter((col) => {
      return activeFilterFields.indexOf(col.field) === -1;
    }); */
  }, [cols, activeFilters]);

  const filterControls = React.useMemo(() => {
    let updatedControls: any[] = [];
    if (filters) {
      for (let i = 0; i < filters.controls.length; i++) {
        const activeIndex = activeFilters.findIndex((filter) => filter.field === filters.controls[i].field);
        if (activeIndex > -1) {
          if (filters.controls[i].options.length > 5) {
            const usedValues = rows.map((row) => row[filters.controls[i].field]);
            const updatedControlOptions: any[] = filters.controls
              .slice()
              [i].options.filter((option) => usedValues.indexOf(option.value) > -1);
            if (updatedControlOptions.length > 0) {
              updatedControls.push({ ...filters.controls[i], options: updatedControlOptions });
            }
          } else {
            updatedControls.push({ ...filters.controls[i] });
          }
        } else {
          const usedValues = rows.map((row) => row[filters.controls[i].field]);
          const updatedControlOptions: any[] = filters.controls
            .slice()
            [i].options.filter((option) => usedValues.indexOf(option.value) > -1);
          if (updatedControlOptions.length > 0) {
            updatedControls.push({ ...filters.controls[i], options: updatedControlOptions });
          }
        }
      }
    }
    return updatedControls;
  }, [filters]);

  const initialState = React.useMemo(() => {}, [
    initialColumnVisibilityModel,
    initialSortModel,
    columnVisibilityModel,
    sortModel
  ]);

  return (
    <DataGridContainer>
      <DataGrid
        onRowDoubleClick={onRowDoubleClick}
        onRowClick={onRowClick}
        loading={loading}
        rows={rows}
        columns={cols}
        hideFooterSelectedRowCount
        columnVisibilityModel={columnVisibilityModel}
        sortModel={sortModel}
        onColumnVisibilityModelChange={handleChangeColumnVisibilityModel}
        onSortModelChange={handleChangeSortModel}
        localeText={{
          toolbarColumns: '',
          toolbarExport: '',
          noResultsOverlayLabel: t(`${TKB_INJURY}.datagrid.noResultsOverlayLabel`),
          noRowsLabel: t(`${TKB_INJURY}.datagrid.noRowsLabel`),
          toolbarQuickFilterPlaceholder: t(`${TKB_INJURY}.datagrid.searchText`),
          columnsPanelShowAllButton: t(`${TKB_INJURY}.datagrid.showAll`),
          columnsPanelHideAllButton: t(`${TKB_INJURY}.datagrid.hideAll`),
          toolbarExportCSV: t(`${TKB_INJURY}.datagrid.exportCSV`),
          toolbarExportPrint: t(`${TKB_INJURY}.datagrid.exportPrint`),
          columnMenuFilter: t(`${TKB_INJURY}.datagrid.filter`),
          columnMenuSortAsc: t(`${TKB_INJURY}.datagrid.sortAsc`),
          columnMenuSortDesc: t(`${TKB_INJURY}.datagrid.sortDesc`),
          columnMenuHideColumn: t(`${TKB_INJURY}.datagrid.hideColumn`),
          columnMenuManageColumns: t(`${TKB_INJURY}.datagrid.manageColumns`),
          columnsPanelTextFieldLabel: t(`${TKB_INJURY}.datagrid.columnsPanelTextFieldLabel`),
          columnsPanelTextFieldPlaceholder: t(`${TKB_INJURY}.datagrid.columnsPanelTextFieldPlaceholder`)
        }}
        slots={{
          toolbar: RDataGridToolbar,
          pagination: RDataGridPagination,
          baseTextField: CustomTextField
        }}
        columnHeaderHeight={48}
        slotProps={{
          toolbar: {
            filters:
              {
                ...filters,
                controls: filterControls
              } || undefined,
            addableControls: addableControls || undefined,
            selected: selected || undefined
          },
          columnsPanel: {}
        }}
        pageSizeOptions={[25]}
        initialState={{
          pagination: { paginationModel: { pageSize: 25 } }
        }}
      />
    </DataGridContainer>
  );
};
