import Checkbox from '@mui/material/Checkbox';
import {
  GridColDef,
  GridColumnHeaders,
  GridColumnVisibilityModel,
  GridComparatorFn,
  GridEventListener,
  GridRow,
  GridRowSelectionModel,
  GridSortModel
} from '@mui/x-data-grid';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { RDataGrid } from '../../../../components/DataGrid/RDataGrid';
import { IActiveFilter, IFilterControl } from '../../../../components/DataGrid/types';
import { AppIcon } from '../../../../components/Elements';
import { RootState, useAppDispatch, useAppSelector } from '../../../../store';
import { formatDateFromTimestamp, formatDateTimeFromTimestamp } from '../../../../utils/format';
import { getActiveUser, getHasHandlerReadClaim } from '../../../../utils/user';
import {
  makeSelectEmployeeHandlerDepartmentIdsByTenantAdminUserId,
  makeSelectStudentHandlerDepartmentIdsByTenantAdminUserId,
  selectAllDepartments,
  selectDepartmentEntities
} from '../../../departments/departmentsSlice';
import { selectAllEmployees, selectEmployeesFetched } from '../../../employees/employeesSlice';
import { selectAppLoading, selectSplitViewEnabled } from '../../../global/controlsSlice';
import { selectNonconformityReportEntities } from '../../../nonconformity/nonconformitySlice';
import { selectCurrentSiteId, selectSitePaths } from '../../../sites/sitesSlice';
import { CASE_STATUS, REPORT_CATEGORY, REPORT_TYPES, TKB_INJURY } from '../../constants';
import { EMPLOYEE_SECTION_NAMES } from '../../constants/employee';
import { STUDENT_SECTION_NAMES } from '../../constants/student';
import {
  InjuryModuleName,
  InjuryViewName,
  activeFilterRemoved,
  activeFilterSet,
  filterControlAdded,
  filterControlsReset,
  injuryControlChanged,
  makeSelectInjuryControlsByQuery
} from '../../store/injuryControlsSlice';
import {
  injuryReportCleared,
  injuryReportItemClicked,
  selectAllInjuryReports,
  selectAllInjuryUnits,
  selectCurrentInjuryReportId,
  selectInjuryHandlerReportIds,
  selectInjuryRegistrationReportIds,
  selectInjuryReportsFetched,
  selectInjuryUnitEntities
} from '../../store/injuryReportsSlice';
import { InjuryReportCase } from '../../types';
import { RenderCopyCell, RenderLinkCell } from './RenderCell';
import { RenderProgressWithStatusColor } from './RenderCell/RenderProgressCell';
import { RenderCategoryCell, RenderDepartmentCell, RenderDueDateCell, RenderHandlerCell, RenderInjuryCommentsCell, RenderInjuryMessagesCell, RenderNcReportCell, RenderStatusCell, RenderTypeCell, RenderUserCell } from './RenderCell/RenderStatusCell';
import { getTranslatedInjuryReportName } from '../../helpers';

const datetimeSortComparator: GridComparatorFn<any> = (a, b) => {
  return new Date(a.value).getTime() - new Date(b.value).getTime();
}

const MemoizedRow = React.memo(GridRow);
const MemoizedColumnHeaders = React.memo(GridColumnHeaders);
const userClaimId = 'b4cb162c-2f5f-11ee-be56-0242ac120002';

const CustomCheckbox = (props: any) => {
  return <Checkbox icon={<AppIcon iconName="clinical_notes" />} {...props} />;
};

export type UnsavedInjuryReportsDataGridFilter = {
  field: any;
  value?: string;
};

export type InjuryReportsFilter = {
  field: any;
  getFieldValue?: (value: any) => any;
  operator?: string; // default to '==' operator
  // operator
  value: string;
};

type InjuryReportsDataGridProps = {
  isHandler?: boolean;
  categoryFilter?: string;
  userIdFilter?: string;
  reporterFilter?: string;
  // view
  view?: InjuryViewName;
  // or query or list
  module?: InjuryModuleName;
};

type adminModuleType = {
  critical: boolean;
  documentHandling: boolean;
  fall: boolean;
  injuryEmployee: boolean;
  injuryStudent: boolean;
  manuals: boolean;
  nonconformity: boolean;
  privacypolicy: boolean;
  scheduler: boolean;
  weblink: boolean;
};

export const InjuryReportsDataGrid = (props: InjuryReportsDataGridProps) => {
  const { categoryFilter, userIdFilter, isHandler = false, reporterFilter, view = 'reporter', module = 'all' } = props;
  const [adminModules, setAdminModules] = React.useState<adminModuleType>(undefined);
  const [userClaim, setUserClaim] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(true);
  const { t, i18n } = useTranslation();
  const activeUser = getActiveUser();
  const navigate = useNavigate();

  const selectInjuryControlsByViewAndModule = React.useMemo(makeSelectInjuryControlsByQuery, []);
  const injuryControls = useAppSelector((state: RootState) =>
    selectInjuryControlsByViewAndModule(state, { view, module })
  );
  const { sortModel, columnVisibilityModel, addedControls, activeFilters } = injuryControls ?? {};

  const handleChangeColumnVisibilityModel = (newModel: GridColumnVisibilityModel) => {
    dispatch(injuryControlChanged({ view, module, controlName: 'columnVisibilityModel', controlValue: newModel }));
  };
  const handleChangeSortModel = (newModel: GridSortModel) => {
    dispatch(injuryControlChanged({ view, module, controlName: 'sortModel', controlValue: newModel }));
  };

  const handleSetFilter = (newFilter: IActiveFilter) => {
    dispatch(activeFilterSet({ view, module, newFilter }));
  };

  const handleRemoveFilter = (field: string) => {
    dispatch(activeFilterRemoved({ view, module, field }));
  };

  if (useAppSelector(selectAppLoading)) setLoading(useAppSelector(selectAppLoading));

  React.useEffect(() => {
    setLoading(true);
    // Retrieve the item from local storage
    const ngStorageCurrent = localStorage.getItem('ngStorage-current');
    const current = JSON.parse(ngStorageCurrent);
    //set permitted modules by super admin
    if (current && current.organization) setAdminModules(current.organization.adminModules);
    //set user claim
    if (
      current &&
      current.user &&
      current.user.permissions &&
      current.user.permissions.claims &&
      current.user.permissions.claims.length
    )
      current.user.permissions.claims.map((claim) => {
        if (claim.claim_id === userClaimId) setUserClaim(true);
      });
    setLoading(false);
  }, []);

  const injuryReports = useAppSelector(selectAllInjuryReports);
  const ncReports = useAppSelector(selectNonconformityReportEntities);
  const currentSite = useAppSelector(selectCurrentSiteId);
  const sitePaths = useAppSelector(selectSitePaths);
  const currentInjuryReportId = useAppSelector(selectCurrentInjuryReportId);
  const splitViewEnabled = useAppSelector(selectSplitViewEnabled);
  const units = useAppSelector(selectAllInjuryUnits);
  const unitEntities = useAppSelector(selectInjuryUnitEntities);
  const departments = useAppSelector(selectAllDepartments);
  const departmentEntities = useAppSelector(selectDepartmentEntities);
  const employees = useAppSelector(selectAllEmployees);
  const handlerReportIds = useAppSelector(selectInjuryHandlerReportIds);
  const injuryRegistrationReportIds = useAppSelector(selectInjuryRegistrationReportIds);

  const controls = React.useMemo(() => {
    let controlItems: IFilterControl[] = [
      {
        field: 'status',
        label: t(`${TKB_INJURY}.status`),
        options: [
          { value: CASE_STATUS.ONGOING, label: t(`${TKB_INJURY}.ongoing`) },
          { value: CASE_STATUS.CLOSED, label: t(`${TKB_INJURY}.closed`) },
          { value: CASE_STATUS.REJECTED, label: t(`${TKB_INJURY}.rejected`) }
        ]
      },
      {
        field: 'type',
        label: t(`${TKB_INJURY}.type`),
        options: [
          { value: REPORT_TYPES.ACCIDENT, label: t(`${TKB_INJURY}.reportType.accident`) },
          { value: REPORT_TYPES.ILLNESS, label: t(`${TKB_INJURY}.reportType.illness`) },
          { value: REPORT_TYPES.MINOR_INJURY, label: t(`${TKB_INJURY}.reportType.minorInjury`) }
        ]
      }
    ];
    /* if (!isHandler) {
      controlItems.push({
        field: 'reporter_id',
        label: t('wif.injury.reportedBy'),
        options: employees.map(employee => ({ value: employee.id, label: employee?.name || employee.id }))
      });
    } */
    if (module === 'all') {
      controlItems.unshift({
        field: 'category',
        label: t(`${TKB_INJURY}.category`),
        options: [
          { value: REPORT_CATEGORY.STUDENT, label: t(`${TKB_INJURY}.reportCategory.student`) },
          { value: REPORT_CATEGORY.EMPLOYEE, label: t(`${TKB_INJURY}.reportCategory.employee`) }
        ]
      });
    }

    return controlItems;
  }, [module, view, t]);
  
  const selectInjuryStudentHandlerDepartmentIdsByUserId = React.useMemo(makeSelectStudentHandlerDepartmentIdsByTenantAdminUserId, []);
  const studentHandlerDepartmentIds = useAppSelector((state: RootState) => selectInjuryStudentHandlerDepartmentIdsByUserId(state, activeUser?.id));
  
  const selectInjuryEmployeeHandlerDepartmentIdsByUserId = React.useMemo(makeSelectEmployeeHandlerDepartmentIdsByTenantAdminUserId, []);
  const employeeHandlerDepartmentIds = useAppSelector((state: RootState) => selectInjuryEmployeeHandlerDepartmentIdsByUserId(state, activeUser?.id));
  
  
  
  const getDepartmentId = (injuryReport: InjuryReportCase) => {
    let sectionWithUnitAndDepartmentName = '';
    let departmentId = '';

    if (injuryReport.category === REPORT_CATEGORY.STUDENT) {
      if (injuryReport.type === REPORT_TYPES.MINOR_INJURY) {
        sectionWithUnitAndDepartmentName = STUDENT_SECTION_NAMES.MINOR_INJURY;
      } else {
        sectionWithUnitAndDepartmentName = STUDENT_SECTION_NAMES.SCHOOL;
      }
    } else if (injuryReport.category === REPORT_CATEGORY.EMPLOYEE) {
      if (injuryReport.type === REPORT_TYPES.MINOR_INJURY) {
        sectionWithUnitAndDepartmentName = EMPLOYEE_SECTION_NAMES.MINOR_INJURY;
      } else {
        sectionWithUnitAndDepartmentName = EMPLOYEE_SECTION_NAMES.EMPLOYER;
      }
    }
    /*  if (injuryReport?.sections[sectionWithUnitAndDepartmentName]?.unitId) {
      unitId = injuryReport.sections[sectionWithUnitAndDepartmentName].unitId;
    } */
    if (injuryReport?.sections[sectionWithUnitAndDepartmentName]?.departmentId) {
      departmentId = injuryReport.sections[sectionWithUnitAndDepartmentName].departmentId as string;
    }

    return departmentId;
  };

  const userHasReadAccess = getHasHandlerReadClaim();

  const filtered = React.useMemo(() => {
    let filtered = injuryReports.filter((injuryReport) => {
      if (categoryFilter && injuryReport?.category !== categoryFilter) {
        return false;
      }
      if (view === 'reporter') {
        return injuryRegistrationReportIds.indexOf(injuryReport.id) !== -1;
      }
      if (view === 'handler') {
        return handlerReportIds.indexOf(injuryReport.id) !== -1 || userHasReadAccess;
      }
      /* const departmentId = getDepartmentId(injuryReport);
      if (departmentId !== '' && isHandler) {
        if (injuryReport.category === 'employee' && employeeHandlerDepartmentIds.indexOf(departmentId) === -1) {
          return false;
        }
        if (injuryReport.category === 'student' && studentHandlerDepartmentIds.indexOf(departmentId) === -1) {
          return false;
        }
      } */ /* else if (departmentId !== '' && !isHandler) {
        if (injuryReport.category === 'employee' && employeeHandlerDepartmentIds.indexOf(departmentId) !== -1) {
          return false;
        }
        if (injuryReport.category === 'student' && studentHandlerDepartmentIds.indexOf(departmentId) !== -1) {
          return false;
        }
      } */
      /* if (reporterFilter && injuryReport?.reporter_id !== reporterFilter) {
        return false;
      } */
      return true;
    });
    return filtered;
  }, [injuryReports, categoryFilter, reporterFilter, userHasReadAccess, handlerReportIds, injuryRegistrationReportIds]);

  const getSectionWithDepartmentAndUnitName = (injuryReport: InjuryReportCase) => {
    let sectionWithUnitAndDepartmentName = undefined;
    if (injuryReport.category === REPORT_CATEGORY.STUDENT) {
      if (injuryReport.type === REPORT_TYPES.MINOR_INJURY) {
        sectionWithUnitAndDepartmentName = STUDENT_SECTION_NAMES.MINOR_INJURY;
      } else {
        sectionWithUnitAndDepartmentName = STUDENT_SECTION_NAMES.SCHOOL;
      }
    } else if (injuryReport.category === REPORT_CATEGORY.EMPLOYEE) {
      if (injuryReport.type === REPORT_TYPES.MINOR_INJURY) {
        sectionWithUnitAndDepartmentName = EMPLOYEE_SECTION_NAMES.MINOR_INJURY;
      } else {
        sectionWithUnitAndDepartmentName = EMPLOYEE_SECTION_NAMES.EMPLOYER;
      }
    }
    return sectionWithUnitAndDepartmentName;
  }

  const addableControls = React.useMemo(() => {
    let injuryDepartmentIds = filtered.map((report) => {
      const sec = getSectionWithDepartmentAndUnitName(report);
      if (sec && report?.sections[sec]?.departmentId) {
        return report?.sections[sec]?.departmentId;
      }
    });
    let injuryUnitIds = filtered.map((report) => {
      const sec = getSectionWithDepartmentAndUnitName(report);
      if (sec && report?.sections[sec]?.unitId) {
        return report?.sections[sec]?.unitId;
      }
    });
    let injuryAssignees = filtered.map((report) => report?.assignee_id);
    let injuryReporters = filtered.map((report) => report?.reporter_id);
    /* let injuryUnitIds = injuryReports.map((report) => report.) */
    injuryDepartmentIds = _.uniq(injuryDepartmentIds);
    injuryUnitIds = _.uniq(injuryUnitIds);
    injuryAssignees = _.uniq(injuryAssignees);
    injuryReporters = _.uniq(injuryReporters);
    let filteredDepartments = departments.filter((department) => injuryDepartmentIds.indexOf(department.id) !== -1);
    let filteredUnitIds = units.filter((unit) => injuryUnitIds.indexOf(unit.unit_id) !== -1);
    let filteredAssignees = employees.filter((user) => injuryAssignees.indexOf(user.id) !== -1);
    let filteredReporters = employees.filter((user) => injuryReporters.indexOf(user.id) !== -1);
    let controlItems: IFilterControl[] = [];
    if (filteredDepartments.length > 0) {
      controlItems.push({
        field: 'departmentId',
        label: t(`${TKB_INJURY}.department`),
        options: filteredDepartments.map(department => ({ value: department.id, label: department?.name || department.id }))
      });
    }
    if (filteredUnitIds.length > 0) {
      controlItems.push({
        field: 'unit',
        label: t(`${TKB_INJURY}.unit`),
        options: filteredUnitIds.map(unit => ({ value: unit.unit_name, label: unit.unit_name }))
      });
    }
    if (filteredAssignees.length > 0) {
      controlItems.push({
        field: 'handler',
        label: t(`${TKB_INJURY}.assignedTo`),
        options: filteredAssignees.map(employee => ({ value: employee.id, label: activeUser.id === employee.id ? 'Me' : employee?.name || employee.id }))
      });
    }
    if (filteredReporters.length > 0) {
      controlItems.push({
        field: 'reporter_id',
        label: t('wif.injury.reportedBy'),
        options: filteredReporters.map(employee => ({ value: employee.id, label: employee?.name || employee.id }))
      });
    }
    return controlItems.filter(control => !addedControls.find(added => added.field === control.field));
  }, [units, departments, employees, addedControls, isHandler, filtered, t]);

  const items = React.useMemo(() => {
    let filteredInjuryReports: any = [...filtered];
    if (categoryFilter) {
      filteredInjuryReports = filteredInjuryReports.filter(
        (injuryReport: any) => injuryReport?.category && injuryReport?.category === categoryFilter
      );
    }
    if (userIdFilter) {
      filteredInjuryReports = filteredInjuryReports.filter(
        (injuryReport: any) => injuryReport?.reporter_id === userIdFilter
      );
    }
    if (adminModules && !adminModules.injuryStudent) {
      filteredInjuryReports = filteredInjuryReports.filter(
        (injuryReport: any) => injuryReport?.category && injuryReport?.category !== REPORT_CATEGORY.STUDENT
      );
    }
    if (adminModules && !adminModules.injuryEmployee) {
      filteredInjuryReports = filteredInjuryReports.filter(
        (injuryReport: any) => injuryReport?.category && injuryReport?.category !== REPORT_CATEGORY.EMPLOYEE
      );
    }
    /* if (currentSite) {
      filteredInjuryReports = filteredInjuryReports.filter((injuryReport: any) => injuryReport.sitePath === currentSite);
    } */

    return filteredInjuryReports.map((injuryReport: any) => {
      let unit = null;
      let departmentId = null;
      let departmentName = null;
      let assignee_details = null;
      let ncReportId = null;
      let ncReportName = null;
      let handler = null;
      let progress = 0;
      let allActionsCompleted = true;

      if (injuryReport?.actions.length > 0 && progress > 0) {
        const completedActionsCount = injuryReport?.actions.filter((action) => action?.status === 'completed').length;
        if (completedActionsCount < injuryReport?.actions.length) {
          allActionsCompleted = false;
          /* progress = progress * (completedActionsCount/injuryReport?.actions.length); */
        }
      }
      if (allActionsCompleted) {
        if (injuryReport?.nav_form_url) {
          progress = 0.9;
        } else if (injuryReport?.closing_details) {
          progress = 0.6;
        } else {
          if (injuryReport.type === REPORT_TYPES.MINOR_INJURY) {
            if (injuryReport?.approved_sections.length > 0) {
              progress = 0.3;
            }
          } else if (injuryReport.category === REPORT_CATEGORY.STUDENT) {
            progress = 0.3 * (injuryReport?.approved_sections.length / 4);
            /* if (injuryReport?.approved_sections.length === 4) {
              progress = 0.3;
            } */
          } else if (injuryReport.category === REPORT_CATEGORY.EMPLOYEE) {
            progress = 0.3 * (injuryReport?.approved_sections.length / 5);
          }
        }
      } else {
        if (injuryReport.type === REPORT_TYPES.MINOR_INJURY) {
          if (injuryReport?.approved_sections.length > 0) {
            progress = 0.3;
          }
        } else if (injuryReport.category === REPORT_CATEGORY.STUDENT) {
          progress = 0.3 * (injuryReport?.approved_sections.length / 4);
          /* if (injuryReport?.approved_sections.length === 4) {
            progress = 0.3;
          } */
        } else if (injuryReport.category === REPORT_CATEGORY.EMPLOYEE) {
          progress = 0.3 * (injuryReport?.approved_sections.length / 5);
        }
        if (injuryReport?.actions.length > 0 && progress > 0) {
          const completedActionsCount = injuryReport?.actions.filter((action) => action?.status === 'completed').length;
          if (completedActionsCount < injuryReport?.actions.length) {
            progress = progress * (completedActionsCount / injuryReport?.actions.length);
          }
        }
      }

      if (injuryReport.status === CASE_STATUS.CLOSED) {
        progress = 1;
      }

      if (injuryReport?.assignee_id) {
        handler = injuryReport.assignee_id;
      }

      if (injuryReport?.assignee_details) {
        assignee_details = injuryReport?.assignee_details;
      }

      let sectionWithUnitAndDepartmentName = '';
      if (injuryReport.category === REPORT_CATEGORY.STUDENT) {
        if (injuryReport.type === REPORT_TYPES.MINOR_INJURY) {
          sectionWithUnitAndDepartmentName = STUDENT_SECTION_NAMES.MINOR_INJURY;
        } else {
          sectionWithUnitAndDepartmentName = STUDENT_SECTION_NAMES.SCHOOL;
          if (injuryReport.sections[STUDENT_SECTION_NAMES.FURTHER_INFORMATION]?.ncReportId) {
            if (injuryReport.sections[STUDENT_SECTION_NAMES.FURTHER_INFORMATION].ncReportId !== 'None') {
              const nid = injuryReport.sections[STUDENT_SECTION_NAMES.FURTHER_INFORMATION].ncReportId;
              ncReportId = nid;
              if (ncReports[nid]) {
                ncReportName = `${ncReports[nid].name}`;
              } else {
                ncReportName = injuryReport.sections[STUDENT_SECTION_NAMES.FURTHER_INFORMATION].ncReportId;
              }
            }
          }
        }
      } else if (injuryReport.category === REPORT_CATEGORY.EMPLOYEE) {
        if (injuryReport.type === REPORT_TYPES.MINOR_INJURY) {
          sectionWithUnitAndDepartmentName = EMPLOYEE_SECTION_NAMES.MINOR_INJURY;
        } else {
          sectionWithUnitAndDepartmentName = EMPLOYEE_SECTION_NAMES.EMPLOYER;
          if (injuryReport.sections[EMPLOYEE_SECTION_NAMES.FURTHER_INFORMATION]?.ncReportId) {
            if (injuryReport.sections[EMPLOYEE_SECTION_NAMES.FURTHER_INFORMATION].ncReportId !== 'None') {
              const nid = injuryReport.sections[EMPLOYEE_SECTION_NAMES.FURTHER_INFORMATION].ncReportId;
              ncReportId = nid;
              if (ncReports[nid]) {
                ncReportName = `${ncReports[nid].name}`;
              } else {
                ncReportName = injuryReport.sections[EMPLOYEE_SECTION_NAMES.FURTHER_INFORMATION].ncReportId;
              }
            }
          }
        }
      }

      if (injuryReport?.sections[sectionWithUnitAndDepartmentName]?.unitId) {
        const unitId = injuryReport.sections[sectionWithUnitAndDepartmentName].unitId;
        unit = unitEntities[unitId]?.unit_name;
      }

      if (injuryReport?.sections[sectionWithUnitAndDepartmentName]?.departmentId) {
        departmentId = injuryReport.sections[sectionWithUnitAndDepartmentName].departmentId;
        /* department = departmentEntities[departmentId]?.name; */
      }

      if (injuryReport?.sections[sectionWithUnitAndDepartmentName]?.departmentName) {
        departmentName = injuryReport.sections[sectionWithUnitAndDepartmentName].departmentName;
      }

      const name = getTranslatedInjuryReportName(injuryReport, t);
      
      let userIsHandlerForDepartment = false; 
      if (injuryReport.category === 'employee') {
        if (departmentEntities[departmentId]?.injury_employee_users.includes(activeUser.id)) {
          userIsHandlerForDepartment = true;
        }
      } else {
        if (departmentEntities[departmentId]?.injury_student_users.includes(activeUser.id)) {
          userIsHandlerForDepartment = true;
        }
      }
      
      return {
        id: injuryReport.id,
        name: name,
        assignee_details: assignee_details,
        reportedTimestamp: injuryReport.reportedTimestamp,
        updated_at: injuryReport?.updated_at,
        reporter_id: injuryReport?.reporter_id,
        category: injuryReport.category,
        type: injuryReport.type,
        status: injuryReport.status,
        departmentId: departmentId,
        departmentName: departmentName,
        ncReportName: ncReportName,
        unit: unit,
        progress: progress,
        userHasReadAccess: userHasReadAccess && view === 'handler' && !userIsHandlerForDepartment,
        due_date: (injuryReport?.due_date && formatDateFromTimestamp(injuryReport?.due_date) !== 'invalid date') ? new Date(injuryReport?.due_date) : injuryReport?.due_date ? new Date(`${injuryReport?.due_date.split('.')[2]}-${injuryReport?.due_date.split('.')[1]}-${injuryReport?.due_date.split('.')[0]}`) : null,
        comments: injuryReport?.comments?.length || 0, 
        messages: injuryReport?.messages?.length || 0,
        handler: handler,
        sitePath: injuryReport.sitePath,
        ncReportId: ncReportId
      }
    })
  }, [injuryReports, activeFilters, currentSite, splitViewEnabled, departments, units, employees, categoryFilter, isHandler, filtered, t]);

  React.useEffect(() => {
    return () => {
      dispatch(injuryReportCleared());
    };
  }, []);

  const columns = React.useMemo(() => {
    let columnByField: { [field: string]: GridColDef } = {
      id: {
        field: 'id',
        headerName: 'ID',
        width: 120,
        renderCell: (params) => <RenderCopyCell {...params} />
      },
      name: {
        field: 'name',
        headerName: t(`${TKB_INJURY}.name`),
        flex: 2,
        minWidth: 240,
        renderCell: (params) => <RenderLinkCell {...params} />
      },
      reportedTimestamp: {
        field: 'reportedTimestamp',
        headerName: t('wif.injury.reportedDate'),
        minWidth: 140,
        flex: 1,
        type: 'dateTime',
        valueGetter: (params) => new Date(params?.value), 
        valueFormatter: ({ value }) => formatDateFromTimestamp(value),
      },
      'updated_at': {
        field: 'updated_at',
        headerName: t('wif.injury.lastUpdated'),
        minWidth: 160,
        flex: 1,
        type: 'dateTime',
        valueGetter: (params) => new Date(params?.value), 
        valueFormatter: ({ value }) => formatDateTimeFromTimestamp(value),
      },
      'reporter_id': {
        field: 'reporter_id',
        headerName: t('wif.injury.reportedBy'),
        minWidth: 160,
        flex: 1,
        renderCell: (params) => <RenderUserCell {...params} />
      },
      category: {
        field: 'category',
        headerName: t(`${TKB_INJURY}.category`),
        minWidth: 130,
        flex: 1,
        renderCell: (params) => <RenderCategoryCell {...params} />,
        type: 'singleSelect',
        valueOptions: [REPORT_CATEGORY.EMPLOYEE, REPORT_CATEGORY.STUDENT]
      },
      type: {
        field: 'type',
        headerName: t(`${TKB_INJURY}.type`),
        minWidth: 130,
        flex: 1,
        renderCell: (params) => <RenderTypeCell {...params} />,
        type: 'singleSelect',
        /* hideable: false, */
        valueOptions: [REPORT_TYPES.ACCIDENT, REPORT_TYPES.ILLNESS, REPORT_TYPES.MINOR_INJURY]
      },
      status: {
        field: 'status',
        headerName: t(`${TKB_INJURY}.status`),
        minWidth: 130,
        flex: 1,
        renderCell: (params) => <RenderStatusCell {...params} />
      },
      departmentId: {
        field: 'departmentId',
        headerName: t(`${TKB_INJURY}.department`),
        flex: 1.5,
        minWidth: 200,
        renderCell: (params) => <RenderDepartmentCell {...params} />
      },
      ncReportName: {
        field: 'ncReportName',
        headerName: t(`${TKB_INJURY}.nonconformity`),
        flex: 1,
        minWidth: 160,
        renderCell: (params) => <RenderNcReportCell {...params} />
      },
      unit: {
        field: 'unit',
        headerName: t(`${TKB_INJURY}.unit`),
        flex: 1,
        minWidth: 160
        /* renderCell: (params) => RenderUnitCell(params), */
      },
      progress: {
        field: 'progress',
        headerName: t(`${TKB_INJURY}.progress`),
        minWidth: 160,
        renderCell: (params) => <RenderProgressWithStatusColor {...params} />
      },
      due_date: {
        field: 'due_date',
        headerName: t(`${TKB_INJURY}.dueDate`),
        minWidth: 140,
        flex: 1,
        renderCell: (params) => <RenderDueDateCell {...params} />,
        type: 'dateTime',
        sortingOrder: ['asc', 'desc'],
      },
      'comments': {
        field: 'comments',
        headerName: t('wif.injury.comments'),
        minWidth: 120,
        flex: 0.75,
        renderCell: (params) => <RenderInjuryCommentsCell {...params} />,
      },
      'messages': {
        field: 'messages',
        headerName: t('wif.injury.messages'),
        minWidth: 120,
        flex: 0.75,
        renderCell: (params) => <RenderInjuryMessagesCell {...params} />,
      },
    };

    if (view === 'handler') {
      
    };
    let cols = ['id', 'name', 'type', 'reportedTimestamp', 'handler', 'status', 'ncReportName', 'departmentId', 'unit', 'comments', 'messages', 'due_date', 'updated_at'];
    
    if (view === 'handler') {
      cols.splice(4, 0, 'reporter_id');
      cols.push('progress');
      columnByField = {
        ...columnByField,
        handler: {
          field: 'handler',
          headerName: t('wif.injury.assignedTo'),
          flex: 1,
          minWidth: 200,
          renderCell: (params) => <RenderHandlerCell {...params} />
        }
      };
    } else {
      columnByField = {
        ...columnByField,
        handler: {
          field: 'handler',
          headerName: t('wif.injury.assignedTo'),
          flex: 1,
          minWidth: 200,
          renderCell: (params) => <RenderHandlerCell {...params} />
        }
      };
    }
    if (module === 'all') {
      cols.splice(2, 0, 'category');
    }
    return cols.map((field) => columnByField[field]);
  }, [t]);

  const initialColumnVisibilityModel: GridColumnVisibilityModel = {
    departmentId: false,
    unit: false,
    category: categoryFilter === undefined,
    reporter_id: categoryFilter !== undefined,
    /* 'due_date': false, */
    ncReportId: false
  };

  const dispatch = useAppDispatch();

  const [rowSelectionModel, setRowSelectionModel] = React.useState<GridRowSelectionModel>(
    currentInjuryReportId ? [currentInjuryReportId] : []
  );

  const handleChangeSelectionModel = (newRowSelectionModel: GridRowSelectionModel) => {
    if (newRowSelectionModel.length > 0) {
      if (newRowSelectionModel[0] !== currentInjuryReportId) {
        dispatch(injuryReportItemClicked(newRowSelectionModel[0] as string));
      }
    } else {
      dispatch(injuryReportCleared());
    }
    setRowSelectionModel(newRowSelectionModel);
  };

  React.useEffect(() => {
    if (!currentInjuryReportId) {
      setRowSelectionModel([]);
    }
  }, [currentInjuryReportId]);

  const handleAddControl = (control: IFilterControl) => {
    dispatch(filterControlAdded({ view, module, control }));
  };

  const handleResetFilterControls = () => {
    dispatch(filterControlsReset({ view, module }));
  };

  const employeesFetched = useAppSelector(selectEmployeesFetched);
  const injuryReportsFetched = useAppSelector(selectInjuryReportsFetched);

  const handleDoubleClickRow: GridEventListener<'rowDoubleClick'> = (params) => {
    if (userHasReadAccess) return;
    else navigate(params.row.id);
  };

  const handleRowClick: GridEventListener<'rowClick'> = (params) => {
    /* navigate(params.row.id); */
    dispatch(injuryReportItemClicked((params.row.id)));  
  }

  return (
    <RDataGrid
      onRowDoubleClick={handleDoubleClickRow}
      onRowClick={handleRowClick}
      items={items}
      cols={columns}
      loading={injuryReportsFetched !== 'succeeded'}
      /* initialColumnVisibilityModel={initialColumnVisibilityModel} */
      /* initialSortModel={{
        field: 'due_date',
        sort: 'asc'
      }} */
      filters={{
        controls: [...controls],
        addedControls: addedControls?.length > 0 ? [...addedControls] : [],
        active: activeFilters,
        handleSetFilter,
        handleRemoveFilter,
        handleResetFilterControls
      }}
      addableControls={{
        controls: addableControls,
        handleAddFilterControl: handleAddControl
      }}
      sortModel={sortModel}
      handleChangeSortModel={handleChangeSortModel}
      columnVisibilityModel={columnVisibilityModel}
      handleChangeColumnVisibilityModel={handleChangeColumnVisibilityModel}

      /* loading={employeesFetched === 'pending'} */
    />
  );
};
