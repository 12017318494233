import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useCurrentInjuryReport } from '../../../routes/CurrentInjuryReportContext';
import { Avatar, Box, Button, Divider, Drawer, IconButton, Link, List, ListSubheader, Paper, Stack, ToggleButton, Tooltip, Typography } from '@mui/material';
import { AllSectionDetails, CASE_STATUS, REPORT_CATEGORY, REPORT_TYPES, TKB_INJURY } from '../../../constants';
import { AppIconButton } from '../../../../../components/Elements/AppIconButton';
import { AppIcon } from '../../../../../components/Elements';
import { DescriptionList, DescriptionListItem } from '../../../../../components/DescriptionList/DescriptionsGrid';
import { formatDateFromTimestamp } from '../../../../../utils/format';
import RejectInjuryReportDialog from '../CaseHandlingDialogs/RejectInjuryReportDialog';
import { RootState, useAppSelector } from '../../../../../store';
import {
  CASE_HISTORY_TYPES,
  selectInjuryReportLogs,
  InjuryReportHistoryItem,
  downloadInjuryReport
} from '../../../store/injuryReportsSlice';
import { useTheme, alpha } from '@mui/material/styles';
import { HistoryPanel } from '../CasePanels/History';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/nb';
import { AppExpandableSection } from '../ApprovableSection/ApprovableSection';
import { set } from 'lodash';
import { APP_COLORS, SIKRI_COLORS } from '../../../../../config';
import { selectEmployeeById, selectEmployees } from '../../../../employees/employeesSlice';
import DueDateDialog from '../CaseHandlingDialogs/DueDateDialog';
import AssigneeDialog from '../CaseHandlingDialogs/AssigneeDialog';
import CookieStorage from '../../../../../utils/CookieStorage';
import { CONSTANTS } from '../../../../../common/constants';

type ActionPanelProps = {
  handleOpenHandlingDialog: (dialogId: string) => void;
};

import { useTranslation } from 'react-i18next';
import {
  makeSelectEmployeeHandlerDepartmentIdsByTenantAdminUserId,
  makeSelectStudentHandlerDepartmentIdsByTenantAdminUserId
} from '../../../../departments/departmentsSlice';
import { STUDENT_SECTION_NAMES } from '../../../constants/student';
import { EMPLOYEE_SECTION_NAMES } from '../../../constants/employee';
import { InjuryDocumentDialog } from '../../InjuryDocument/InjuryDocumentDialog';
import { generateAvatarColor } from '../../../../../utils/color';
import { ActionListItem } from '../../../../actions/components/ActionListItem';
import { TabsContainer } from '../../../../../components/Layout/AppTabs';
import { AppUserAvatar } from '../../../../../components/Elements/AppAvatar';
import ReopenInjuryReportDialog from '../CaseHandlingDialogs/ReopenInjuryReportDialog';
type ActionIconButton = {
  iconName: string;
  onClick?: () => any;
  color?: string;
  iconColor?: string;
  tooltip?: string;
  disabled?: boolean;
};

export const ActionPanel = (props: ActionPanelProps) => {
  const { handleOpenHandlingDialog } = props;

  const { t } = useTranslation();
  const injuryReport = useCurrentInjuryReport();
  const theme = useTheme();

  const isOngoing = injuryReport.status === CASE_STATUS.ONGOING;
  const isRejected = injuryReport.status === CASE_STATUS.REJECTED;
  const isClosed = injuryReport.status === CASE_STATUS.CLOSED;

  const [historyPanelOpen, setHistoryPanelOpen] = React.useState(false);
  const [isFileDownloading, setIsFileDownloading] = React.useState(false)
  const [activeTab, setActiveTab] = React.useState('details');

  const toggleHistoryPanel = () => {
    console.log(`toggleHistoryPanel: ${!historyPanelOpen}`);
    setHistoryPanelOpen((prev) => !prev);
  };

  async function handlePrint() {
    setIsFileDownloading(true);
    try{
      const org = CookieStorage.getItem(CONSTANTS.COOKIE_KEYS.SELECTED_ORG);
      const parsedOrg = JSON.parse(org)
      const req = {
        body: {
        reportId: injuryReport.id,
          orgName: parsedOrg.org_name      
        }
      };
      const resultUrl = await downloadInjuryReport(req)
      window.open(resultUrl.url, '_blank');
    }catch(err){
      console.log(err);
    }
    setIsFileDownloading(false);
  }

  const iconButtons = React.useMemo(() => {
    const buttons: ActionIconButton[] = [];

    if (isOngoing) {
      buttons.push({
        iconName: 'note_add',
        tooltip: t('wif.injury.linkNcr'),
        onClick: () => {
          handleOpenHandlingDialog('linkNonconformity');
        }
      });
    }

    // if (isOngoing) {
    //   buttons.push({
    //     iconName: 'send',
    //     tooltip: t(`${TKB_INJURY}.forward`)
    //   });
    // }

    if (isOngoing) {
      buttons.push({
        iconName: 'send',
        onClick: () => handleOpenHandlingDialog('emails'),
        tooltip: t(`${TKB_INJURY}.forward`)
      });
    }

    /* buttons.push({
      iconName: 'print', onClick: () => handlePrint(), tooltip: t(`${TKB_INJURY}.download`),
      disabled: isFileDownloading
    }); */

    buttons.push({
      iconName: 'download', 
      onClick: () => handleOpenHandlingDialog('pdf'),
      tooltip: t(`${TKB_INJURY}.download`),
    });
    return buttons;
  }, [injuryReport, isFileDownloading, t]);

  const [rejectDialogOpen, setRejectDialogOpen] = React.useState(false);

  const [openDialog, setOpenDialog] = React.useState<undefined | string>();

  const employees = useAppSelector(selectEmployees);

  const departmentId = React.useMemo(() => {
    let sectionWithUnitAndDepartmentName = '';
    let departmentId = undefined;

    if (injuryReport.category === REPORT_CATEGORY.STUDENT) {
      if (injuryReport.type === REPORT_TYPES.MINOR_INJURY) {
        sectionWithUnitAndDepartmentName = STUDENT_SECTION_NAMES.MINOR_INJURY;
      } else {
        sectionWithUnitAndDepartmentName = STUDENT_SECTION_NAMES.SCHOOL;
      }
    } else if (injuryReport.category === REPORT_CATEGORY.EMPLOYEE) {
      if (injuryReport.type === REPORT_TYPES.MINOR_INJURY) {
        sectionWithUnitAndDepartmentName = EMPLOYEE_SECTION_NAMES.MINOR_INJURY;
      } else {
        sectionWithUnitAndDepartmentName = EMPLOYEE_SECTION_NAMES.EMPLOYER;
      }
    }
    if (injuryReport?.sections[sectionWithUnitAndDepartmentName]?.departmentId) {
      departmentId = injuryReport.sections[sectionWithUnitAndDepartmentName].departmentId;
    }

    if (departmentId !== undefined) {
      return departmentId;
    }
    return undefined;
  }, [injuryReport]);

  const handlerId = React.useMemo(() => {
    return injuryReport?.assignee_id || '';
  }, [injuryReport]);

  const selectInjuryStudentHandlerDepartmentIdsByUserId = React.useMemo(
    makeSelectStudentHandlerDepartmentIdsByTenantAdminUserId,
    []
  );
  const studentHandlerDepartmentIds = useAppSelector((state: RootState) =>
    selectInjuryStudentHandlerDepartmentIdsByUserId(state, handlerId)
  );

  const selectInjuryEmployeeHandlerDepartmentIdsByUserId = React.useMemo(
    makeSelectEmployeeHandlerDepartmentIdsByTenantAdminUserId,
    []
  );
  const employeeHandlerDepartmentIds = useAppSelector((state: RootState) =>
    selectInjuryEmployeeHandlerDepartmentIdsByUserId(state, handlerId)
  );

  const invalidHandlerId = React.useMemo(() => {
    if (handlerId !== '' && departmentId) {
      if (injuryReport?.category === 'employee') {
        return employeeHandlerDepartmentIds.indexOf(departmentId) === -1;
      } else if (injuryReport?.category === 'student') {
        return studentHandlerDepartmentIds.indexOf(departmentId) === -1;
      }
    }
    return false;
  }, [handlerId, studentHandlerDepartmentIds, employeeHandlerDepartmentIds, departmentId]);

  const deadlineStatus = React.useMemo(() => {
    const due_date = injuryReport?.due_date;

    if (due_date) {
      const deadline = new Date(
        `${injuryReport?.due_date.split('.')[2]}-${injuryReport?.due_date.split('.')[1]}-${
          injuryReport?.due_date.split('.')[0]
        }`
      ).getTime();
      if (deadline && deadline !== null) {
        let deadlineDate = new Date(deadline);
        if (deadlineDate) {
          deadlineDate.setHours(0, 0, 0, 0);
          let today = new Date();
          today.setHours(0, 0, 0, 0);
          console.log(
            `deadline: ${deadlineDate.getTime()}, today: ${today.getTime()}, > ${
              today.getTime() > deadlineDate.getTime()
            }}`
          );
          if (today > deadlineDate) {
            return 'overdue';
          } else if (today.getTime() === deadlineDate.getTime()) {
            return 'today';
          }
        }
      }
    }
    return 'normal';
  }, [injuryReport?.due_date]);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
  }

  const formattedDueDate = React.useMemo(() => {
    let dueDate = t(`${TKB_INJURY}.notSet`);
    if (injuryReport?.due_date) {
      if (formatDateFromTimestamp(injuryReport?.due_date) !== 'invalid date') {
        dueDate = formatDateFromTimestamp(injuryReport?.due_date);
      } else {
        dueDate = injuryReport?.due_date;
      }
    }
    return dueDate;
  }, [injuryReport]);

  const renderNameValue = () => {
    let name = '';
    if (injuryReport?.assignee_id && employees[injuryReport.assignee_id]) {
      if (injuryReport?.assignee_details && employees[injuryReport.assignee_id]?.name === 'unresolved') {
        name = injuryReport?.assignee_details.name;
      } else {
        name = employees[injuryReport.assignee_id]?.name;
      }
    } else if (injuryReport?.assignee_id) {
      name = t(`${TKB_INJURY}.unassigned`);
    }

    return name;
  };

  return (
    <Stack
      direction='column'
      gap={2}
    >
      <Stack
        direction='row'
        justifyContent='space-between'
        gap={1}
        mb={1}
        width='100%'
      >
        <Stack
          direction='row'
          gap={1.25}
        >
          {iconButtons.map((button, index) => (
            <Tooltip 
              title={button?.tooltip || 'Tooltip'}
              placement='bottom'
              key={`${button?.tooltip}-${index}`}
            >
              {/* {button.type === 'toggle' ? (
                <ToggleButton
                  value='check'
                  selected={historyPanelOpen}
                  onChange={() => {
                    setHistoryPanelOpen(!historyPanelOpen);
                  }}
                >
                  <AppIcon iconName={button.iconName} opticalSize={22} />
                </ToggleButton>
              ) : (
                
              )} */}
            <AppIconButton
              color='plain'
              onClick={button.onClick}
            >
              <AppIcon iconName={button.iconName} opticalSize={22} />
            </AppIconButton>
            {/* <IconButton
              key={index}
              size='medium'
              onClick={button?.onClick}
              disabled = {button?.disabled}
            >
              <AppIcon iconName={button.iconName} opticalSize={22} />
            </IconButton> */}
          </Tooltip>
          
        ))}
        {/* <Tooltip 
          title={t(`${TKB_INJURY}.history`)}
          placement='bottom'
        >
          <AppIconButton
            size='medium'
            color={historyPanelOpen ? 'secondary' : 'plain'}
            variant={'contained'}
            sx={{
              ...(historyPanelOpen && {
                bgcolor: `${SIKRI_COLORS.galaxyGreenSelected}!important`
              })
              
            }}
            onClick={() => setHistoryPanelOpen(!historyPanelOpen)}
          >
            <AppIcon iconName={'history'} opticalSize={22} />
          </AppIconButton>
        </Tooltip> */}
        
      </Stack>
      {isOngoing && (
        <Button
          onClick={() => setOpenDialog('reject')}
        >
          {t(`${TKB_INJURY}.reject`)}
        </Button>
      )}
      {(isClosed || isRejected)  && (
        <Button
          onClick={() => setOpenDialog('reopen')}
        >
          {t(`${TKB_INJURY}.reopen`)}
        </Button>
      )}
    </Stack>
    {/* <TabsContainer px={0}>
      <Tabs
        value={activeTab}
        onChange={handleChangeTab}
      >
        <Tab
          label='Details'
          value={'details'}
        />
        <Tab
          label='History'
          value={'history'}
        />
      </Tabs>
    </TabsContainer>
    <Box sx={{ mt: -0.5 }} /> */}
    <AppExpandableSection
      title={t(`${TKB_INJURY}.assignment`)}
    >
      <DescriptionList>
        <DescriptionListItem
          field={t(`${TKB_INJURY}.assignee`)}
          valueComponent={
            <Stack
              direction='row'
              alignItems='center'
              gap={1} 
              sx={{
                height: '24px',
                ...(!injuryReport.assignee_id && {
                  color: 'text.disabled'
                }),
                ...(isOngoing && {
                  '&:hover': {
                    '& .visible-on-hover': {
                      display: 'flex', 
                      alignItems: 'center',
                      justifyContent: 'center',
                    }
                  }
                }),
              }}
            >
              <AppUserAvatar
                username={employees[injuryReport.assignee_id]?.name !== 'unresolved' && employees[injuryReport.assignee_id]?.name || injuryReport.assignee_details?.name || injuryReport?.assignee_id}
                size='small'
              />
              <Typography
                variant='body1'
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  ...(!injuryReport.assignee_id && {
                    color: 'text.disabled'
                  })
                }}
              >
                {renderNameValue()}
              </Typography>
              <Box
                className='visible-on-hover'
                sx={{
                  display: 'none',
                  
                }}
              >
                <Tooltip
                  title={t(`${TKB_INJURY}.assignHandler`)}
                  placement='right'
                >
                  <IconButton
                    onClick={() => setOpenDialog('assignee')}
                    size='small'
                  >
                    <AppIcon iconName='edit' opticalSize={18} color='text.primary' />
                  </IconButton>
                </Tooltip>
                
              </Box>
            </Stack>
          }
        />
        <DescriptionListItem
          field={t(`${TKB_INJURY}.dueDate`)}
          valueComponent={
            <Stack
              direction='row'
              alignItems='center'
              gap={1} 
              sx={{
                height: '24px',
                ...(!injuryReport.due_date && {
                  color: 'text.disabled'
                }),
                ...(deadlineStatus === 'overdue' && {
                  color: (theme) => theme.palette.mode === 'light' ? 'error.dark' : 'error.light'
                }),
                ...(deadlineStatus === 'today' && {
                  color: (theme) => theme.palette.mode === 'light' ? 'warning.dark' : 'warning.light'
                }),
                ...(isOngoing && {
                  '&:hover': {
                    '& .visible-on-hover': {
                      display: 'flex', 
                      alignItems: 'center',
                      justifyContent: 'center',
                    }
                  }
                }),
              }}
            >
              <AppIcon iconName={injuryReport?.status === 'closed' ? `event_available` : deadlineStatus === 'today' ? 'calendar_today' : 'calendar_month'} color={injuryReport?.status === 'closed' ? 'success.main' : deadlineStatus === 'today' ? 'warning.main' : (deadlineStatus === 'overdue') ? 'error.main' : 'textVariant'} opticalSize={24} />
              {formattedDueDate}
              <Box
                className='visible-on-hover'
                sx={{
                  display: 'none',
                  
                }}
              >
                <Tooltip
                  title={t(`${TKB_INJURY}.setDueDate`)}
                  placement='right'
                >
                  <IconButton
                    onClick={() => setOpenDialog('dueDate')}
                  >
                    <AppIcon iconName='edit' opticalSize={18} color='text.primary' />
                  </IconButton>
                </Tooltip>
                
              </Box>
            </Stack>
            }
          />
        </DescriptionList>
    </AppExpandableSection>
      
      {historyPanelOpen && (
        <AppExpandableSection
          title={t(`${TKB_INJURY}.history`)}
        >
          <HistoryPanel />
        </AppExpandableSection>
      )}
      <DueDateDialog 
        open={openDialog === 'dueDate'}
        onClose={() => setOpenDialog(undefined)}
      />
      <RejectInjuryReportDialog 
        injuryReportId={injuryReport.id}
        open={openDialog === 'reject'}
        onClose={() => setOpenDialog(undefined)}
      />
      <ReopenInjuryReportDialog
        open={openDialog === 'reopen'}
        onClose={() => setOpenDialog(undefined)}
        injuryReportId={injuryReport.id}
      />
      <AssigneeDialog
        open={openDialog === 'assignee'}
        onClose={() => setOpenDialog(undefined)}
      />
      <InjuryDocumentDialog
        open={openDialog === 'pdf'}
        handleClose={() => setOpenDialog(undefined)}
      />
      <Drawer
        anchor='right'
        open={historyPanelOpen}
        onClose={() => setHistoryPanelOpen(false)}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1
        }}
      > 
        <Paper
          sx={{
            px: 2.5, 
            py: 2.5, 
            maxWidth: '480px',
            overflowY: 'auto',
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            height: '100%',
          }}
        >
          <HistoryPanel />
        </Paper>
        
      </Drawer>
    </Stack>
  )
}