import dayjs, { Dayjs } from 'dayjs';

export const formatDateFromTimestamp = (timestamp: number|string, format?: string) => dayjs(timestamp).format(format ? format : 'DD.MM.YYYY').toLowerCase(); 
export const formatDateTimeFromTimestamp = (timestamp: number|string) => dayjs(timestamp).format('DD.MM.YYYY, HH:mm');
export const nokCurrencyFormatter = new Intl.NumberFormat('nb-NO', {
  currency: 'NOK',
});
export function formatBytes(a,b=2){if(!+a)return"0 Bytes";const c=0>b?0:b,d=Math.floor(Math.log(a)/Math.log(1024));return`${parseFloat((a/Math.pow(1024,d)).toFixed(c))} ${["Bytes","KiB","MiB","GiB","TiB","PiB","EiB","ZiB","YiB"][d]}`}

export const getTranslateFriendlyUpdatedStr = (timestamp: any) => {
  const seconds = Math.floor(((new Date().getTime() - timestamp) / 1000));

  let interval = seconds / 31536000;
  if (interval > 1) {
    return ['wif.injury.yearsAgoWithCount', { count: Math.floor(interval) }];
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return ['wif.injury.monthsAgoWithCount', { count: Math.floor(interval) }];
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return ['wif.injury.daysAgoWithCount', { count: Math.floor(interval) }];
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return ['wif.injury.hoursAgoWithCount', { count: Math.floor(interval) }];
  }
  interval = seconds / 60;
  if (interval > 1) {
    return ['wif.injury.minutesAgoWithCount', { count: Math.floor(interval) }];
  }
  if (seconds > 1) {
    return [`wif.injury.justNow`];
  }
  return [`wif.injury.justNow`];
}

export const getLastUpdatedStr = (timestamp: any) => {
  const date = new Date(timestamp);
  const seconds = Math.floor(((new Date().getTime() - timestamp) / 1000));

  let interval = seconds / 31536000;
  if (interval > 1) {
    return `${Math.floor(interval)} years ago`;
  }
  interval = seconds / 2592000;
  if (interval > 2) {
    return `${Math.floor(interval)} months ago`;
  } else if (interval > 1) {
    return `${Math.floor(interval)} month ago`;
  }
  interval = seconds / 86400;
  if (interval > 2) {
    return `${Math.floor(interval)} days ago`;
  } else if (interval > 1) {
    return `${Math.floor(interval)} day ago`;
  }
  interval = seconds / 3600;
  if (interval > 2) {
    return `${Math.floor(interval)} hours ago`;
  } else if (interval > 1) {
    return `${Math.floor(interval)} hour ago`;
  }
  interval = seconds / 60;
  if (interval > 2) {
    return `${Math.floor(interval)} minutes ago`;
  } else if (interval > 1) {
    return `${Math.floor(interval)} minute ago`;
  }
  if (seconds > 1) {
    return `Now`;
  }
  return `Now`;
  /* return padTo2Digits(date.getHours()) + ':' + padTo2Digits(date.getMinutes()); */
};