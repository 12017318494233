import React from 'react';
import { Box, Stack, useTheme, Typography, IconButton, Breadcrumbs } from '@mui/material';
import Link, { LinkProps } from '@mui/material/Link';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { AppIcon } from '../Elements';
import { APP_COLORS } from '../../config';

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

function LinkRouter(props: LinkRouterProps) {
  return <Link {...props} component={RouterLink as any} />;
}

type ContentHeaderProps = {
  title?: string;
  titleComponent?: React.ReactNode;
  breadcrumbs?: {
    to: string;
    name: string;
  }[];
  onBack?: () => void;
  backLabel?: string;
  action?: React.ReactNode;
  tabs?: React.ReactNode;
  primaryVariant?: boolean;
  leftAction?: React.ReactNode;
};

export const ContentHeader = (props: ContentHeaderProps) => {
  const {
    title,
    titleComponent,
    breadcrumbs = [],
    onBack,
    action,
    tabs,
    primaryVariant = false,
    backLabel = 'Back',
    leftAction
  } = props;
  const theme = useTheme();
  const location = useLocation();

  return (
    <Box
      sx={{
        display: 'flex', 
        flexDirection: 'column',
        gap: 1.5,
        ...(primaryVariant ? {
          bgcolor: 'primary.main',
          color: '#fff',
        } : {
          borderBottom: 1,
          borderColor: 'divider',
        color: 'text.primary',
        }),
        zIndex: 1,
        ...(tabs && {
          borderBottom: 1, 
          borderColor: 'divider',
        }),
          px: 3,
          pb: tabs ? 0 : leftAction ? 2 : 3,
        }}
      >
      {/* <Stack
          direction='row'
          alignItems='center'
          gap={primaryVariant ? 2 : 0}
          sx={{
            ...(tabs && { pb: 0.75 }),
            mt: -0.5,
          }}
        >
          {onBack && (
            <IconButton
              size='small'
              edge='start'
              sx={{ ml: primaryVariant ? 0 : -1, color: primaryVariant ? APP_COLORS.darkTextPrimary : 'textVariant', bgcolor: primaryVariant ? 'primary.dark' : 'transparent' }}
              onClick={onBack}
            >
              <AppIcon iconName='arrow_back' opticalSize={20} />
            </IconButton>
          )}
          <Typography variant='body2' fontWeight={500} color='text.secondary'>{backLabel}</Typography>
        </Stack> */}
      <Stack
        direction="row"
        alignItems="center"
        gap={primaryVariant ? 2 : 1}
        sx={{
          width: '100%',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          ...(tabs && { pb: 0.75 })
        }}
      >
        {titleComponent && titleComponent}
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        gap={2}
        sx={{
          ...(tabs && { pb: 0.75 })
        }}
      >
        <Typography variant="h1" color="text.primary" 
          sx={{ 
            flexGrow: 1, 
            color: 'text.primary',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {title}
        </Typography>
        {!leftAction && action && action}
      </Stack>
      {leftAction && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          gap={primaryVariant ? 2 : 1}
          sx={{
            ...(tabs && { pb: 0.75 })
          }}
        >
          {leftAction}
          {action && action}
        </Stack>
      )}
      {tabs && tabs}
    </Box>
  );
};
