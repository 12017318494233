export const asdf = 'asdf';
import React from 'react';
import { useTheme, alpha } from '@mui/material/styles';
import { BarChart } from '../../../../components/Charts/BarChart';
import { getChartColorByIndex, getDefaultChartPropsWithTitle } from '../../../../components/Charts/helpers';
import _ from 'lodash';
import { APP_COLORS } from '../../../../config';
import { Box, Button } from '@mui/material';
import { ChartContainer } from '../../../../components/Charts/ChartContainer';
import { useTranslation } from 'react-i18next';


type MostCommonValuesBarProps = {
  title?: string;
  subtitle?: string; 
  valuesWithCount: { value: any, count: number, type?: string; percentage?: any; }[];
  color?: string;
  colors?: string[];
  limit?: number;
  typeIncluded?: boolean;
  getValueLabel?: (value: string) => string;
}

export const MostCommonValuesBar = (props: MostCommonValuesBarProps) => {
  const { title = 'Title', subtitle = 'Subtitle', valuesWithCount, color = APP_COLORS.primary, limit = 5, colors, typeIncluded, getValueLabel } = props;
  const { t } = useTranslation();
  const [limitMultiplier, setLimitMultiplier] = React.useState(1);



  const theme = useTheme() as any;

  const chartData = React.useMemo(() => {
    let labels: any = [];
    let datasetData: any = [];
    let backgrounds: any = [];

    let options = getDefaultChartPropsWithTitle({ theme, title: title, subtitle: subtitle, displayDataLabels: true });
    options['indexAxis'] = 'y';
    options.scales.x.grid.display = true;
    options.scales.x.border.display = true;
    options.scales.x['beginAtZero'] = true;
    options.scales.x['grace'] = '5%';
    options.scales.y.grid['display'] = false;
    options.scales.y.ticks['display'] = true;
    options.plugins.legend['display'] = false;
    _.set(options, 'plugins.y.ticks.stepSize', 1);
    _.set(options, 'plugins.y.ticks.autoSkip', false);
    options.plugins.title.padding.bottom = 10;
    const sortedValuesWithCount = valuesWithCount.slice().sort((a: any, b: any) => b.count - a.count);

    for (let i = 0; i < sortedValuesWithCount.length && i < (limit * limitMultiplier); i++) {
      const { value, count } = sortedValuesWithCount[i];
      if (getValueLabel) {
        labels.push(getValueLabel(value));
      } else {
        labels.push(value);
      }
      
      datasetData.push(count);
      backgrounds.push(getChartColorByIndex(i));
    }

    const data = {
      labels: labels,
      datasets: [
        {
          label: t('wif.injury.reports'),
          data: datasetData,
          backgroundColor: colors ? colors : color,
          datalabels: {
            labels: {
              ...(typeIncluded && {
                name: {
                  anchor: 'end',
                  align: 'end',
                  color: function (ctx) {
                    return colors ? ctx.dataset.backgroundColor[ctx.dataIndex] : color;
                  },
                  formatter: function (value, ctx) {
                    if (value < datasetData[0] * 0.9) {
                      return valuesWithCount[ctx.dataIndex].type;
                    }
                    return `${valuesWithCount[ctx.dataIndex].type.substring(0, 6)}...`;
                  }
                }
              }),

              value: {
                anchor: 'center',
                align: 'center',
                color: theme.palette.background.paper,
                formatter: function (value, ctx) {
                  if (value > datasetData[0] / 2.5) {
                    if (valuesWithCount[ctx.dataIndex]?.percentage) {
                      return `${value} (${valuesWithCount[ctx.dataIndex].percentage}%)`;
                    }
                    return value;
                  }
                  return '';
                }
              }
            }
          }
        }
      ]
    };

    return {
      data,
      options
    };
  }, [valuesWithCount, theme, limitMultiplier, t]);

  return (
    <ChartContainer
      sx={{
        height: valuesWithCount.length > (limit * limitMultiplier) ? `calc(280px * (${limit * limitMultiplier}/5))` : `260px`
      }}
    >
      <Box 
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex', 
        flexDirection: 'column'
      }}
    >
      <Box 
        sx={{
          flex: 1, 
          width: '100%'
        }}
      >
        <BarChart
          id={title}
          data={chartData.data}
          options={chartData.options}
        />
      </Box>
      {/* {valuesWithCount.length > limit && (limit * limitMultiplier) >= valuesWithCount.length && (
        <Button
          variant='text'
          color='primary'
          onClick={() => setLimitMultiplier((prev) => prev - 1)}
          sx={{ mb: -1.25 }}
        >
          Show less
        </Button>
      )} */}
      {(valuesWithCount.length > (limit * limitMultiplier)) && (
        <Button
          variant='text'
          color='primary'
          onClick={() => setLimitMultiplier((prev) => prev + 1)}
          sx={{ mb: -1.25 }}
        >
          {t('wif.injury.showMore')}
        </Button>
      )}
      
    </Box>
    </ChartContainer>
  );
}