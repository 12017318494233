import React from 'react';
import { WithChildren } from '../../types';
import { Box, Container, Paper, Stack, Typography, useTheme } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link, { LinkProps } from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import { APP_COLORS } from '../../config';
import { AppIcon } from '../Elements/AppIcon';
import useMediaQuery from '@mui/material/useMediaQuery';
import { getActiveUser } from '../../utils/user';

type ContentLayoutProps = WithChildren<{
  header?: React.ReactNode;
}>;

export function NewContentLayout(props: ContentLayoutProps) {
  const { header, children } = props;

  return (
    <React.Fragment>
      <Box
        sx={{
          bgcolor: APP_COLORS.dark3,
          width: '100%',
          color: APP_COLORS.darkTextPrimary
        }}
      >
        <Container
          maxWidth="xl"
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            minHeight: 0,
            pt: 3
          }}
        >
          {header}
        </Container>
      </Box>
      <Container
        maxWidth="xl"
        sx={{
          width: '100%',
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          minHeight: 0
        }}
      >
        {children}
      </Container>
    </React.Fragment>
  );
}

interface LinkRouterProps extends LinkProps {
  to?: string;
  replace?: boolean;
}

function LinkRouter(props: LinkRouterProps) {
  return <Link {...props} component={RouterLink as any} />;
}

type OldContentLayoutProps = WithChildren<{
  title?: string;
  breadcrumbs?: {
    to: string;
    name?: string;
  }[];
  controlPanel?: any;
  maxWidth?: any;
  elevation?: number;
  primaryContainer?: boolean;
}>;

export const ContentLayout = (props: OldContentLayoutProps) => {
  const {
    title = '',
    breadcrumbs,
    controlPanel,
    children,
    maxWidth = 'xl',
    elevation = 0,
    primaryContainer = false
  } = props;

  const theme = useTheme();
  const lgScreen = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });

  const maxBreadcrumbItems = React.useMemo(() => {
    if (lgScreen) {
      return 4;
    }
    return 2;
  }, [lgScreen]); 

  const activeUser = getActiveUser();

  const backToSamsvar = () => {
    if (activeUser?.userRole === 'superadmin') {
      window.location.hash = `#/dashboardlist`;
      window.location.reload();
    } else {
      window.location.hash = `#/mainDashboard`;
      window.location.reload();
    }
  };

  return (
   <Box
      sx={{
        /* pr: 4,
        pl: 2, */
        /* ...(elevation === 0 && { flex: 1 }), */
        display: 'flex', 
        pl: 2,
        pr: 1.5,
        flexGrow: 1,
        flexDirection: 'column',
        gap: 1,
        overflow: 'hidden',
      }}
    >
      {/* {breadcrumbs && (
        <Breadcrumbs
          maxItems={2}
          itemsAfterCollapse={2}
          separator={<Typography variant='body1' sx={{ mx: 0.25, color: 'secondary' }}>/</Typography>}
          sx={{
            color: 'textVariant', 
            fontSize: 'body1.fontSize',
            mt: 1.5,
            ml: 1.5,
            '& .MuiLink-root': {
              color: 'primary',
            },
          }}
        >
          {[...breadcrumbs].map((breadcrumb, index) => {
            const to = `${breadcrumb.to}`;
              return (
                <LinkRouter underline="hover" to={to} key={to} color='primary.main' sx={{ typography: 'body1', fontWeight: 500 }}>
                  {breadcrumb?.name || breadcrumb.to}
                </LinkRouter>
              )
            })}
            <></>
          </Breadcrumbs>
        )} */}
      {controlPanel && controlPanel}
      <Paper
        elevation={1}
        sx={{
          /* boxShadow: BOX_SHADOWS.elevation0, */
          borderColor: 'divider',
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          width: '100%',
          mb: 1.5,
          mt: 0.5,
          /* overflow: 'visible', */
          /* borderRadius: 1,
            borderBottomLeftRadius: 0, */
          ...(primaryContainer && {
            bgcolor: 'primary.main'
          })

          /*   borderBottomRightRadius: 0,
            ...(elevation === 0 && { 
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
            }), */
        }}
      >
        <Box
          sx={{
            px: 3,
            pt: 2.25,
            pb: 0.25,
            width: '100%',
            display: 'flex', 
            flexDirection: 'row'
          }}
        >
          {(breadcrumbs && breadcrumbs?.length > 0) ? (
            <Breadcrumbs
              maxItems={maxBreadcrumbItems}
              separator={<AppIcon iconName='chevron_right' opticalSize={22} weight={500} sx={{ mx: -0.5 }} color='textVariant' />}
              sx={{
                color: 'textVariant', 
                fontSize: 'body2.fontSize',
                flex: 1,
                overflow: 'hidden',
                /* display: 'flex',
                flexDirection: 'row',
                '& .MuiBreadcrumbs-ol': {
                  display: 'contents'
                }, */
                '& .MuiBreadcrumbs-li': {
                  height: '24px!important',
                  display: 'flex', 
                  alignItems: 'center', 
                  justifyContent: 'center',
                  minHeight: 0,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  '&:last-of-type': {
                    flex: 1,
                  }
                },
                '& .MuiLink-root': {
                  ...(theme.palette.mode === 'light' ? {
                    color: 'primary',
                  } : {
                    color: 'textVariant',
                  }),
                },
              }}
            >
              {breadcrumbs.map((breadcrumb, index) => {
                const to = `${breadcrumb.to}`;
                if (index === 0) {
                  return (
                    <Link 
                      underline="hover" 
                      key={to || index} 
                      sx={{ typography: 'h5', fontWeight: 500 }}
                      onClick={index === 0 && backToSamsvar}
                    >
                      {breadcrumb?.name ? breadcrumb.name : breadcrumb.to}
                    </Link>
                  )
                }
                  
                return (
                  <LinkRouter 
                    underline="hover" 
                    to={to} 
                    key={to} 
                    sx={{ typography: 'h5', fontWeight: 500 }}
                  >
                    {breadcrumb?.name ? breadcrumb.name : breadcrumb.to}
                  </LinkRouter>
                )
              })}
                <Typography color='text.secondary' variant='h5' fontWeight={600}
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    width: '100%'
                  }}
                >
                  {title}
                </Typography>
              </Breadcrumbs>
            ) : (
              <Typography variant={'h5'} color='text.primary' sx={{ fontWeight: 600, color: 'text.primary' }}>{title}</Typography>
            )}
          </Box>
          {children}
        </Paper>
    </Box>
  );
};
