import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TKB_INJURY } from '../../../constants';
import { useAppSelector } from '../../../../../store';
import { selectInjuryHandlerReportIds } from '../../../store/injuryReportsSlice';

export const RenderLinkCell = (params: any) => {
  const { t } = useTranslation();
  /* const mappedIconNames: any = {
    'Injury': 'personal_injury',
    'Illness': 'coronavirus'
  }; */
  const handlerReportIds = useAppSelector(selectInjuryHandlerReportIds);

  if (params.row.userHasReadAccess || handlerReportIds.indexOf(params.row.id) === -1) {
    return <>{`${params.value}`}</>;
  }
  
  return (
    <Link
      component={RouterLink}
      variant='body2'
      to={`${params.row.id}`}
      underline={'none'}
      sx={{ /* textDecoration: 'none',  */'&:hover': { textDecoration: 'underline', color: 'text.primary' }, fontWeight: 400, minWidth: 0, color: (theme) => theme.palette.mode === 'light' ? 'primary.main' : '#c7d2fe' /* indigo 300 */ }}
    >
      {`${params.value}`}
    </Link>
  )
}