import { useEffect } from 'react';
import * as yup from 'yup';
import {
  FormDatePicker,
  FormRadioGroup,
  FormTimePicker,
  YES_NO_LABELS,
  YES_NO_OPTIONS
} from '../../../../../components/FormInputs';
import { FormRow, WithFormMethods } from '../../../../../components/Forms';
import { useTranslation } from 'react-i18next';
import { GENERIC_VALIDATION_MSG } from '../../../constants/mappedInputLabels';
import dayjs from 'dayjs';
import 'dayjs/locale/nb';
import { TKB_INJURY } from '../../../constants';
import { MSectionFieldNameMapping, getTranslationKeyForSectionFieldLabel } from '../../../helpers/dataMapping';
import { EMPLOYEE_SECTION_NAMES } from '../../../constants/employee';

export const WORK_TIME_ARRANGEMENTS_OPTIONS = ['Only during the day(06.00 - 21.00)', 'other'];
export const WORK_TIME_ARRANGEMENTS_LABELS: any = {
  'Only during the day(06.00 - 21.00)': 'Only during daytime (06.00 - 21.00)',
  other: 'Other'
};

export const FORM_OF_SALARY_OPTIONS = ['Hourly wage/fixed wage', 'performanceChord'];
export const FORM_OF_SALARY_LABELS: any = {
  'Hourly wage/fixed wage': 'Hourly/fixed wage',
  performanceChord: 'Perfomance/chord based'
};

export const INSIDE_OUTSIDE_OPTIONS = ['Inside', 'Outside'];
export const EMPLOYEE_ACCIDENT_OCCURED_OPTIONS = ['During normal working hours', 'During overtime', 'Outside working hours'];

export const EMPLOYEE_ACCIDENT_TWO_OPTIONS = ['option1', 'option2'];

export const EMPLOYEE_ACCIDENT_THREE_OPTIONS = ['option1', 'option2', 'option3'];

export const EMPLOYEE_ACCIDENT_YES_NO_OPTIONS = ['yes', 'no'];

export const employeeAccidentSchema = yup.object().shape({
  date: yup.string().required(GENERIC_VALIDATION_MSG),
  time: yup.string().required(GENERIC_VALIDATION_MSG),
  workTimeArrangements: yup.string().required(GENERIC_VALIDATION_MSG),
  accidentOccured: yup.string().required(GENERIC_VALIDATION_MSG),
  formOfSalary: yup.string().required(GENERIC_VALIDATION_MSG),
  atNormalWorkplace: yup.string().required(GENERIC_VALIDATION_MSG),
  insideOutside: yup.string().required(GENERIC_VALIDATION_MSG),
  onTheWayToWork: yup.string().required(GENERIC_VALIDATION_MSG),
  onTheWayBetweenWork: yup.string().required(GENERIC_VALIDATION_MSG),
  deathFromAccident: yup.string().required(GENERIC_VALIDATION_MSG),
  necessaryTraining: yup.string().required(GENERIC_VALIDATION_MSG),
  reportedToLIA: yup.string().required(GENERIC_VALIDATION_MSG)
});

export type EmployeeAccidentSection = yup.InferType<typeof employeeAccidentSchema>;

export const EmployeeAccidentControls = ({
  methods,
  methods: { control }
}: WithFormMethods<EmployeeAccidentSection>) => {
  const { t } = useTranslation();

  const arrangementsOptions = {
    option1: t(`${TKB_INJURY}.employee.accidentTab.arrangements.option1`),
    option2: t(`${TKB_INJURY}.employee.accidentTab.arrangements.option2`)
  };

  const accidentOccurredOptions = {
    option1: t(`${TKB_INJURY}.employee.accidentTab.accidentOccurred.option1`),
    option2: t(`${TKB_INJURY}.employee.accidentTab.accidentOccurred.option2`),
    option3: t(`${TKB_INJURY}.employee.accidentTab.accidentOccurred.option3`)
  };

  const formOfSalaryOptions = {
    option1: t(`${TKB_INJURY}.employee.accidentTab.formOfSalary.option1`),
    option2: t(`${TKB_INJURY}.employee.accidentTab.formOfSalary.option2`)
  };

  const whereAccidentOccurredOptions = {
    option1: t(`${TKB_INJURY}.employee.accidentTab.whereAccidentOccurred.option1`),
    option2: t(`${TKB_INJURY}.employee.accidentTab.whereAccidentOccurred.option2`)
  };

  const yesNoOptions = {
    yes: t(`${TKB_INJURY}.yesNoOptions.yes`),
    no: t(`${TKB_INJURY}.yesNoOptions.no`)
  };

  const reversedSectionName = MSectionFieldNameMapping[EMPLOYEE_SECTION_NAMES.EMPLOYEE_ACCIDENT_TYPES];
  /* const today = dayjs(new Date(), 'DD.MM.YYYY'); */
  return (
    <>
      <FormRow>
        <FormDatePicker
          control={control}
          /* datePickerProps={{
            maxDate: today
          }} */
          name="date"
          label={t(getTranslationKeyForSectionFieldLabel(EMPLOYEE_SECTION_NAMES.ACCIDENT, 'workTimeArrangements'))}
          formatTemplate="DD.MM.YYYY"
        />
        <FormTimePicker control={control} name="time" label={t(getTranslationKeyForSectionFieldLabel(EMPLOYEE_SECTION_NAMES.ACCIDENT, 'time'))} />
      </FormRow>
      <FormRadioGroup
        control={control}
        direction="column"
        name="workTimeArrangements"
        label={t(getTranslationKeyForSectionFieldLabel(EMPLOYEE_SECTION_NAMES.ACCIDENT, 'workTimeArrangements'))}
        options={EMPLOYEE_ACCIDENT_TWO_OPTIONS}
        mappedLabels={arrangementsOptions}
      />
      <FormRadioGroup
        direction="column"
        label={t(getTranslationKeyForSectionFieldLabel(EMPLOYEE_SECTION_NAMES.ACCIDENT, 'accidentOccured'))}
        control={control}
        name="accidentOccured"
        options={EMPLOYEE_ACCIDENT_THREE_OPTIONS}
        mappedLabels={accidentOccurredOptions}
      />
      <FormRadioGroup
        control={control}
        direction="column"
        name="formOfSalary"
        label={t(getTranslationKeyForSectionFieldLabel(EMPLOYEE_SECTION_NAMES.ACCIDENT, 'formOfSalary'))}
        options={EMPLOYEE_ACCIDENT_TWO_OPTIONS}
        mappedLabels={formOfSalaryOptions}
      />
      {/*  <FormSectionSubHeader>Location</FormSectionSubHeader> */}
      <FormRadioGroup
        label={t(getTranslationKeyForSectionFieldLabel(EMPLOYEE_SECTION_NAMES.ACCIDENT, 'atNormalWorkplace'))}
        control={control}
        name="atNormalWorkplace"
        options={EMPLOYEE_ACCIDENT_YES_NO_OPTIONS}
        mappedLabels={yesNoOptions}
      />
      <FormRadioGroup
        control={control}
        name="insideOutside"
        label={t(getTranslationKeyForSectionFieldLabel(EMPLOYEE_SECTION_NAMES.ACCIDENT, 'insideOutside'))}
        options={EMPLOYEE_ACCIDENT_TWO_OPTIONS}
        mappedLabels={whereAccidentOccurredOptions}
      />
      <FormRadioGroup
        control={control}
        label={t(getTranslationKeyForSectionFieldLabel(EMPLOYEE_SECTION_NAMES.ACCIDENT, 'onTheWayToWork'))}
        name="onTheWayToWork"
        options={EMPLOYEE_ACCIDENT_YES_NO_OPTIONS}
        mappedLabels={yesNoOptions}
      />
      <FormRadioGroup
        control={control}
        label={t(getTranslationKeyForSectionFieldLabel(EMPLOYEE_SECTION_NAMES.ACCIDENT, 'onTheWayBetweenWork'))}
        name="onTheWayBetweenWork"
        options={EMPLOYEE_ACCIDENT_YES_NO_OPTIONS}
        mappedLabels={yesNoOptions}
      />
      <FormRadioGroup
        control={control}
        label={t(getTranslationKeyForSectionFieldLabel(EMPLOYEE_SECTION_NAMES.ACCIDENT, 'deathFromAccident'))}
        name="deathFromAccident"
        options={EMPLOYEE_ACCIDENT_YES_NO_OPTIONS}
        mappedLabels={yesNoOptions}
      />
      <FormRadioGroup
        control={control}
        label={t(getTranslationKeyForSectionFieldLabel(EMPLOYEE_SECTION_NAMES.ACCIDENT, 'necessaryTraining'))}
        name="necessaryTraining"
        options={EMPLOYEE_ACCIDENT_YES_NO_OPTIONS}
        mappedLabels={yesNoOptions}
      />
      
      <FormRadioGroup
        control={control}
        label={t(getTranslationKeyForSectionFieldLabel(EMPLOYEE_SECTION_NAMES.ACCIDENT, 'reportedToLIA'))}
        name="reportedToLIA"
        options={EMPLOYEE_ACCIDENT_YES_NO_OPTIONS}
        mappedLabels={yesNoOptions}
      />
    </>
  );
};
