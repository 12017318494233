import {
  Avatar,
  Box,
  Button,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Drawer,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  Paper,
  Stack,
  Step,
  StepButton,
  StepLabel,
  Stepper,
  ToggleButton,
  Tooltip,
  Typography
} from '@mui/material';
import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import _, { filter } from 'lodash';
import FormControl from '@mui/material/FormControl';
import { InjuryReportsDataGrid } from '../components/InjuryReportsDataGrid/InjuryReportsDataGrid';
import { ContentHeader, ContentLayout, NewContentLayout } from '../../../components/Layout';
import { NamedTabPanel, TabPanel } from '../../../components/Elements/TabPanel';
import { Statistics } from '../components/Statistics/Statistics';
import AppMap from '../../sites/components/Map';
import { RootState, useAppDispatch, useAppSelector } from '../../../store';
import { PDFViewer } from '@react-pdf/renderer';
import {
  UpdateInjuryCaseReq,
  initializeTestData,
  makeSelectInjuryReportById,
  selectInjuryReportLoading,
  selectInjuryReportsTestDataInitialized,
  selectInjuryUnitEntities,
  updateApprovedSections,
  updateInjuryCase,
  updateInjuryReport
} from '../store/injuryReportsSlice';
import { AppIcon } from '../../../components/Elements';
import { Insights } from '../components/Statistics/Insights';
import FullScreenDialog from '../../../components/Elements/FullScreenDialog';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { DescriptionList, DescriptionListItem } from '../../../components/DescriptionList/DescriptionsGrid';
import { formatDateFromTimestamp, formatDateTimeFromTimestamp } from '../../../utils/format';
import { Content } from '../../../components/Layout/Content';
import { CurrentInjuryReportContext } from './CurrentInjuryReportContext';
import {
  AppExpandableSection,
  ApprovableSectionList,
  BaseSectionList,
  InjurySectionListItem
} from '../components/ReportDetails/ApprovableSection/ApprovableSection';
import { ActionPanel } from '../components/ReportDetails/ActionPanel/ActionPanel';
import AppStepper, { SimpleAppStepper } from '../../../components/Elements/AppStepper/AppStepper';
import { CASE_STATUS, REPORT_CATEGORY, REPORT_TYPES, TKB_INJURY } from '../constants';
import { AppIconButton } from '../../../components/Elements/AppIconButton';
import { SectionValuesListItem } from '../../../components/SectionValuesList/SectionValuesListItem';
import { CustomStepConnector, CustomStepIcon } from '../components/InjuryForm/InjuryWizard';
import { DashedDivider } from '../../../components/Elements/DashedDivider';
import RejectInjuryReportDialog from '../components/ReportDetails/CaseHandlingDialogs/RejectInjuryReportDialog';
import { ReportMessages } from '../components/ReportDetails/CasePanels/Messages';
import ContentWrapper from '../../../components/Layout/ContentWrapper';
import { RightContainer } from '../../../components/Layout/RightContainer';
import { Header } from '../../../components/Layout/Header';
import { TabsContainer } from '../../../components/Layout/AppTabs';
import { CaseStatusColors } from '../constants/mappedProps';
import CloseCasePanel, {
  likelihoodOfRecurrenceOptions,
  oldLikelyhoodOptionsMappedToNew
} from '../components/ReportDetails/CloseCasePanel/CloseCasePanel';
import PrintAndArchiveFormsPanel from '../components/ReportDetails/CasePanels/PrintAndArchiveFormsPanel';
import { Actions } from '../components/ReportDetails/CasePanels/Actions';
import { ReportComments } from '../components/ReportDetails/CasePanels/Comments';
import Grid from '@mui/material/Unstable_Grid2';
import { HistoryPanel } from '../components/ReportDetails/CasePanels/History';
import { loadingFinished, loadingStarted, snackAlertOpened } from '../../global/controlsSlice';
import LinkNonconformityReportDialog from '../components/ReportDetails/CaseHandlingDialogs/LinkNonconformityReportDialog';
import { selectNonconformityReportEntities } from '../../nonconformity/nonconformitySlice';
import { useTranslation } from 'react-i18next';
import Badge from '@mui/material/Badge';
import { selectEmployeeById } from '../../employees/employeesSlice';
import { pathToBackLabel } from './InjuryReportReporterPage';
import AssigneeDialog from '../components/ReportDetails/CaseHandlingDialogs/AssigneeDialog';
import {
  makeSelectEmployeeHandlerDepartmentIdsByTenantAdminUserId,
  makeSelectStudentHandlerDepartmentIdsByTenantAdminUserId,
  selectDepartmentEntities
} from '../../departments/departmentsSlice';
import { EMPLOYEE_SECTION_NAMES } from '../constants/employee';
import { STUDENT_SECTION_NAMES } from '../constants/student';
import { getActiveUser } from '../../../utils/user';
import { InjuryDocument } from '../components/InjuryDocument/InjuryDocument';
import { InjuryDocumentDialog } from '../components/InjuryDocument/InjuryDocumentDialog';
import InjuryEmailDialog from '../components/InjuryEmails/InjuryEmalilDialog';
import { LoadingButtonContainer } from '../../../components/Elements/LoadingButtonContainer';
import FSidePanel from '../../../components/Layout/ContentSidePanel';
import { SIKRI_COLORS } from '../../../config';
import { getTranslatedInjuryReportName } from '../helpers';
import ArchiveConfirmation from '../components/InjuryArchive/ArchiveConfirmation';
import config from '../../../config/config';
import { httpGet } from '../../../utils/httpService';
import { MINOR_INJURY } from '../constants/reportTypes';
import { fetchInjuryReportById } from '../store/injuryReportThunks';
import { useLoading } from '../../../common/useLoading';

const mappedBreadcrumbNames: any = {
  '/injuries/student': 'Student injury reports',
  '/injuries/all': 'All injury reports',
  '/injuries/employee': 'Employee injury reports'
};

type InjuryReportPageProps = {
  category?: string;
};

const tabNames = {
  DETAILS: 'DETAILS',
  COMMENTS: 'COMMENTS',
  MESSAGES: 'MESSAGES',
  ACTIONS: 'ACTIONS',
  REJECTION_DETAILS: 'REJECTION_DETAILS',
  FORMS: 'FORMS',
  CLOSING_CASE: 'CLOSING_CASE'
};

export const InjuryReportPage = (props: InjuryReportPageProps) => {
  const { category } = props;

  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const { t } = useTranslation();

  const injuryReportId = params.injuryReportId || '';
  const [{ isLoading }, { startLoading, stopLoading }] = useLoading();

  React.useEffect(() => {
    /* const interval = setInterval(() => {
      dispatch(fetchInjuryReportById(injuryReportId));  
    }, 60 * 1000);
    return () => clearInterval(interval); */
    dispatch(fetchInjuryReportById(injuryReportId));
  }, [injuryReportId]);

  const selectInjuryReportById = React.useMemo(makeSelectInjuryReportById, []);
  const injuryReport = useAppSelector((state: RootState) => selectInjuryReportById(state, injuryReportId));

  const [archiveData, setData] = React.useState(null);

  React.useEffect(() => {
    const type = injuryReport.category == 'student' ? 'injuryStudent' : 'injuryEmployee';
    httpGet(`${config.baseUrl}/auth/getArchiveSettingById/${type}`)
      .then((response) => response.data)
      .then((data) => setData(data));
  }, []);

  const ncReports = useAppSelector(selectNonconformityReportEntities);
  const departments = useAppSelector(selectDepartmentEntities);
  const units = useAppSelector(selectInjuryUnitEntities);

  const steps = ['1', '2', '3'];
  const [activeStep, setActiveStep] = React.useState(0);
  const [activeTab, setActiveTab] = React.useState(tabNames.DETAILS);
  const [approvedSections, setApprovedSections] = React.useState<string[]>([]);
  const [anchor, setAnchor] = React.useState<any>(undefined);
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openDialog, setOpenDialog] = React.useState<undefined | string>();

  const handleApproveSection = (sectionName: string, event: React.ChangeEvent<HTMLInputElement>) => {
    setApprovedSections((prev: any) => {
      let newApprovedSections = [...prev];
      if (event.target.checked && !prev.includes(sectionName)) {
        newApprovedSections.push(sectionName);
      } else if (!event.target.checked && prev.includes(sectionName)) {
        newApprovedSections = newApprovedSections.filter((section: string) => section !== sectionName);
      }
      return newApprovedSections;
    });
  };

  const [activeSidePanel, setActiveSidePanel] = React.useState<string | undefined>(undefined);

  const handleActiveSidePanel = (newActiveSidePanel: string) => {
    if (activeSidePanel !== newActiveSidePanel) {
      setActiveSidePanel(newActiveSidePanel);
    } else {
      setActiveSidePanel(undefined);
    }
  };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
  };

  if (!injuryReport) {
    return <></>;
  }

  const handleCloseMore = () => {
    setMenuAnchorEl(null);
  };

  const handleOpenDialog = (dialogName: string) => {
    if (menuAnchorEl) {
      handleCloseMore();
    }
    setOpenDialog(dialogName);
  };

  const isOngoing = injuryReport.status === CASE_STATUS.ONGOING;
  const isRejected = injuryReport.status === CASE_STATUS.REJECTED;
  const isClosed = injuryReport.status === CASE_STATUS.CLOSED;

  const iconButtons = React.useMemo(() => {
    const buttons: any[] = [];

    if (isOngoing) {
      buttons.push({ iconName: 'send' });
    }

    buttons.push({ iconName: 'print' });
    if (isOngoing) {
      buttons.push({ iconName: 'note_add' });
    }

    buttons.push({ iconName: 'history' });

    return buttons;
  }, [injuryReport]);

  const sidePanelActionButtons = React.useMemo(() => {
    let buttons: any[] = [];
    buttons.push({ iconName: 'chat' });
    buttons.push({ iconName: 'forum' });
    buttons.push({ iconName: 'assignment' });
    return buttons;
  }, [injuryReport]);

  const [handlingDialogId, setHandlingDialogId] = React.useState<undefined | string>();

  const handleOpenHandlingDialog = (dialogId: string) => {
    setHandlingDialogId(dialogId);
  };

  const handleClickStep = (step: number) => {
    if (step < activeStep) {
      setActiveStep(step);
    }
  };

  const [loading, setLoading] = React.useState(false);

  const handleSave = async () => {
    if (activeStep === 0) {
      if (approvedSections !== injuryReport?.approved_sections) {
        const req: UpdateInjuryCaseReq = {
          reportId: injuryReport.id,
          body: {
            approved_sections: approvedSections
          }
        };
        /* dispatch(loadingStarted()); */
        startLoading();
        const resultAction = await dispatch(updateInjuryCase(req));
        if (updateInjuryCase.fulfilled.match(resultAction)) {
          dispatch(snackAlertOpened({ message: t('wif.injury.reportSaved'), severity: 'success' }));
          stopLoading();
          if (approvedSections.length >= Object.keys(injuryReport?.sections)?.length) {
            setActiveStep(1);
          }
          /* dispatch(loadingFinished()); */
        } else {
          if (resultAction.payload) {
            dispatch(snackAlertOpened({ message: t('wif.injury.snackMessages.genericError'), severity: 'error' }));
            stopLoading();
          } else {
            dispatch(
              snackAlertOpened({
                message: t('wif.injury.snackMessages.errorWithMessage', { message: resultAction.error }),
                severity: 'error'
              })
            );
            stopLoading();
          }
        }
      }
    }
    if (activeStep === 1) {
    }
  };

  const getBackPath = () => {
    const backPath = location.pathname.split('/').slice(0, -1).join('/');
    return backPath;
  };

  const getBackHome = () => {
    const homePath = location.pathname.split('/').slice(0, 2).join('/');
    return homePath;
  };

  React.useEffect(() => {
    const backPath = location.pathname.match(/\/injuries\/\w+/g);
  }, [location]);

  const handleBack = () => {
    navigate(getBackPath());
  };

  const reporterAccount = useAppSelector((state: RootState) => selectEmployeeById(state, injuryReport.reporter_id));

  const handleCopyText = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  const handleCompleteReport = async () => {
    if (archiveData == '' || !archiveData.EnableArchive) {
      const req = {
        reportId: injuryReportId,
        body: {
          status: CASE_STATUS.CLOSED
        }
      };
      const resultAction = await dispatch(updateInjuryReport(req));
      if (updateInjuryReport.fulfilled.match(resultAction)) {
        dispatch(snackAlertOpened({ message: `Report completed!`, severity: 'success' }));
      } else {
        if (resultAction.payload) {
          dispatch(snackAlertOpened({ message: t('wif.injury.snackMessages.genericError'), severity: 'error' }));
        } else {
          dispatch(
            snackAlertOpened({
              message: t('wif.injury.snackMessages.errorWithMessage', { message: resultAction.error }),
              severity: 'error'
            })
          );
        }
      }
    } else {
      setOpenDialog('archive');
    }
  };
  const canCompleteReport = React.useMemo(() => {
    if ([REPORT_TYPES.ACCIDENT, REPORT_TYPES.ILLNESS].includes(injuryReport.type)) {
      if (injuryReport.category === REPORT_CATEGORY.STUDENT) {
        if (injuryReport?.navForm) {
          return true;
        }
      } else if (injuryReport.category === REPORT_CATEGORY.EMPLOYEE) {
        if (injuryReport?.navForm && injuryReport?.laborForm) {
          return true;
        }
      }
    }
    if (injuryReport.type === REPORT_TYPES.MINOR_INJURY) {
      return true;
    }
    return false;
  }, [injuryReport]);

  const departmentName = React.useMemo(() => {
    let dname = '';
    let dNameBackup = '';

    let departmentId = undefined;
    if (injuryReport.category === REPORT_CATEGORY.EMPLOYEE) {
      if (injuryReport.type === REPORT_TYPES.MINOR_INJURY) {
        departmentId = injuryReport.sections[EMPLOYEE_SECTION_NAMES.MINOR_INJURY]?.departmentId;
        dNameBackup = injuryReport.sections[EMPLOYEE_SECTION_NAMES.MINOR_INJURY]?.departmentName;
      } else if (injuryReport.type === REPORT_TYPES.ACCIDENT || injuryReport.type === REPORT_TYPES.ILLNESS) {
        const ir = injuryReport as any;
        departmentId = ir?.sections[EMPLOYEE_SECTION_NAMES.EMPLOYER]?.departmentId;
        dNameBackup = ir?.sections[EMPLOYEE_SECTION_NAMES.EMPLOYER]?.departmentName;
      }
    } else if (injuryReport.category === REPORT_CATEGORY.STUDENT) {
      if (injuryReport.type === REPORT_TYPES.MINOR_INJURY) {
        departmentId = injuryReport.sections[STUDENT_SECTION_NAMES.MINOR_INJURY]?.departmentId;
        dNameBackup = injuryReport.sections[STUDENT_SECTION_NAMES.MINOR_INJURY]?.departmentName;
      } else if (injuryReport.type === REPORT_TYPES.ACCIDENT || injuryReport.type === REPORT_TYPES.ILLNESS) {
        const ir = injuryReport as any;
        departmentId = ir?.sections[STUDENT_SECTION_NAMES.SCHOOL]?.departmentId;
        dNameBackup = ir?.sections[STUDENT_SECTION_NAMES.SCHOOL]?.departmentName;
      }
    }

    if (departmentId) {
      const department = departments[departmentId];
      if (department) {
        dname = department.name;
      } else if (dNameBackup) {
        dname = dNameBackup
      } else {
        dname = departmentId;
      }
    }

    return dname;
  }, [injuryReport, departments]);

  const unitName = React.useMemo(() => {
    let uname = '';

    let unitId = undefined;
    if (injuryReport.category === REPORT_CATEGORY.EMPLOYEE) {
      if (injuryReport.type === REPORT_TYPES.MINOR_INJURY) {
        unitId = injuryReport.sections[EMPLOYEE_SECTION_NAMES.MINOR_INJURY]?.unitId;
      } else if (injuryReport.type === REPORT_TYPES.ACCIDENT || injuryReport.type === REPORT_TYPES.ILLNESS) {
        const ir = injuryReport as any;
        unitId = ir?.sections[EMPLOYEE_SECTION_NAMES.EMPLOYER]?.unitId;
      }
    } else if (injuryReport.category === REPORT_CATEGORY.STUDENT) {
      if (injuryReport.type === REPORT_TYPES.MINOR_INJURY) {
        unitId = injuryReport.sections[STUDENT_SECTION_NAMES.MINOR_INJURY]?.unitId;
      } else if (injuryReport.type === REPORT_TYPES.ACCIDENT || injuryReport.type === REPORT_TYPES.ILLNESS) {
        const ir = injuryReport as any;
        unitId = ir?.sections[STUDENT_SECTION_NAMES.SCHOOL]?.unitId;
      }
    }

    if (unitId) {
      const unit = units[unitId];
      if (unit) {
        uname = unit.unit_name;
      } else {
        uname = unitId;
      }
    }

    return uname;
  }, [injuryReport, units]);

  const uncompleteActions = React.useMemo(() => {
    let uncompleted = 0;
    const actions = injuryReport?.actions;

    if (actions) {
      actions.forEach((action) => {
        if (action.status !== 'completed') {
          uncompleted++;
        }
      });
    }

    return uncompleted;
  }, [injuryReport]);

  React.useEffect(() => {
    let uncompletedActions = 0;
    const actions = injuryReport?.actions;

    if (actions) {
      actions.forEach((action) => {
        if (action.status !== 'completed') {
          uncompletedActions++;
        }
      });
    }
    if (uncompletedActions === 0 && injuryReport?.status === 'ongoing') {
      if (
        injuryReport?.closing_details?.likelyhood &&
        injuryReport?.closing_details?.endingEvaluation &&
        injuryReport?.approved_sections?.length >= Object.keys(injuryReport?.sections)?.length
      ) {
        setApprovedSections(injuryReport?.approved_sections || []);
        setActiveStep(2);
      } else if (injuryReport?.approved_sections?.length >= Object.keys(injuryReport?.sections)?.length) {
        setActiveStep(1);
        setApprovedSections(injuryReport?.approved_sections || []);
      } else {
        setApprovedSections(injuryReport?.approved_sections || []);
      }
    } else {
      setApprovedSections(injuryReport?.approved_sections || []);
    }
  }, [injuryReportId]);

  const activeUser = getActiveUser();

  const selectInjuryStudentHandlerDepartmentIdsByUserId = React.useMemo(
    makeSelectStudentHandlerDepartmentIdsByTenantAdminUserId,
    []
  );

  const studentHandlerDepartmentIds = useAppSelector((state: RootState) =>
    selectInjuryStudentHandlerDepartmentIdsByUserId(state, activeUser?.id)
  );
  const studentHandlerDepartments = React.useMemo(() => {
    return studentHandlerDepartmentIds.map((id) => departments[id]);
  }, [studentHandlerDepartmentIds]);

  const selectInjuryEmployeeHandlerDepartmentIdsByUserId = React.useMemo(
    makeSelectEmployeeHandlerDepartmentIdsByTenantAdminUserId,
    []
  );

  const employeeHandlerDepartmentIds = useAppSelector((state: RootState) =>
    selectInjuryEmployeeHandlerDepartmentIdsByUserId(state, activeUser?.id)
  );
  const employeeHandlerDepartments = React.useMemo(() => {
    return employeeHandlerDepartmentIds.map((id) => departments[id]);
  }, [employeeHandlerDepartmentIds]);

  const step1Completed = React.useMemo(() => {
    if (
      approvedSections.length < Object.keys(injuryReport.sections).length ||
      (activeStep === 1 && !injuryReport.closing_details) ||
      uncompleteActions > 0
    ) {
      return false;
    }
    return true;
  }, [injuryReport]);

  const step2Completed = React.useMemo(() => {
    return step1Completed && injuryReport['closing_details'];
  }, [step1Completed, injuryReport]);

  const completedSteps = React.useMemo(() => {
    const step1Complete =
      injuryReport.approved_sections.length >=
      Object.keys(injuryReport.sections).length; /*  || (uncompleteActions > 0) */
    const step2Complete = step1Complete && injuryReport['closing_details'];
    const step3Complete = step2Complete && injuryReport.status === 'closed';

    return {
      0: step1Complete,
      1: step2Complete,
      2: step3Complete
    };
  }, [injuryReport, approvedSections]);

  const isHandler = React.useMemo(() => {
    const userId = getActiveUser()?.id;
    const assigneeId = _.get(injuryReport, 'assignee_id', null);
    return userId === assigneeId;
  }, [injuryReport]);

  const handleClickNCLink = (repId) => {
    if (ncReports[repId]?.type.toLowerCase() === 'critical') window.open(`#/handler/critical/${repId}`, '_blank');
    else window.open(`#/handler/general/${repId}`, '_blank');
  };

  const handleSwitchToReporterView = () => {
    let initialPath = '';
    if (category) {
      if (category === 'student') {
        initialPath = '/injuries/student/registrations';
      }
      if (category === 'employee') {
        initialPath = '/injuries/employee/registrations';
      }
    } else {
      initialPath = '/injuries/all/registrations';
    }
    navigate(`${initialPath}/${injuryReport.id}`, { replace: true });
  };

  const getClosingCaseLikelyhoodKey = React.useMemo(() => {
    let likelyhoodKey = injuryReport?.closing_details?.likelyhood || 'not_evaluated';
    if (!likelihoodOfRecurrenceOptions.includes(likelyhoodKey)) {
      likelyhoodKey = oldLikelyhoodOptionsMappedToNew[likelyhoodKey] || 'not_evaluated';
    }
    return likelyhoodKey;
  }, [injuryReport]);

  return (
    <CurrentInjuryReportContext.Provider value={injuryReport}>
      <ContentWrapper>
        <ContentLayout
          maxWidth="lg"
          breadcrumbs={[
            {
              to: getBackHome(),
              name: t(`${TKB_INJURY}.home`)
            },
            {
              to: getBackPath(),
              name: t(`${TKB_INJURY}.breadcrumbs.${pathToBackLabel[getBackPath()]}`)
            }
          ]}
          title={getTranslatedInjuryReportName(injuryReport, t)}
        >
          <ContentHeader
            title={getTranslatedInjuryReportName(injuryReport, t)}
            onBack={handleBack}
            backLabel={pathToBackLabel[getBackPath()]}
            action={
              <Stack direction="row" gap={1}>
                {/* {injuryReport?.reporter_id === activeUser?.id && (
              <Button
                variant='outlined'
                onClick={handleSwitchToReporterView}
              >
                Switch to reporter view
              </Button>
            )} */}

                <Tooltip title={t('wif.injury.history')} placement="bottom">
                  <IconButton
                    onClick={() => handleActiveSidePanel('history')}
                    sx={{
                      height: '36px',
                      width: '36px',
                      color: 'text.secondary',
                      ...(activeSidePanel === 'history' && {
                        color: 'primary.main',
                        bgcolor: (theme) =>
                          theme.palette.mode === 'light'
                            ? `${SIKRI_COLORS.galaxyGreenSelected}!important`
                            : `${SIKRI_COLORS.galaxyGreenSelectedDark}!important`
                      })
                    }}
                  >
                    <AppIcon iconName="history" opticalSize={22} />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t('wif.injury.comments')} placement="bottom">
                  <IconButton
                    onClick={() => handleActiveSidePanel('comments')}
                    sx={{
                      height: '36px',
                      width: '36px',
                      color: 'text.secondary',
                      mr: 1.5,
                      ...(activeSidePanel === 'comments' && {
                        color: 'primary.main',
                        bgcolor: (theme) =>
                          theme.palette.mode === 'light'
                            ? `${SIKRI_COLORS.galaxyGreenSelected}!important`
                            : `${SIKRI_COLORS.galaxyGreenSelectedDark}!important`
                      })
                      /* bgcolor: historyPanelOpen ? `${SIKRI_COLORS.galaxyGreenSelected}!important` : 'transparent', */
                    }}
                  >
                    <Badge variant="dot" color="primary" invisible={injuryReport?.comments?.length < 1}>
                      <AppIcon iconName="comment" opticalSize={22} />
                    </Badge>
                  </IconButton>
                </Tooltip>
                {/* <Button
              variant='outlined'
              onClick={() => setHandlingDialogId('pdf')}
            >
              Download PDF
            </Button> */}
                <LoadingButtonContainer loading={isLoading}>
                  {activeStep === 1 ? (
                    <Button
                      type="submit"
                      form="close-injury-report-form"
                      variant="contained"
                      color="primary"
                      disabled={approvedSections === injuryReport.approved_sections || isLoading}
                      startIcon={<AppIcon iconName="save" />}
                    >
                      {t(`${TKB_INJURY}.save`)}
                    </Button>
                  ) : (
                    <Button
                      onClick={handleSave}
                      variant="contained"
                      color="primary"
                      disabled={activeStep === 2 || approvedSections === injuryReport.approved_sections || isLoading}
                      startIcon={<AppIcon iconName="save" />}
                    >
                      {t(`${TKB_INJURY}.save`)}
                    </Button>
                  )}
                </LoadingButtonContainer>
              </Stack>
            }
          />
          <Box
            sx={{
              flex: 1,
              px: {
                xs: 2,
                lg: 2.5,
                xl: 3
              },
              py: {
                xs: 2,
                lg: 2.5,
                xl: 3.25
              }
              /* pt: {
              xs: 0, 
              lg: 0.5, 
              xl: 1.25
            } */
            }}
          >
            <Grid
              container
              spacing={{
                xs: 3,
                lg: 4,
                xl: 6
              }}
            >
              <Grid xs={12} lg={8}>
                <Box
                  sx={{
                    flex: 1,
                    borderRadius: 1,
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    bgcolor: 'background.paper'
                  }}
                >
                  <Box
                    sx={{
                      display: {
                        xs: 'flex',
                        flexDirection: 'column',
                        lg: 'none',
                        mb: 1,
                        width: '100%'
                      }
                    }}
                  >
                    <ActionPanel handleOpenHandlingDialog={handleOpenHandlingDialog} />
                  </Box>
                  <Typography variant="h5" sx={{ mb: 2, mt: 1.25, display: { xs: 'block', lg: 'none' } }}>
                    Properties
                  </Typography>
                  <DescriptionList>
                    <DescriptionListItem
                      field={t('wif.injury.caseId')}
                      valueComponent={
                        <Stack
                          direction="row"
                          gap={0.75}
                          sx={{
                            '&:hover': {
                              '& .visible-on-hover': {
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                              }
                            }
                          }}
                        >
                          <Typography
                            variant="body1"
                            sx={{
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis'
                            }}
                          >
                            {injuryReport.id}
                          </Typography>
                          <Box
                            className="visible-on-hover"
                            sx={{
                              display: 'none'
                            }}
                          >
                            <Tooltip title="Copy ID" placement="bottom">
                              <IconButton onClick={() => handleCopyText(injuryReport.id)} size="small" sx={{ p: 0 }}>
                                <AppIcon iconName="content_copy" opticalSize={20} />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </Stack>
                      }
                    />
                    <DescriptionListItem
                      field={t('wif.injury.status')}
                      value={t(`${TKB_INJURY}.${injuryReport.status}`)}
                    />
                    {!category && (
                      <DescriptionListItem
                        field={t('wif.injury.category')}
                        value={t(`${TKB_INJURY}.reportCategory.${injuryReport.category}`)}
                      />
                    )}

                    <DescriptionListItem
                      field={t('wif.injury.type')}
                      value={t(`${TKB_INJURY}.reportType.${injuryReport.type}`)}
                    />
                    <DescriptionListItem field={t('wif.injury.department')} value={departmentName} />
                    {injuryReport.type !== REPORT_TYPES.MINOR_INJURY ? (
                      <DescriptionListItem field={t('wif.injury.unit')} value={unitName} />
                    ) : (
                      <></>
                    )}
                    <DescriptionListItem
                      field={t('wif.injury.reportedDate')}
                      value={formatDateTimeFromTimestamp(injuryReport.reported)}
                    />
                    <DescriptionListItem
                      field={t('wif.injury.reportedBy')}
                      value={reporterAccount ? reporterAccount.name : injuryReport.reporter_id}
                    />
                    {injuryReport.linked_nc_report_id && injuryReport.linked_nc_report_id !== 'None' && (
                      <DescriptionListItem
                        field={t('wif.injury.linkedNcr')}
                        valueComponent={
                          <Link onClick={() => handleClickNCLink(injuryReport.linked_nc_report_id)}>
                            {ncReports[injuryReport.linked_nc_report_id]
                              ? `${ncReports[injuryReport.linked_nc_report_id].name} (${injuryReport.linked_nc_report_id
                              })`
                              : injuryReport.linked_nc_report_id}
                          </Link>
                        }
                      />
                    )}
                  </DescriptionList>
                  {injuryReport.status === CASE_STATUS.ONGOING && (
                    <>
                      <Stepper
                        activeStep={activeStep}
                        sx={{ mx: -1, mt: 4.75, mb: 2.5 }}
                        connector={<CustomStepConnector />}
                      >
                        {[0, 1, 2].map((step: number, i: number) => (
                          <Step key={step} completed={completedSteps[i]}>
                            <StepLabel
                              StepIconComponent={CustomStepIcon}
                              sx={{
                                '& .MuiStepLabel-label': {
                                  '&.Mui-completed': {
                                    color: 'text.primary',
                                    fontWeight: 600
                                  },
                                  '&.Mui-active': {
                                    color: 'text.primary',
                                    fontWeight: 600
                                  },
                                  fontWeight: 500
                                }
                              }}
                            >
                              {!(injuryReport.type === REPORT_TYPES.MINOR_INJURY && step === 2) &&
                                t(`${TKB_INJURY}.caseStepper.${step}.title`)}
                              {injuryReport.type === REPORT_TYPES.MINOR_INJURY &&
                                step === 2 &&
                                t(`${TKB_INJURY}.caseStepper.${step}.minorInjuryTitle`)}
                            </StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                      <Box sx={{ width: '100%', my: 2 }}>
                        <Typography variant="h3">
                          {!(injuryReport.type === REPORT_TYPES.MINOR_INJURY && activeStep === 2) &&
                            t(`${TKB_INJURY}.caseStepper.${activeStep}.fullTitle`)}
                          {injuryReport.type === REPORT_TYPES.MINOR_INJURY &&
                            activeStep === 2 &&
                            t(`${TKB_INJURY}.caseStepper.${activeStep}.minorInjuryTitle`)}
                        </Typography>
                        {!(
                          injuryReport.type === REPORT_TYPES.MINOR_INJURY &&
                          (activeStep === 2 || activeStep === 1)
                        ) && (
                          <Typography variant="body1" marginTop={0.25} color="text.secondary">
                            {t(`${TKB_INJURY}.caseStepper.${activeStep}.description`)}
                          </Typography>
                        )}
                      </Box>
                    </>
                  )}
                  {injuryReport.status !== CASE_STATUS.ONGOING && <Box sx={{ my: 2 }} />}
                  {activeStep === 0 && (
                    <>
                      <TabsContainer px={0}>
                        <Tabs value={activeTab} onChange={handleChangeTab}>
                          <Tab label={t(`${TKB_INJURY}.details`)} value={tabNames.DETAILS} />
                          {injuryReport.status === CASE_STATUS.CLOSED && (
                            <Tab label={t('wif.injury.forms')} value={tabNames.FORMS} />
                          )}
                          {/* <Tab 
                        value={tabNames.COMMENTS}
                        label={<Badge variant='dot' color='primary' invisible={injuryReport?.comments?.length < 1}>{t(`${TKB_INJURY}.comments`)} </Badge>} sx={{ overflow: 'visible' }} /> */}
                          <Tab value={tabNames.MESSAGES} label={t(`${TKB_INJURY}.messages`)} />

                          <Tab
                            value={tabNames.ACTIONS}
                            label={
                              <Badge variant="dot" color="primary" invisible={uncompleteActions === 0}>
                                {t(`${TKB_INJURY}.actions`)}{' '}
                              </Badge>
                            }
                            sx={{ overflow: 'visible' }}
                          />
                          {injuryReport.status === CASE_STATUS.CLOSED && (
                            <Tab label={t(`${TKB_INJURY}.closingCase`)} value={tabNames.CLOSING_CASE} />
                          )}
                          {/* <Tab 
                        sx={{
                          overflow: 'visible',
                        }}
                        label={comments.length > 0 ? (
                          <Badge color="primary" variant="dot">
                            Comments
                          </Badge>
                        ) : 'Comments'} 
                        /> */}
                          {injuryReport.status === CASE_STATUS.REJECTED && (
                            <Tab value={tabNames.REJECTION_DETAILS} label={t(`${TKB_INJURY}.rejectedInfo`)} />
                          )}
                        </Tabs>
                      </TabsContainer>
                      <Box
                        sx={{
                          py: 2.5,
                          ...((activeTab === tabNames.COMMENTS || activeTab === tabNames.MESSAGES) && {
                            pt: 0
                          }),
                          flex: 1
                        }}
                      >
                        <NamedTabPanel value={activeTab} name={tabNames.FORMS}>
                          <PrintAndArchiveFormsPanel />
                        </NamedTabPanel>
                        <NamedTabPanel value={activeTab} name={tabNames.CLOSING_CASE}>
                          <List disablePadding>
                            <ListItem
                              disableGutters
                              sx={{
                                pt: 0.5,
                                pb: 0.25
                              }}
                            >
                              <ListItemText
                                sx={{
                                  '& .MuiListItemText-primary': {
                                    fontWeight: 500,
                                    color: 'text.primary',
                                    fontSize: 'body2.fontSize'
                                  },
                                  '& .MuiListItemText-secondary': {
                                    color: injuryReport?.closing_details?.likelyhood
                                      ? 'text.secondary'
                                      : 'text.disabled',
                                    fontSize: 'body2.fontSize'
                                  }
                                }}
                                primary={t('wif.injury.closeCaseTab.likelihoodOfRecurrence.title')}
                                secondary={
                                  injuryReport?.closing_details?.likelyhood
                                    ? t(
                                      `wif.injury.closeCaseTab.likelihoodOfRecurrence.options.${getClosingCaseLikelyhoodKey}`
                                    )
                                    : t('wif.injury.closeCaseTab.likelihoodOfRecurrence.options.not_evaluated')
                                }
                              />
                            </ListItem>
                            {injuryReport?.closing_details?.endingEvaluation && (
                              <ListItem
                                disableGutters
                                sx={{
                                  pt: 0.5,
                                  pb: 0.25
                                }}
                              >
                                <ListItemText
                                  sx={{
                                    '& .MuiListItemText-primary': {
                                      fontWeight: 500,
                                      color: 'text.primary',
                                      fontSize: 'body2.fontSize'
                                    },
                                    '& .MuiListItemText-secondary': {
                                      color: injuryReport?.closing_details?.endingEvaluation
                                        ? 'text.secondary'
                                        : 'text.disabled',
                                      fontSize: 'body2.fontSize'
                                    }
                                  }}
                                  primary={t('wif.injury.closeCaseTab.endingEvaluation.title')}
                                  secondary={injuryReport?.closing_details?.endingEvaluation || 'Undefined'}
                                />
                              </ListItem>
                            )}
                          </List>
                        </NamedTabPanel>
                        <NamedTabPanel value={activeTab} name={tabNames.DETAILS}>
                          {injuryReport.status === CASE_STATUS.ONGOING && (
                            <ApprovableSectionList
                              approvedSections={approvedSections}
                              handleApproveSection={handleApproveSection}
                            />
                          )}
                          {injuryReport.status !== CASE_STATUS.ONGOING && <BaseSectionList shouldAllowEdit={false} />}
                        </NamedTabPanel>

                        {/* <NamedTabPanel
                    value={activeTab}
                    name={tabNames.COMMENTS}
                  >
                    
                    <ReportComments />
                  </NamedTabPanel> */}
                        <NamedTabPanel value={activeTab} name={tabNames.MESSAGES}>
                          <ReportMessages />
                        </NamedTabPanel>

                        <NamedTabPanel value={activeTab} name={tabNames.ACTIONS}>
                          <Actions injuryReport={injuryReport} />
                        </NamedTabPanel>
                        <NamedTabPanel value={activeTab} name={tabNames.REJECTION_DETAILS}>
                          <Typography variant="h5">Rejection message</Typography>
                          {injuryReport.rejected_information && (
                            <Typography variant="body1">{injuryReport.rejected_information}</Typography>
                          )}
                        </NamedTabPanel>
                      </Box>
                    </>
                  )}
                  {activeStep === 1 && (
                    <>
                      <CloseCasePanel />
                    </>
                  )}
                  {activeStep === 2 && (
                    <>
                      <PrintAndArchiveFormsPanel />
                    </>
                  )}
                  {injuryReport.status === CASE_STATUS.ONGOING && (
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{
                        pt: 2.5,
                        borderTop: 1,
                        borderColor: 'divider'
                      }}
                    >
                      {activeStep > 0 && (
                        <Button onClick={() => setActiveStep(activeStep - 1)}>{t('wif.injury.back')}</Button>
                      )}
                      {activeStep === 0 && (
                        <Typography variant="body1">
                          {Object.keys(injuryReport.sections).length - approvedSections.length > 0
                            ? `${t('wif.injury.sectionsNeedApproval', {
                              count: Object.keys(injuryReport.sections).length - approvedSections.length
                            })} `
                            : ``}
                          {uncompleteActions > 0
                            ? `${t('wif.injury.actionsNeedToBeCompleted', { count: uncompleteActions })}`
                            : ''}
                        </Typography>
                      )}
                      <Stack direction="row" gap={1}>
                        {activeStep === 1 && (
                          <Stack direction="row" gap={1.5}>
                            <LoadingButtonContainer loading={loading}>
                              <Button
                                type="submit"
                                form="close-injury-report-form"
                                color={injuryReport?.closing_details?.endingEvaluation ? 'plain' : 'primary'}
                                disabled={loading}
                              >
                                {injuryReport?.closing_details?.endingEvaluation
                                  ? t('wif.injury.saveUpdates')
                                  : t('wif.injury.saveAndContinue')}
                              </Button>
                            </LoadingButtonContainer>
                            {injuryReport?.closing_details?.endingEvaluation && (
                              <Button
                                color="primary"
                                /* disabled={approvedSections.length < Object.keys(injuryReport.sections).length || (activeStep === 1 && !injuryReport.closing_details) || (uncompleteActions > 0)} */
                                onClick={() => setActiveStep(activeStep + 1)}
                              >
                                {t('wif.injury.next')}
                              </Button>
                            )}
                          </Stack>
                        )}
                        {activeStep === 0 && (
                          <Button
                            color="primary"
                            disabled={
                              approvedSections.length < Object.keys(injuryReport?.sections).length ||
                              uncompleteActions > 0
                            }
                            onClick={() => {
                              if (approvedSections.length !== injuryReport?.approved_sections?.length) {
                                handleSave();
                              }
                            }}
                          >
                            {injuryReport?.approved_sections &&
                              injuryReport?.approved_sections?.length >= approvedSections.length &&
                              injuryReport?.approved_sections?.length >= Object.keys(injuryReport?.sections).length
                              ? t('wif.injury.next')
                              : t('wif.injury.saveAndContinue')}
                          </Button>
                        )}
                        {activeStep === 2 && (
                          <Button
                            color="primary"
                            onClick={handleCompleteReport}
                            disabled={injuryReport.type !== REPORT_TYPES.MINOR_INJURY && !injuryReport?.navForm}
                          >
                            {t('wif.injury.completeReport')}
                          </Button>
                        )}
                      </Stack>
                    </Stack>
                  )}
                </Box>
              </Grid>
              <Grid
                xs={0}
                lg={4}
                sx={{
                  display: {
                    xs: 'none',
                    lg: 'block'
                  }
                }}
              >
                <ActionPanel handleOpenHandlingDialog={handleOpenHandlingDialog} />
              </Grid>
            </Grid>
          </Box>
        </ContentLayout>
        <FSidePanel
          open={Boolean(activeSidePanel)}
          onClose={() => setActiveSidePanel(undefined)}
          title={t(`wif.injury.${activeSidePanel}`)}
        /* titleDivider={activeSidePanel === 'comments'} */
        >
          {activeSidePanel === 'history' && <HistoryPanel />}
          {activeSidePanel === 'comments' && <ReportComments />}
        </FSidePanel>
      </ContentWrapper>
      {/* <CloseCaseDialog
        open={handlingDialogId === 'close'}
        onClose={() => setHandlingDialogId(undefined)}
      /> */}
      <LinkNonconformityReportDialog
        open={handlingDialogId === 'linkNonconformity'}
        onClose={() => setHandlingDialogId(undefined)}
      />

      {openDialog === 'archive' && (
        <ArchiveConfirmation open={openDialog === 'archive'} onClose={() => setOpenDialog(undefined)} />
      )}

      <InjuryEmailDialog
        open={handlingDialogId === 'emails'}
        injuryReportId={injuryReportId}
        onClose={() => setHandlingDialogId(undefined)}
      />
      <InjuryDocumentDialog open={handlingDialogId === 'pdf'} handleClose={() => setHandlingDialogId(undefined)} />
    </CurrentInjuryReportContext.Provider>
  );
};
