import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type AppPaletteMode = 'dark'|'light';

interface AppSnackAlert {
  message: string;
  severity: 'error'|'warning'|'info'|'success';
  title?: string;
}

export interface ControlsState {
  paletteMode: AppPaletteMode;
  snackAlert?: AppSnackAlert;
  loading?: boolean;
  splitViewEnabled: boolean;
  testUserRole: 'user'|'admin',
}

const initialState: ControlsState = {
  paletteMode: 'light',
  splitViewEnabled: false,
  testUserRole: 'admin',
};

export const controlsSlice = createSlice({
  name: 'controls', 
  initialState, 
  reducers: {
    paletteModeToggled: (state) => {
      if (state.paletteMode === 'light') {
        state.paletteMode = 'dark';
      } else {
        state.paletteMode = 'light';
      }
    },
    snackAlertOpened: (state, action: PayloadAction<AppSnackAlert>) => {
      state.snackAlert = action.payload;
    },
    loadingStarted: (state) => {
      state.loading = true;
    },
    loadingFinished: (state) => {
      state.loading = false;
    },
    toggleSplitView: (state) => {
      state.splitViewEnabled = !state.splitViewEnabled;
    },
    closeSplitView: (state) => {
      state.splitViewEnabled = false;
    },
    toggleTestUserRole: (state) => {
      state.testUserRole = state.testUserRole === 'user' ? 'admin' : 'user';
    },
  },
});

export const { 
  paletteModeToggled,
  snackAlertOpened,
  toggleSplitView, 
  closeSplitView,
  toggleTestUserRole,
  loadingStarted, 
  loadingFinished,
} = controlsSlice.actions;

export const selectPaletteMode = (state: any) => state.controls.paletteMode;
export const selectSnackAlert = (state: any) => state.controls.snackAlert;
export const selectSplitViewEnabled = (state: any) => state.controls.splitViewEnabled;
export const selectTestUserRole = (state: any) => state.controls.testUserRole;
export const selectAppLoading = (state: any) => state.controls.loading;

export default controlsSlice.reducer;