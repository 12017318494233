import React from 'react';
import clsx from 'clsx';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';
import { APP_COLORS } from '../../../../../config';
import { CASE_STATUS } from '../../../constants';
import { Box } from '@mui/system';

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  alignItems: 'center',
  gap: theme.spacing(1.5)
}));

export function renderProgress(params: GridRenderCellParams<any, number, any>) {
  if (params.value == null) {
    return '';
  }

  const valueInPercent = params.value * 100;
  // If the aggregated value does not have the same unit as the other cell
  // Then we fall back to the default rendering based on `valueGetter` instead of rendering a progress bar.
  return (
    <Root>
      <LinearProgress
        value={valueInPercent}
        variant="determinate"
        sx={{ width: '120px', height: '6px', borderRadius: '8px' }}
      />

      {`${valueInPercent.toFixed(0)}%`}
    </Root>
  );
}

export function RenderProgressWithStatusColor(params: GridRenderCellParams<any, number, any>) {
  if (params.value == null) {
    return <></>;
  }

  const valueInPercent = params.value * 100;
  const status = params.row.status;
  // If the aggregated value does not have the same unit as the other cell
  // Then we fall back to the default rendering based on `valueGetter` instead of rendering a progress bar.
  return (
    <Root>
      <LinearProgress
        value={valueInPercent}
        variant="determinate"
        color={status === CASE_STATUS.CLOSED ? 'success' : 'primary'}
        sx={{
          width: '120px',
          height: '6px',
          borderRadius: '8px',
          ...(status === CASE_STATUS.REJECTED && {
            opacity: 0.4
          })
          /*  ...(valueInPercent === 0 && {
            opacity: 0.64
          }), */
        }}
      />
      <Box
        sx={{
          ...(status === CASE_STATUS.REJECTED && {
            opacity: 0.64
          }),
          ...(valueInPercent === 0 && {
            opacity: 0.8
          }),
          color: `${status === CASE_STATUS.CLOSED ? 'success.main' : 'primary.main'}`
        }}
      >
        {`${valueInPercent.toFixed(0)}%`}
      </Box>
    </Root>
  );
}
