import { Box, Button, Chip, Divider, Drawer, Paper, Stack, Step, StepLabel, Stepper, ToggleButton, ToggleButtonGroup, Toolbar, Typography } from '@mui/material';
import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import InputLabel from '@mui/material/InputLabel';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import FormControl from '@mui/material/FormControl';
import { InjuryReportsDataGrid } from '../components/InjuryReportsDataGrid/InjuryReportsDataGrid';
import { ContentHeader, NewContentLayout, ContentLayout } from '../../../components/Layout';
import { NamedTabPanel, TabPanel } from '../../../components/Elements/TabPanel';
import { Statistics } from '../components/Statistics/Statistics';
import AppMap from '../../sites/components/Map';
import { RootState, useAppDispatch, useAppSelector } from '../../../store';
import {
  fetchInjuryReportLogs,
  fetchInjuryReports,
  fetchInjuryUnits,
  initializeTestData,
  injuryReportCleared,
  makeSelectInjuryReportsByModule,
  makeSelectTotalInjuryActionsByModule,
  makeSelectTotalInjuryMessagesByModule,
  selectAllInjuryReports,
  selectCurrentInjuryReportId,
  selectInjuryHandlerReportIds,
  selectInjuryRegistrationReportIds,
  selectInjuryReportsFetched,
  selectInjuryReportsTestDataInitialized
} from '../store/injuryReportsSlice';
import { AppIcon } from '../../../components/Elements';
import { Insights } from '../components/Statistics/Insights';
import FullScreenDialog from '../../../components/Elements/FullScreenDialog';
import CreateSiteDialog from '../../sites/components/CreateSiteDialog/CreateSiteDialog';
import { InjuryFormWrapper } from '../components/InjuryForm/InjuryFormWrapper';
import { useParams, useNavigate } from 'react-router-dom';
import { ContentGrid } from '../../../components/Layout/ContentGrid';
import Grid from '@mui/material/Unstable_Grid2';
import { ContentPanel } from '../../../components/Layout/ContentPanel';
import { PageHeader } from '../../../components/Layout/PageHeader';
import { PageLayout } from '../../../components/Layout/PageLayout';
import { Header } from '../../../components/Layout/Header';
import { TabsContainer } from '../../../components/Layout/AppTabs';
import { APP_COLORS } from '../../../config';
import { selectSplitViewEnabled } from '../../global/controlsSlice';
import ContentWrapper from '../../../components/Layout/ContentWrapper';
import { RightContainer } from '../../../components/Layout/RightContainer';
import { useTheme, alpha, styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { REPORT_CATEGORY, REPORT_TYPES, TKB_INJURY, TKB_INJURY_REPORT } from '../constants';
import CircularLoader from '../../../components/Loader/circularLoader';
import { fetchEmployeeUsers } from '../../employees/employeesSlice';
import { fetchAllDepartments } from '../../departments/departmentsSlice';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { navigateToUrl } from 'single-spa';
import { InjuryActions } from '../components/InjuryActions/InjuryActions';
import { InjuryReportActionsDataGrid } from '../../actions/components/ActionsDataGrid';
import { InjuryInbox } from '../components/InjuryInbox';

type InjuryReportsHandlerPageProps = {
  category?: string;
};

export const InjuryReportsHandlerPage = (props: InjuryReportsHandlerPageProps) => {
  const { category } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [formOpen, setFormOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  React.useEffect(() => {
    if (category && category === 'no_access') {
      window.location.href.replace('/fm/injuries', '');
      navigateToUrl(`#/mainDashboard`);
      window.location.reload();
    }
  }, [category]);

  const currentInjuryReportId = useAppSelector(selectCurrentInjuryReportId);
  const injuryReportsFetched = useAppSelector(selectInjuryReportsFetched);

  const handleOpenForm = () => {
    setFormOpen(true);
  };

  const handleCloseForm = () => {
    setFormOpen(false);
  };

  const [tab, setTab] = React.useState('reports');
  const [view, setView] = React.useState<string>('list');

  const handleChangeView = (event: React.MouseEvent<HTMLElement>, newView: string | null) => {
    if (newView !== null) {
      setView(newView);
    }
  };

  React.useEffect(() => {
    setView('list');
  }, [tab]);

  const handleChangeTab = (event: React.SyntheticEvent, newTab: string) => {
    dispatch(injuryReportCleared());
    setTab(newTab);
  };

  const handleReportInjury = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleInjuryReportDestination = (type: string) => {
    setAnchorEl(null);
    window.location.href.replace('/fm/injuries', '');
    const injuryReportRoute = '#/injury';

    switch (type) {
      case `${REPORT_CATEGORY.STUDENT}`:
        navigateToUrl(`${injuryReportRoute}`);
        window.location.reload();
        return;
      case `${REPORT_CATEGORY.EMPLOYEE}`:
        navigateToUrl(`${injuryReportRoute}/employee`);
        window.location.reload();
        return;
      case `${REPORT_CATEGORY.STUDENT}/${REPORT_TYPES.MINOR_INJURY}`:
        navigateToUrl(`${injuryReportRoute}/minorInjury`);
        window.location.reload();
        return;
      case `${REPORT_CATEGORY.EMPLOYEE}/${REPORT_TYPES.MINOR_INJURY}`:
        navigateToUrl(`${injuryReportRoute}/employee/minorInjury`);
        window.location.reload();
        return;
      default:
        return;
    }
  };

  const selectTotalInjuryMessagesByModule = React.useMemo(makeSelectTotalInjuryMessagesByModule, [category]);
  const selectTotalInjuryActionsByModule = React.useMemo(makeSelectTotalInjuryActionsByModule, [category]);
  const selectInjuryReportsByModule = React.useMemo(makeSelectInjuryReportsByModule, [category]);

  const totalMessages = useAppSelector((state: RootState) => selectTotalInjuryMessagesByModule(state, (category || 'all' as any)));
  const totalActions = useAppSelector((state: RootState) => selectTotalInjuryActionsByModule(state, (category || 'all' as any)));

  const injuryReports = useAppSelector((state: RootState) => selectInjuryReportsByModule(state, (category || 'all' as any)));

  const handlerReportIds = useAppSelector(selectInjuryHandlerReportIds);
  return (
    <ContentWrapper>
      <ContentLayout
        breadcrumbs={[{ to: '/', name: t(`${TKB_INJURY}.home`) }]}
        title={
          category === REPORT_CATEGORY.EMPLOYEE
            ? t(`${TKB_INJURY}.employeeInjuries`)
            : category === REPORT_CATEGORY.STUDENT
            ? t(`${TKB_INJURY}.studentInjuries`)
            : category === undefined
            ? t(`${TKB_INJURY}.sidebar.allInjuries`)
            : ''
        }
        maxWidth="xl"
      >
        <Stack
          direction="column"
          spacing={1}
          sx={{
            pt: 1.5
          }}
        >
          <Header
            actions={
              <Stack direction="row" gap={1.5}>
                {tab === 'actions' && (
                  <ToggleButtonGroup
                  value={view}
                  exclusive
                  onChange={handleChangeView}
                  aria-label='View mode'
                >
                  <ToggleButton value='list' aria-label='List'>
                    <AppIcon iconName='view_list' opticalSize={22} />
                  </ToggleButton>
                  <ToggleButton value='grid' aria-label='Grid'>
                    <AppIcon iconName='grid_view' opticalSize={22} />
                  </ToggleButton>
                </ToggleButtonGroup>
                )}
                {tab === 'filters'}
                {tab === 'reports' && (
                  <>
                    <Button
                      color="primary"
                      id="fade-button"
                      aria-controls={open ? 'fade-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      onClick={handleReportInjury}
                    >
                      {t(`${TKB_INJURY}.reportInjury`)}
                    </Button>
                    <Menu
                      id="fade-menu"
                      MenuListProps={{
                        'aria-labelledby': 'fade-button'
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={() => handleInjuryReportDestination('overview')}
                      TransitionComponent={Fade}
                    >
                      {(category === REPORT_CATEGORY.STUDENT || category === undefined) && (
                        <>
                          <MenuItem onClick={() => handleInjuryReportDestination(`${REPORT_CATEGORY.STUDENT}`)}>
                            {t(`${TKB_INJURY_REPORT}.studentInjury`)}
                          </MenuItem>
                          <MenuItem
                            onClick={() =>
                              handleInjuryReportDestination(`${REPORT_CATEGORY.STUDENT}/${REPORT_TYPES.MINOR_INJURY}`)
                            }
                          >
                            {t(`${TKB_INJURY_REPORT}.studentMinorInjury`)}
                          </MenuItem>
                        </>
                      )}
                      {(category === REPORT_CATEGORY.EMPLOYEE || category === undefined) && (
                        <>
                          <MenuItem onClick={() => handleInjuryReportDestination(`${REPORT_CATEGORY.EMPLOYEE}`)}>
                            {t(`${TKB_INJURY_REPORT}.employeeInjury`)}
                          </MenuItem>
                          <MenuItem
                            onClick={() =>
                              handleInjuryReportDestination(`${REPORT_CATEGORY.EMPLOYEE}/${REPORT_TYPES.MINOR_INJURY}`)
                            }
                          >
                            {t(`${TKB_INJURY_REPORT}.employeeMinorInjury`)}
                          </MenuItem>
                        </>
                      )}
                    </Menu>
                  </>
                )}
              </Stack>
            }
          >
            <>
              {category === REPORT_CATEGORY.EMPLOYEE && t(`${TKB_INJURY}.employeeInjuries`) + `: `}
              {category === REPORT_CATEGORY.STUDENT && t(`${TKB_INJURY}.studentInjuries`) + `: `}
              {category === undefined && t(`${TKB_INJURY}.allInjuries`) + `: `}
              <Typography
                variant="h1"
                component="span"
                sx={{
                  display: 'inline',
                  fontWeight: 500,
                  color: 'text.secondary'
                }}
              >
                {t(`${TKB_INJURY}.reportHandler`)}
              </Typography>
            </>
          </Header>
          <TabsContainer px={3}>
            <Tabs value={tab} onChange={handleChangeTab} aria-label="basic tabs example">
              <Tab label={t(`${TKB_INJURY}.reports`) + ` (${handlerReportIds.length})`} value='reports' />
              {totalActions > 0 && <Tab label={`${t('wif.injury.actions')} (${totalActions})`} value='actions' />}
              {totalMessages > 0 && <Tab label={`${t('wif.injury.messages')} (${totalMessages})`} value='messages' />}
              <Tab label={t(`${TKB_INJURY}.statistics`)} value='statistics' />
              {/* {category === undefined && <Tab label={t(`${TKB_INJURY}.map`)} value='map' />} */}
            </Tabs>
          </TabsContainer>
        </Stack>

        <NamedTabPanel value={tab} name='reports'>
          <InjuryReportsDataGrid isHandler={true} categoryFilter={category} view='handler' module={category || 'all' as any} />
        </NamedTabPanel>
        <NamedTabPanel value={tab} name='actions'>
          {view === 'list' && 
            <InjuryReportActionsDataGrid module={category || 'all' as any} />
          }
          {view === 'grid' && <InjuryActions category={category} />}
          {/* <InjuryActions category={category} /> */}
        </NamedTabPanel>
        <NamedTabPanel value={tab} name='messages'>
          <InjuryInbox module={category || 'all' as any} />
        </NamedTabPanel>
        <NamedTabPanel value={tab} name='statistics'>
          
          <Insights module={category || 'all' as any} />
        </NamedTabPanel>
        <NamedTabPanel value={tab} name='map'>
          <Statistics />
        </NamedTabPanel>

        <FullScreenDialog open={formOpen} handleClose={handleCloseForm} title="Report new injury">
          <Box sx={{}}>
            <InjuryFormWrapper />
          </Box>
        </FullScreenDialog>
        <CreateSiteDialog handleClose={handleCloseForm} open={false} />
      </ContentLayout>

      {/* {currentInjuryReportId && (
        <RightContainer>
          <InjuryReportDetails injuryReportId={currentInjuryReportId} />
        </RightContainer>
        
      )} */}
    </ContentWrapper>
  );
};