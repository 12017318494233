import React from 'react';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { default as datalabelsPlugin } from 'chartjs-plugin-datalabels';

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  datalabelsPlugin
);

type args = {
  theme: any,
  title?: string,
  subtitle?: string
}

export const getDefaultDoughnutChartOptionsWithTitle = ({ theme, title, subtitle }: args) => {
  return {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'right' as const,
        /* align: 'end', */
        labels: {
          boxWidth: 15,
          boxHeight: 15,
          /* usePointStyle: true, */
          /* useBorderRadius: true,
          borderRadius: 6, */
          usePointStyle: false,
          color: theme.palette.text.secondary,
          font: {
            size: 14,
            family: 'Inter',
            opacity: 1,
          }
        },
      },
      title: {
        padding: {
          bottom: -10,
        },
        display: true,
        align: 'start',
        color: theme.palette.text.primary,
        font: {
          family: 'Inter', 
          size: theme.typography.h4.fontSize, 
          weight: 600,
          lineHeight: theme.typography.h4.lineHeight,
        },
        text: title ? title : 'Title',
      },
      subtitle: {
        display: true,
        text: subtitle || 'Last year',
        align: 'start',
        color: theme.palette.text.secondary,
        font: {
          family: 'Inter', 
          size: theme.typography.body2.fontSize, 
          lineHeight: theme.typography.body2.fontSize,
        },
        padding: {
          top: 14
        }
      },
      datalabels: {
        backgroundColor: function(context) {
          return context.dataset.backgroundColor;
        },
        borderColor: theme.palette.mode === 'light' ? theme.palette.background.paper : theme.palette.backgroundVariant,
        borderRadius: 25,
        borderWidth: 2,
        color: theme.palette.background.paper,
        display: function(context) {
          var dataset = context.dataset;
          var count = dataset.data.length;
          var value = dataset.data[context.dataIndex];
          return value > count * 1.5;
        },
        font: {
          weight: 'bold'
        },
        padding: 6,
        formatter: Math.round
      }
    }
  } as any
}

const defaultOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Chart.js Doughnut Chart'
    },
    
  }
} as any;

interface DoughnutChartProps {
  options?: any;
  data?: any;
}

export default function DoughnutChart(props: DoughnutChartProps) {
  const { options = defaultOptions, data } = props;
  return <Doughnut options={options} data={data} />;
}
