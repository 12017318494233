import { Avatar, Button, IconButton, Paper, Stack, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import { WithChildren } from "../../types";
import { FormFileInput } from "../FormInputs/FormFileInput";
import { AppIcon } from "../Elements";
import { FormTextField } from "../FormInputs";
import { LoadingButtonContainer } from "../Elements/LoadingButtonContainer";
import { BOX_SHADOWS } from "../../config/theme/boxShadows";
import { useTranslation } from 'react-i18next';

export const SendMessageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1.5),
  width: '100%',
  paddingBottom: theme.spacing(1.5),
  
  overflow: 'visible'
}));

export const SendMessagePaper = (props) => {
  return (
    <Paper
      variant='outlined'
      sx={{
        borderRadius: 1,
        display: 'flex', 
        flexDirection: 'row', 
        alignItems: 'center', 
        boxShadow: BOX_SHADOWS.elevation0,
        pb: 1.5,
        width: '100%', 
      }}
    >
      <Stack
        direction='column'
        gap={0}
        width='100%'
      >
        {props.children}
      </Stack>
    </Paper>
  );
}

export const SendMessageAttachmentsContainer = (props) => {

  return (
    <Grid 
      spacing={1}
      container 
      sx={{
        p: 1.5,
        py: 1.5
      }}
    >
      {props.children}
    </Grid>
  );
}
type SendMessageAttachmentItemProps = WithChildren<{
  fullWidth?: boolean;
}>
export const SendMessageAttachmentItem = (props) => {
  const { fullWidth } = props;
  return (
    <Grid  
      xs={12}
      lg={fullWidth ? 12 : 6}
      sx={{
        display: 'flex', 
        flexDirection: 'row',
      }}
    >
      <Box 
        sx={{
          bgcolor: 'background.default',
          display: 'flex', 
          flexDirection: 'row', 
          alignItems: 'start',  
          borderRadius: 1,
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          flex: 1,
          py: 1.25,
          px: 1.5,  
          pr: 1.25,
        }}
      >
        {props.children}
      </Box>
    </Grid>
  );
}

type SendMessageInputsContainerProps = WithChildren<{
  borderTop?: boolean;
}>;

export const SendMessageInputsContainer = (props) => {
  const { borderTop = false } = props;
  return (
    <Stack
      direction='column'
      gap={1.5}
      sx={{ 
        flex: 1, 
        px: 1.5,
        pt: 1.5,
        
        ...(borderTop && {
          borderTop: 1, 
          borderColor: 'divider'
        })
      }}
    >
      {props.children}
    </Stack>
  )
};

type SendMessageProps = {
  formId: string;
  onSubmit: (data: any) => void;
  watch: any;
  control: any;
  append: any;
  remove: any; 
  fields: any;
  attachments: any;
  loading: boolean;
  sendButtonLabel?: string;
  sendButtonIconName?: string;
};

export const SendMessage = (props: SendMessageProps) => {
  const { formId, onSubmit, watch, control, append, remove, fields, attachments, loading = false, sendButtonLabel = 'post', sendButtonIconName } = props;
  const { t } = useTranslation();

  const canAddAttachment = React.useMemo(() => {
    let canAdd = true;
    if (attachments?.length < 2) {
      return true;
    }
    if (attachments.filter((attachment) => attachment?.file)?.length < attachments?.length) {
      return false;
    }
    for (let i = 0; i < attachments.length; i++) {
      const attachment = attachments[i];
      console.log(`attachment.${i}: ${JSON.stringify(attachment)}`);
      const file = attachment?.file;
      if (file) {
        console.log(`file.${i}: ${file}`);
      }
    }
    return canAdd;
  }, [attachments]);

  return (
    <SendMessageContainer>
      <form id={formId} onSubmit={onSubmit}>
        <SendMessagePaper>
        {fields.length > 0 && (
          
          <SendMessageAttachmentsContainer>
            {fields.map((field, index) => (
              <SendMessageAttachmentItem 
                fullWidth={(fields.length % 2 !== 0 && index === fields.length - 1) ? 12 : 6}
              >
                <FormFileInput 
                  label={t('wif.injury.upload')}
                  name={`attachments.${index}.file` as const}
                  watch={watch}
                  control={control}
                  index={index}
                  outlinedBtn
                />
                <IconButton 
                  onClick={() => remove(index)}
                >
                  <AppIcon
                    iconName='delete'
                    color='error.main' 
                  />
                </IconButton>
              </SendMessageAttachmentItem>
            ))}
          </SendMessageAttachmentsContainer>
        )}
        <SendMessageInputsContainer
          borderTop={fields.length > 0}
        >
          <FormTextField 
            variant='standard'
            name='message'
            label=''
            maxRows={6}
            multiline
            control={control}
            fullWidth
            placeholder={t('wif.injury.messageInput.textPlaceholder')}
          />
          <Stack 
            direction='row'
            justifyContent='space-between'
            alignItems='start'
            gap={2}
            width='100%'
          >
            {/* <IconButton
              edge='start'
              sx={{
                height: '36px',
                width: '36px'
              }}
            >
              <AppIcon iconName='attach_file' />
            </IconButton> */}
            <Box>
            <Button
              variant='text'
              color='primary'
              onClick={() => append({ file: undefined })}
              startIcon={<AppIcon iconName='attach_file' />}
              disabled={!canAddAttachment}
              sx={{ ml: -0.5 }}
            >
              {t('wif.injury.addAttachment')}
            </Button>
            </Box>
            <LoadingButtonContainer
              loading={loading}
            >
              <Button 
                type='submit'
                variant='contained'
                color='primary'
                disabled={loading}
                endIcon={sendButtonIconName && <AppIcon iconName={sendButtonIconName} />}
              >
                {t(`wif.injury.messageInput.${sendButtonLabel}`)}
              </Button>
            </LoadingButtonContainer>
          </Stack>
          </SendMessageInputsContainer>
        </SendMessagePaper>
      </form>
    </SendMessageContainer>
  );
}