import { IconButton, Link, Tooltip, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import { Stack } from '@mui/system';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { AppIcon } from '../../../../../components/Elements';
import { LoadingButtonContainer } from '../../../../../components/Elements/LoadingButtonContainer';
import { AppFileAttachment } from '../../../../../components/Messages/AppMessage';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { formatDateTimeFromTimestamp } from '../../../../../utils/format';
import { getActiveUser } from '../../../../../utils/user';
import { selectEmployees } from '../../../../employees/employeesSlice';
import { snackAlertOpened } from '../../../../global/controlsSlice';
import { CASE_STATUS, REPORT_CATEGORY, REPORT_TYPES, TKB_INJURY } from '../../../constants';
import { useCurrentInjuryReport } from '../../../routes/CurrentInjuryReportContext';
import {
  downloadLIA,
  downloadNAV,
  downloadNAVFromKey,
  updateInjuryCase,
  uploadCoverPage
} from '../../../store/injuryReportsSlice';
import { InjuryDocumentDialog } from '../../InjuryDocument/InjuryDocumentDialog';

const LikelyhoodOfReoccurrenceOptions = ['Not evaluated', 'Not likely', 'Likely', 'Very likely'];

export const closingCaseSchema = yup.object().shape({
  likelyhoodOfReoccurence: yup.string().oneOf(LikelyhoodOfReoccurrenceOptions).required(),
  endingEvaluation: yup.string().required().min(4, 'min 4')
});

interface RejectInjuryReportDialogProps {
  open?: boolean;
  onClose?: () => void;
}

const NavFormCreateCoverPage = () => {
  const { t } = useTranslation();
  const injuryReport = useCurrentInjuryReport();

  return (
    <>
      <Typography variant="h6">1. {t(`${TKB_INJURY}.steps.printAndArchive.createCoverPage.title`)}</Typography>
      <Link 
        href={injuryReport?.category === REPORT_CATEGORY.EMPLOYEE ? 'https://www.nav.no/soknader/nb/person/helse/yrkesskade/NAV%2013-07.05/ettersendelse/brev' : 'https://www.nav.no/soknader/nb/person/helse/yrkesskade/NAV%2013-10.01/brev'}
        variant="body2"
        color="primary.main"
        target="_blank"
        sx={{ mt: 0.5 }}
      >
        {t('wif.injury.steps.printAndArchive.createCoverPage.description')}.
      </Link>
    </>
  );
}

const NavFormUploadCoverPage = () => {
  const { t } = useTranslation();
  const injuryReport = useCurrentInjuryReport();
}

export default function PrintAndArchiveFormsPanel(props: RejectInjuryReportDialogProps) {
  const { open = true, onClose } = props;
  const { t } = useTranslation();

  const injuryReport = useCurrentInjuryReport();
  const dispatch = useAppDispatch();
  const users = useAppSelector(selectEmployees);

  const [coverPageUrl, setCoverPageUrl] = React.useState('');
  const [isDisabled, setIsDisabled] = React.useState(true);
  const [downloadIsLoading, setDownloadIsLoading] = React.useState(false);
  const [downloadLaborIsLoading, setdDownloadLaborIsLoading] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState<undefined | string>();

  React.useEffect(() => {
    setIsDisabled(false);
  }, [coverPageUrl]);

  const [file, setFile] = React.useState(null);

  const handleFileChange = async (acceptedFile: any) => {
    
    const reader = new FileReader();

    reader.onload = async () => {
      const base64Data = (reader.result as string).split(',')[1];
      if (base64Data) {
        const req = {
          body: {
            content: base64Data,
            reportId: injuryReport.id
          }
        };
        setDownloadIsLoading(true);
        const resultAction = await uploadCoverPage(req);
        setCoverPageUrl(resultAction);
        if (resultAction) {
          setDownloadIsLoading(false);
        }
      }
    };

    // reader.readAsArrayBuffer(acceptedFile);
    reader.readAsDataURL(acceptedFile);
  };

  const onDrop = (acceptedFile) => {
    // Handle the dropped files
    setFile(acceptedFile[0]);
    handleFileChange(acceptedFile[0]);
  };

  const { getRootProps, getInputProps } = useDropzone({ maxFiles: 1, onDrop });

  const handleLIAPdf = async () => {
    const req = {
      body: {
        reportId: injuryReport.id
      }
    };
    setdDownloadLaborIsLoading(true);
    const laborResult = await downloadLIA(req);
    if (laborResult !== null) {
      const timestamp = Date.now();
      const updateReq = {
        //changed the data structure
        body: {
          laborForm: {
            downloadedTimestamp: timestamp,
            key: laborResult.key
          }
          // labor_form_download_timestamp: timestamp,
          // labor_form_url: laborResult.key
        },
        reportId: injuryReport.id
      };
      const resultAction = await dispatch(updateInjuryCase(updateReq));
      if (updateInjuryCase.fulfilled.match(resultAction)) {
        setdDownloadLaborIsLoading(false);
        dispatch(snackAlertOpened({ message: `Download saved.`, severity: 'success' }));
      } else {
        if (resultAction.payload) {
          setdDownloadLaborIsLoading(false);
          dispatch(snackAlertOpened({ message: t('wif.injury.snackMessages.genericError'), severity: 'error' }));
        } else {
          setdDownloadLaborIsLoading(false);
          dispatch(snackAlertOpened({ message: t('wif.injury.snackMessages.errorWithMessage', { message: resultAction.error }), severity: 'error' }));
        }
      }
      window.open(laborResult?.signedUrl, '_blank');
      setFile(null);
    }
  };

  const handleFileDownload = async () => {
    if (injuryReport?.navForm?.key && (!coverPageUrl || coverPageUrl === '')) {
      const req = {
        body: {
          bucketKey: injuryReport?.navForm?.key
        }
      };
      setDownloadIsLoading(true);
      const resultUrl = await downloadNAVFromKey(req);
      if (resultUrl !== null) {
        window.open(resultUrl, '_blank');
        setDownloadIsLoading(false);
      } else {
        setDownloadIsLoading(false);
      }
    } else {
      const req = {
        body: {
          reportId: injuryReport.id,
          coverPageSignedUrl: coverPageUrl,
          category: injuryReport.category
        }
      };
      if (coverPageUrl !== '') {
        setDownloadIsLoading(true);
        const navResult = await downloadNAV(req);
        if (navResult !== null) {
          const timestamp = Date.now();
          const updateCaseReq = {
            body: {
              navForm: {
                downloadedTimestamp: timestamp,
                key: navResult.key
              }
            },
            reportId: injuryReport.id
          };
          const resultAction = await dispatch(updateInjuryCase(updateCaseReq));
          if (updateInjuryCase.fulfilled.match(resultAction)) {
            /* dispatch(loadingFinished()); */
            setDownloadIsLoading(false);
            dispatch(snackAlertOpened({ message: t('wif.injury.snackMessages.downloadSaved.success'), severity: 'success' }));
          } else {
            if (resultAction.payload) {
              setDownloadIsLoading(false);
              dispatch(snackAlertOpened({ message: t('wif.injury.snackMessages.genericError'), severity: 'error' }));
            } else {
              setDownloadIsLoading(false);
              dispatch(snackAlertOpened({ message: t('wif.injury.snackMessages.errorWithMessage', { message: resultAction.error }), severity: 'error' }));
            }
          }
          window.open(navResult?.signedUrl, '_blank');
          setCoverPageUrl('');
          setFile(null);
        }
      }
    }
  };

  const navFormDownloadedAtStr = React.useMemo(() => {
    let str = t('wif.injury.notDownloadedYet');
    if (injuryReport?.navForm) {
      str = `${t('wif.injury.downloadedAt')} ${formatDateTimeFromTimestamp(injuryReport?.navForm?.downloadedTimestamp)}`;
      if (injuryReport?.navForm?.downloadedBy) {
        const activeUser = getActiveUser();
        if (injuryReport.navForm.downloadedBy === activeUser.id) {
          str += ` ${t('wif.injury.by').toLowerCase()} ${t('wif.injury.youObject').toLowerCase()}`;
        } else if (users?.[injuryReport?.navForm?.downloadedBy]?.name) {
          str += ` ${t('wif.injury.by').toLowerCase()} ${users?.[injuryReport?.navForm?.downloadedBy]?.name}`;
        } 
        
      }
    }
    return str;
  }, [injuryReport?.navForm, users, t]);

  const [navDownloadTab, setNavDownloadTab] = React.useState('downloadExisting');
  
  const handleChangeNavDownloadTab = (
    event: React.MouseEvent<HTMLElement>,
    newNavDownloadTab: string | null // TODO add type report category
  ) => {
    if (newNavDownloadTab !== null) {
      setNavDownloadTab(newNavDownloadTab);
    }
  };
  const theme = useTheme();

  const outdatedForms = React.useMemo(() => {
    let outdated = [];
    if (injuryReport?.form_sections_updated_at) {

      const formSectionsUpdatedTimestamp = new Date(injuryReport?.form_sections_updated_at).getTime();
      if (injuryReport?.navForm?.downloadedTimestamp) {
        if (formSectionsUpdatedTimestamp > injuryReport?.navForm?.downloadedTimestamp) {
          outdated.push('navForm');
        }
      }
      if (injuryReport?.laborForm?.downloadedTimestamp) {
        if (formSectionsUpdatedTimestamp > injuryReport?.laborForm?.downloadedTimestamp) {
          outdated.push('laborForm');
        }
      }
    }
    return outdated;
  }, [injuryReport]);

  return (
    <Stack
      direction="column"
      gap={2.5}
      sx={{
        flex: 1,
        width: '100%',
        pt: 1,
        ...(injuryReport?.status === CASE_STATUS.ONGOING && {
          borderTop: 1,
          pt: 3,
          borderColor: 'divider'
        }),

        pb: 3
      }}
    >
      <Box>
        {injuryReport?.type !== REPORT_TYPES.MINOR_INJURY && (<Typography variant="h4">{t('wif.injury.navForm')}</Typography>)}
        {injuryReport?.type === REPORT_TYPES.MINOR_INJURY && (<Typography variant="h4">{t('wif.injury.getReport')}</Typography>)}
        <Typography variant="body2" color="textVariant">
          {injuryReport?.navForm?.downloadedTimestamp ? `${t('wif.injury.downloadedAt')} ${formatDateTimeFromTimestamp(injuryReport.navForm.downloadedTimestamp)}` : t('wif.injury.notDownloadedYet')}
          {injuryReport?.archive?.navForm ? ` | ${t('wif.injury.archivedOn')} ${formatDateTimeFromTimestamp(injuryReport?.archive?.navForm)}`: ``}
        </Typography>
        {outdatedForms.includes('navForm') && (
          <Stack
            direction='row'
            gap={0.5}
            alignItems={'center'}
            sx={{ mt: 0.5 }}
          >
            <Typography variant="body2" color="warning.main">
              {t('wif.injury.downloadIsOutdated')}
              
            </Typography>
            <Tooltip 
              title={`${t('wif.injury.reportDetailsWereUpdatedAfter')} (${formatDateTimeFromTimestamp(injuryReport?.form_sections_updated_at)}).`}
            >
              <IconButton
                size='small'
                sx={{ height: '20px', width: '20px'}}
              >
                <AppIcon 
                  iconName='help'
                  color='textVariant'
                  opticalSize={20}
                />
              </IconButton>
            </Tooltip>
          </Stack>
          
        )}
      </Box>
      {injuryReport?.status === CASE_STATUS.ONGOING && injuryReport?.type !== REPORT_TYPES.MINOR_INJURY && (
        <>
          
          <Box>
            <Typography variant="h6">1. {t(`${TKB_INJURY}.steps.printAndArchive.createCoverPage.title`)}</Typography>
            <Link 
              href={injuryReport?.category === REPORT_CATEGORY.EMPLOYEE ? 'https://www.nav.no/soknader/nb/person/helse/yrkesskade/NAV%2013-07.05/ettersendelse/brev' : 'https://www.nav.no/soknader/nb/person/helse/yrkesskade/NAV%2013-10.01/brev'}
              variant="body2"
              color="primary.main"
              target="_blank"
              sx={{ mt: 0.5 }}
            >
              {t('wif.injury.steps.printAndArchive.createCoverPage.description')}.
            </Link>
          </Box>
          <Box>
            <Typography variant="h6">2. {t(`${TKB_INJURY}.steps.printAndArchive.uploadCoverPage.title`)}</Typography>
            <Typography variant="body2" sx={{ mt: 0.5, mb: 1.5 }}>
              {t(`${TKB_INJURY}.steps.printAndArchive.uploadCoverPage.description`)}
            </Typography>
            
            {/* <Typography variant="body2" sx={{ mt: 0.5 }}>
              {file ? file.name : ''}
            </Typography> */}
            <Box>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              {file && file?.name ? (
                <AppFileAttachment
                  file={file}
                  onClickRemove={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setCoverPageUrl('');
                    setFile(null);
                  }}
                />
              ) : (
                <Button
                  /* disabled={injuryReport.type === REPORT_TYPES.MINOR_INJURY} */
                  color={!injuryReport?.navForm?.key ? 'primary' : 'plain'}
                  variant='outlined'
                  startIcon={<AppIcon iconName='upload' />}
                >
                  {/* {injuryReport?.navForm ? t(`${TKB_INJURY}.uploadNew`) : t(`${TKB_INJURY}.upload`)} */}
                  {t(`${TKB_INJURY}.upload`)}
                </Button>
              )}
              
            </div>
            </Box>
            
          </Box>
          <Box
            sx={{
              display: 'flex', 
              flexDirection: 'column',
              position: 'relative',
            }}
          >
            {injuryReport?.status === CASE_STATUS.ONGOING && (
              <Typography variant="h6"
                sx={{
                  mb: injuryReport?.status === CASE_STATUS.ONGOING ? 1.5 : 0
                }}
              >
                3. {t(`${TKB_INJURY}.steps.printAndArchive.uploadCoverPage.downloadForm`)}
              </Typography>
            )}
            <Box 
              sx={{
                display: 'flex', 
                flexDirection: 'row', 
              }}
            >
              <LoadingButtonContainer
                loading={downloadIsLoading}
              >
                <Button
                  onClick={handleFileDownload}
                  color="primary"
                  disabled={downloadIsLoading || (coverPageUrl === '' && !injuryReport.navForm)}
                  
                >
                  {(coverPageUrl !== '' && injuryReport.navForm) 
                    ? t(`${TKB_INJURY}.createAndDownload`)
                    : injuryReport?.navForm ? t(`${TKB_INJURY}.download`)
                    : t(`${TKB_INJURY}.createAndDownload`)
                  }
                </Button>
              </LoadingButtonContainer>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex', 
              flexDirection: 'column',
              position: 'relative',
              typography: 'body2', 
              color: 'text.secondary'
            }}
          >
            <Typography variant="h6"
            >
              4. {t(`${TKB_INJURY}.steps.printAndArchive.submitForm.title`)}
            </Typography>
            <Link 
              href='https://skademelding.nav.no/yrkesskade/'
              variant='body2'
              color='primary.main'
              target="_blank"
              sx={{ mt: 0.5 }}
            >
              {t(`${TKB_INJURY}.steps.printAndArchive.submitForm.description`)}
            </Link>
            <Typography variant='body2' sx={{ mt: 1, mb: 0.75, color: 'text.secondary' }}>
              {t(`${TKB_INJURY}.steps.printAndArchive.submitForm.info.text`)}
            </Typography>
            <ul>
              <li>{t(`${TKB_INJURY}.steps.printAndArchive.submitForm.info.bulletpoint1`)}</li>
              <li>{t(`${TKB_INJURY}.steps.printAndArchive.submitForm.info.bulletpoint2`)}</li>
              <li>{t(`${TKB_INJURY}.steps.printAndArchive.submitForm.info.bulletpoint3`)}</li>
              <li>{t(`${TKB_INJURY}.steps.printAndArchive.submitForm.info.bulletpoint4`)}</li>
            </ul>
          </Box>
        </>
      )}
      {injuryReport?.type === REPORT_TYPES.MINOR_INJURY && (
        <>
          <InjuryDocumentDialog open={openDialog === 'pdf'} handleClose={() => setOpenDialog(undefined)} />
          <Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row'
              }}
            >
              <Button
                onClick={() => {
                  setOpenDialog('pdf');
                }}
                color="primary"
              >
                {t(`${TKB_INJURY}.download`)}
              </Button>
            </Box>
          </Box>
        </>
      )}
      {/*(injuryReport.category === 'employee' && injuryReport.type === 'accident')*/}

      {injuryReport.category === 'employee' && injuryReport.type === 'accident' && (
        <>
          <Box sx={{ mt: 1.5 }}>
            <Typography variant="h4">{t('wif.injury.laborForm')}</Typography>
            <Typography variant="body2" color="text.secondary">
              {injuryReport?.laborForm?.downloadedTimestamp
                ? `${t('wif.injury.downloadedAt')} ${formatDateTimeFromTimestamp(
                    injuryReport.laborForm.downloadedTimestamp
                  )}`
                : t('wif.injury.notDownloadedYet')}
                {injuryReport?.archive?.labourInspectionAuthForm && ` | ${t('wif.injury.archivedOn')} ${formatDateTimeFromTimestamp(injuryReport?.archive?.labourInspectionAuthForm)}`}
            </Typography>
          </Box>

          <Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row'
              }}
            >
              <LoadingButtonContainer loading={downloadLaborIsLoading}>
                <Button color="primary" onClick={handleLIAPdf} disabled={downloadLaborIsLoading}>
                  {t('wif.injury.createAndDownload')}
                </Button>
              </LoadingButtonContainer>
            </Box>
          </Box>
        </>
      )}
    </Stack>
  );
}
