import { configureStore } from '@reduxjs/toolkit'
import sitesSlice from '../features/sites/sitesSlice';
import injuryReportsSlice from '../features/injuries/store/injuryReportsSlice'
import controlsSlice from '../features/global/controlsSlice';
import employeesSlice from '../features/employees/employeesSlice';
import departmentsSlice from '../features/departments/departmentsSlice';
import nonconformitySlice from '../features/nonconformity/nonconformitySlice';
import actionsSlice from '../features/actions/actionsSlice';
import injuryControlsSlice from '../features/injuries/store/injuryControlsSlice';

import locationsSlice from '../features/locations/locationsSlice';
export const store = configureStore({
  reducer: {
    controls: controlsSlice,
    sites: sitesSlice,
    injuryReports: injuryReportsSlice,
    employees: employeesSlice,
    departments: departmentsSlice,
    nonconformity: nonconformitySlice, 
    actions: actionsSlice,
    locations: locationsSlice,
    injuryControls: injuryControlsSlice,
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
