import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import { Avatar, Chip, CircularProgress, IconButton, Link, Typography } from '@mui/material';
import { AppIcon } from '../../../../../components/Elements';
import { CASE_STATUS, TKB_INJURY } from '../../../constants';
import { RootState, useAppSelector } from '../../../../../store';
import { makeSelectEmployeeHandlerDepartmentIdsByTenantAdminUserId, makeSelectStudentHandlerDepartmentIdsByTenantAdminUserId, selectDepartmentEntities } from '../../../../departments/departmentsSlice';
import { selectInjuryHandlerReportIds, selectInjuryUnitEntities } from '../../../store/injuryReportsSlice';
import { selectEmployeeById, selectEmployees, selectEmployeesFetched } from '../../../../employees/employeesSlice';
import { Box } from '@mui/system';
import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { formatDateFromTimestamp } from '../../../../../utils/format';
import { getActiveUser } from '../../../../../utils/user';
import { APP_COLORS } from '../../../../../config';
import { generateAvatarColor } from '../../../../../utils/color';
import { selectAllNonconformityReports, selectNonconformityReportEntities } from '../../../../nonconformity/nonconformitySlice';
import _, { filter } from 'lodash';


export const RenderInjuryMessagesCell = (params: any) => {
  if (!params.value) {
    return <></>
  }
  return (
    <Stack
      direction='row'
      alignItems='center'
      gap={0.75} 
      sx={{
      }}
    >
      
      <AppIcon iconName='chat' color='textVariant' opticalSize={20} />
      <Typography
        variant='body2'
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {params.value}
      </Typography>
    </Stack>
  );
}

export const RenderInjuryCommentsCell = (params: any) => {
  if (!params.value) {
    return <></>
  }
  return (
    <Stack
      direction='row'
      alignItems='center'
      gap={0.75} 
      sx={{
      }}
    >
      
      <AppIcon iconName='forum' color='textVariant' opticalSize={20} />
      <Typography
        variant='body2'
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {params.value}
      </Typography>
    </Stack>
  );
}

export const RenderCategoryCell = (params: any) => {
  const { t } = useTranslation();

  return (
    <>
      {/* <Stack
      direction='row'
      alignItems='center'
      gap={0.75}
      sx={{
        color: 'text.primary',
        ml: -0.5
      }}
    >
      <AppIcon iconName={ReportCategoryIconNames[params.value] || 'pending'} opticalSize={20} sx={{ ml: 0.5, mr: 0 }} color='textVariant' />
      {params.value}
    </Stack> */}
      {t(`${TKB_INJURY}.reportCategory.${params.value}`)}
    </>

  );
}


export const RenderTypeCell = (params: any) => {
  const { t } = useTranslation();

  return (
    <>
      {/* <Stack
      direction='row'
      alignItems='center'
      gap={0.75}
      sx={{
        color: 'text.primary',
        ml: -0.5
      }}
    >
      <AppIcon iconName={ReportTypeIconNames[params.value] || 'pending'} opticalSize={20} sx={{ ml: 0.5, mr: 0 }} color='textVariant' />
      {t(`${TKB_INJURY}.type.${params.value}`)}
    </Stack> */}
      {t(`${TKB_INJURY}.reportType.${params.value}`)}
    </>
  );
}

export const RenderSiteCell = (params: any) => {

  return (
    <Stack
      direction='row'
      alignItems='center'
      gap={0.75}
      sx={{
        color: 'text.primary',
        ml: -0.5
      }}
    >
      <AppIcon iconName='location_on' opticalSize={20} sx={{ ml: 0.5, mr: 0 }} color='textVariant' />
      {params.value.split('/')[1]}
    </Stack>
  )
}

/* export const RenderTypeCell = (params: any) => {
  const { t } = useTranslation();

  return (
    <>{t(`type.${params.value}`)}</>
  )
}
 */
export const RenderUnitCell = (params: any) => {
  const units = useAppSelector(selectInjuryUnitEntities);

  const unit = units[params.value];
  return (
    <>
      {unit ? unit?.unit_name : params.value}
    </>
  )
};

export const RenderDepartmentCell = (params: any) => {
  const departments = useAppSelector(selectDepartmentEntities);

  const department = departments[params.value];
  return (
    <>
      {department ? department?.name : params.row.departmentName || params.value}
    </>
  )
};

export const RenderNcReportCell = (params: any) => {
  const ncReports = useAppSelector(selectNonconformityReportEntities);

  return (
    <Link
      variant='body2'
      underline={'none'}
      sx={{ 
        overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap",
        /* textDecoration: 'none',  */'&:hover': { textDecoration: 'underline', color: 'text.primary' }, fontWeight: 400, minWidth: 0, color: (theme) => theme.palette.mode === 'light' ? 'primary.main' : '#c7d2fe' /* indigo 300 */ }}
    >
      {params.value || params?.row?.ncReportId || ''}
    </Link>
  )
}

export const RenderHandlerCell = (params: any) => {
  const { t } = useTranslation();

  const employeesFetched = useAppSelector(selectEmployeesFetched);
  const departments = useAppSelector(selectDepartmentEntities);
  const users = useAppSelector(selectEmployees);
  const userHandlingIds = useAppSelector(selectInjuryHandlerReportIds);

  /* const invalidHandler = React.useMemo(() => {
    if (params.value.length < 1) {
      return false;
    }
    const userId = params.value;

    const activeUser = getActiveUser();
    if (activeUser) {
      if (activeUser.role === 'tenantadmin') {
        return false;
      }
      if (activeUser.id === userId) {
        return userHandlingIds.indexOf(params.row.id) === -1;
      }
    }

    const user = users[userId];
    if (user) {
      if (user.role === 'tenantadmin') {
        return false;
      }
    }

    const departmentId = params.row.departmentId;
    const department = departments[departmentId];

    if (department) {
      if (department?.injury_dept_leaders && department?.injury_dept_leaders?.length > 0) {
        if (department.injury_dept_leaders.includes(userId)) {
          return false;
        }
      }
      
      const category = params.row.category;

      if (category === 'student') {
        if (department.injury_student_users.includes(userId)) {
          return false;
        }
      } else if (category === 'employee') {
        if (department.injury_employee_users.includes(userId)) {
          return false;
        }
      }
    }

    return true;
  }, [departments, users, userHandlingIds]);  */

  const hasAssignee = params.value && params.value !== '';

  const assigneeName = React.useMemo(() => {
    if (hasAssignee) {
      const backupAssignee = params.row.assignee_details?.name;
      if (backupAssignee && users[params.value]?.name === 'unresolved') return backupAssignee;
      return users[params.value]?.name || params.value;
    }

    return t('wif.injury.unassigned');
  }, [users, t]);

  if (!users[params.value] && params.value && params.value !== '') {
    return (
      <>
        <CircularProgress color='warning' size={20} />
      </>
    );
  }

  return (
    <Stack
      direction='row'
      alignItems='center'
      gap={0.75} 
      sx={{
        /* ...(invalidHandler && {
          color: 'warning.dark',
        }), */
      }}
    >
      <Avatar
        sx={{ 
          height: 24,
          width: 24,
          ...(hasAssignee ? {
            bgcolor: (theme) => generateAvatarColor(assigneeName, theme.palette.mode === 'dark'),
          } : {
            bgcolor: 'text.disabled'
          }),
          fontSize: 'body2.fontSize',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {hasAssignee ? `${assigneeName.charAt(0).toUpperCase()}` : <AppIcon iconName="person" />}
      </Avatar>
      <Typography
        variant='body2'
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          ...(!hasAssignee && {
            color: 'textVariant'
          })
        }}
      >
        {_.capitalize(assigneeName)}
      </Typography>
      
      {/* {invalidHandler && (
        <Tooltip
          placement='right'
          title='The assigned handler is missing handler privileges for this department.'
        >
        <Box
          sx={{
            display: 'flex', 
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <AppIcon iconName='privacy_tip' color='warning.main' opticalSize={18} />
        </Box>
      </Tooltip>
      )} */}
    </Stack>
  );
};


/* export const RenderDepartmentCell = (params: any) => {
  const users = useAppSelector(selectEmployees);


  return (
    <>
      {users[params.value]?.name || params.value}
    </>
  )
}; */


export const RenderUserCell = (params: any) => {
  const { t } = useTranslation();

  const users = useAppSelector(selectEmployees);

  const hasAssignee = params.value && params.value !== '';

  const assigneeName = React.useMemo(() => {
    if (params.value && params.value !== '') {
      const backupAssignee = params.row.assignee_details?.name;
      if (backupAssignee && users[params.value]?.name === 'unresolved') return backupAssignee;
      return users[params.value]?.name || params.value;
    }

    return t('wif.injury.anonymous');
  }, [users]);

  return (
    <Stack
      direction='row'
      alignItems='center'
      gap={0.75} 
      sx={{
        /* ...(invalidHandler && {
          color: 'warning.dark',
        }), */
      }}
    >
      <Avatar
        sx={{ 
          height: 24,
          width: 24,
          ...(hasAssignee ? {
            bgcolor: (theme) => generateAvatarColor(assigneeName, theme.palette.mode === 'dark'),
          } : {
            bgcolor: 'text.disabled'
          }),
          fontSize: 'body2.fontSize',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {hasAssignee ? `${assigneeName.charAt(0).toUpperCase()}` : <AppIcon iconName="person" />}
      </Avatar>
      <Typography
        variant='body2'
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          ...(!hasAssignee && {
            color: 'textVariant'
          })
        }}
      >
        {assigneeName}
      </Typography>
    </Stack>
  )
};

export const RenderDueDateCell = (params: any) => {
  
  const getDeadlineStatus = () => {
    const deadline = params.value;
   
    if (deadline && deadline !== null) {
      let deadlineDate = new Date(deadline);
      if (deadlineDate) {
        deadlineDate.setHours(0, 0, 0, 0);
        let today = new Date();
        today.setHours(0, 0, 0, 0);
        console.log(`deadline: ${deadlineDate.getTime()}, today: ${today.getTime()}, > ${today.getTime() > deadlineDate.getTime()}}`);
        if (today > deadlineDate) {
          return 'overdue';
        } else if (today.getTime() === deadlineDate.getTime()) {
          return 'today';
        }
      }
      
    }
    return 'normal';
  };
  return (
    <Box
      sx={{
        ...(!params.value && {
          color: 'text.disabled'
        }),
        ...(getDeadlineStatus() === 'overdue' && {
          color: 'error.main'
        }),
        ...(getDeadlineStatus() === 'today' && {
          color: 'warning.main'
        }),
      }}
    >
    <Stack
      direction='row'
      alignItems='center'
      gap={0.75} 
      sx={{
        ...(!params.value && {
          color: 'text.disabled'
        }),
        ...(getDeadlineStatus() === 'overdue' && {
          color: (theme) => theme.palette.mode === 'light' ? 'error.dark' : 'error.light'
        }),
        ...(getDeadlineStatus() === 'today' && {
          color: (theme) => theme.palette.mode === 'light' ? 'warning.dark' : 'warning.light'
        }),
      }}
    >
      <Typography
        variant='body2'
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {params.value ? formatDateFromTimestamp(params.value) : ''}
      </Typography>
      {/* {getDeadlineStatus() === 'overdue' && (
        <Tooltip
          placement='right'
          title='The deadline is overdue.'
        >
          <Box
            sx={{
              display: 'flex', 
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <AppIcon iconName='assignment_late' color='error.main' opticalSize={18} />
          </Box>
          
        </Tooltip>
      )} */}
    </Stack>
        
    </Box>
  );
}

export const RenderStatusCell = (params: any) => {
  const { t } = useTranslation();

  const statusColors: any = {
    [CASE_STATUS.ONGOING]: 'info',
    [CASE_STATUS.CLOSED]: 'success',
    [CASE_STATUS.REJECTED]: 'error',
  }

  return (
    <Stack
      direction='row'
      alignItems='center'
      gap={0.75} 
      sx={{
        /* ...(invalidHandler && {
          color: 'warning.dark',
        }), */
      }}
    >
      <Box
        sx={{
          height: 8,
          width: 8,
          borderRadius: '50%',
          bgcolor: `${statusColors[params.value]}.main`
        }}
      />
      <Typography
        variant='body2'
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {t(`${TKB_INJURY}.${params.value}`)}
      </Typography>
      {/*   <Stack
        direction='row'
        alignItems='center'
        gap={0.75}
        sx={{
          color: 'text.primary',
          ml: -0.5
        }}
      >
        <AppIcon iconName={statusIconNames[params.value]} opticalSize={20} sx={{ ml: 0.5, mr: 0 }} color={`${statusColors[params.value]}`} />
        {t(`${TKB_INJURY}.${params.value}`)}
      </Stack> */}
      {/* <Chip
        label={t(`${TKB_INJURY}.${params.value}`)}
        color={statusColors[params.value] || 'primary'}
        size='small'
      /> */}
    </Stack>
  );
}