import {
  GridColDef,
  GridColumnVisibilityModel
} from '@mui/x-data-grid';
import React from 'react';
import { useAppSelector } from '../../../../store';

import { Stack } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { RDataGrid } from '../../../../components/DataGrid/RDataGrid';
import { IActiveFilter, IFilterControl } from '../../../../components/DataGrid/types';
import { AppIcon } from '../../../../components/Elements';
import { RenderCopyCell } from '../../../injuries/components/InjuryReportsDataGrid/RenderCell';
import { getSiteTypeById } from '../../../locations/constants';
import { selectAllAreas, selectAllSites } from '../../../locations/locationsSlice';

const RenderSiteTypeCell = (params: any) => {
  
  const siteType = getSiteTypeById(params.value);
  return (
    <Stack
      direction='row'
      alignItems='center'
      gap={0.75}
      sx={{
        color: 'text.primary'
      }}
    >
      {/* <AppIcon iconName={siteType.iconName} opticalSize={20} sx={{ ml: 0.5, mr: 0 }} color='textVariant' /> */}
      {siteType.label}
    </Stack>
  )
}
const CustomCheckbox = (props: any) => {
  return (
    <Checkbox
      icon={<AppIcon iconName='clinical_notes' />}
      {...props}
    />
  )
}

export type UnsavedInjuryReportsDataGridFilter = {
  field: any; 
  value?: string;
}


export type InjuryReportsFilter = {
  field: any; 
  getFieldValue?: (value: any) => any;
  operator?: string; // default to '==' operator
  // operator 
  value: string;
}



export const SitesDataGrid = () => {
  const sites = useAppSelector(selectAllSites);
  const areas = useAppSelector(selectAllAreas);

  const controls = React.useMemo(() => {
    let controlItems: IFilterControl[] = [];

    return controlItems;
  }, [sites, areas]);
  
  const [activeFilters, setActiveFilters] = React.useState<any[]>([]);

  const handleClearFilters = () => {
    setActiveFilters([]);
  }

  const handleRemoveFilter = (field: string) => {
    setActiveFilters(prevFilters => {
      let newFilters = [...prevFilters];
      newFilters = newFilters.filter(filter => filter.field !== field);
      return newFilters;
    });
  }
  const handleSetFilter = (newFilter: IActiveFilter) => {
    setActiveFilters(prevFilters => {
      let newFilters = [...prevFilters];
      const filterIndex = newFilters.findIndex(filter => filter.field === newFilter.field);
      if (filterIndex > -1) {
        newFilters[filterIndex] = newFilter;
      } else {
        newFilters.push(newFilter);
      }
      return newFilters;
    });
  }

  const items = React.useMemo(() => {
    return sites.map(site => (
      {
        id: site.id,
        name: site.name,
        coordinates: `${site.coordinates.lat}, ${site.coordinates.lng}`,
        type: site.type,
        address: site.address,
        areasCount: areas.filter(area => area.site === site.id).length
      }
    ))
  }, [areas, sites]);

  const columns = React.useMemo(() => {
    
    const columnByField: { [field: string]: GridColDef } = {
      'id': {  
        field: 'id',
        headerName: 'ID',
        width: 120,
        renderCell: (params) => RenderCopyCell(params)
      },
      'name': {  
        field: 'name',
        headerName: 'Name',
        minWidth: 200,
        flex: 2,
      },
      'coordinates': {
        field: 'coordinates',
        headerName: 'Coordinates',
        minWidth: 200,
        flex: 2,
      },
      'type': {
        field: 'type',
        headerName: 'Type',
        minWidth: 160,
        flex: 1,
        renderCell: (params) => RenderSiteTypeCell(params)
      },
      'address': {
        field: 'address',
        headerName: 'Address',
        minWidth: 160,
        flex: 1,
      },
      'areasCount': {
        field: 'areasCount',
        headerName: 'Areas',
        minWidth: 140,
        flex: 1,
      },
    };
    
    let cols = ['id', 'name', 'coordinates', 'type', 'address', 'areasCount'];

    return cols.map((field) => columnByField[field]);
  }, []);

  const initialColumnVisibilityModel: GridColumnVisibilityModel = {
    
  };

  return (
    <RDataGrid
      items={items}
      cols={columns}
      filters={{
        controls: controls, 
        addedControls: [],
        active: activeFilters, 
        handleSetFilter, 
        handleRemoveFilter, 
        handleResetFilterControls: handleClearFilters,
      }}
      initialColumnVisibilityModel={initialColumnVisibilityModel}
    />
  )
}