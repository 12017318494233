import { IconButton, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import { useAppDispatch, useAppSelector } from '../../../../store';
import AppMap from '../../../sites/components/Map';
import { selectAllSites, selectCurrentSiteId, selectSites, siteDeselected } from '../../../sites/sitesSlice';
import { AppIcon } from '../../../../components/Elements';
import { APP_COLORS } from '../../../../config';
import { BOX_SHADOWS } from '../../../../config/theme/boxShadows';
import { MapLocationsControl } from '../../../sites/components/MapLocationsControl/MapLocationsControl';
/* import { SiteDetails } from '../../../sites/components/SiteDetails/SiteDetails';
 */
type MapMenuListItemProps = {
  selected?: boolean;
  iconName: string;
  label?: string;
}

const MapMenuListItem = (props: MapMenuListItemProps) => {
  const { selected, iconName, label = 'Label' } = props;

  return (
    <ListItemButton
      selected={selected}
      sx={{
        ...(!selected ? {
          width: '40px',
        } : {
          pl: 1.5,
          pr: 0.75,
        }),
        borderRadius: 1,
        height: '48px!important',
        minWidth: 0,
        display: 'flex',
        justifyContent: 'center',
        pt: 0,
        mr: 1,
        '&.Mui-selected': {
          bgcolor: APP_COLORS.light2,
          boxShadow: BOX_SHADOWS.elevation1
        }
      }}
    >
      <AppIcon iconName={iconName} color={selected ? 'primary.main' : 'text.secondary'} />
      {selected && (
        <React.Fragment>
          <Typography variant='body2' sx={{ ml: 1, mr: 1, color: 'text.primary' }}>{label}</Typography>
          <IconButton
            edge="end"
            size='small'
          >
            <AppIcon
              iconName='close'
              opticalSize={18}
            />
          </IconButton>
        </React.Fragment>
      )}
    </ListItemButton>
  );
}

const MapMenuList = () => {
  return (
    <List
      sx={{
        py: 1,
        pl: 1,
        minWidth: 0,
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      <MapMenuListItem
        selected
        iconName='domain'
        label='Base locations'
      />
      <MapMenuListItem
        iconName='pin_drop'
        label='Sites'
      />
      <MapMenuListItem
        iconName='clinical_notes'
        label='Injury reports'
      />
    </List>
  )
}

export const Statistics = () => {
  const dispatch = useAppDispatch();
  const currentSiteId = useAppSelector(selectCurrentSiteId);

  const sites = useAppSelector(selectSites);

  const handleDeselectSite = () => {
    dispatch(siteDeselected());
  };

  const allSites = useAppSelector(selectAllSites);

  return (
    <Box
      sx={{
        flexGrow: 1,
        overflowY: 'hidden',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          flex: 1,
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <AppMap />
      </Box>
      <MapLocationsControl />
      {/* <SiteDetails /> */}
    </Box>
  )
}