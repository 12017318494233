import * as yup from 'yup';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormDatePicker, FormSelect, FormSelectWithSearch, FormTextField } from '../../../../components/FormInputs';
import { WithFormMethods } from '../../../../components/Forms';
import { useAppSelector } from '../../../../store';
import { selectAllDepartments, selectDepartmentEntities } from '../../../departments/departmentsSlice';
import { selectAllInjuryUnits, selectInjuryUnitEntities } from '../../store/injuryReportsSlice';
import { TKB_INJURY } from '../../constants/common';
import { UnitPaper } from './UnitPaper';
import { GENERIC_VALIDATION_MSG } from '../../constants/mappedInputLabels';
import { getTranslationKeyForSectionFieldLabel, getTranslationKeyForSectionFieldPlaceholder } from '../../helpers/dataMapping';
import { EMPLOYEE_SECTION_NAMES } from '../../constants/employee';
import _, { filter } from 'lodash';
import { FormSingleAutocomplete } from '../../../../components/FormInputs/FormSingleAutocomplete';
import { selectAllEmployees, selectEmployeesFetched } from '../../../employees/employeesSlice';

export const baseMinorInjurySchema = yup.object().shape({
  title: yup.string().required(GENERIC_VALIDATION_MSG),
  date: yup.string().required(GENERIC_VALIDATION_MSG),
  nameOfInjuredParty: yup.string().required(GENERIC_VALIDATION_MSG),
  ssn: yup.string().required('minmax 11').min(11, 'min 11').max(11, 'max 11').matches(/^\d+$/),
  departmentId: yup.string().required(GENERIC_VALIDATION_MSG),
  departmentName: yup.string().optional(),
  // unitId: yup.string().required(GENERIC_VALIDATION_MSG),
  unitId: yup.string().optional(),
  description: yup.string().required(GENERIC_VALIDATION_MSG)
});

export type BaseMinorInjurySection = yup.InferType<typeof baseMinorInjurySchema>;

export const BaseMinorInjurySection = (props: WithFormMethods<BaseMinorInjurySection>) => {
  const { t } = useTranslation();
  const { methods } = props;
  const { control, watch, setValue } = methods;

  const departments = useAppSelector(selectAllDepartments);
  const departmentEntities = useAppSelector(selectDepartmentEntities);
  // const units = useAppSelector(selectAllInjuryUnits);
  // const unitEntities = useAppSelector(selectInjuryUnitEntities);
  const employees = useAppSelector(selectAllEmployees);
  const injuryEmployeesFetched: string = useAppSelector(selectEmployeesFetched);
  // const departmentId = watch('departmentId');

  // const filteredDepartments = React.useMemo(() => {
  //   let unitDepartmentIds: string[] = [];
  //   for (let unit of units) {
  //     if (!unitDepartmentIds.includes(unit.department_id)) {
  //       unitDepartmentIds.push(unit.department_id);
  //     }
  //   }
  //   return departments.filter((department) => unitDepartmentIds.indexOf(department.id) === -1);
  // }, [units]);

  // const departmentFilteredUnits = React.useMemo(() => {
  //   if (departmentId) {
  //     return units.filter((unit) => unit.department_id === departmentId || !unit.department_id);
  //   }
  //   return [];
  // }, [departmentId]);
  // const unitId = watch('unitId');

  // React.useEffect(() => {
  //   let validUnitIds = departmentFilteredUnits.map((unit) => unit.unit_id);
  //   if (!validUnitIds.includes(unitId)) {
  //     if (validUnitIds.length > 0) {
  //       setValue('unitId', validUnitIds[0]);
  //     } else {
  //       setValue('unitId', '');
  //     }
  //   }
  // }, [departmentFilteredUnits]);

  const initialTranslationPath = `${TKB_INJURY}.schoolPlaceTab`;
  let departmentNames: any = _.mapValues(departmentEntities, (dep) => dep.name);
  // let unitNames: any = _.mapValues(unitEntities, (unit) => unit.unit_name);

  return (
    <>
      <FormTextField
        control={control}
        name="title"
        label={t(getTranslationKeyForSectionFieldLabel(EMPLOYEE_SECTION_NAMES.MINOR_INJURY, 'title'))}
        placeholder={t(getTranslationKeyForSectionFieldPlaceholder(EMPLOYEE_SECTION_NAMES.MINOR_INJURY, 'title'))}
      />
      <FormDatePicker
        control={control}
        name="date"
        label={t(getTranslationKeyForSectionFieldLabel(EMPLOYEE_SECTION_NAMES.MINOR_INJURY, 'date'))}
      />
      <FormSelectWithSearch
        control={control}
        name="nameOfInjuredParty"
        label={t(getTranslationKeyForSectionFieldLabel(EMPLOYEE_SECTION_NAMES.MINOR_INJURY, 'nameOfInjuredParty'))}
        // placeholder={t(getTranslationKeyForSectionFieldPlaceholder(EMPLOYEE_SECTION_NAMES.MINOR_INJURY, 'nameOfInjuredParty'))}
        options={employees.map((employee) => ({
          value: employee.id,
          label: employee?.name
        }))}
        minorInjury={true}
        loading={injuryEmployeesFetched !== 'succeeded'}
        employees={employees}
      />
      <FormTextField
        control={control}
        name="ssn"
        label={t(getTranslationKeyForSectionFieldLabel(EMPLOYEE_SECTION_NAMES.MINOR_INJURY, 'ssn'))}
        placeholder={t(getTranslationKeyForSectionFieldPlaceholder(EMPLOYEE_SECTION_NAMES.MINOR_INJURY, 'ssn'))}
      />
      <FormSingleAutocomplete
        control={control}
        name="departmentId"
        label={t(getTranslationKeyForSectionFieldLabel(EMPLOYEE_SECTION_NAMES.EMPLOYER, 'department'))}
        options={departments.map((department, i) => department.id)}
        optionLabels={departmentNames}
      />
      {/* {departmentFilteredUnits.length > 0 && (
        <FormSingleAutocomplete
          control={control}
          name='unitId'
          label={t(getTranslationKeyForSectionFieldLabel(EMPLOYEE_SECTION_NAMES.EMPLOYER, 'unit'))}
          options={departmentFilteredUnits.map((unit, i) => unit.unit_id)}
          optionLabels={unitNames}
        />
      )}
      {unitId && <UnitPaper unitId={unitId} />}
      <FormTextField
        control={control}
        name='description'
        label={t(getTranslationKeyForSectionFieldLabel(EMPLOYEE_SECTION_NAMES.MINOR_INJURY, 'description'))}
        multiline
        rows={3}
      /> */}
    </>
  );
};
