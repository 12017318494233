import { TKB_INJURY } from '../common';
import * as EMPLOYEE_SECTION_NAMES from './sectionNames';
export * as EMPLOYEE_SECTION_NAMES from './sectionNames';

export const EmployeeSectionDetails = {
  [EMPLOYEE_SECTION_NAMES.EMPLOYEE]: {
    title: `${TKB_INJURY}.sectionNames.employee`,
    description: `${TKB_INJURY}.sectionDescriptions.employeeDescription`,
  },
  [EMPLOYEE_SECTION_NAMES.EMPLOYMENT]: {
    title: `${TKB_INJURY}.sectionNames.employment`,
    description: `${TKB_INJURY}.sectionDescriptions.employementDescription`,
  },
  [EMPLOYEE_SECTION_NAMES.EMPLOYER]: {
    title: `${TKB_INJURY}.sectionNames.employer`,
    description: `${TKB_INJURY}.sectionDescriptions.employerDescription`,
  },
  [EMPLOYEE_SECTION_NAMES.ACCIDENT]: {
    title: `${TKB_INJURY}.sectionNames.employeeAccident`,
    description: `${TKB_INJURY}.sectionDescriptions.employeeAccidentDescription`,
  },
  [EMPLOYEE_SECTION_NAMES.ILLNESS]: {
    title: `${TKB_INJURY}.sectionNames.employeeIllness`,
    description: `${TKB_INJURY}.sectionDescriptions.employeeIllnessDescription`,
  },
  [EMPLOYEE_SECTION_NAMES.MINOR_INJURY]: {
    title: `${TKB_INJURY}.sectionNames.employeeMinorInjury`,
    description: `${TKB_INJURY}.sectionDescriptions.employeeMinorInjuryDescription`,
  },
  [EMPLOYEE_SECTION_NAMES.FURTHER_INFORMATION]: {
    title: `${TKB_INJURY}.sectionNames.employeeFurtherInformation`,
    description: `${TKB_INJURY}.sectionDescriptions.employeeFurtherInfoDescription`,
  },
  [EMPLOYEE_SECTION_NAMES.EMPLOYEE_ACCIDENT_TYPES]: {
    title: `${TKB_INJURY}.sectionNames.employeeTypes`,
    description: `${TKB_INJURY}.sectionDescriptions.employeeTypesDescription`,
  },
};