export const asdf = 'asdf';
import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { APP_COLORS } from '../../config';
import { AppIcon } from '../Elements';
import { useTheme, alpha } from '@mui/material/styles';

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose?: () => void;
  bgcolor?: string;
}

export function AppDialogTitle(props: DialogTitleProps) {
  const { children, onClose, bgcolor = 'primary.main', ...other } = props;
  const theme = useTheme();
  return (
    <DialogTitle sx={{ m: 0, py: 2, px: 2.5, typography: 'h5', fontWeight: 500, bgcolor: `${bgcolor}`, color: (theme) => theme.palette.mode === 'light' ? APP_COLORS.darkTextPrimary : APP_COLORS.textPrimary }} {...other}>
      {children}
      {onClose ? (
        <IconButton 
          onClick={onClose}
          sx={{
            position: 'absolute', 
            right: 8, 
            top: 8, 
            color: APP_COLORS.darkTextPrimary,
          }}
        >
          <AppIcon iconName='close' color={theme.palette.mode === 'light' ? APP_COLORS.darkTextSecondary : APP_COLORS.textSecondary} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
