import React from 'react';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  const current = value === index;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{
        flex: 1,
        display: current ? 'flex' : 'none',
        flexDirection: 'column',
        /* overflow: 'hidden', */
        minHeight: 0,
        width: '100%',
        minWidth: 0
      }}
    >
      {current && children}
    </div>
  );
};

interface NamedTabPanelProps {
  children?: React.ReactNode;
  name: string;
  value: string;
}

export const NamedTabPanel = (props: NamedTabPanelProps) => {
  const { children, value, name, ...other } = props;

  const current = value === name;
  return (
    <div
      role="tabpanel"
      hidden={value !== name}
      id={`simple-tabpanel-${name}`}
      aria-labelledby={`simple-tab-${name}`}
      style={{
        flexGrow: 1,
        display: current ? 'flex' : 'none',
        flexDirection: 'column',
        /* overflow: 'hidden', */
        minHeight: 0,
        width: '100%',
        minWidth: 0
      }}
    >
      {current && children}
    </div>
  );
};
