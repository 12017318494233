import mapboxgl, { LngLat } from "mapbox-gl";
import { useRef } from 'react';
import MapGL, { Layer, Marker, Source } from 'react-map-gl';
import mapMarkerImg from '../../../../assets/mapmarker.svg'
import { useAppSelector } from "../../../../store";
import { NewCoordinates, NewSite, selectAllAreas, selectAllSites, selectInitialCoordinates, selectSiteFeatures, Site } from "../../../locations/locationsSlice";
import { MAPBOX_TOKEN, paletteMapStyle } from "../Map";
import React from 'react';
import { alpha } from '@mui/material/styles';
import centerOfMass from "@turf/center-of-mass";
import buffer from "@turf/buffer";
import bbox from "@turf/bbox";
import { point } from "@turf/helpers";
import '../map.css';
import { baseClusterCountLayer, baseClusterLayer, baseSiteLayer } from "../CreateAreaMap/siteLayers";
import { APP_COLORS } from "../../../../config";
import { Box } from "@mui/system";
import { Button, Paper } from "@mui/material";
import GeocoderControl from "./GeocoderControl";
import { useTheme } from '@mui/material';

const mapStyles: any = {
  width: '100%', 
  height: '100%', 
  position: 'relative',
  minHeight: '320px',
  minWidth: '480px',
  flex: 1,
}

interface CreateSiteMapProps {
  coordinates: NewCoordinates;
  handleChangeCoordinates: (newCoordinates: { lat: number, lng: number }) => void;
  handleChangeAddress: (newAddress: string) => void;
  onMarkerDrag: any;
}

export default function CreateSiteMap(props: CreateSiteMapProps) {
  const { coordinates, handleChangeCoordinates, handleChangeAddress, onMarkerDrag } = props;
  const theme = useTheme();

  const sites = useAppSelector(selectAllSites);
  const siteFeatures = useAppSelector(selectSiteFeatures);
  const initialCoordinates = useAppSelector(selectInitialCoordinates);

  const sitesFeatureCollection = React.useMemo(() => ({
    type: 'FeatureCollection', 
    features: [...siteFeatures]
  }) as GeoJSON.FeatureCollection<GeoJSON.Point>, [siteFeatures]);

  const onClick = (event: any) => {
    if (event.lngLat) {
      let newCoordinates = { lat: event.lngLat.lat, lng: event.lngLat.lng };
      handleChangeCoordinates(newCoordinates);
    }
  }

  const [cursor, setCursor] = React.useState('auto');

  React.useEffect(() => {
    if (coordinates) {
      setCursor('auto');
     /*  const inputElements: any = document.getElementsByClassName(
        'mapboxgl-ctrl-geocoder--input',
      );
      
      if (inputElements.length > 0) {
        inputElements[0].blur();
        inputElements[0].value = `${+coordinates.lat.toFixed(5)}, ${+coordinates.lng.toFixed(5)}`;
        inputElements[0].focus();
      } */
    } else {
      setCursor(`url(${mapMarkerImg}) 20 20, auto`);
    }
    
  }, [coordinates]);

  return (
    <Paper 
      variant='outlined'
      sx={{
        position: 'relative',
        flex: 1,
        overflow: 'hidden',
      }}
    >
      <MapGL
        initialViewState={{
          latitude: initialCoordinates.lat,
          longitude: initialCoordinates.lng,
          zoom: 17,
        }}
        style={mapStyles}
        cursor={cursor}
        maxPitch={85}
        onClick={onClick}
        mapStyle={paletteMapStyle[theme.palette.mode as any]}
        mapboxAccessToken={MAPBOX_TOKEN}
      >
      <Source
        id='main'
        type='geojson'
        data={sitesFeatureCollection}
        cluster={true}
      >
        <Layer 
          id='main-sites'
          type='symbol'
          layout={{
            'icon-size': 0.6,
            'icon-allow-overlap': true,
            'text-allow-overlap': true,
            'text-field': ['get', 'name'],  
            'text-font': ['Inter Tight Medium'],
            'text-offset': [0, 1.7],
            'text-max-width': 15,
            'text-size': 13,
            'icon-image': ['get', 'iconImage'], 
          }}
          paint={{
            'text-color': theme.palette.text.secondary, 
          }}
        />
        <Layer {...baseClusterLayer} />
        <Layer {...baseClusterCountLayer} />
      </Source>
      {coordinates && (
        <Marker
          longitude={coordinates.lng}
          latitude={coordinates.lat}
          draggable
          onDrag={onMarkerDrag}
        >
          <img src={mapMarkerImg} />
        </Marker>
      )}
      <GeocoderControl 
        mapboxAccessToken={MAPBOX_TOKEN} 
        /* query={coordinates ? `${+coordinates?.lat.toFixed(5)}, ${+coordinates?.lng.toFixed(5)}` : undefined} */
        position='top-left'
        /* coordinates={coordinates} */
        onResult={(result: any) => {
          handleChangeAddress(result.result.place_name);
          handleChangeCoordinates({ lng: result.result.center[0], lat: result.result.center[1] });
        }}
      />
      
    </MapGL>

    </Paper>
    
  )
}