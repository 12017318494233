import React from 'react';
import { Provider } from 'react-redux';
import { store } from './store';
import { AppProvider } from './provider/app';
import AppRouter from './routes/AppRouter';
import './App.css';
import './i18n';
import './assets/Inter-roman.var.woff2';

import { InjuryDocumentDialog } from './features/injuries/components/InjuryDocument/InjuryDocumentDialog';

function App() {
  React.useEffect(() => {
    const el = document.getElementById('launcher');
    if (el && el.style) el.style.display = 'none';
  }, []);

  return (
    <Provider store={store}>
      <AppProvider>
        <AppRouter />
      </AppProvider>
    </Provider>
  );
}

export default App;
