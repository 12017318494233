import { Box } from '@mui/material';
import { WithChildren } from '../../types';
import { useTheme, alpha } from '@mui/material/styles';
import { APP_COLORS, SIKRI_COLORS } from '../../config';

export function DataGridContainer({ children }: WithChildren<{}>) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        px: 3,
        py: 2.5,
        flexGrow: 1,
        width: '100%',
        minHeight: 0,
        minWidth: 0,
        overflow: 'visible', 
        '& .MuiDataGrid-cell:hover': {
          '& .visible-on-hover': {
            display: 'flex', 
            alignItems: 'center',
            justifyContent: 'center',
          }
        },
        "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
          outline: "none"
        }, 
        '& .MuiDataGrid-row .MuiDataGrid-cellCheckbox': {
          paddingLeft: '0px!important'
        },
        '& .MuiDataGrid-columnHeaderCheckbox': {
          paddingLeft: '0px!important'
        },
        '& .MuiDataGrid-root': {
          borderWidth: 0,
          /* overflow: 'visible',  */
          minWidth: '100%',
          width: 0,
          /* border: 'none',  */
          fontVariantNumeric: 'tabular-nums!important',
          borderRadius: '0px',
          /* borderRadius: '8px', */
          /* borderWidth: '2px', */
          overflow: 'hidden',
          /* backgroundColor: theme.palette.background.paper, */
        }, 
        '& .MuiDataGrid-columnHeaders': {
          backgroundColor: (theme) => theme.palette.mode === 'light' ? 'background.default' : 'backgroundVariant',
          /* border: 1,b
          borderColor: 'backgroundVariant', */
          borderRadius: 2,
          borderColor: 'backgroundVariant',
        },
        '& .MuiDataGrid-columnSeparator': {
          color: 'divider'
        },
        '& .MuiDataGrid-row': {
          '&:hover': {
            '& .visible-on-hover': {
              display: 'flex', 
              alignItems: 'center',
              justifyContent: 'center',
            }
          }
        },
        '& .MuiDataGrid-row.Mui-selected': {
          /* outline: 'solid #1b116e 1px',  */
          ...(theme.palette.mode === 'light' ? {
            bgcolor: `${SIKRI_COLORS.galaxyGreenSelected}!important`,
          } : {
            bgcolor: `${alpha(SIKRI_COLORS.galaxyGreen, 0.24)}!important`
          }),
          
          overflow: 'hidden', 
         /*  color: (theme) => theme.palette.primary.main, */
         '& .MuiAvatarGroup-avatar': {
            ...(theme.palette.mode === 'light' ? {
              borderColor: `${SIKRI_COLORS.galaxyGreenSelected}!important`,
            } : {
              borderColor: `${SIKRI_COLORS.galaxyGreenSelectedDark}!important`
            }),
            
          },
          '& .MuiBadge-root .MuiBox-root': {
            color: (theme) => theme.palette.primary.main,
          },
          '& .MuiBadge-dot': {
            boxShadow: `0 0 0 2px #d6faea!important`,
            '&:hover': {
              boxShadow: `0 0 0 2px #ebeaf0!important`,
            },
          },
          '&:hover .MuiBadge-dot': {
            boxShadow: `0 0 0 2px #ebeaf0!important`,
          },
          /* borderBottomColor: (theme) => alpha(theme.palette.divider, 0.5), */
        },
        
        '& .MuiDataGrid-row .MuiDataGrid-cell': {
          borderBottomColor: (theme) => theme.palette.mode === 'light' ? APP_COLORS.light4 : 'backgroundVariant',
          /* borderBottomColor: (theme) => alpha(theme.palette.divider, 0.5), */
        },
        '& .MuiDataGrid-footerContainer': {
          /* borderTop: 2, 
          borderColor: 'background.default', */
          pt: 2,
          pl: 2.5,
          borderColor: (theme) => `${theme.palette.divider}!important`,
        },
        '& .MuiDataGrid-columnHeaderTitle': {
          fontWeight: 500,
        },
        '& .MuiDataGrid-columnHeader:first-child': {
          paddingLeft: 2.5,
        },
        '& .MuiDataGrid-row .MuiDataGrid-cell:first-child': {
          paddingLeft: 2.5,
        },
        '& .MuiDataGrid-columnHeader:last-child': {
          paddingRight: 2.5,
        },
        '& .MuiDataGrid-row .MuiDataGrid-cell:last-child': {
          paddingRight: 2.5,
        },
      }}
    >
      {children}
    </Box>
  )
}