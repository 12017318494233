/* const getSiteStatus = ['get', 'reportsCountStatus', ['get', 'total']];
const getSiteCurrent = ['get', 'current']; */

import { LayerProps } from "react-map-gl";

export const baseSiteLayer: any = {
  layout: {
    'icon-size': [
      0.6
    ],
    'icon-allow-overlap': true,
    'text-allow-overlap': false,
    'text-field': ['get', 'name'],  
    'text-font': [
      'Inter Tight Medium',
    ],
    'text-max-width': 15,
    'text-size': 13,
  },
};

export const baseClusterLayer: LayerProps = {
  id: 'clusters',
  type: 'circle',
  source: 'main',
  filter: ['has', 'point_count'],
  paint: {
    'circle-color': ['step', ['get', 'point_count'], '#51bbd6', 5, '#f1f075', 10, '#f28cb1'],
    'circle-radius': ['step', ['get', 'point_count'], 20, 5, 30, 10, 40]
  }
};

export const baseClusterCountLayer: LayerProps = {
  id: 'cluster-count',
  type: 'symbol',
  source: 'main',
  filter: ['has', 'point_count'],
  layout: {
    'text-field': '{point_count_abbreviated}',
    'text-font': ['Inter Tight Medium'],
    'text-size': 12
  }
};
