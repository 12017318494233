import { createAsyncThunk, createSelector, createSlice, createEntityAdapter, EntityState } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { FetchedStatus } from '../../types';
import { httpGet, httpPost } from '../../utils/httpService';
import config from '../../config/config';

export type AppEmployee = {
  id: string;
  name: string;
  email: string;
  role: string;
  primary_locale?: string;
  phone?: string;
  mfa?: boolean;
  country_code?: string;
  suffix_phone?: string;
};

const employeesAdapter = createEntityAdapter<AppEmployee>({});

export interface EmployeesFetchedState {
  fetched: FetchedStatus;
  cursorHasNext: boolean;
  nextCursor?: string;
}

const initialState = employeesAdapter.getInitialState({
  fetched: 'idle',
  cursorHasNext: true
} as EmployeesFetchedState);

export const fetchUsersByIds = createAsyncThunk(
  'employees/fetchUsersByIds',
  async (user_ids: string[], { rejectWithValue }) => {
    try {
      const response = await httpPost(`${config.baseUrl}/util/usersById`, { user_ids: user_ids });
      let json = await response.data;
      if (response.status !== 200 && response.status !== 201) {
        return rejectWithValue(json);
      }
      return json;
    } catch (error) {
      console.log('error', error);
      console.log('data', error.response.data);
      console.log('message', error.response.data.message);
      return rejectWithValue(error.response.data.message);
    }
  }
)

export const fetchEmployeeUsers = createAsyncThunk<
  any,
  any,
  {
    state: {
      employees: { ids: any[]; entities: any; fetched: FetchedStatus; cursorHasNext: boolean; nextCursor?: string };
    };
  }
>('employees/fetchUsers', async (req: any, { getState }) => {
  let users: any = [];
  const { cursorHasNext, nextCursor } = getState().employees;

  let response: any;

  if (cursorHasNext && nextCursor) {
    response = await httpPost(`${config.baseUrl}/util/users`, { cursor: nextCursor });
  } else {
    response = await httpPost(`${config.baseUrl}/util/users`, { limit: 500 });
  }
  let json = await response.data;

  users = [...json.users];
  if (json.cursor.hasNext && json.cursor.next) {
    return {
      users: users,
      cursorHasNext: true,
      nextCursor: json.cursor.next
    };
  }
  return {
    users: users,
    cursorHasNext: false
  };
});

export const employeesSlice = createSlice({
  name: 'employees',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchEmployeeUsers.pending, (state, action) => {
      state.fetched = 'pending';
    });
    builder.addCase(fetchEmployeeUsers.fulfilled, (state, action) => {
      employeesAdapter.addMany(state, action.payload.users);
      if (action.payload.cursorHasNext) {
        state.nextCursor = action.payload.nextCursor;
      }
      state.cursorHasNext = action.payload.cursorHasNext;
      state.fetched = 'succeeded';
    });
    builder.addCase(fetchUsersByIds.fulfilled, (state, action) => {
      employeesAdapter.setMany(state, action.payload.users);
    });
  }
});

export const {
  selectIds: selectEmployeeIds,
  selectEntities: selectEmployees,
  selectAll: selectAllEmployees,
  selectTotal: selectTotalEmployees,
  selectById: selectEmployeeById
} = employeesAdapter.getSelectors<RootState>((state) => state.employees);

export const selectEmployeesFetched = (state: RootState) => state.employees.fetched;
export const selectEmployeesCursorHasNext = (state: RootState) => state.employees.cursorHasNext;
export const selectEmployeesNextCursor = (state: RootState) => state.employees.nextCursor;

const getId = (_: any, id: string) => id;

export const makeSelectEmployeeById = () => {
  return createSelector([selectEmployees, getId], (employees, id) => employees[id]);
};

export default employeesSlice.reducer;
