import {
  EntityState,
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
  createEntityAdapter
} from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { CASE_STATUS, LOG_TYPES, REPORT_CATEGORY, REPORT_TYPES } from '../constants';
import { EMPLOYEE_SECTION_NAMES } from '../constants/employee';
import { STUDENT_SECTION_NAMES } from '../constants/student';
import { InjuryReportCase, InjuryReportLog } from '../types';
import { FetchedStatus } from '../../../types';
import { formatDateFromTimestamp } from '../../../utils/format';
import _, { filter } from 'lodash';
import {
  getReverseMappedSectionFieldName,
  getReverseMappedSectionName,
  getReverseMappedSectionNameByCategory,
  getTableValidSection
} from '../helpers/dataMapping';
import dayjs from 'dayjs';
import { CreateInjuryActionPayload } from '../components/CreateInjuryActionDialog';
import { IActionCompletion } from '../../actions/components/CompleteActionDialog';
import { selectDepartmentEntities } from '../../departments/departmentsSlice';
import { IAction } from '../../actions/actionTypes';
import { CreateMessagePayload } from '../components/ReportDetails/CasePanels/Comments';
import { IActiveFilter, IFilterControl, IFilters } from '../../../components/DataGrid/types';
import { GridColumnVisibilityModel, GridSortModel } from '@mui/x-data-grid';
import * as INJURY_CONTROL from './controlsHelpers';
import {
  completeInjuryAction,
  createInjuryActionComment,
  fetchInjuryAttachments,
  fetchInjuryReportById,
  reopenInjuryAction,
  reopenInjuryReport
} from './injuryReportThunks';
import { INJURY_LOG_TYPES } from '../components/History/InjuryHistoryItem';
import { httpDelete, httpGet, httpPost, httpPut } from '../../../utils/httpService';
import config from '../../../config/config';
import { EmailMessagePayload } from '../../../components/Messages/EmailMessage';

const transformLogUpdate = (update: any) => {
  if (update.key === 'illness_information.durationImpact') {
    if (update.previousValue.length > 0) {
      if (update.previousValue[0]?.id) {
        update.previousValue = update.previousValue.map((o) => formatDateFromTimestamp(o.date, 'MM.YYYY'));
      }
    }
  }
  if (
    update.key === 'school_place_information.department' ||
    update.key === 'minor_injury_information.department' ||
    update.key === 'employer_information.department'
  ) {
    update.previousValue = update.previousValue?.id;
    update.new = update.new.id;
  }
  if (
    update.key === 'school_place_information.unit' ||
    // update.key === 'minor_injury_information.unit' ||
    update.key === 'employer_information.unit'
  ) {
    update.previousValue = update.previousValue?.unit_id;
    update.new = update.new?.unit_id;
  }
};

export type AppFilter = {
  field: any;
  value: any;
};

export interface InjuryUnit {
  unit_id: string;
  address: string;
  category: string;
  department_id?: string;
  insurance_company_name: string;
  insurance_url: string;
  municipality: string;
  organization_number?: string;
  phone?: string;
  unit_name?: string;
}

export type AppComment = {
  _id: string;
  report_id: string;
  account_id: string;
  message: string;
  timestamp: number;
};

const injuryUnitsAdapter = createEntityAdapter<InjuryUnit>({
  selectId: (unit) => unit.unit_id
});

export interface InjuryAttachment {
  _id?: string;
  account_id: string;
  key: string;
  file_name: string;
  file_size: string;
  file_type: string;
  updated_at: string;
  url: string;
}

const injuryAttachmentsAdapter = createEntityAdapter<InjuryAttachment>({
  selectId: (attachment) => attachment.key
});

export type AppTag = {
  id: string;
  name: string;
  edit?: boolean;
};

const injuryTagsAdapter = createEntityAdapter<AppTag>({});

export interface InjuryLog {
  _id: string;
  report_id: string;
  account_id: string;
  user_id: string;
  time: any;
  type: string;
  key?: string;
  previous?: any;
  previous_details?: any;
  new?: any;
  new_details?: any;
  action_id?: string;
  updates?: {
    key?: string;
    previous?: any;
    new?: any;
  }[];
}

const injuryLogsAdapter = createEntityAdapter<InjuryLog>({
  selectId: (log) => log._id
});

type InjuryReportDraft = {
  id: string;
  type: string;
  category: string;
  name?: string;
  sections: {
    [sectionName: string]: any;
  };
  status: string;
  created_at_timestamp?: number;
};

export type InjuryReportsState = {
  ids: string[];
  reports: {
    [id: string]: InjuryReportCase;
  };
  draftIds: string[];
  drafts: {
    [id: string]: InjuryReportDraft;
  };
  registrationReportIds: string[];
  handlerReportIds: string[];
  units: EntityState<InjuryUnit>;
  history: {
    [id: string]: InjuryReportHistoryItem[];
  };
  logs: EntityState<InjuryLog>;
  testDataInitialized: boolean;
  currentInjuryReport?: string;
  injuryReportsFetched: FetchedStatus;
  unitsFetched: FetchedStatus;
  loading: FetchedStatus;
  filters: AppFilter[];
  tags: {
    student: EntityState<AppTag>;
    employee: EntityState<AppTag>;
  };
  attachments: EntityState<InjuryAttachment>;
};

export type BaseMessage = {
  timestamp: number;
  sender: string;
  message: string;
  //
};

export type BaseComment = {
  timestamp: number;
  sender: string;
  message: string;
  //
};

const initialState: InjuryReportsState = {
  ids: [],
  reports: {},
  draftIds: [],
  drafts: {},
  registrationReportIds: [],
  handlerReportIds: [],
  units: injuryUnitsAdapter.getInitialState(),
  history: {},
  logs: injuryLogsAdapter.getInitialState(),
  testDataInitialized: false,
  // Fetch statuses
  injuryReportsFetched: 'idle',
  unitsFetched: 'idle',
  loading: 'idle',
  filters: [],
  attachments: injuryAttachmentsAdapter.getInitialState(),
  tags: {
    student: injuryTagsAdapter.getInitialState(),
    employee: injuryTagsAdapter.getInitialState()
  }
};

type UploadCoverPageRequest = {
  body: any;
};

/**
 * Request for upload the cover page and return signed url.
 */
export const uploadCoverPage = async (req: UploadCoverPageRequest) => {
  try {
    const response = await httpPost(`${config.baseUrl}/injury/uploadCoverPage`, req.body);
    return response.data;
  } catch (error) {
    console.log('error', error);
    console.log('data', error.response.data);
    console.log('message', error.response.data.message);
    return null;
  }
};

/**
 * Request for download Nav file and return signed url.
 */
export const downloadNAV = async (req: UploadCoverPageRequest) => {
  try {
    const response = await httpPost(`${config.baseUrl}/injury/generateNavPdf`, req.body);
    return response.data;
  } catch (error) {
    console.log('error', error);
    console.log('data', error.response.data);
    console.log('message', error.response.data.message);
    return null;
  }
};
/**
 * Request for generate the Labor inspection authority form return signed url.
 */
export const downloadLIA = async (req: UploadCoverPageRequest) => {
  try {
    const response = await httpPost(`${config.baseUrl}/injury/generateLIAPdf`, req.body);
    return response.data;
  } catch (error) {
    console.log('error', error);
    console.log('data', error.response.data);
    console.log('message', error.response.data.message);
    return null;
  }
};

export const downloadNAVFromKey = async (req: UploadCoverPageRequest) => {
  try {
    const response = await httpPost(`${config.baseUrl}/injury/downloadNAVFromKey`, req.body);
    return response.data;
  } catch (error) {
    console.log('error', error);
    console.log('data', error.response.data);
    console.log('message', error.response.data.message);
    return null;
  }
};

export const downloadInjuryReport = async (req: UploadCoverPageRequest) => {
  try {
    const response = await httpPost(`${config.baseUrl}/injury/generateInjuryReportPDF`, req.body);
    return response.data;
  } catch (error) {
    console.log('error', error);
    console.log('data', error.response.data);
    console.log('message', error.response.data.message);
    return null;
  }
};

/**
 * Request for retrieving all injury reports and populating store.
 */
export const fetchInjuryReports = createAsyncThunk(
  'injuryReports/fetchInjuryReports',
  async (_, { rejectWithValue }) => {
    try {
      const response = await httpGet(`${config.baseUrl}/injury/getAllInjuryReports`);
      const data = response.data;
      if (response.status !== 200 && response.status !== 201) {
        return rejectWithValue(data);
      }
      return data;
    } catch (error) {
      console.log('error', error);
      console.log('data', error.response.data);
      console.log('message', error.response.data.message);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const fetchInjuryUnits = createAsyncThunk('injuryReports/fetchInjuryUnits', async (thunkAPI) => {
  const response = await httpGet(`${config.baseUrl}/injury/getAllUnits`);
  const data = await response.data;
  return data;
});

/**
 * Request for retrieving all injury reports and populating store.
 */
export const fetchInjuryReportLogs = createAsyncThunk('injuryReports/fetchInjuryReportLogs', async (thunkAPI) => {
  const response = await httpGet(`${config.baseUrl}/injury/getAllInjuryReportLogs`);
  return response.data;
});

type GetInjuryTagsRequest = {
  category: string;
};

export const fetchInjuryTagsByCategory = createAsyncThunk(
  'injuryReports/fetchInjuryTagsByCategory',
  async (req: GetInjuryTagsRequest, thunkAPI) => {
    const response = await httpGet(`${config.baseUrl}/injury/getInjuryTags/${req.category}`);
    const data = await response.data;
    return {
      category: req.category,
      response: data
    };
  }
);

export const fetchAllInjuryComments = createAsyncThunk('injuryReports/fetchAllInjuryComments', async (thunkAPI) => {
  const response = await httpGet(`${config.baseUrl}/injury/getAllComments`);
  return response.data;
});

export interface UpdateInjuryCaseReq {
  reportId: string;
  body: {
    assignee_id?: string;
    due_date?: any;
    approved_sections?: string[];
    navForm?: {
      key: string;
      downloadedTimestamp: any;
    };
    laborForm?: {
      key: string;
      downloadedTimestamp: any;
    };
  };
}

export const updateInjuryCase = createAsyncThunk(
  'injuryReports/updateCase',
  async (req: UpdateInjuryCaseReq, { rejectWithValue }) => {
    try {
      const response = await httpPut(`${config.baseUrl}/injury/updateCase/${req.reportId}`, req.body);
      const data = response.data;
      if (response.status !== 200 && response.status !== 201) {
        return rejectWithValue(data);
      }
      return data;
    } catch (error) {
      console.log('error', error);
      console.log('data', error.response.data);
      console.log('message', error.response.data.message);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export type UpdateInjuryReportRequest = {
  reportId: string;
  body: any;
};

export const updateInjuryReport = createAsyncThunk(
  'injuryReports/updateInjuryReport',
  async (req: UpdateInjuryReportRequest, { rejectWithValue }) => {
    try {
      const response = await httpPut(`${config.baseUrl}/injury/updateInjuryReport/${req.reportId}`, req.body);
      const data = response.data;
      if (response.status !== 200 && response.status !== 201) {
        return rejectWithValue(data);
      }
      return data;
    } catch (error) {
      console.log('error', error);
      console.log('data', error.response.data);
      console.log('message', error.response.data.message);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const updateApprovedSections = createAsyncThunk(
  'injuryReports/updateApprovedSections',
  async (req: UpdateInjuryReportRequest, { rejectWithValue }) => {
    try {
      const response = await httpPut(`${config.baseUrl}/injury/updateApprovedSections/${req.reportId}`, req.body);
      const data = response.data;
      if (response.status !== 200 && response.status !== 201) {
        return rejectWithValue(data);
      }
      return data;
    } catch (error) {
      console.log('error', error);
      console.log('data', error.response.data);
      console.log('message', error.response.data.message);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export interface updateInjuryReportActionReq {
  payload: IAction;
  reportId: string;
  action_id: string;
}

export const updateInjuryReportAction = createAsyncThunk(
  'injuryReports/updateAction',
  async (req: updateInjuryReportActionReq, { rejectWithValue }) => {
    try {
      const response = await httpPut(
        `${config.baseUrl}/injury/reports/${req.reportId}/actions/${req.action_id}`,
        req.payload
      );
      const data = response.data;
      if (response.status !== 200 && response.status !== 201) {
        return rejectWithValue(data);
      }
      return data;
    } catch (error) {
      console.log('error', error);
      console.log('data', error.response.data);
      console.log('message', error.response.data.message);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const deleteInjuryReportAction = createAsyncThunk(
  'injuryReports/deleteAction',
  async (req: { reportId: string; action_id: string }, { rejectWithValue }) => {
    try {
      const response = await httpDelete(`${config.baseUrl}/injury/reports/${req.reportId}/actions/${req.action_id}`);
      const data = response.data;
      if (response.status !== 200 && response.status !== 201) {
        return rejectWithValue(data);
      }
      return data;
    } catch (error) {
      console.log('error', error);
      console.log('data', error.response.data);
      console.log('message', error.response.data.message);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export interface CreateInjuryActionReq {
  payload: CreateInjuryActionPayload;
  reportId: string;
}

export const createInjuryReportAction = createAsyncThunk(
  'injuryReports/createAction',
  async (req: CreateInjuryActionReq, { rejectWithValue }) => {
    try {
      const response = await httpPost(`${config.baseUrl}/injury/reports/${req.reportId}/actions`, req.payload);
      const data = response.data;
      if (response.status !== 200 && response.status !== 201) {
        return rejectWithValue(data);
      }
      return data;
    } catch (error) {
      console.log('error', error);
      console.log('data', error.response.data);
      console.log('message', error.response.data.message);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const createInjuryReportMessage = createAsyncThunk(
  'injuryReports/createMessage',
  async (req: { payload: CreateMessagePayload; reportId: string }, { rejectWithValue }) => {
    try {
      const response = await httpPost(`${config.baseUrl}/injury/reports/${req.reportId}/messages`, req.payload);
      const data = response.data;
      if (response.status !== 200 && response.status !== 201) {
        return rejectWithValue(data);
      }
      return data;
    } catch (error) {
      console.log('error', error);
      console.log('data', error.response.data);
      console.log('message', error.response.data.message);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const createInjuryReportComment = createAsyncThunk(
  'injuryReports/createComment',
  async (req: { payload: CreateMessagePayload; reportId: string }, { rejectWithValue }) => {
    try {
      const response = await httpPost(`${config.baseUrl}/injury/reports/${req.reportId}/comments`, req.payload);
      const data = response.data;
      if (response.status !== 200 && response.status !== 201) {
        return rejectWithValue(data);
      }
      return data;
    } catch (error) {
      console.log('error', error);
      console.log('data', error.response.data);
      console.log('message', error.response.data.message);
      return rejectWithValue(error.response.data.message);
    }
  }
);

// Actions
export type BaseAction = {
  status: string;
  assignee?: string;
  dueDate?: number;
  name: string;
  description?: string;
  attachments?: string[];
  type: string;
}; // Can have comments

export type ActionA = {
  type: 'A';
  issueToSolve: string;
  members: string[];
  comments: BaseComment[];
};

export type AppAction = BaseAction & ActionA;

// History log
export type BaseHistoryItem = {
  timestamp: number;
  description?: string;
  type: string;
};

type ValueUpdated = {
  field: string;
  previousValue: any;
  newValue: any;
};

const SECTION_VALUES_UPDATED = 'sectionValuesUpdated';
const APPROVED_SECTIONS_UPDATED = 'approvedSectionsUpdated';

export const CASE_HISTORY_TYPES = {
  SECTION_VALUES_UPDATED,
  APPROVED_SECTIONS_UPDATED
};

export type SectionValuesUpdated = {
  section: string;
  updates: {
    [field: string]: ValueUpdated;
  };
  type: typeof SECTION_VALUES_UPDATED;
};

export type ApprovedSectionsUpdated = {
  type: typeof APPROVED_SECTIONS_UPDATED;
  updates: {
    [section: string]: {
      previousValue: any;
      newValue: any;
    };
  };
};

type HistoryPayload = {
  id: string;
};

// Form submit handling

const getSectionFromKeyValueArr = (arr: { key: string; val?: any }[]) => {
  let section = {};
  for (let item of arr) {
    if (item?.val) {
      section[item.key] = item.val;
    }
  }
  return section;
};

export type InjuryReportHistoryItem = BaseHistoryItem & (SectionValuesUpdated | ApprovedSectionsUpdated);

export const injuryReportsSlice = createSlice({
  name: 'injuryReports',
  initialState,
  reducers: {
    initializeTestData: (state) => {
      /* const testData = generateInjuryReports(Math.floor(Math.random() * (300 - 100 + 1) + 100)); */
      /* const testData = generateInjuryReports(Math.floor(Math.random() * 75 + 25));
      state.ids = testData.ids;
      state.reports = testData.reports;
      state.testDataInitialized = true; */
    },

    injuryReportItemClicked: (state, action: PayloadAction<string>) => {
      if (action.payload !== undefined) {
        if (state.currentInjuryReport !== action.payload) {
          state.currentInjuryReport = action.payload;
        } else {
          state.currentInjuryReport = undefined;
        }
      }
    },

    registrationSubmitted: (state, action: PayloadAction<any>) => {
      let testCase = action.payload;
      const previousId = state.ids
        .slice()
        .filter((id) => id.startsWith('46t1s'))
        .map((id) => parseInt(id.slice(5)))
        .sort((a, b) => b - a)[0];
      let i = previousId + 1;
      let newId = '46t1s';
      if (i < 10) {
        newId += `00${i}`;
      } else if (i < 100) {
        newId += `0${i}`;
      } else {
        newId += `${i}`;
      }
      const status = CASE_STATUS.ONGOING;
      const handler = 'John Doe';
      let name = `${testCase.type}: `;
      if (action.payload.category === REPORT_CATEGORY.EMPLOYEE) {
        name += `${testCase.sections[EMPLOYEE_SECTION_NAMES.EMPLOYEE].name} ${testCase.sections[EMPLOYEE_SECTION_NAMES.EMPLOYEE].address
          }`;
      } else if (action.payload.category === REPORT_CATEGORY.STUDENT) {
        name += `${testCase.sections[STUDENT_SECTION_NAMES.STUDENT].name} ${testCase.sections[STUDENT_SECTION_NAMES.STUDENT].address
          }`;
      }
      const reportedTimestamp = Date.now();
      const newInjuryReport = {
        ...testCase,
        id: newId,
        status,
        handler,
        name,
        reportedTimestamp
      };
      state.ids.push(newId);
      state.reports[newId] = newInjuryReport;
    },
    injuryReportCleared: (state) => {
      state.currentInjuryReport = undefined;
    },
    sectionValuesUpdated: (
      state,
      action: PayloadAction<{ id: string; sectionName: string; updates: { [field: string]: any } }>
    ) => {
      const formattedUpdates = getTableValidSection(action.payload.sectionName, action.payload.updates);
      console.log(`formattedUpdates:\n${JSON.stringify(formattedUpdates)}`);
      const currentValues = state.reports[action.payload.id].sections[action.payload.sectionName];

      let historyUpdates: { [field: string]: ValueUpdated } = {};

      for (let field in action.payload.updates) {
        if (currentValues[field] !== action.payload.updates[field]) {
          historyUpdates[field] = {
            field,
            previousValue: currentValues[field],
            newValue: action.payload.updates[field]
          };
        }
      }

      if (state.history[action.payload.id] === undefined) {
        state.history[action.payload.id] = [
          {
            timestamp: Date.now(),
            section: action.payload.sectionName,
            updates: historyUpdates,
            type: SECTION_VALUES_UPDATED
          }
        ];
      } else {
        state.history[action.payload.id].push({
          timestamp: Date.now(),
          section: action.payload.sectionName,
          updates: historyUpdates,
          type: SECTION_VALUES_UPDATED
        });
      }

      state.reports[action.payload.id].sections[action.payload.sectionName] = action.payload.updates;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(createInjuryReportAction.fulfilled, (state, action) => {
      const injuryReportRes = action.payload?.injuryReportRes;
      if (injuryReportRes) {
        const report = state.reports[injuryReportRes.report_id];
        if (report) {
          report.actions = injuryReportRes.actions;
          report.updated_at = injuryReportRes.updated_at;
        }
      }
      const injuryLogRes = action.payload?.injuryLogRes;
      if (injuryLogRes) {
        injuryLogsAdapter.addOne(state.logs, injuryLogRes);
      }
    });
    builder.addCase(createInjuryActionComment.fulfilled, (state, action) => {
      const injuryReportRes = action.payload?.injuryReportRes;
      if (injuryReportRes) {
        const report = state.reports[injuryReportRes.report_id];
        if (report) {
          report.actions = injuryReportRes.actions;
          report.updated_at = injuryReportRes.updated_at;
        }
      }
      const injuryLogRes = action.payload?.injuryLogRes;
      if (injuryLogRes) {
        injuryLogsAdapter.addOne(state.logs, injuryLogRes);
      }
      const injuryAttachmentRes = action.payload?.injuryAttachmentRes;
      if (injuryAttachmentRes) {
        injuryAttachmentsAdapter.setMany(state.attachments, injuryAttachmentRes);
      }
    });
    builder.addCase(createInjuryReportComment.fulfilled, (state, action) => {
      const injuryReportRes = action.payload?.injuryReportRes;
      if (injuryReportRes) {
        const report = state.reports[injuryReportRes.report_id];
        if (report) {
          report.comments = injuryReportRes.comments;
          report.updated_at = injuryReportRes.updated_at;
        }
      }
      const injuryLogRes = action.payload?.injuryLogRes;
      if (injuryLogRes) {
        injuryLogsAdapter.addOne(state.logs, injuryLogRes);
      }
      const injuryAttachmentRes = action.payload?.injuryAttachmentRes;
      if (injuryAttachmentRes) {
        injuryAttachmentsAdapter.setMany(state.attachments, injuryAttachmentRes);
      }
    });
    builder.addCase(sendInjuryReportMessageEmails.fulfilled, (state, action) => {
      const injuryReportRes = action.payload?.injuryReportRes;
      if (injuryReportRes) {
        const report = state.reports[injuryReportRes.report_id];
        if (report) {
          report.emails = injuryReportRes.emails;
          report.updated_at = injuryReportRes.updated_at;
        }
      }
      const injuryLogRes = action.payload?.injuryLogRes;
      if (injuryLogRes) {
        injuryLogsAdapter.addOne(state.logs, injuryLogRes);
      }
    });
    
    builder.addCase(createInjuryReportMessage.fulfilled, (state, action) => {
      const injuryReportRes = action.payload?.injuryReportRes;
      if (injuryReportRes) {
        const report = state.reports[injuryReportRes.report_id];
        if (report) {
          report.messages = injuryReportRes.messages;
          report.updated_at = injuryReportRes.updated_at;
        }
      }
      const injuryLogRes = action.payload?.injuryLogRes;
      if (injuryLogRes) {
        injuryLogsAdapter.addOne(state.logs, injuryLogRes);
      }
      const injuryAttachmentRes = action.payload?.injuryAttachmentRes;
      if (injuryAttachmentRes) {
        injuryAttachmentsAdapter.setMany(state.attachments, injuryAttachmentRes);
      }
    });
    builder.addCase(completeInjuryAction.fulfilled, (state, action) => {
      const injuryReportRes = action.payload?.injuryReportRes;
      if (injuryReportRes) {
        const report = state.reports[injuryReportRes.report_id];
        if (report) {
          report.actions = injuryReportRes.actions;
          report.updated_at = injuryReportRes.updated_at;
        }
      }
      const injuryLogRes = action.payload?.injuryLogRes;
      if (injuryLogRes) {
        injuryLogsAdapter.addOne(state.logs, injuryLogRes);
      }
    });
    builder.addCase(reopenInjuryAction.fulfilled, (state, action) => {
      const injuryReportRes = action.payload?.injuryReportRes;
      if (injuryReportRes) {
        const report = state.reports[injuryReportRes.report_id];
        if (report) {
          report.actions = injuryReportRes.actions;
          report.updated_at = injuryReportRes.updated_at;
        }
      }
      const injuryLogRes = action.payload?.injuryLogRes;
      if (injuryLogRes) {
        injuryLogsAdapter.addOne(state.logs, injuryLogRes);
      }
    });
    builder.addCase(updateInjuryReportAction.fulfilled, (state, action) => {
      const injuryReportRes = action.payload.injuryReportRes;
      const actionRes = action.payload.actionRes;
      const historyRes = action.payload.historyRes;

      const report = state.reports[injuryReportRes.report_id];
      if (report) {
        const indexOfAction = report.actions.findIndex((a) => a.action_id === actionRes.action_id);
        if (indexOfAction !== -1) {
          report.actions[indexOfAction] = actionRes;
        }
      }
      if (historyRes) {
        injuryLogsAdapter.addOne(state.logs, historyRes);
      }
    });
    builder.addCase(deleteInjuryReportAction.fulfilled, (state, action) => {
      const injuryReportRes = action.payload?.injuryReportRes;
      if (injuryReportRes) {
        const report = state.reports[injuryReportRes.report_id];
        if (report) {
          report.actions = injuryReportRes.actions;
          report.updated_at = injuryReportRes.updated_at;
        }
      }
      const injuryLogRes = action.payload?.injuryLogRes;
      if (injuryLogRes) {
        injuryLogsAdapter.addOne(state.logs, injuryLogRes);
      }
    });
    builder.addCase(fetchInjuryReports.pending, (state, action) => {
      state.injuryReportsFetched = 'pending';
    });
    builder.addCase(fetchInjuryReports.rejected, (state, action) => {
      state.injuryReportsFetched = 'failed';
    });
    builder.addCase(fetchInjuryReportById.fulfilled, (state, action) => {
      const injuryReport = action.payload; 
      if (injuryReport) {
        state.reports[injuryReport._id] = injuryReport;
      }
    });
    builder.addCase(fetchInjuryReports.fulfilled, (state, action) => {
      for (let p of action.payload.injuryReports) {
        let reportIsValid = true;
        let title = 'PLACEHOLDER_NAME',
          category = p.category,
          type = p.report_type === 'minor injury' ? REPORT_TYPES.MINOR_INJURY : p.report_type,
          status = p.status;
        let name = '',
          nameOfUnit;

        if (p.status === 'draft') {
          name = `Draft (${type})`;
        }

        let assignee_id = 'None';
        let due_date = undefined;
        let sections: any = {};

        const pPerson = p.person_information;
        const pEmployer = p.employer_information;
        const pStudent = p.student_information;
        const pSchool = p.school_place_information;
        const pAccident = p.accident_information;
        const pIllness = p.illness_information;
        const pMinorInjury = p.minor_injury_information;
        const pInjuryTypes = p.type_information;
        const pFurther = p.further_information;

        let linked_nc_report_id = 'None';
        if (type === REPORT_TYPES.MINOR_INJURY)
          linked_nc_report_id = pMinorInjury?.nonconformityReport?.rep_id || pFurther?.nonconformityReport || 'None';
        else linked_nc_report_id = pFurther?.nonconformityReport?.rep_id || pFurther?.nonconformityReport || 'None';

        const bFurtherKeyVals = [
          { key: 'description', val: pFurther?.description },
          { key: 'ncReportId', val: pFurther?.nonconformityReport?.rep_id || pFurther?.nonconformityReport }
        ];

        const eEmployeeKeyVals = [
          { key: 'nationalIdentityNumber', val: pPerson?.ssn },
          { key: 'citizenship', val: pPerson?.citizenship },
          { key: 'address', val: pPerson?.residence },
          { key: 'municipality', val: pPerson?.municipality },
          { key: 'employeeId', val: pPerson?.registeredEmployees?.id },
          { key: 'phoneNumber', val: pPerson?.phone },
          { key: 'bankAccountNumber', val: pPerson?.bankAccount },
          { key: 'profession', val: pPerson?.position },
          { key: 'occupation', val: pPerson?.occupation },
          {
            key: 'employmentStartDate',
            val: pPerson?.employmentFrom && formatDateFromTimestamp(pPerson.employmentFrom, 'DD.MM.YYYY')
          },
          {
            key: 'employmentEndDate',
            val: pPerson?.employmentTo && formatDateFromTimestamp(pPerson.employmentTo, 'DD.MM.YYYY')
          },
          { key: 'assumedAnnualIncomeForClaimYear', val: pPerson?.annualIncome },
          {
            key: 'competenceLevel',
            val: pPerson?.competenceLevel?.id || pPerson?.competenceLevel
          },
          {
            key: 'natureOfEmployment',
            val: pPerson?.natureOfWorking?.id || pPerson?.natureOfWorking
          },
          {
            key: 'voluntaryOccupationalInjuryInsurance',
            val: pPerson?.voluntaryInsurance?.id || pPerson?.voluntaryInsurance
          }
        ];

        const eEmployerKeyVals = [
          { key: 'name', val: pEmployer?.employer },
          { key: 'departmentId', val: pEmployer?.department?.id },
          { key: 'departmentName', val: pEmployer?.department?.name },
          { key: 'unitId', val: pEmployer?.unit?.unit_id },
          {
            key: 'accidentHappenedAtMainAddress',
            val: pEmployer?.isAccLocationCorrect?.id || pEmployer?.isAccLocationCorrect
          },
          { key: 'addressOfAccident', val: pEmployer?.AccLocation },
          { key: 'insuranceCompanyName', val: pEmployer?.insuranceName },
          { key: 'insuranceCompanyAddress', val: pEmployer?.insuranceAddress }
        ];

        const sStudentKeyVals = [
          { key: 'name', val: pStudent?.fullName },
          { key: 'nationalIdentityNumber', val: pStudent?.ssn },
          { key: 'citizenship', val: pStudent?.citizenship },
          { key: 'address', val: pStudent?.residence },
          { key: 'municipality', val: pStudent?.municipality }
        ];

        const sSchoolKeyVals = [
          // { key: 'siteOfDamage', val: pSchool?.siteOfDamage },
          { key: 'linkToInsurance', val: pSchool?.linkToInsurance },
          { key: 'departmentId', val: pSchool?.department?.id },
          { key: 'departmentName', val: pSchool?.department?.name },
          { key: 'unitId', val: pSchool?.unit?.unit_id }
        ];

        const sAccidentKeyVals = [
          { key: 'date', val: pAccident?.date && formatDateFromTimestamp(pAccident.date, 'DD.MM.YYYY') },
          { key: 'time', val: pAccident?.time && formatDateFromTimestamp(pAccident.time, 'HH:mm') },
          { key: 'accidentType', val: pAccident?.typeOfAccident && pAccident.typeOfAccident.map((o) => o.id) },
          { key: 'damageNature', val: pAccident?.damageNature && pAccident.damageNature.map((o) => o.id) },
          { key: 'damagedBodyPart', val: pAccident?.damagedBodyPart && pAccident.damagedBodyPart.map((o) => o.id) },
          { key: 'accidentOccured', val: pAccident?.accidentOccured?.id || pAccident?.accidentOccured }
        ];

        const eAccidentKeyVals = [
          { key: 'date', val: pAccident?.date && formatDateFromTimestamp(pAccident.date, 'DD.MM.YYYY') },
          { key: 'time', val: pAccident?.time && formatDateFromTimestamp(pAccident.time, 'HH:mm') },
          { key: 'workTimeArrangements', val: pAccident?.arrangements?.id || pAccident?.arrangements },
          { key: 'accidentOccured', val: pAccident?.accidentOccured?.id || pAccident?.accidentOccured },
          { key: 'formOfSalary', val: pAccident?.formOfSalary?.id || pAccident?.formOfSalary },
          { key: 'atNormalWorkplace', val: pAccident?.workplace?.id || pAccident?.workplace },
          { key: 'insideOutside', val: pAccident?.whereAccidentOccurred?.id || pAccident?.whereAccidentOccurred },
          { key: 'onTheWayToWork', val: pAccident?.onTheWay?.id || pAccident?.onTheWay },
          { key: 'onTheWayBetweenWork', val: pAccident?.betweenWorkplaces?.id || pAccident?.betweenWorkplaces },
          { key: 'deathFromAccident', val: pAccident?.deathFromInjury?.id || pAccident?.deathFromInjury },
          {
            key: 'necessaryTraining',
            val: pAccident?.necessaryTraining?.id || pAccident?.necessaryTraining
          },
          {
            key: 'reportedToLIA',
            val: pAccident?.reportedToLIA?.id || pAccident?.reportedToLIA
          }
        ];

        let durationOfImpact = [];
        if (pIllness) {
          if (pIllness?.durationImpact) {
            if (pIllness?.durationImpact.length > 0) {
              if (pIllness.durationImpact[0].id) {
                durationOfImpact = pIllness.durationImpact.map((element, index) => {
                  return element.date;
                });
              } else {
                durationOfImpact = pIllness.durationImpact;
              }
            }
          }
        }

        const bIllnessKeyVals = [
          { key: 'diseaseName', val: pIllness?.diseaseNature },
          {
            key: 'detectionDate',
            val: pIllness?.detectionDate && formatDateFromTimestamp(pIllness.detectionDate, 'DD.MM.YYYY')
          },
          { key: 'deathFromDisease', val: pIllness?.deathFromDisease },
          { key: 'provokingInfluence', val: pIllness?.influence },
          { key: 'durationOfImpact', val: durationOfImpact.length > 0 && durationOfImpact },
          { key: 'workAroundImpact', val: pIllness?.workAroundImpact }
        ];

        const eInjuryTypeKeyVals = [
          { key: 'accidentType', val: pInjuryTypes?.typeOfAccident && pInjuryTypes.typeOfAccident.map((o) => o.id) },
          { key: 'background', val: pInjuryTypes?.background && pInjuryTypes.background.map((o) => o.id) },
          { key: 'damageNature', val: pInjuryTypes?.natureOfDamage && pInjuryTypes.natureOfDamage.map((o) => o.id) },
          {
            key: 'damagedBodyPart',
            val: pInjuryTypes?.damagedBodyPart && pInjuryTypes.damagedBodyPart.map((o) => o.id)
          },
          { key: 'damageMode', val: pInjuryTypes?.damageMode && pInjuryTypes.damageMode.map((o) => o.id) },
          {
            key: 'typeOfWorkplace',
            val: pInjuryTypes?.typeOfWorkplace && pInjuryTypes.typeOfWorkplace.map((o) => o.id)
          },
          { key: 'deviation', val: pInjuryTypes?.deviation && pInjuryTypes.deviation.map((o) => o.id) },
          { key: 'assumedAbsence', val: pInjuryTypes?.assumedAbsence && pInjuryTypes.assumedAbsence.map((o) => o.id) }
        ];

        let typesOfInjury: string[] = [];
        if (pMinorInjury) {
          if (pMinorInjury?.typesOfInjury) {
            typesOfInjury = pMinorInjury.typesOfInjury.map((toi: any) => toi.id);
          }
        }

        const bMinorInjuryKeyVals = [
          { key: 'title', val: pMinorInjury?.title },
          { key: 'date', val: pMinorInjury?.date && formatDateFromTimestamp(pMinorInjury?.date, 'DD.MM.YYYY') },
          { key: 'nameOfInjuredParty', val: pMinorInjury?.nameOfInjurtParty },
          { key: 'ssn', val: pMinorInjury?.ssn },
          { key: 'departmentId', val: pMinorInjury?.department?.id },
          { key: 'departmentName', val: pMinorInjury?.department?.name },
          // { key: 'unitId', val: pMinorInjury?.unit?.unit_id },
          { key: 'description', val: pMinorInjury?.description },
          { key: 'description', val: pMinorInjury?.description },
          { key: 'ncReportId', val: pMinorInjury?.nonconformityReport?.rep_id || pMinorInjury?.nonconformityReport },
          { key: 'typesOfInjury', val: typesOfInjury?.length > 0 && typesOfInjury }
        ];

        if (p.category === 'employee') {
          if (p.report_type === 'minor injury') {
            name = pMinorInjury?.nameOfInjurtParty;
            nameOfUnit = pMinorInjury?.unit?.unit_name;
            sections[EMPLOYEE_SECTION_NAMES.MINOR_INJURY] = getSectionFromKeyValueArr(bMinorInjuryKeyVals);
          } else {
            name = pPerson?.registeredEmployees?.name;
            nameOfUnit = pEmployer?.unit?.unit_name;
            if (pPerson) {
              sections[EMPLOYEE_SECTION_NAMES.EMPLOYEE] = getSectionFromKeyValueArr(eEmployeeKeyVals);
            }
            if (pEmployer) {
              sections[EMPLOYEE_SECTION_NAMES.EMPLOYER] = getSectionFromKeyValueArr(eEmployerKeyVals);
            }
            if (pFurther) {
              sections[EMPLOYEE_SECTION_NAMES.FURTHER_INFORMATION] = getSectionFromKeyValueArr(bFurtherKeyVals);
            }
            if (p.report_type === 'illness' && pIllness) {
              sections[EMPLOYEE_SECTION_NAMES.ILLNESS] = getSectionFromKeyValueArr(bIllnessKeyVals);
            } else if (p.report_type === 'accident') {
              if (pAccident) {
                sections[EMPLOYEE_SECTION_NAMES.ACCIDENT] = getSectionFromKeyValueArr(eAccidentKeyVals);
              }
              if (pInjuryTypes) {
                sections[EMPLOYEE_SECTION_NAMES.EMPLOYEE_ACCIDENT_TYPES] =
                  getSectionFromKeyValueArr(eInjuryTypeKeyVals);
              }
            }
          }
        } else if (p.category === 'student') {
          if (p.report_type === 'minor injury') {
            name = pMinorInjury?.nameOfInjurtParty;
            nameOfUnit = pMinorInjury?.unit?.unit_name;
            sections[STUDENT_SECTION_NAMES.MINOR_INJURY] = getSectionFromKeyValueArr(bMinorInjuryKeyVals);
          } else {
            name = pStudent?.fullName;
            nameOfUnit = pSchool?.unit?.unit_name;
            if (pStudent) {
              sections[STUDENT_SECTION_NAMES.STUDENT] = getSectionFromKeyValueArr(sStudentKeyVals);
            }
            if (pSchool) {
              sections[STUDENT_SECTION_NAMES.SCHOOL] = getSectionFromKeyValueArr(sSchoolKeyVals);
            }
            if (pFurther) {
              sections[STUDENT_SECTION_NAMES.FURTHER_INFORMATION] = getSectionFromKeyValueArr(bFurtherKeyVals);
            }
            if (p.report_type === 'illness' && pIllness) {
              sections[STUDENT_SECTION_NAMES.ILLNESS] = getSectionFromKeyValueArr(bIllnessKeyVals);
            } else if (p.report_type === 'accident' && pAccident) {
              sections[STUDENT_SECTION_NAMES.ACCIDENT] = getSectionFromKeyValueArr(sAccidentKeyVals);
            }
          }
        }

        title = name && nameOfUnit ? `${name} (${nameOfUnit})` : name;

        let navForm = p?.navForm;

        if (!navForm) {
          if (p?.nav_form_url) {
            navForm = {
              key: p?.nav_form_url,
              downloadedTimestamp: p?.nav_form_download_timestamp
            };
          }
        }

        if (p.status === 'draft') {
          let created_at_timestamp;

          if (p.created_at) {
            created_at_timestamp = new Date(p.created_at).getTime();
          }

          state.drafts[p.report_id] = {
            id: p.report_id,
            category,
            type,
            name: title,
            status,
            sections,
            created_at_timestamp
          };
          state.draftIds.push(p.report_id);
        } else if (p.report_type === 'accident' || p.report_type === 'minor injury' || p.report_type === 'illness') {
          let injuryReport: any = {
            id: p.report_id,
            category: category,
            type,
            status,
            handler: 'Dev',
            reporter_id: p.created_by.id,
            due_date: p.due_date,
            assignee_id: p.assignee_id,
            assignee_details: p.assignee_details,
            reportedTimestamp: new Date(p.created_at).getTime(),
            reported: p.created_at,
            name: title,
            sections,
            closing_details: p?.closing_details,
            approved_sections: p?.approved_sections || [],
            linked_nc_report_id: linked_nc_report_id,
            comments: p?.comments || [],
            messages: p?.messages || [],
            actions: p?.actions || [],
            labor_form_download_timestamp: p?.labor_form_download_timestamp,
            labor_form_url: p?.labor_form_url,
            updated_at: p?.updated_at,
            form_sections_updated_at: p?.form_sections_updated_at,
            navForm: navForm,
            laborForm: p?.laborForm,
            archive: p?.archive
          };
          state.reports[p.report_id] = injuryReport;
          state.ids.push(injuryReport.id);
        }
      }
      state.registrationReportIds = action.payload.registration_report_ids;
      state.handlerReportIds = action.payload.handler_report_ids;
      state.injuryReportsFetched = 'succeeded';
    });
    builder.addCase(fetchInjuryReportLogs.fulfilled, (state, action) => {
      const logs = action.payload;
      injuryLogsAdapter.addMany(state.logs, logs);
      /* let toPush: any = [];
      for (let p of action.payload) {
        if (p.type !== LOG_TYPES.UPDATE_STATUS) {
          let log = p;
          for (let update of p.updates) {
            transformLogUpdate(update);
          }
          for (let i = 0; i < p.updates; i++) {
            let update = p.updates[i];
            if (typeof update.previousValue === 'object' || typeof update.new === 'object') {
              p.updates.splice(i, 1);
            }
          }
          state.logs[p._id] = p;
          state.logIds.push(p._id);
        }
        
      } */
    });
    /* builder.addCase(fetchAllInjuryComments.fulfilled, (state, action) => {
      for (let comment of action.payload) {
        injuryCommentsAdapter.addOne(state.comments, comment);
      }
    }); */
    builder.addCase(fetchInjuryTagsByCategory.fulfilled, (state, action) => {
      const tags = action.payload.response.injury_tags;
      if (action.payload.category === REPORT_CATEGORY.EMPLOYEE || action.payload.category === REPORT_CATEGORY.STUDENT) {
        injuryTagsAdapter.setAll(state.tags[action.payload.category], tags);
      }
    });
    builder.addCase(reopenInjuryReport.fulfilled, (state, action) => {
      const logItem = action.payload.injuryLogItemRes;
      if (logItem) {
        injuryLogsAdapter.addOne(state.logs, logItem);

        const report = state.reports[action.payload.injuryReportRes.report_id];
        if (report) {
          report.status = action.payload.injuryReportRes.status;
          report.updated_at = action.payload.injuryReportRes.updated_at;
          report.updated_by = action.payload.injuryReportRes.updated_by;
        }
      }
      

    });
    builder.addCase(fetchInjuryUnits.fulfilled, (state, action) => {
      injuryUnitsAdapter.addMany(state.units, action.payload.units);
      state.unitsFetched = 'succeeded';
    });
    builder.addCase(updateInjuryReport.pending, (state, action) => {
      state.loading = 'pending';
    });
    builder.addCase(updateInjuryCase.fulfilled, (state, action) => {
      const log = action.payload;
      const report_id = action.payload.report_id;
      const report = state.reports[report_id];
      if (report) {
        const key = action.payload.key;
        report[key] = action.payload.new;
      }
      injuryLogsAdapter.addOne(state.logs, log);
    });
    builder.addCase(updateInjuryReport.fulfilled, (state, action) => {
      const logItem = action.payload?.injuryLogRes;
      const injuryAttachmentRes = action.payload?.injuryAttachmentRes;
      if (injuryAttachmentRes) {
        injuryAttachmentsAdapter.setMany(state.attachments, injuryAttachmentRes);
      }
      for (let update of logItem.updates) {
        transformLogUpdate(update);
      }

      const report = state.reports[logItem.report_id];
      if (logItem.type === INJURY_LOG_TYPES.UPDATE_SECTION_DETAILS) {
        report.form_sections_updated_at = logItem.time;
      }
      for (let update of logItem.updates) {
        if (update.key.split('.').length > 1) {
          const originalSectionName = update.key.split('.')[0];
          const sectionName = getReverseMappedSectionNameByCategory(originalSectionName, report?.category);
          const originalFieldName = update.key.split('.')[1];
          const fieldName = getReverseMappedSectionFieldName(sectionName, originalFieldName);
          const newValue = update.new;
          report.sections[sectionName][fieldName] = newValue;
          if (
            [
              'workTimeArrangements',
              'accidentOccured',
              'formOfSalary',
              'atNormalWorkplace',
              'insideOutside',
              'onTheWayToWork',
              'onTheWayBetweenWork',
              'deathFromAccident',
              'competenceLevel',
              'natureOfEmployment',
              'voluntaryOccupationalInjuryInsurance',
              'necessaryTraining',
              'reportedToLIA'
            ].includes(fieldName)
          ) {
            if (newValue && newValue.id) {
              report.sections[sectionName][fieldName] = newValue.id;
            }
          }

          if (
            [
              'accidentType',
              'damageNature',
              'damagedBodyPart',
              'background',
              'damageMode',
              'typeOfWorkplace',
              'deviation',
              'assumedAbsence'
            ].includes(fieldName)
          ) {
            if (newValue && newValue.length) {
              let updatedValues = [];
              for (let index = 0; index < newValue.length; index++) {
                const val = newValue[index];
                if (val.id) updatedValues = [...updatedValues, val.id];
              }
              if (updatedValues.length) report.sections[sectionName][fieldName] = updatedValues;
            }
          }

          if (fieldName === 'typesOfInjury') {
            if (newValue && newValue.length) {
              let injuryTags = newValue.map((value) => {
                return value.id;
              });
              report.sections[sectionName][fieldName] = injuryTags;
            }
          }

          if (['durationOfImpact', 'durationImpact'].includes(fieldName)) {
            if (newValue && newValue.length) {
              let durations = newValue.map((value) => {
                return value.date;
              });
              report.sections[sectionName][fieldName] = durations;
            }
          }

          if (['date', 'detectionDate', 'employmentStartDate', 'employmentEndDate'].includes(fieldName)) {
            report.sections[sectionName][fieldName] = dayjs(newValue).format('DD.MM.YYYY');
          }
          if (fieldName === 'time') {
            report.sections[sectionName][fieldName] = dayjs(newValue).format('HH:mm');
          }
          // Update the linked ID as well so that the general section is also updated
          if (fieldName === 'ncReportId') {
            if (newValue.rep_id) {
              report.sections[sectionName][fieldName] = newValue.rep_id;
              report['linked_nc_report_id'] = newValue.rep_id;
            }
          }
        } else {
          report[update.key] = update.new;
        }
        if (update.key === 'status') {
          report.status = update.new;
        }
      }
      
      injuryLogsAdapter.addOne(state.logs, logItem);
      state.loading = 'succeeded';
    });
    builder.addCase(updateInjuryReport.rejected, (state, action) => {
      state.loading = 'failed';
    });
    builder.addCase(updateApprovedSections.fulfilled, (state, action) => {
      /* const logItem = action.payload[0];
      state.logIds.unshift(logItem._id);
      state.logs[logItem._id] = logItem;
      const report = state.reports[logItem.report_id];

      state.reports[logItem.report_id].approved_sections = logItem.updates[0].newValue; */
    });
    builder.addCase(fetchInjuryAttachments.fulfilled, (state, action) => {
      injuryAttachmentsAdapter.setAll(state.attachments, action.payload);
    });
  }
});

export const {
  initializeTestData,
  injuryReportItemClicked,
  injuryReportCleared,
  registrationSubmitted,
  sectionValuesUpdated
} = injuryReportsSlice.actions;

export default injuryReportsSlice.reducer;

export const {
  selectIds: selectInjuryUnitIds,
  selectEntities: selectInjuryUnitEntities,
  selectAll: selectAllInjuryUnits,
  selectTotal: selectTotalInjuryUnits,
  selectById: selectInjuryUnitById
} = injuryUnitsAdapter.getSelectors((state: RootState) => state.injuryReports.units);

export const { selectEntities: selectInjuryAttachments, selectById: selectInjuryAttachmentById } =
  injuryAttachmentsAdapter.getSelectors((state: RootState) => state.injuryReports.attachments);

export const selectInjuryReportLoading = (state: RootState) => state.injuryReports.loading;
/* export const {
  selectIds: selectInjuryCommentIds,
  selectEntities: selectInjuryCommentEntities,
  selectAll: selectAllInjuryComments,
  selectTotal: selectTotalInjuryComments,
  selectById: selectInjuryCommentById
} = injuryCommentsAdapter.getSelectors((state: RootState) => state.injuryReports.comments); */

export const {
  selectIds: selectStudentInjuryTagIds,
  selectEntities: selectStudentInjuryTagEntities,
  selectAll: selectAllStudentInjuryTags,
  selectTotal: selectTotalStudentInjuryTags,
  selectById: selectStudentInjuryTagById
} = injuryTagsAdapter.getSelectors((state: RootState) => state.injuryReports.tags.student);

export const {
  selectIds: selectEmployeeInjuryTagIds,
  selectEntities: selectEmployeeInjuryTagEntities,
  selectAll: selectAllEmployeeInjuryTags,
  selectTotal: selectTotalEmployeeInjuryTags,
  selectById: selectEmployeeInjuryTagById
} = injuryTagsAdapter.getSelectors((state: RootState) => state.injuryReports.tags.employee);

export const { selectIds: selectInjuryReportLogIds, selectEntities: selectInjuryReportLogs } =
  injuryLogsAdapter.getSelectors((state: RootState) => state.injuryReports.logs);

export const selectInjuryReportIds = (state: RootState) => state.injuryReports.ids;
export const selectInjuryReports = (state: RootState) => state.injuryReports.reports;
export const selectInjuryReportsTestDataInitialized = (state: RootState) => state.injuryReports.testDataInitialized;
export const selectCurrentInjuryReportId = (state: RootState) => state.injuryReports.currentInjuryReport;
export const selectInjuryReportsFetched = (state: RootState) => state.injuryReports.injuryReportsFetched;
export const selectInjuryReportDraftIds = (state: RootState) => state.injuryReports.draftIds;
export const selectInjuryReportDrafts = (state: RootState) => state.injuryReports.drafts;
export const selectInjuryRegistrationReportIds = (state: RootState) => state.injuryReports.registrationReportIds;
export const selectInjuryHandlerReportIds = (state: RootState) => state.injuryReports.handlerReportIds;

const getId = (_: any, id: string) => id;
const getStrKey = (_: any, key: string) => key;

export const makeSelectInjuryAttachmentByKey = () => {
  return createSelector([selectInjuryAttachments, getStrKey], (attachments, key) => attachments[key]);
};

export const selectAllInjuryReportLogs = createSelector(
  [selectInjuryReportLogIds, selectInjuryReportLogs],
  (ids, logs) => ids.map((id) => logs[id])
);

export const makeSelectLogsByInjuryReportId = () => {
  return createSelector([selectAllInjuryReportLogs, getId], (logs, reportId) =>
    logs.filter((log) => log.report_id === reportId)
  );
};

export const makeSelectInjuryUnitsByDepartmentId = () => {
  return createSelector([selectAllInjuryUnits, getStrKey], (units, departmentId) =>
    units.filter((unit) => unit.department_id === departmentId || !unit.department_id)
  );
};

export const makeSelectInjuryReportById = () => {
  return createSelector([selectInjuryReports, getId], (reports, id) => {
    /* httpPost(`${config.baseUrl}/util/log`, {
      path: window.location.hash,
      method: 'GET',
      resourceType: 'injury',
      resourceId: id
    }); */
    return reports[id];
  });
};

export const makeSelectInjuryLogsByActionId = () => {
  return createSelector([selectAllInjuryReportLogs, getStrKey], (logs, actionId) => {
    let filtered = logs.filter(
      (log) => log?.type === INJURY_LOG_TYPES.CREATE_ACTION || log?.type === INJURY_LOG_TYPES.UPDATE_ACTION
    );
    return filtered.filter((log) => log?.key === actionId);
  });
};

export const selectAllInjuryReports = createSelector([selectInjuryReportIds, selectInjuryReports], (ids, reports) =>
  ids.map((id) => reports[id])
);

export const makeSelectTotalInjuryMessagesByModule = () => {
  return createSelector([selectAllInjuryReports, getStrKey], (reports, module) => {
    let total = 0;
    let filtered = reports.slice();
    if (module === 'student' || module === 'employee') {
      filtered = filtered.filter((injuryReport) => injuryReport.category === module);
    }
    for (let report of filtered) {
      if (report?.messages && report?.messages?.length > 0) {
        total += report.messages.length;
      }
    }
    return total;
  });
};

export const makeSelectTotalInjuryMessagesByView = () => {
  return createSelector(
    [selectAllInjuryReports, selectInjuryRegistrationReportIds, selectInjuryHandlerReportIds, getStrKey],
    (reports, reporterIds, handlerIds, view) => {
      let total = 0;
      let filtered = reports.slice();
      if (view === 'reporter') {
        filtered = filtered.filter((injuryReport) => reporterIds.includes(injuryReport.id));
      } else {
        filtered = filtered.filter((injuryReport) => handlerIds.includes(injuryReport.id));
      }
      for (let report of filtered) {
        if (report?.messages && report?.messages?.length > 0) {
          total += report.messages.length;
        }
      }
      return total;
    }
  );
};

export const makeSelectTotalInjuryActionsByModule = () => {
  return createSelector([selectAllInjuryReports, getStrKey], (reports, module) => {
    let total = 0;
    let filtered = reports.slice();
    if (module === 'student' || module === 'employee') {
      filtered = filtered.filter((injuryReport) => injuryReport.category === module);
    }
    for (let report of filtered) {
      if (report?.actions && report?.actions?.length > 0) {
        total += report.actions.length;
      }
    }
    return total;
  });
};

export const selectAllInjuryReportDrafts = createSelector(
  [selectInjuryReportDraftIds, selectInjuryReportDrafts],
  (ids, drafts) => ids.map((id) => drafts[id])
);

export const selectAllInjuryReportRegistrations = createSelector(
  [selectInjuryRegistrationReportIds, selectInjuryReports],
  (ids, reports) => ids.map((id) => reports[id])
);

export const selectAllInjuryReportHandlers = createSelector(
  [selectInjuryHandlerReportIds, selectInjuryReports],
  (ids, reports) => ids.map((id) => reports[id])
);

export const selectDepartmentCountStatistics = createSelector(
  [selectAllInjuryReports, selectDepartmentEntities],
  (reports, departments) => {
    let count: any = {};
    let registered: string[] = [];
    for (let report of reports) {
      if (report.type === REPORT_TYPES.ACCIDENT || report.type === REPORT_TYPES.ILLNESS) {
        if (report.category === REPORT_CATEGORY.EMPLOYEE) {
          const departmentId = report.sections[EMPLOYEE_SECTION_NAMES.EMPLOYER]?.departmentId;
          if (departmentId) {
            if (!registered.includes(departmentId)) {
              registered.push(departmentId);
              count[departmentId] = 1;
            } else {
              count[departmentId]++;
            }
          }
        }
        if (report.category === REPORT_CATEGORY.STUDENT) {
          const departmentId = report.sections[STUDENT_SECTION_NAMES.SCHOOL]?.departmentId;
          if (departmentId) {
            if (!registered.includes(departmentId)) {
              registered.push(departmentId);
              count[departmentId] = 1;
            } else {
              count[departmentId]++;
            }
          }
        }
      }
    }

    return registered.map((registeredVal) => ({
      value: departments[registeredVal]?.name || registeredVal,
      count: count[registeredVal]
    }));
  }
);

const selectTypeCountByModule = (injuryReports: any, module: string, keyOfType: string) => {
  let reports = [...injuryReports.slice()];
  if (module === 'student' || module === 'employee') {
    reports = reports.filter((injuryReport) => injuryReport.category === module);
  }
  let count: any = {};
  let registered: string[] = [];
  for (let report of reports) {
    if (report.type === REPORT_TYPES.ACCIDENT) {
      if (report.category === REPORT_CATEGORY.EMPLOYEE) {
        const typeInfo = _.get(report, `sections.employeeTypes.${keyOfType}`, null);
        if (typeInfo) {
          for (let value of typeInfo) {
            if (!registered.includes(value)) {
              registered.push(value);
              count[value] = 1;
            } else {
              count[value]++;
            }
          }
        }
      } else if (report.category === REPORT_CATEGORY.STUDENT) {
        const typeInfo = _.get(report, `sections.studentAccident.${keyOfType}`, null);
        if (typeInfo) {
          for (let value of typeInfo) {
            if (!registered.includes(value)) {
              registered.push(value);
              count[value] = 1;
            } else {
              count[value]++;
            }
          }
        }
      }
    }
  }

  return registered.map((registeredVal) => ({ value: registeredVal, count: count[registeredVal] }));
};

export const makeSelectAccidentTypeCountByModule = () => {
  return createSelector([selectAllInjuryReports, getStrKey], (injuryReports, module) => {
    return selectTypeCountByModule(injuryReports, module, 'accidentType');
  });
};

export const selectAccidentTypeCount = createSelector([selectAllInjuryReports], (reports) => {
  let count: any = {};
  let registered: string[] = [];
  for (let report of reports) {
    if (report.type === REPORT_TYPES.ACCIDENT) {
      if (report.category === REPORT_CATEGORY.EMPLOYEE) {
        /* if (!report?.sections?.employeeAccident?.accidentType) continue;
          for (let value of report.sections.employeeAccident.accidentType) {
            if (!registered.includes(value)) {
              registered.push(value);
              count[value] = 1;
            } else {
              count[value]++;
            }
          } */
      } else if (report.category === REPORT_CATEGORY.STUDENT) {
        for (let accidentType of report.sections.studentAccident.accidentType) {
          if (!registered.includes(accidentType)) {
            registered.push(accidentType);
            count[accidentType] = 1;
          } else {
            count[accidentType]++;
          }
        }
      }
    }
  }

  return registered.map((registeredVal) => ({ value: registeredVal, count: count[registeredVal] }));
});

export const makeSelectUnitCountStatisticsByModule = () => {
  return createSelector(
    [selectAllInjuryReports, selectInjuryUnitEntities, getStrKey],
    (injuryReports, units, module) => {
      let reports = [...injuryReports.slice()];
      if (module === 'student' || module === 'employee') {
        reports = reports.filter((injuryReport) => injuryReport.category === module);
      }
      let count: any = {};
      let registered: string[] = [];
      for (let report of reports) {
        if (report.type === REPORT_TYPES.ACCIDENT || report.type === REPORT_TYPES.ILLNESS) {
          if (report.category === REPORT_CATEGORY.EMPLOYEE) {
            /* const unitId = report.sections[EMPLOYEE_SECTION_NAMES.EMPLOYER]; */
          }
          if (report.category === REPORT_CATEGORY.STUDENT) {
            const unitId = report.sections[STUDENT_SECTION_NAMES.SCHOOL]?.unitId;
            if (unitId) {
              if (!registered.includes(unitId)) {
                registered.push(unitId);
                count[unitId] = 1;
              } else {
                count[unitId]++;
              }
            }
          }
        }
      }

      return registered.map((registeredVal) => ({
        value: units[registeredVal].unit_name,
        count: count[registeredVal]
      }));
    }
  );
};

export const selectUnitCountStatistics = createSelector(
  [selectAllInjuryReports, selectInjuryUnitEntities],
  (reports, units) => {
    let count: any = {};
    let registered: string[] = [];
    for (let report of reports) {
      if (report.type === REPORT_TYPES.ACCIDENT || report.type === REPORT_TYPES.ILLNESS) {
        if (report.category === REPORT_CATEGORY.EMPLOYEE) {
          /* const unitId = report.sections[EMPLOYEE_SECTION_NAMES.EMPLOYER]; */
        }
        if (report.category === REPORT_CATEGORY.STUDENT) {
          const unitId = report.sections[STUDENT_SECTION_NAMES.SCHOOL]?.unitId;
          if (unitId) {
            if (!registered.includes(unitId)) {
              registered.push(unitId);
              count[unitId] = 1;
            } else {
              count[unitId]++;
            }
          }
        }
      }
    }

    return registered.map((registeredVal) => ({ value: units[registeredVal].unit_name, count: count[registeredVal] }));
  }
);

export const makeSelectDamageNatureCountByModule = () => {
  return createSelector([selectAllInjuryReports, getStrKey], (injuryReports, module) => {
    return selectTypeCountByModule(injuryReports, module, 'damageNature');
  });
};

export const selectDamageNatureCount = createSelector([selectAllInjuryReports], (reports) => {
  let count: any = {};
  let registered: string[] = [];
  for (let report of reports) {
    if (report.type === REPORT_TYPES.ACCIDENT) {
      if (report.category === REPORT_CATEGORY.EMPLOYEE) {
        /* if (!report?.sections?.employeeAccident?.damageNature) continue;
          for (let value of report.sections.employeeAccident.damageNature) {
            if (!registered.includes(value)) {
              registered.push(value);
              count[value] = 1;
            } else {
              count[value]++;
            }
          } */
      } else if (report.category === REPORT_CATEGORY.STUDENT) {
        for (let value of report.sections.studentAccident.damageNature) {
          if (!registered.includes(value)) {
            registered.push(value);
            count[value] = 1;
          } else {
            count[value]++;
          }
        }
      }
    }
  }
});

export const makeSelectDamagedBodyPartCountByModule = () => {
  return createSelector([selectAllInjuryReports, getStrKey], (injuryReports, module) => {
    return selectTypeCountByModule(injuryReports, module, 'damagedBodyPart');
  });
};

export const makeSelectBackgroundCountByModule = () => {
  return createSelector([selectAllInjuryReports, getStrKey], (injuryReports, module) => {
    return selectTypeCountByModule(injuryReports, module, 'background');
  });
};

export const makeSelectDamageModeCountByModule = () => {
  return createSelector([selectAllInjuryReports, getStrKey], (injuryReports, module) => {
    return selectTypeCountByModule(injuryReports, module, 'damageMode');
  });
};

export const makeSelectTypeOfWorkplaceCountByModule = () => {
  return createSelector([selectAllInjuryReports, getStrKey], (injuryReports, module) => {
    return selectTypeCountByModule(injuryReports, module, 'typeOfWorkplace');
  });
};

export const makeSelectDeviationCountByModule = () => {
  return createSelector([selectAllInjuryReports, getStrKey], (injuryReports, module) => {
    return selectTypeCountByModule(injuryReports, module, 'deviation');
  });
};

export const makeSelectAssumedAbsenceCountByModule = () => {
  return createSelector([selectAllInjuryReports, getStrKey], (injuryReports, module) => {
    return selectTypeCountByModule(injuryReports, module, 'assumedAbsence');
  });
};

export const selectDamagedBodyPartCount = createSelector([selectAllInjuryReports], (reports) => {
  let count: any = {};
  let registered: string[] = [];
  for (let report of reports) {
    if (report.type === REPORT_TYPES.ACCIDENT) {
      if (report.category === REPORT_CATEGORY.EMPLOYEE) {
        /* if (!report?.sections?.employeeAccident?.damagedBodyPart) continue;
          for (let value of report.sections.employeeAccident.damagedBodyPart) {
            if (!registered.includes(value)) {
              registered.push(value);
              count[value] = 1;
            } else {
              count[value]++;
            }
          } */
      } else if (report.category === REPORT_CATEGORY.STUDENT) {
        for (let value of report.sections.studentAccident.damagedBodyPart) {
          if (!registered.includes(value)) {
            registered.push(value);
            count[value] = 1;
          } else {
            count[value]++;
          }
        }
      }
    }
  }

  return registered.map((registeredVal) => ({ value: registeredVal, count: count[registeredVal] }));
});

export const makeSelectInjuryReportByCaseStatus = () => {
  return createSelector([selectAllInjuryReports, getStrKey], (reports, status) =>
    reports.filter((report: InjuryReportCase) => report.status === status)
  );
};

export const selectInjuryPropertiesForSiteFeature = createSelector([selectAllInjuryReports], (reports) => {
  /* let siteProperties: any = {
      reportsCount: {
        'total': 0,
        [REPORT_TYPES.ACCIDENT]: 0,
        [REPORT_TYPES.ILLNESS]: 0,
        [REPORT_TYPES.MINOR_INJURY]: 0,
      },
      reportsCountStatus: {
        'total': 0,
        [REPORT_TYPES.ACCIDENT]: 0,
        [REPORT_TYPES.ILLNESS]: 0,
        [REPORT_TYPES.MINOR_INJURY]: 0,
      },
    }; */
  let siteProperties: any = {};
  let typeCount: any = {
    [REPORT_TYPES.ACCIDENT]: 0,
    [REPORT_TYPES.ILLNESS]: 0,
    [REPORT_TYPES.MINOR_INJURY]: 0
  };
  for (let report of reports) {
    if (!Object.keys(siteProperties).includes(report.sitePath)) {
      siteProperties[report.sitePath] = {
        reportsCount: {
          total: 0,
          [REPORT_TYPES.ACCIDENT]: 0,
          [REPORT_TYPES.ILLNESS]: 0,
          [REPORT_TYPES.MINOR_INJURY]: 0
        },
        reportsCountStatus: {
          total: 0,
          [REPORT_TYPES.ACCIDENT]: 0,
          [REPORT_TYPES.ILLNESS]: 0,
          [REPORT_TYPES.MINOR_INJURY]: 0
        }
      };
    }
    siteProperties[report.sitePath].reportsCount['total'] += 1;
    siteProperties[report.sitePath].reportsCount[report.type] += 1;
    typeCount[report.type] += 1;
  }
  const sitePaths = Object.keys(siteProperties);
  const averageTotalCount = reports.length / sitePaths.length;
  const averageAccidentCount = typeCount[REPORT_TYPES.ACCIDENT] / sitePaths.length;
  const averageIllnessCount = typeCount[REPORT_TYPES.ILLNESS] / sitePaths.length;
  const averageMinorInjuryCount = typeCount[REPORT_TYPES.MINOR_INJURY] / sitePaths.length;
  for (let site in siteProperties) {
    const curSiteTotalCount = siteProperties[site].reportsCount['total'];
    const curSiteAccidentCount = siteProperties[site].reportsCount[REPORT_TYPES.ACCIDENT];
    const curSiteIllnessCount = siteProperties[site].reportsCount[REPORT_TYPES.ILLNESS];
    const curSiteMinorInjuryCount = siteProperties[site].reportsCount[REPORT_TYPES.MINOR_INJURY];
    siteProperties[site].reportsCountStatus['total'] =
      curSiteTotalCount > averageTotalCount * 1.5
        ? 3
        : curSiteTotalCount > averageTotalCount
          ? 2
          : curSiteTotalCount > 0
            ? 1
            : 0;
    siteProperties[site].reportsCountStatus[REPORT_TYPES.ACCIDENT] =
      curSiteAccidentCount > averageAccidentCount * 1.5
        ? 3
        : curSiteAccidentCount > averageAccidentCount
          ? 2
          : curSiteAccidentCount > 0
            ? 1
            : 0;
    siteProperties[site].reportsCountStatus[REPORT_TYPES.ILLNESS] =
      curSiteIllnessCount > averageIllnessCount * 1.5
        ? 3
        : curSiteIllnessCount > averageIllnessCount
          ? 2
          : curSiteIllnessCount > 0
            ? 1
            : 0;
    siteProperties[site].reportsCountStatus[REPORT_TYPES.MINOR_INJURY] =
      curSiteMinorInjuryCount > averageMinorInjuryCount * 1.5
        ? 3
        : curSiteMinorInjuryCount > averageMinorInjuryCount
          ? 2
          : curSiteMinorInjuryCount > 0
            ? 1
            : 0;
  }
  return siteProperties;
});

export const makeSelectInjuryReportsBySitePath = () => {
  return createSelector([selectAllInjuryReports, getStrKey], (reports, siteName) =>
    reports.filter((report: InjuryReportCase) => report.sitePath === siteName)
  );
};

export const makeSelectInjuryReportsByAreaId = () => {
  return createSelector([selectAllInjuryReports, getStrKey], (reports, areaId) =>
    reports.filter((report: InjuryReportCase) => report.areaId === areaId)
  );
};

export const makeSelectInjuryReportsByModule = () => {
  return createSelector([selectAllInjuryReports, getStrKey], (injuryReports, module) => {
    let reports = [...injuryReports.slice()];
    if (module === 'student' || module === 'employee') {
      reports = reports.filter((injuryReport) => injuryReport.category === module);
    }
    return reports;
  });
};

// FOR TESTING RESTRICTED USER
export const selectSimpleUserInjuryReports = createSelector([selectAllInjuryReports], (reports) => {
  let restrictedReports: any = [];
  for (let report of reports) {
    if (report.category === REPORT_CATEGORY.STUDENT) {
      if (report.sitePath.split('/')[0] === 'Marikollen') {
        restrictedReports.push(report);
      }
    }
  }
});

export const selectAllInjuryActions = createSelector([selectAllInjuryReports], (reports) => {
  let actions: any = [];
  for (let report of reports) {
    if (report.actions) {
      for (let action of report.actions) {
        if (action?.report_id) {
          actions.push(action);
        }
      }
    }
  }
  let sorted = [...actions.filter((action) => action?.deadline && action?.status !== 'completed')];
  let remaining = [...actions.filter((action) => !action?.deadline && action?.status !== 'completed')];
  let completed = [...actions.filter((action) => action?.status === 'completed')];
  sorted.sort((a, b) => new Date(a.deadline).getTime() - new Date(b.deadline).getTime());
  remaining.sort((a, b) => a.created_at - b.created_at);
  completed.sort((a, b) => a.updated_at - b.updated_at);
  return [...sorted, ...remaining, ...completed];
});

export const sendInjuryReportMessageEmails = createAsyncThunk(
  'injuryReports/injuryMessageEmail',
  async (req: { payload: EmailMessagePayload; reportId: string }, { rejectWithValue }) => {
    try {
      const response = await httpPost(`${config.baseUrl}/injury/reports/${req.reportId}/messageEmails`, req.payload);
      const data = response.data;
      if (response.status !== 200 && response.status !== 201) {
        return rejectWithValue(data);
      }
      return data;
    } catch (error) {
      console.log('error', error);
      console.log('data', error.response.data);
      console.log('message', error.response.data.message);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const uploadGeneralReport = async (req: UploadCoverPageRequest) => {
    try {
        const response = await httpPost(`${config.baseUrl}/injury/uploadGeneralReport`, req.body);
        return response.data;
    } catch (error) {
        console.log('error', error);
        console.log('data', error.response.data);
        console.log('message', error.response.data.message);
        return null;
    }
};
