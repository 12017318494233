import { InjuredStudentSection } from '../../components/FormSections/student/InjuredStudentSection';
import { TKB_INJURY } from '../common';
import * as STUDENT_SECTION_NAMES from './sectionNames';
export * as STUDENT_SECTION_NAMES from './sectionNames';

export type StudentAccidentSections = {
  [STUDENT_SECTION_NAMES.STUDENT]: InjuredStudentSection;
}

export const StudentSectionDetails = {
  [STUDENT_SECTION_NAMES.STUDENT]: {
    title: `${TKB_INJURY}.sectionNames.pupilStudent`,
    description: `${TKB_INJURY}.sectionDescriptions.pupilStudentDescription`,
  },
  [STUDENT_SECTION_NAMES.SCHOOL]: {
    title: `${TKB_INJURY}.sectionNames.schoolPlace`,
    description: `${TKB_INJURY}.sectionDescriptions.schoolPlaceDescription`,
  },
  [STUDENT_SECTION_NAMES.ACCIDENT]: {
    title: `${TKB_INJURY}.sectionNames.studentAccident`,
    description: `${TKB_INJURY}.sectionDescriptions.studentAccidentDescription`,
  },
  [STUDENT_SECTION_NAMES.ILLNESS]: {
    title: `${TKB_INJURY}.sectionNames.studentIllness`,
    description: `${TKB_INJURY}.sectionDescriptions.studentIllnessDescription`,
  },
  [STUDENT_SECTION_NAMES.MINOR_INJURY]: {
    title: `${TKB_INJURY}.sectionNames.studentMinorInjury`,
    description: `${TKB_INJURY}.sectionDescriptions.studentMinorInjuryDescription`,
  },
  [STUDENT_SECTION_NAMES.FURTHER_INFORMATION]: {
    title: `${TKB_INJURY}.sectionNames.studentFurtherInformation`,
    description: `${TKB_INJURY}.sectionDescriptions.studentFurtherInfoDescription`,
  },
};