import React from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Box } from '@mui/system';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface FullScreenDialogProps {
  open?: boolean;
  handleClose: () => void;
  title?: string;
  children?: React.ReactNode;
}

export default function FullScreenDialog(props: FullScreenDialogProps) {
  const { open = false, handleClose, title = 'Report', children } = props;

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      PaperProps={{
        sx: {
          bgcolor: {
            xs: 'background.paper',
            xl: 'background.default'
          }
        }
      }}
      sx={{
        '*': {
          '::-webkit-scrollbar': {
            width: '14px'
          },
          '::-webkit-scrollbar-track': {
            borderRadius: 100,
            background: (theme) => (theme.palette.mode === 'light' ? `#dfdee6!important` : '#323144'),
            borderColor: (theme) => theme.palette.background.default,
            borderWidth: `3px`,
            borderStyle: 'solid'
          },
          '::-webkit-scrollbar-thumb': {
            borderRadius: 100,
            backgroundColor: (theme) => (theme.palette.mode === 'light' ? '#a7a5b4' : '#323144'),
            border: '3px solid transparent',
            backgroundClip: 'content-box'
          },
          scrollbarColor: `#d1d5db #f5f5f5`
        }
      }}
    >
      <AppBar sx={{ position: 'relative', borderRadius: 0 }}>
        <Toolbar sx={{}}>
          <Typography sx={{ flex: 1 }} variant="h5" component="div">
            {title}
          </Typography>
          <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          overflowY: 'auto',
          bgcolor: 'background.paper',
          flex: 1
        }}
      >
        {children}
      </Box>
    </Dialog>
  );
}
