import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PinCoordinateMap from '../CreateAreaDialog/CreateAreaMap';
import { useAppDispatch, useAppSelector } from '../../../../store';
import {
  NewCoordinates,
  selectSiteIds,
  selectSites,
  areaCreated,
  siteCreated
} from '../../../locations/locationsSlice';
import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  StepButton,
  Typography
} from '@mui/material';
import Box from '@mui/system/Box';
import { Stack } from '@mui/system';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import type { MarkerDragEvent, LngLat } from 'react-map-gl';
import CreateSiteMap from './CreateSiteMap';
import { snackAlertOpened } from '../../../global/controlsSlice';
import { useTranslation } from 'react-i18next';

interface CreateSiteDialogProps {
  open?: boolean;
  handleClose: () => void;
}

export default function CreateSiteDialog(props: CreateSiteDialogProps) {
  const { open = true, handleClose } = props;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  
  const [name, setName] = React.useState('');
  const [type, setType] = React.useState<undefined | string>('general');
  const [coordinates, setCoordinates] = React.useState<NewCoordinates | undefined>(undefined);
  const [address, setAddress] = React.useState('');

  const handleChangeCoordinates = (newCoordinates: NewCoordinates) => {
    setCoordinates({
      lat: +newCoordinates.lat.toFixed(5),
      lng: +newCoordinates.lng.toFixed(5)
    });
  };

  const handleMarkerDrag = React.useCallback((e: MarkerDragEvent) => {
    setCoordinates({
      lat: +e.lngLat.lat.toFixed(5),
      lng: +e.lngLat.lng.toFixed(5)
    });
  }, []);

  const handleChangeType = (event: SelectChangeEvent) => {
    setType(event.target.value as string);
  };

  const handleSubmit = () => {
    const site = {
      name,
      type,
      coordinates,
      address
    };
    dispatch(siteCreated(site));
    dispatch(snackAlertOpened({ message: `Site "${site.name}" created!`, severity: 'success' }));
    handleClose();
  };
  return (
    <Dialog fullScreen open={open} onClose={handleClose} maxWidth="lg">
      <DialogTitle>Create site</DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2.5,
          px: {
            xs: 0,
            xl: 24
          }
        }}
      >
        <TextField
          sx={{ mt: 2.5 }}
          fullWidth
          label="Name of site"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <FormControl fullWidth>
          <InputLabel id="site-type-label">Type of site</InputLabel>
          <Select
            labelId="site-type-label"
            id="site-type-select"
            value={type}
            label="Type of site "
            onChange={handleChangeType}
          >
            <MenuItem value={'general'}>General</MenuItem>
            <MenuItem value={'school'}>School</MenuItem>
            <MenuItem value={'work'}>Workplace</MenuItem>
          </Select>
        </FormControl>
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            flex: 1
          }}
        >
          <CreateSiteMap
            coordinates={coordinates}
            handleChangeCoordinates={handleChangeCoordinates}
            handleChangeAddress={(newAddress) => setAddress(newAddress)}
            onMarkerDrag={handleMarkerDrag}
          />
        </Box>
        {coordinates && (
          <TextField fullWidth label="Address" value={address} onChange={(e) => setAddress(e.target.value)} />
        )}
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: 'start'
        }}
      >
        <Button color="plain" variant="contained" onClick={handleClose}>
        {t('wif.injury.cancel')}
        </Button>
        <Button color="secondary" onClick={handleSubmit} disabled={!coordinates || name.length < 1}>
          Create site
        </Button>
      </DialogActions>
    </Dialog>
  );
}
