import { Box, Button, Chip, Divider, Drawer, Paper, Stack, Step, StepLabel, Stepper, Toolbar, Typography } from '@mui/material';
import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import InputLabel from '@mui/material/InputLabel';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import FormControl from '@mui/material/FormControl';
import { InjuryReportsDataGrid } from '../../components/InjuryReportsDataGrid/InjuryReportsDataGrid';
import { ContentHeader, NewContentLayout, ContentLayout } from '../../../../components/Layout';
import { NamedTabPanel, TabPanel } from '../../../../components/Elements/TabPanel';
import { Statistics } from '../../components/Statistics/Statistics';
import AppMap from '../../../sites/components/Map';
import { RootState, useAppDispatch, useAppSelector } from '../../../../store';
import {
  fetchInjuryReportLogs,
  fetchInjuryReports,
  fetchInjuryUnits,
  initializeTestData,
  injuryReportCleared,
  makeSelectInjuryReportsByModule,
  makeSelectTotalInjuryActionsByModule,
  makeSelectTotalInjuryMessagesByModule,
  selectCurrentInjuryReportId,
  selectInjuryRegistrationReportIds,
  selectInjuryReportsFetched,
  selectInjuryReportsTestDataInitialized
} from '../../store/injuryReportsSlice';
import { AppIcon } from '../../../../components/Elements';
import { Insights } from '../../components/Statistics/Insights';
import FullScreenDialog from '../../../../components/Elements/FullScreenDialog';
import CreateSiteDialog from '../../../sites/components/CreateSiteDialog/CreateSiteDialog';
import { InjuryFormWrapper } from '../../components/InjuryForm/InjuryFormWrapper';
import { useParams, useNavigate } from 'react-router-dom';
import { ContentGrid } from '../../../../components/Layout/ContentGrid';
import Grid from '@mui/material/Unstable_Grid2';
import { ContentPanel } from '../../../../components/Layout/ContentPanel';
import { InjuryReportDetails } from '../InjuryReportDetails';
import { PageHeader } from '../../../../components/Layout/PageHeader';
import { PageLayout } from '../../../../components/Layout/PageLayout';
import { Header } from '../../../../components/Layout/Header';
import { TabsContainer } from '../../../../components/Layout/AppTabs';
import { APP_COLORS } from '../../../../config';
import { selectSplitViewEnabled } from '../../../global/controlsSlice';
import ContentWrapper from '../../../../components/Layout/ContentWrapper';
import { RightContainer } from '../../../../components/Layout/RightContainer';
import { useTheme, alpha, styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { REPORT_CATEGORY, REPORT_TYPES, TKB_INJURY, TKB_INJURY_REPORT } from '../../constants';
import CircularLoader from '../../../../components/Loader/circularLoader';
import { fetchEmployeeUsers } from '../../../employees/employeesSlice';
import { fetchAllDepartments } from '../../../departments/departmentsSlice';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { InjuryReportDraftsDataGrid } from '../../components/InjuryReportsDataGrid/InjuryReportDraftsDataGrid';
import { getActiveUser } from '../../../../utils/user';
import { navigateToUrl } from 'single-spa';
import { InjuryInbox } from '../../components/InjuryInbox';

export const AppPageNewTableContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  minHeight: 0,
  minWidth: 0,
  overflow: 'visible'
}));

type InjuryRegistrationsPageProps = {
  category?: string;
};

export const InjuryRegistrationsPage = (props: InjuryRegistrationsPageProps) => {
  const { category } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [formOpen, setFormOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [tab, setTab] = React.useState('reports');

  const handleChangeTab = (event: React.SyntheticEvent, newTab: string) => {
    /* dispatch(injuryReportCleared()); */
    setTab(newTab);
  };

  const handleReportInjury = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleInjuryReportDestination = (type: string) => {
    setAnchorEl(null);
    window.location.href.replace('/fm/injuries', '');
    const injuryReportRoute = '#/injury';

    switch (type) {
      case `${REPORT_CATEGORY.STUDENT}`:
        navigateToUrl(`${injuryReportRoute}`);
        window.location.reload();
        return;
      case `${REPORT_CATEGORY.EMPLOYEE}`:
        navigateToUrl(`${injuryReportRoute}/employee`);
        window.location.reload();
        return;
      case `${REPORT_CATEGORY.STUDENT}/${REPORT_TYPES.MINOR_INJURY}`:
        navigateToUrl(`${injuryReportRoute}/minorInjury`);
        window.location.reload();
        return;
      case `${REPORT_CATEGORY.EMPLOYEE}/${REPORT_TYPES.MINOR_INJURY}`:
        navigateToUrl(`${injuryReportRoute}/employee/minorInjury`);
        window.location.reload();
        return;
      default:
        return;
    }
  };

  const currentUser = getActiveUser();
  const selectTotalInjuryMessagesByModule = React.useMemo(makeSelectTotalInjuryMessagesByModule, [category]);
  const selectInjuryReportsByModule = React.useMemo(makeSelectInjuryReportsByModule, [category]);

  const totalMessages = useAppSelector((state: RootState) => selectTotalInjuryMessagesByModule(state, (category || 'all' as any)));  
  const injuryReports = useAppSelector((state: RootState) => selectInjuryReportsByModule(state, (category || 'all' as any)));
  
  const registrationIds = useAppSelector(selectInjuryRegistrationReportIds);

  const filteredInjuryReports = React.useMemo(() => {
    return injuryReports.filter((report) => registrationIds.indexOf(report.id) !== -1)
  }, [injuryReports, registrationIds]);

  const messagesCount = React.useMemo(() => {
    let total = 0;
    for (let report of filteredInjuryReports) {
      if (report?.messages && report?.messages?.length > 0) {
        total += report.messages.length;
      }
    }
    return total;
  }, [injuryReports, registrationIds]);

  return (
    <ContentWrapper>
      <ContentLayout
        breadcrumbs={[{ to: '/', name: t(`${TKB_INJURY}.home`) }]}
        title={
          category === REPORT_CATEGORY.EMPLOYEE
            ? t(`${TKB_INJURY}.employeeInjuries`)
            : category === REPORT_CATEGORY.STUDENT
            ? t(`${TKB_INJURY}.studentInjuries`)
            : category === undefined
            ? t(`${TKB_INJURY}.sidebar.allInjuries`)
            : ''
        }
        maxWidth="xl"
      >
        <Stack
          direction="column"
          spacing={1}
          sx={{
            pt: 1.5
          }}
        >
          <Header
            actions={
              <Stack direction="row" gap={1}>
                <Button
                  color="primary"
                  id="fade-button"
                  aria-controls={open ? 'fade-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleReportInjury}
                >
                  {t(`${TKB_INJURY}.reportInjury`)}
                </Button>
                <Menu
                  id="fade-menu"
                  MenuListProps={{
                    'aria-labelledby': 'fade-button'
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={() => handleInjuryReportDestination('overview')}
                  TransitionComponent={Fade}
                >
                  {(category === REPORT_CATEGORY.STUDENT || category === undefined) && (
                    <>
                      <MenuItem onClick={() => handleInjuryReportDestination(`${REPORT_CATEGORY.STUDENT}`)}>
                        {t(`${TKB_INJURY_REPORT}.studentInjury`)}
                      </MenuItem>
                      <MenuItem
                        onClick={() =>
                          handleInjuryReportDestination(`${REPORT_CATEGORY.STUDENT}/${REPORT_TYPES.MINOR_INJURY}`)
                        }
                      >
                        {t(`${TKB_INJURY_REPORT}.studentMinorInjury`)}
                      </MenuItem>
                    </>
                  )}
                  {(category === REPORT_CATEGORY.EMPLOYEE || category === undefined) && (
                    <>
                      <MenuItem onClick={() => handleInjuryReportDestination(`${REPORT_CATEGORY.EMPLOYEE}`)}>
                        {t(`${TKB_INJURY_REPORT}.employeeInjury`)}
                      </MenuItem>
                      <MenuItem
                        onClick={() =>
                          handleInjuryReportDestination(`${REPORT_CATEGORY.EMPLOYEE}/${REPORT_TYPES.MINOR_INJURY}`)
                        }
                      >
                        {t(`${TKB_INJURY_REPORT}.employeeMinorInjury`)}
                      </MenuItem>
                    </>
                  )}
                </Menu>
              </Stack>
            }
          >
            <>
              {category === REPORT_CATEGORY.EMPLOYEE && t(`${TKB_INJURY}.employeeInjuries`) + `: `}
              {category === REPORT_CATEGORY.STUDENT && t(`${TKB_INJURY}.studentInjuries`) + `: `}
              {category === undefined && t(`${TKB_INJURY}.allInjuries`) + `: `}
              <Typography
                variant="h1"
                component="span"
                sx={{
                  display: 'inline',
                  fontWeight: 500,
                  color: 'text.secondary'
                }}
              >
                {t(`${TKB_INJURY}.registrations`)}
              </Typography>
            </>
          </Header>
          <TabsContainer px={3}>
            <Tabs value={tab} onChange={handleChangeTab} aria-label='main-tabs'>
              <Tab label={filteredInjuryReports.length > 0 ? `${t('wif.injury.reports')} (${filteredInjuryReports.length})` : t('wif.injury.reports')} value='reports' />
              {/* <Tab label={t(`${TKB_INJURY}.drafts`)} value='drafts' /> */}
              {messagesCount > 0 && <Tab label={messagesCount > 0 ? `${t('wif.injury.messages')} (${messagesCount})` : t('wif.injury.messages')} value='messages' />}
            </Tabs>
          </TabsContainer>
        </Stack>

        <NamedTabPanel value={tab} name='reports'>
          <InjuryReportsDataGrid 
            categoryFilter={category}
            reporterFilter={currentUser?.id}
            view="reporter"
            module={category || ('all' as any)}
          />
        </NamedTabPanel>
        <NamedTabPanel value={tab} name='drafts'>
          <InjuryReportDraftsDataGrid 
            category={category}
          />
        </NamedTabPanel>
        <NamedTabPanel value={tab} name='messages'>
          <InjuryInbox 
            view='reporter'
            module={category || 'all' as any} 
          />
        </NamedTabPanel>
      </ContentLayout>
    </ContentWrapper>
  );
};