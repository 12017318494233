import { Avatar, Button, IconButton, Paper, Stack, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from 'react';
import { useTheme, alpha } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import { AppIcon } from "../Elements";
import { formatBytes, formatDateTimeFromTimestamp } from "../../utils/format";
import { makeSelectInjuryAttachmentByKey } from "../../features/injuries/store/injuryReportsSlice";
import { RootState, useAppSelector } from "../../store";
import { AppUserAvatar } from "../Elements/AppAvatar";

export function useChatScroll<T>(dep: T): React.MutableRefObject<HTMLDivElement> {
  const ref = React.useRef<HTMLDivElement>();
  React.useEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = ref.current.scrollHeight;
    }
  }, [dep]);
  return ref;
}

type AppFileAttachmentProps = {
  file: File;
  onClickRemove?: (e: any) => void;
}

export function AppFileAttachment(props: AppFileAttachmentProps) {
  const { file, onClickRemove } = props;

  const url = URL.createObjectURL(file);

  const theme = useTheme();
  const previewIcon = React.useMemo(() => {
    let iconName = 'note';
    let bgcolor = 'background.default';
    let color = 'text.secondary';


    if (file?.type === 'application/pdf') {
      iconName = 'picture_as_pdf';
      bgcolor = alpha(theme.palette.error.main, 0.9);
      color = '#fff';
    }
    return {
      iconName,
      bgcolor,
      color
    };
  }, [file]);

  if (!file) {
    return <></>;
  }

  return (
    <Box>
      <Box
        sx={{
          '&:hover': {
            cursor: 'pointer'
          },
          bgcolor: (theme) => theme.palette.mode === 'light' ? 'background.default' : 'backgroundVariant', 
          borderRadius: 1,
          py: 1.25,
          px: 1.5,  
          pr: 1.25,
        }}
      >
      <Stack
        direction='row'
        alignItems='center'
        gap={2}
      >
        {file.type.split('/')[0] === 'image' ? (
          <img 
            src={url}
            style={{
              height: 60,
              width: 60, 
              objectFit: 'cover'
            }}
            alt={file.name}
          />
        ) : (
          <Avatar
            sx={{
              height: 60,
              width: 60,
              borderRadius: 1,
              bgcolor: previewIcon.bgcolor,
              color: previewIcon.color
            }}
          >
            <AppIcon
              iconName={previewIcon.iconName}
            />
          </Avatar>
        )}
        <Stack
          direction='column'
          flexGrow={1}
        >
          <Typography variant='body2' fontWeight={500}>{file?.name}</Typography> 
          <Typography variant='body2' color='textVariant'>{file?.type}</Typography>
          <Typography variant='body2' color='textVariant'>{formatBytes(file?.size, 2)}</Typography>
        </Stack>
        {onClickRemove && (
          <IconButton onClick={onClickRemove}>
            <AppIcon iconName="delete" color="error.main" />
          </IconButton>
        )}
        
      </Stack>
      
    </Box>
    </Box>
  );
}
type AppMessageAttachmentProps = {
  attachmentKey: string;
}

export function AppMessageAttachment(props: AppMessageAttachmentProps) {
  const { attachmentKey } = props;

  const selectAttachmentByKey = React.useMemo(makeSelectInjuryAttachmentByKey, []);

  const attachment = useAppSelector((state: RootState) => 
    selectAttachmentByKey(state, attachmentKey)
  );

  const theme = useTheme();
  const previewIcon = React.useMemo(() => {
    let iconName = 'note';
    let bgcolor = 'background.default';
    let color = 'text.secondary';


    if (attachment?.file_type === 'application/pdf') {
      iconName = 'picture_as_pdf';
      bgcolor = alpha(theme.palette.error.main, 0.9);
      color = '#fff';
    }
    return {
      iconName,
      bgcolor,
      color
    };
  }, [attachment?.file_type]);

  if (!attachment) {
    return <></>;
  }

  return (
    <Box>
      <Box
      sx={{
        bgcolor: (theme) => theme.palette.mode === 'light' ? 'background.default' : 'backgroundVariant', 
        borderRadius: 1,
        py: 1.25,
        px: 1.5,  
        pr: 1.25,
      }}
    >
      <Stack
        direction='row'
        alignItems='center'
        gap={2}
      >
        {attachment.file_type.split('/')[0] === 'image' ? (
          <img 
            src={attachment.url}
            style={{
              height: 60,
              width: 60, 
              objectFit: 'cover'
            }}
            alt={attachment.file_name}
          />
        ) : (
          <Avatar
            sx={{
              height: 60,
              width: 60,
              borderRadius: 1,
              bgcolor: previewIcon.bgcolor,
              color: previewIcon.color
            }}
          >
            <AppIcon
              iconName={previewIcon.iconName}
            />
          </Avatar>
        )}
        <Stack
          direction='column'
        >
          <a href={attachment.url}><Typography variant='body2' fontWeight={500} color='primary.main'>{attachment.file_name}</Typography></a> 
          <Typography variant='body2' color='textVariant'>{attachment.file_type}</Typography>
          <Typography variant='body2' color='textVariant'>{formatBytes(attachment.file_size, 2)}</Typography>
        </Stack>
      </Stack>
    </Box>
    </Box>
  )
}

type AppMessageProps = {
  timestamp: number;
  sender: string;
  senderRole?: string;
  message: string;
  attachmentKeys?: string[];
  sx?: any;
}

export function AppMessage(props: AppMessageProps) {
  const { timestamp, sender, senderRole, message, attachmentKeys = [], sx } = props;

  return (
    <Box
      sx={{ 
        display: 'flex',
        flexDirection: 'row',
        gap: 1.5,
        width: '100%',
        ...sx
      }}
    >
      <AppUserAvatar
        username={sender}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          flexGrow: 1,
          overflow: 'hidden',
        }}
      >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: 0.5
        }}
      >
        
        <Typography variant='body2' sx={{ fontWeight: 600 }}>{sender}{senderRole && ` (${senderRole})`}</Typography>
        <Typography variant='body2' sx={{ color: 'text.secondary' }}>{formatDateTimeFromTimestamp(timestamp)}</Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          mt: -0.75,
          overflow: 'hidden',
          width: '100%',
        }}
      >
        
          <Typography variant='body1' 
            sx={{ 
              wordWrap: 'break-word',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {message}
          </Typography>
         
      </Box>
      {attachmentKeys && attachmentKeys?.length > 0 && attachmentKeys.map((attachmentKey: string) => (
        <AppMessageAttachment
          key={attachmentKey}
          attachmentKey={attachmentKey}
        />
      ))}
    </Box>
    </Box>
  )
}