
export const IBM_COLORS = {
	Green30:'#6fdc8c',
  Teal40:'#08bdba',
	Teal50:'#009d9a',
  Teal60:'#007d79',
  Teal70:'#005d5d',
  Cyan50:'#1192e8',
  Black:"#000000",
  White:"#ffffff",
  Red100:"#2d0709",
  Red90:"#520408",
  Red80:"#750e13",
  Red70:"#a2191f",
  Red60:"#da1e28",
  Red50:"#fa4d56",
  Red40:"#ff8389",
  Red30:"#ffb3b8",
  Red20:"#ffd7d9",
  Red10:"#fff1f1",
  Magenta100:"#2a0a18",
  Magenta90:"#510224",
  Magenta80:"#740937",
  Magenta70:"#9f1853",
  Magenta60:"#d02670",
  Magenta50:"#ee5396",
  Magenta40:"#ff7eb6",
  Magenta30:"#ffafd2",
  Magenta20:"#ffd6e8",
  Magenta10:"#fff0f7",
  Purple90:"#31135e",
  Purple80:"#491d8b",
  Purple70:"#6929c4",
  Purple60:"#8a3ffc",
  Purple50:"#a56eff",
  Purple40:"#be95ff",
  Purple30:"#d4bbff",
  Purple20:"#e8daff",
  Purple10:"#f6f2ff",
  Blue90:"#001d6c",
  Blue80:"#002d9c",
  Blue70:"#0043ce",
  Blue60:"#0f62fe",
  Blue50:"#4589ff",
  Blue40:"#78a9ff",
  Blue30:"#a6c8ff",
  Blue20:"#d0e2ff",
  Blue10:"#edf5ff",
  Cyan90:"#012749",
  Cyan80:"#003a6d",
  Cyan70:"#00539a",
	Cyan40:'#33b1ff',
	Cyan20:'#bae6ff',
  Green60:'#198038',
  Yellow50:'#b28600',
  Orange70:'#8a3800',
	Yellow40:'#d2a106',
	Orange60:'#ba4e00'
};

export const IBM_DEFAULT_CATEGORICAL_LIGHT = [
  IBM_COLORS.Purple70,
  IBM_COLORS.Cyan50,
  IBM_COLORS.Teal70,
  IBM_COLORS.Magenta70,
  IBM_COLORS.Red50,
  IBM_COLORS.Red90,
  IBM_COLORS.Green60,
  IBM_COLORS.Blue80,
  IBM_COLORS.Magenta50,
  IBM_COLORS.Yellow50,
  IBM_COLORS.Teal50,
  IBM_COLORS.Cyan90,
  IBM_COLORS.Orange70,
  IBM_COLORS.Purple50,
];

export const IBM_CATEGORICAL_DARK: any = {
	1: {
		1: {
			1: IBM_COLORS.Purple30,
		},
		2: {
			1: IBM_COLORS.Blue50,
		},
		3: {
			1: IBM_COLORS.Cyan40,
		},
		4: {
			1: IBM_COLORS.Teal40,
		},
	},
	2: {
		1: {
			1: IBM_COLORS.Purple60,
			2: IBM_COLORS.Teal40,
		},
		2: {
			1: IBM_COLORS.Purple60,
			2: IBM_COLORS.Magenta40,
		},
		3: {
			1: IBM_COLORS.Magenta40,
			2: IBM_COLORS.Red10,
		},
		4: {
			1: IBM_COLORS.Blue50,
			2: IBM_COLORS.Cyan20,
		},
		5: {
			1: IBM_COLORS.Teal60,
			2: IBM_COLORS.Green30,
		},
	},
	3: {
		1: {
			1: IBM_COLORS.Purple60,
			2: IBM_COLORS.Teal40,
			3: IBM_COLORS.Cyan20,
		},
		2: {
			1: IBM_COLORS.Purple60,
			2: IBM_COLORS.Magenta40,
			3: IBM_COLORS.Red10,
		},
		3: {
			1: IBM_COLORS.Blue50,
			2: IBM_COLORS.Teal40,
			3: IBM_COLORS.Purple30,
		},
		4: {
			1: IBM_COLORS.Blue50,
			2: IBM_COLORS.Green30,
			3: IBM_COLORS.Red10,
		},
		5: {
			1: IBM_COLORS.Teal60,
			2: IBM_COLORS.Green30,
			3: IBM_COLORS.Cyan20,
		},
	},
	4: {
		1: {
			1: IBM_COLORS.Purple60,
			2: IBM_COLORS.Teal40,
			3: IBM_COLORS.Cyan20,
			4: IBM_COLORS.Blue50,
		},
		2: {
			1: IBM_COLORS.Blue50,
			2: IBM_COLORS.Teal40,
			3: IBM_COLORS.Purple30,
			4: IBM_COLORS.Red10,
		},
		3: {
			1: IBM_COLORS.Teal60,
			2: IBM_COLORS.Red10,
			3: IBM_COLORS.Cyan40,
			4: IBM_COLORS.Green30,
		},
	},
	5: {
		1: {
			1: IBM_COLORS.Purple60,
			2: IBM_COLORS.Teal50,
			3: IBM_COLORS.Cyan20,
			4: IBM_COLORS.Blue50,
			5: IBM_COLORS.Magenta40,
		},
		2: {
			1: IBM_COLORS.Blue50,
			2: IBM_COLORS.Teal40,
			3: IBM_COLORS.Purple30,
			4: IBM_COLORS.Red10,
			5: IBM_COLORS.Green30,
		},
	},
	14: {
		1: {
			1:  IBM_COLORS.Purple70,
			2:  IBM_COLORS.Cyan50,
			3:  IBM_COLORS.Teal70,
			4:  IBM_COLORS.Magenta70,
			5:  IBM_COLORS.Red50,
			6:  IBM_COLORS.Red90,
			7:  IBM_COLORS.Green60,
			8:  IBM_COLORS.Blue80,
			9:  IBM_COLORS.Magenta50,
			10: IBM_COLORS.Yellow50,
			11: IBM_COLORS.Teal50,
			12: IBM_COLORS.Cyan90,
			13: IBM_COLORS.Orange70,
			14: IBM_COLORS.Purple50,
		},
  },
};

export const IBM_DEFAULT_CATEGORICAL_DARK: any = [
  IBM_COLORS.Purple60,
  IBM_COLORS.Cyan40,
  IBM_COLORS.Teal60,
  IBM_COLORS.Magenta40,
  IBM_COLORS.Red50,
  IBM_COLORS.Red10,
	IBM_COLORS.Green30,
  IBM_COLORS.Blue50,
  IBM_COLORS.Magenta60,
  IBM_COLORS.Yellow40,
  IBM_COLORS.Teal40,
  IBM_COLORS.Cyan20,
  IBM_COLORS.Orange60,
  IBM_COLORS.Purple30,
];

export const IBM_CATEGORICAL_LIGHT: any ={
	1: {
		1: {
			1: IBM_COLORS.Purple70,
		},
		2: {
			1: IBM_COLORS.Blue80,
		},
		3: {
			1: IBM_COLORS.Cyan50,
		},
		4: {
			1: IBM_COLORS.Teal60,
		},
	},
	2: {
		1: {
			1: IBM_COLORS.Purple70,
			2: IBM_COLORS.Teal50,
		},
		2: {
			1: IBM_COLORS.Purple60,
			2: IBM_COLORS.Red90,
		},
		3: {
			1: IBM_COLORS.Magenta70,
			2: IBM_COLORS.Red90,
		},
		4: {
			1: IBM_COLORS.Cyan50,
			2: IBM_COLORS.Teal70,
		},
		5: {
			1: IBM_COLORS.Teal50,
			2: IBM_COLORS.Blue80,
		},
	},
	3: {
		1: {
			1: IBM_COLORS.Magenta50,
			2: IBM_COLORS.Cyan50,
			3: IBM_COLORS.Purple70,
		},
		2: {
			1: IBM_COLORS.Magenta70,
			2: IBM_COLORS.Red50,
			3: IBM_COLORS.Red90,
		},
		3: {
			1: IBM_COLORS.Purple50,
			2: IBM_COLORS.Teal70,
			3: IBM_COLORS.Blue80,
		},
		4: {
			1: IBM_COLORS.Purple50,
			2: IBM_COLORS.Teal70,
			3: IBM_COLORS.Magenta70,
		},
		5: {
			1: IBM_COLORS.Cyan90,
			2: IBM_COLORS.Purple70,
			3: IBM_COLORS.Teal50,
		},
	},
	4: {
		1: {
			1: IBM_COLORS.Purple70,
			2: IBM_COLORS.Cyan90,
			3: IBM_COLORS.Teal50,
			4: IBM_COLORS.Magenta50,
		},
		2: {
			1: IBM_COLORS.Magenta70,
			2: IBM_COLORS.Red50,
			3: IBM_COLORS.Red90,
			4: IBM_COLORS.Purple50,
		},
		3: {
			1: IBM_COLORS.Teal50,
			2: IBM_COLORS.Blue80,
			3: IBM_COLORS.Purple50,
			4: IBM_COLORS.Magenta70,
		},
	},
	5: {
		1: {
			1: IBM_COLORS.Purple70,
			2: IBM_COLORS.Cyan50,
			3: IBM_COLORS.Teal70,
			4: IBM_COLORS.Magenta70,
			5: IBM_COLORS.Red90,
		},
		2: {
			1: IBM_COLORS.Blue80,
			2: IBM_COLORS.Teal50,
			3: IBM_COLORS.Magenta70,
			4: IBM_COLORS.Red90,
			5: IBM_COLORS.Purple50,
		},
	},
	14: {
		1: {
			1:  IBM_COLORS.Purple70,
			2:  IBM_COLORS.Cyan50,
			3:  IBM_COLORS.Teal70,
			4:  IBM_COLORS.Magenta70,
			5:  IBM_COLORS.Red50,
			6:  IBM_COLORS.Red90,
			7:  IBM_COLORS.Green60,
			8:  IBM_COLORS.Blue80,
			9:  IBM_COLORS.Magenta50,
			10: IBM_COLORS.Yellow50,
			11: IBM_COLORS.Teal50,
			12: IBM_COLORS.Cyan90,
			13: IBM_COLORS.Orange70,
			14: IBM_COLORS.Purple50,
		},
	},
};

export const getDefaultIbmCategorical = (args: { paletteMode?: string }) => {
	const { paletteMode = 'light' } = args;

	if (paletteMode === 'light') {
		return IBM_DEFAULT_CATEGORICAL_LIGHT;
	}
	return IBM_DEFAULT_CATEGORICAL_DARK;
};

export const getCustomIbmCategorical = (args: { paletteMode?: string, numberOfCategories: number, palette: number }): string[] => {
	const { paletteMode = 'light', numberOfCategories, palette } = args;
	const paletteCollection = paletteMode === 'light' ? IBM_CATEGORICAL_LIGHT : IBM_CATEGORICAL_DARK;

	const paletteObj = paletteCollection[numberOfCategories][palette];
  let paletteColors: string[] = [];
  for (let i = 1; i <= numberOfCategories; i++) {
		paletteColors.push(paletteObj[i]);
	}
  return paletteColors;
};