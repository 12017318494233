import { TKB_INJURY } from "../../injuries/constants";

export const SiteTypes = [
  {
    id: 'school',
    iconName: 'school',
    label: `${TKB_INJURY}.sectionNames.school`,
  },
  {
    id: 'work',
    iconName: 'apartments',
    label: `${TKB_INJURY}.sectionNames.school`,
  },
  {
    id: 'general',
    iconName: 'home_work',
    label: `${TKB_INJURY}.sectionNames.school`,
  },
];

export const getSiteTypeById = (siteTypeId: string) => {
  return SiteTypes.find(siteType => siteType.id === siteTypeId);
};