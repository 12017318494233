import React from 'react';
import * as yup from 'yup';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useCurrentInjuryReport } from '../../../routes/CurrentInjuryReportContext';
import { AppDialogTitle } from '../../../../../components/Display/AppDialogTitle';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { selectAllNonconformityReports, selectNonconformityReportEntities } from '../../../../nonconformity/nonconformitySlice';
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import { AppIcon } from '../../../../../components/Elements/AppIcon';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { updateInjuryReport } from '../../../store/injuryReportsSlice';
import { snackAlertOpened } from '../../../../global/controlsSlice';
import { REPORT_TYPES } from '../../../constants';

const LikelyhoodOfReoccurrenceOptions = ['Not evaluated', 'Not likely', 'Likely', 'Very likely'];

export const closingCaseSchema = yup.object().shape({
  likelyhoodOfReoccurence: yup.string().oneOf(LikelyhoodOfReoccurrenceOptions).required(),
  endingEvaluation: yup.string().required().min(4, 'min 4')
});

interface RejectInjuryReportDialogProps {
  open?: boolean;
  onClose?: () => void;
}

export default function LinkNonconformityReportDialog(props: RejectInjuryReportDialogProps) {
  const { open = true, onClose } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const injuryReport = useCurrentInjuryReport();
  const ncReports = useAppSelector(selectAllNonconformityReports);
  const ncReportEntities = useAppSelector(selectNonconformityReportEntities);

  const [ncReportId, setNcReportId] = React.useState('None');
  const [query, setQuery] = React.useState('');
  const [sort, setSort] = React.useState('asc');

  const handleChangeNcReportId = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNcReportId((event.target as HTMLInputElement).value);
  };

  React.useEffect(() => {
    setNcReportId(injuryReport?.linked_nc_report_id || 'None');
  }, [injuryReport]);

  const filteredAndSortedNcReports = React.useMemo(() => {
    return ncReports
      .filter((ncReport) => {
        if (query.length > 0 && ncReportId !== ncReport.rep_id) {
          return (
            ncReport.name.toLowerCase().includes(query.toLowerCase()) ||
            ncReport.rep_id.toLowerCase().includes(query.toLowerCase())
          );
        }
        return true;
      })
      .sort((a, b) => {
        if (sort === 'asc') {
          return a.name.localeCompare(b.name);
        }
        return b.name.localeCompare(a.name);
      });
  }, [query, sort, ncReports]);

  const handleUpdateLinkedNcReport = async () => {
    if (ncReportId && ncReportId !== 'None' && ncReportEntities[ncReportId]?.name) {

      let body: any = {
        'further_information.nonconformityReport': {
          'rep_id': ncReportId,
          'name': ncReportEntities[ncReportId]?.name
        }
      };
      if (injuryReport.type === REPORT_TYPES.MINOR_INJURY) {
        body = {
          'minor_injury_information.nonconformityReport': {
            'rep_id': ncReportId,
            'name': ncReportEntities[ncReportId]?.name 
          }
        };
      }
      /* let body: any = {
        'further_information.nonconformityReport.rep_id': ncReportId,
        'further_information.nonconformityReport.name': ncReportEntities[ncReportId]?.name
      };
      if (injuryReport.type === REPORT_TYPES.MINOR_INJURY) {
        body = {
          'minor_injury_information.nonconformityReport.rep_id': ncReportId,
          'minor_injury_information.nonconformityReport.name': ncReportEntities[ncReportId]?.name
        };
      } */
      const req: any = {
        reportId: injuryReport.id,
        body
      };

      const resultAction = await dispatch(updateInjuryReport(req));
      if (updateInjuryReport.fulfilled.match(resultAction)) {
        /* dispatch(loadingFinished()); */
        dispatch(snackAlertOpened({ message: t('wif.injury.snackMessages.linkedNcrUpdated.success'), severity: 'success' }));
        onClose();
      } else {
        if (resultAction.payload) {
          /* dispatch(loadingFinished()); */
          dispatch(snackAlertOpened({ message: t('wif.injury.snackMessages.genericError'), severity: 'error' }));
        } else {
          /* dispatch(loadingFinished()); */
          dispatch(snackAlertOpened({ message: t('wif.injury.snackMessages.errorWithMessage', { message: resultAction.error }), severity: 'error' }));
        }
      }
    }
    
  }
  return (
    <Dialog open={open} onClose={onClose}>
      <AppDialogTitle id="close-report-dialog-title" onClose={onClose}>
        {t('wif.injury.linkNCRDialog.title')}
      </AppDialogTitle>

      <DialogContent
        dividers
        sx={{
          overflowY: 'none'
        }}
      >
        <Stack direction="row" gap={1} mb={2}>
          <OutlinedInput
            placeholder={t('wif.injury.linkNCRDialog.searchText')}
            multiline
            value={query}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setQuery(event.target.value);
            }}
            sx={{
              flex: 1,
              height: 40,
              pl: 1.75
            }}
            size="small"
            defaultValue=""
            startAdornment={
              <InputAdornment position="start" sx={{ minWidth: '0px' }}>
                <AppIcon iconName="search" color="textVariant" weight={500} />
              </InputAdornment>
            }
          />
          <Button
            variant="text"
            sx={{ height: 40 }}
            onClick={() => setSort(sort === 'asc' ? 'desc' : 'asc')}
            startIcon={
              <AppIcon
                iconName={sort === 'asc' ? 'arrow_upward' : 'arrow_downward'}
                color="textVariant"
                weight={500}
                sx={{ mr: -1 }}
              />
            }
          >
            AZ
          </Button>
        </Stack>

        <Box
          sx={{
            width: '100%',
            height: '400px',
            overflowY: 'auto'
          }}
        >
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={ncReportId}
              onChange={handleChangeNcReportId}
            >
              <FormControlLabel value="None" onClick={() => setNcReportId('None')} control={<Radio />} label={t('wif.injury.none')} />
              {filteredAndSortedNcReports.map((ncReport) => (
                <FormControlLabel
                  key={ncReport.rep_id}
                  value={ncReport.rep_id}
                  control={<Radio />}
                  onClick={() => setNcReportId(ncReport.rep_id)}
                  label={`${ncReport.name} (${ncReport.rep_id})`}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: 'space-between'
        }}
      >
        <Box> </Box>
        {/* <Button disabled>Report nonconformity</Button> */}
        <Stack direction="row" alignItems="center" gap={1}>
          <Button color="primary" variant="text" onClick={onClose}>
          {t('wif.injury.cancel')}
          </Button>
          <Button onClick={handleUpdateLinkedNcReport} variant="contained" color="secondary" disabled={!ncReportId || ncReportId === 'None'}>
            {t('wif.injury.save')}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
