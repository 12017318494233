import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { InjuryLog, selectEmployeeInjuryTagEntities, selectInjuryUnitEntities, selectStudentInjuryTagEntities } from '../../store/injuryReportsSlice';
import { Avatar, Button, IconButton, List, ListItem, ListItemText, Paper, Stack, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useAppSelector } from '../../../../store';
import { selectEmployees } from '../../../employees/employeesSlice';
import { formatDateFromTimestamp, formatDateTimeFromTimestamp, nokCurrencyFormatter } from '../../../../utils/format';
import { AppUserAvatar } from '../../../../components/Elements/AppAvatar';
import { getActiveUser } from '../../../../utils/user';
import { AppMessageAttachment } from '../../../../components/Messages/AppMessage';
import _, { filter } from 'lodash';
import { AppIcon } from '../../../../components/Elements';
import { BOX_SHADOWS } from '../../../../config/theme/boxShadows';
import { selectActions } from '../../../actions/actionsSlice';
import dayjs from 'dayjs';
import { AllSectionDetails, LOG_TYPES, TKB_INJURY } from '../../constants';
import { useTranslation } from 'react-i18next';
import {
  MSectionTranslationKeyMapping,
  getReverseMappedSectionName,
  getReverseMappedSectionNameByCategory
} from '../../helpers/dataMapping';
import { useCurrentInjuryReport } from '../../routes/CurrentInjuryReportContext';
import { selectNonconformityReportEntities } from '../../../nonconformity/nonconformitySlice';
import { EMPLOYEE_SECTION_NAMES } from '../../constants/employee';
import { STUDENT_SECTION_NAMES } from '../../constants/student';
import { selectDepartmentEntities } from '../../../departments/departmentsSlice';

type FieldValuePartProps = {
  field: string;
  previousValue?: any;
  newValue?: any;
};

const FieldValuePart = (props: FieldValuePartProps) => {
  const { field, previousValue, newValue } = props;

  return (
    <Stack direction="column" gap={0.25}>
      <Typography variant="body2" sx={{ fontWeight: 500 }}>
        {field}
      </Typography>
      {previousValue && (
        <Typography variant="body2" sx={{ color: 'text.secondary', textDecoration: 'line-through' }}>
          {previousValue}
        </Typography>
      )}
      <Stack direction="row" alignItems="center" gap={0.75}>
        <Typography variant="body2" sx={{ color: 'text.primary' }}>
          {newValue}
        </Typography>
      </Stack>
    </Stack>
  );
};

type SectionUpdateTypeLogItemProps = {
  log: InjuryLog;
};

const SectionUpdateTypeLogItem = (props: SectionUpdateTypeLogItemProps) => {
  const { log } = props;
  const { t } = useTranslation();
  const report = useCurrentInjuryReport();

  const employeeTags = useAppSelector(selectEmployeeInjuryTagEntities);
  const studentTags = useAppSelector(selectStudentInjuryTagEntities);
  const departments = useAppSelector(selectDepartmentEntities);
  const units = useAppSelector(selectInjuryUnitEntities);
  const ncReports = useAppSelector(selectNonconformityReportEntities);
  const users = useAppSelector(selectEmployees);

  const mappedSectionValueFuncs = React.useCallback((sectionName: string) => {
 
    return {
      employeeId: (val: string) => users[val]?.name || val,
      damageNature: (val: any[]) =>
        val
          .map((v) => `${v.id ? v.id : v} ` + t(`${TKB_INJURY}.employee.typeTab.natureOfDamage.${v.id ? v.id : v}`))
          .join('; '),
      damagedBodyPart: (val: any[]) =>
        val
          .map((v) => `${v.id ? v.id : v} ` + t(`${TKB_INJURY}.employee.typeTab.damagedBodyPart.${v.id ? v.id : v}`))
          .join('; '),
      accidentType: (val: any[]) =>
        val
          .map((v) => `${v.id ? v.id : v} ` + t(`${TKB_INJURY}.employee.typeTab.typeOfAccident.${v.id ? v.id : v}`))
          .join('; '),
      background: (val: any[]) =>
        val
          .map((v) => `${v.id ? v.id : v} ` + t(`${TKB_INJURY}.employee.typeTab.background.${v.id ? v.id : v}`))
          .join('; '),
      damageMode: (val: any[]) =>
        val
          .map((v) => `${v.id ? v.id : v} ` + t(`${TKB_INJURY}.employee.typeTab.damageMode.${v.id ? v.id : v}`))
          .join('; '),
      typeOfWorkplace: (val: any[]) =>
        val
          .map((v) => `${v.id ? v.id : v} ` + t(`${TKB_INJURY}.employee.typeTab.typeOfWorkplace.${v.id ? v.id : v}`))
          .join('; '),
      deviation: (val: any[]) =>
        val
          .map((v) => `${v.id ? v.id : v} ` + t(`${TKB_INJURY}.employee.typeTab.deviation.${v.id ? v.id : v}`))
          .join('; '),
      assumedAbsence: (val: any[]) =>
        val
          .map((v) => `${v.id ? v.id : v} ` + t(`${TKB_INJURY}.employee.typeTab.assumedAbsence.${v.id ? v.id : v}`))
          .join('; '),
      ncReportId: (val: string) => ncReports[val]?.name || val,
      durationOfImpact: (val: string[]) => val.join(', '),
      assumedAnnualIncomeForClaimYear: (val: number) => `kr ${nokCurrencyFormatter.format(val)}`,
      employmentPercentage: (val: number) => `${val} %`,
      competenceLevel: (val: string) => t(`${TKB_INJURY}.employee.employeePersonTab.competenceLevel.${val}`),
      natureOfEmployment: (val: string) => t(`${TKB_INJURY}.employee.employeePersonTab.natureOfWorking.${val}`),
      voluntaryOccupationalInjuryInsurance: (val: string) => yesNoOptions[val],
      workTimeArrangements: (val: string) => t(`${TKB_INJURY}.employee.accidentTab.arrangements.${val}`),
      accidentOccured: (val: string) =>
        sectionName === EMPLOYEE_SECTION_NAMES.ACCIDENT
          ? t(`${TKB_INJURY}.employee.accidentTab.accidentOccurred.${val}`)
          : sectionName === STUDENT_SECTION_NAMES.ACCIDENT
          ? studentAccidentMapped[val]
          : '',
      accidentHappenedAtMainAddress: (val: string) => t(`wif.injury.yesNoOptions.${val}`),
      formOfSalary: (val: any) => t(`${TKB_INJURY}.employee.accidentTab.formOfSalary.${val}`),
      atNormalWorkplace: (val: any) => t(`${TKB_INJURY}.yesNoOptions.${val}`),
      insideOutside: (val: any) => t(`${TKB_INJURY}.employee.accidentTab.whereAccidentOccurred.${val}`),
      onTheWayToWork: (val: any) => t(`${TKB_INJURY}.yesNoOptions.${val}`),
      onTheWayBetweenWork: (val: any) => t(`${TKB_INJURY}.yesNoOptions.${val}`),
      deathFromAccident: (val: any) => t(`${TKB_INJURY}.yesNoOptions.${val}`),
      necessaryTraining: (val: any) => t(`${TKB_INJURY}.yesNoOptions.${val}`),
      reportedToLIA: (val: any) => t(`${TKB_INJURY}.yesNoOptions.${val}`),
      departmentId: (val: string) => departments[val]?.name || val,
      unitId: (val: string) => units[val]?.unit_name || val,
      typesOfInjury: (val: any[]) =>
        sectionName === EMPLOYEE_SECTION_NAMES.MINOR_INJURY
          ? (val &&
              val
                .map((v: any) => employeeTags && employeeTags[v] && employeeTags[v]?.name)
                .map((v: any) =>
                  t(`${TKB_INJURY}.typesOfInjury.${v}`).includes(TKB_INJURY) ? v : t(`${TKB_INJURY}.typesOfInjury.${v}`)
                )
                .join(', ')) ||
            ''
          : sectionName === STUDENT_SECTION_NAMES.MINOR_INJURY
          ? (val &&
              val
                .map((v: any) => studentTags && studentTags[v] && studentTags[v]?.name)
                .map((v: any) =>
                  t(`${TKB_INJURY}.typesOfInjury.${v}`).includes(TKB_INJURY) ? v : t(`${TKB_INJURY}.typesOfInjury.${v}`)
                )
                .join(', ')) ||
            ''
          : (val && val.join(', ')) || ''
    };
  }, [t, users]);

  const fieldValuePairs = React.useMemo(() => {
    let pairs: any[] = [];
    for (let update of log?.updates) {
      let oSectionName = update?.key?.split('.')?.[0];
      let fieldName = update?.key?.split('.')?.[1];
      let newValue = update?.new;
      let previousValue = update?.previous;
      if (newValue && fieldName) {
        if (fieldName === 'unit') {
          newValue = newValue?.unit_name;
          if (previousValue) {
            previousValue = previousValue?.name;
          }
        }
        if (
          [
            'department',
            'workTimeArrangements',
            'accidentOccured',
            'formOfSalary',
            'atNormalWorkplace',
            'insideOutside',
            'onTheWayToWork',
            'onTheWayBetweenWork',
            'deathFromAccident',
            'competenceLevel',
            'natureOfEmployment',
            'voluntaryOccupationalInjuryInsurance',
            'necessaryTraining',
            'reportedToLIA'
          ].includes(fieldName)
        ) {
          if (newValue?.id) {
            if (previousValue && previousValue?.id) {
              if (mappedSectionValueFuncs(oSectionName)[fieldName]) {
                previousValue = mappedSectionValueFuncs(oSectionName)[fieldName](previousValue.id)
              } else {
                previousValue = previousValue?.name;
              }
            } else {
              previousValue = undefined;
            }
            if (mappedSectionValueFuncs(oSectionName)[fieldName]) {
              newValue = mappedSectionValueFuncs(oSectionName)[fieldName](newValue.id)
            } else {
              newValue = newValue?.name;
            }
            
          }
          
        }
        if (
          [
            'typeOfAccident',
            'natureOfDamage',
            'damagedBodyPart',
            'background',
            'damageMode',
            'typeOfWorkplace',
            'deviation',
            'assumedAbsence'
          ].includes(fieldName)
        ) {
          if (newValue?.length) {
            newValue = newValue.map((val) => `${val.id} ${val.name}`).join(', ');
          }

          if (previousValue && previousValue?.length) {
            previousValue = previousValue.map((val) => `${val.id} ${val.name}`).join(', ');
          }
        }
        if (['date', 'detectionDate', 'employmentStartDate', 'employmentEndDate'].includes(fieldName)) {
          newValue = dayjs(newValue).format('DD.MM.YYYY');
          if (previousValue) {
            previousValue = dayjs(previousValue).format('DD.MM.YYYY');
          }
        }
        if (fieldName === 'time') {
          newValue = dayjs(newValue).format('HH:mm');
          if (previousValue) {
            previousValue = dayjs(previousValue).format('HH:mm');
          }
        }
        if (fieldName === 'nonconformityReport') {
          if (newValue?.name && newValue?.rep_id) {
            newValue = `${newValue?.name} (${newValue?.rep_id})`;
          } else {
            continue;
          }
          if (previousValue) {
            if (previousValue?.name && previousValue?.rep_id) {
              previousValue = `${previousValue?.name} (${previousValue?.rep_id})`;
            } else {
              continue;
            }
          }
          
        }
        if (newValue?.id) {
          newValue = newValue?.name;
        }
        if (previousValue && previousValue?.id) {
          previousValue = previousValue?.name;
        }

        const sectionName = getReverseMappedSectionNameByCategory(oSectionName, report?.category);
        fieldName = sectionName
          ? t(`wif.injury.${MSectionTranslationKeyMapping[sectionName]}.${fieldName}.title`)
          : fieldName;

        pairs.push({
          key: update?.key?.split('.')?.[1],
          field: fieldName,
          previousValue: previousValue,
          newValue: newValue
        });
      }
    }
    return pairs;
  }, [log, t, mappedSectionValueFuncs]);
 
  

  const studentAccidentMapped = {
    '67604946-cf85-11ed-afa1-0242ac120002': t(`${TKB_INJURY}.accidentTypesOccurredTags.teachingLocation`),
    '751d94a8-cf85-11ed-afa1-0242ac120002': t(`${TKB_INJURY}.accidentTypesOccurredTags.duringTeaching`),
    '7c6476d2-cf85-11ed-afa1-0242ac120002': t(`${TKB_INJURY}.accidentTypesOccurredTags.sfo`),
    '8369f466-cf85-11ed-afa1-0242ac120002': t(`${TKB_INJURY}.accidentTypesOccurredTags.onTheWay`),
    '1d4b309e-cf87-11ed-afa1-0242ac120002': t(`${TKB_INJURY}.accidentTypesOccurredTags.other`)
  };

  const yesNoOptions = {
    option1: t(`${TKB_INJURY}.yesNoOptions.yes`),
    option2: t(`${TKB_INJURY}.yesNoOptions.no`)
  };
  
  
  
  return (
    <Stack direction="column" gap={1}>
      {fieldValuePairs.map((pair, index) => (
        <FieldValuePart key={index} field={pair.field} previousValue={pair.previousValue} newValue={pair.newValue} />
      ))}
    </Stack>
  );
};

const SimpleMessageLogItem = (props: { log: InjuryLog }) => {
  const { log } = props;
  const { t } = useTranslation();

  return (
    <FieldValuePart
      field={`${t('wif.injury.comment')}:`} 
      newValue={log?.new?.message}
    />
  );
}

type UpdateCompletionTypeLogItemProps = {
  log: InjuryLog;
};

const UpdateCompletionTypeLogItem = (props: UpdateCompletionTypeLogItemProps) => {
  const { log } = props;
  const { t } = useTranslation();

  const fieldValuePairs = React.useMemo(() => {
    const update = log?.updates?.[0];
    let pairs: any[] = [];
    if (update?.new?.likelyhood) {
      pairs.push({
        field: t('wif.injury.closeCaseTab.likelihoodOfRecurrence.title'),
        previousValue: update?.previous?.likelyhood,
        newValue: update?.new?.likelyhood
      });
    }
    if (update?.new?.endingEvaluation) {
      pairs.push({
        field: t('wif.injury.closeCaseTab.endingEvaluation.title'),
        previousValue: update?.previous?.endingEvaluation,
        newValue: update?.new?.endingEvaluation
      });
    }
    return pairs;
  }, [log]);

  return (
    <Stack direction="column" gap={1}>
      {fieldValuePairs.map((pair) => (
        <FieldValuePart
          key={pair.field}
          field={pair.field}
          previousValue={pair.previousValue}
          newValue={pair.newValue}
        />
      ))}
      {log?.updates?.[0]?.new?.attachment_keys && log?.updates?.[0]?.new?.attachment_keys?.length > 0 && (
        <Stack direction="column" gap={0.5}>
          <Typography variant="body2" sx={{ fontWeight: 500 }}>
            Attachments
          </Typography>
          {log?.updates?.[0]?.new?.attachment_keys.map((attachment_key) => (
            <AppMessageAttachment key={attachment_key} attachmentKey={attachment_key} />
          ))}
        </Stack>
      )}
    </Stack>
  );
};

type ActionTypeLogProps = {
  log: InjuryLog;
  includeNonChanged?: boolean;
};

const includedActionFields = ['text', 'actionDescription', 'measureType', 'deadline', 'assignee', 'members'];

const includedActionCompletionFields = ['evaluation', 'workDone'];

const actionCompletionFieldLabels = {
  evaluation: 'Evaluation',
  workDone: 'Work done'
};

const includedReopenedActionFields = ['responsible', 'comment'];

const actionFieldLabels = {
  text: 'Title',
  actionDescription: 'Description',
  measureType: 'Reduces',
  deadline: 'Deadline',
  assignee: 'Assignees',
  members: 'Members'
};

const actionReducesOptionLabels = {
  reduces_likelyhood: 'Likelyhood',
  reduces_consequence: 'Consequence',
  reduces_likelyhood_consequence: 'Likelyhood and consequence'
};

const ActionTypeLog = (props: ActionTypeLogProps) => {
  const { log, includeNonChanged = true } = props;
  const { t } = useTranslation();
  const users = useAppSelector(selectEmployees);

  const fieldValuePairs = React.useMemo(() => {
    let pairs: any[] = [];

    if (log?.type === INJURY_LOG_TYPES.COMPLETE_ACTION) {
      for (let field of includedActionCompletionFields) {
        if (log?.new?.completionAndEvaluation?.[field]) {
          let newValue = log?.new?.completionAndEvaluation?.[field];
          let previousValue = log?.previous?.completionAndEvaluation?.[field];
          pairs.push({
            field: field,
            previousValue: previousValue,
            newValue: newValue
          });
        }
      }
    } else if (log?.type === INJURY_LOG_TYPES.REOPEN_ACTION) {
      for (let field of includedReopenedActionFields) {
        if (log?.new?.[field]) {
          let newValue = log?.new?.[field];
          pairs.push({
            field: field,
            newValue: newValue
          });
        }
      }
    } else {
      for (let field of includedActionFields) {
        if (log?.new?.[field]) {
          if (includeNonChanged || JSON.stringify(log?.previous?.[field]) !== JSON.stringify(log?.new?.[field])) {
            let newValue = log?.new?.[field];
            let previousValue = log?.previous?.[field];
            if (field === 'measureType') {
              if (newValue) {
                newValue = actionReducesOptionLabels[newValue] || newValue;
              }
              if (previousValue) {
                previousValue = actionReducesOptionLabels[previousValue] || previousValue;
              }
            }
            if (field === 'deadline') {
              if (newValue) {
                newValue = formatDateFromTimestamp(newValue);
              }
              if (previousValue) {
                previousValue = formatDateFromTimestamp(previousValue);
              }
            }
            if (field === 'assignee' || field === 'members') {
              if (newValue) {
                newValue = newValue?.map((val) => users[val]?.name || val);
                newValue = newValue?.join(', ');
              }
              if (previousValue) {
                previousValue = previousValue?.map((val) => users[val]?.name || val);
                previousValue = previousValue?.join(', ');
              }
            }
            pairs.push({
              field: field,
              previousValue:
                JSON.stringify(log?.previous?.[field]) !== JSON.stringify(log?.new?.[field]) && previousValue,
              newValue: newValue
            });
          }
        }
      }
    }

    return pairs;
  }, [log, users]);

  return (
    <Stack direction="column" gap={1}>
      {fieldValuePairs.map((pair: any) => (
        <FieldValuePart
          key={pair.field}
          field={t(`wif.injury.action.fields.${pair.field}.title`)}
          previousValue={pair.previousValue}
          newValue={pair.newValue}
        />
      ))}
    </Stack>
  );
};

type CaseUpdateTypeLogProps = {
  log: InjuryLog;
};

const CaseUpdateTypeLog = (props: CaseUpdateTypeLogProps) => {
  const { log } = props;
  const { key, new: updated, new_details: updated_details, previous, previous_details } = log;
  const { t } = useTranslation();
  const activeUser = getActiveUser();

  const users = useAppSelector(selectEmployees);

  const keyTitle = React.useMemo(() => {
    if (key === 'approved_sections') {
      return t('wif.injury.approvedSections');
    }
    if (key === 'assignee_id' || key === 'assignee') {
      return t('wif.injury.assignee');
    }
    if (key === 'due_date') {
      return t('wif.injury.dueDate');
    }
    return '';
  }, [log, t]);

  const formattedVal = React.useMemo(() => {
    if (key === 'approved_sections') {
      return updated.map((str) => t(`wif.injury.sectionNames.${str}`)).join(', ');
    }
    if (key === 'assignee_id' || key === 'assignee') {
      if (activeUser?.id === updated) {
        return 'You';
      }

      if (updated_details?.name && users[updated]?.name === 'unresolved') return updated_details?.name;
      return users[updated]?.name || updated;
    } 
    if (key === 'due_date') {
      return formatDateFromTimestamp(updated);
    }
    return '';
  }, [log, users, t]);

  const formattedPrevious = React.useMemo(() => {
    if (previous) {
      if (key === 'approved_sections') {
        return previous.map((str) => t(`wif.injury.sectionNames.${str}`)).join(', ');
      }
      if (key === 'assignee_id' || key === 'assignee') {
        if (activeUser?.id === previous) {
          return 'You';
        }

        if (previous_details?.name && users[previous]?.name === 'unresolved') return previous_details?.name;
        return users[previous]?.name || previous;
      }
      if (key === 'due_date') {
        return formatDateFromTimestamp(previous);
      }
    }

    return '';
  }, [log, users]);

  return (
    <Stack direction="column" gap={0.25}>
      <Typography variant="body2" sx={{ fontWeight: 500 }}>
        {keyTitle}
      </Typography>
      {/* {key === 'approved_sections' && (
        <>

        </>
      )} */}
      <Stack direction="row" alignItems="center" gap={0.75}>
        {previous && (
          <>
            <Typography variant="body2" sx={{ color: 'text.secondary', textDecoration: 'line-through' }}>
              {formattedPrevious}
            </Typography>
            <AppIcon iconName="arrow_forward" color="textVariant" />
          </>
        )}

        <Typography variant="body2" sx={{ color: 'text.primary' }}>
          {formattedVal}
        </Typography>
      </Stack>
    </Stack>
  );
};

type MessageTypeLogProps = {
  message: string;
  attachmentKeys?: string[];
};

const MessageTypeLog = (props: MessageTypeLogProps) => {
  const { message, attachmentKeys } = props;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1
      }}
    >
      <Typography variant="body2" sx={{}}>
        {message}
      </Typography>
      {attachmentKeys &&
        attachmentKeys?.length > 0 &&
        attachmentKeys.map((attachmentKey: string) => (
          <AppMessageAttachment key={attachmentKey} attachmentKey={attachmentKey} />
        ))}
    </Box>
  );
};

type MessageEmailTypeLogProps = {
  message: string;
  receivers?: any[];
};

const MessageEmailTypeLog = (props: MessageEmailTypeLogProps) => {
  const { message, receivers } = props;

  const slicedMessage = React.useMemo(() => {
    let lines = []
    for (let i = 0 ; i < message.length; i += 33){
      lines.push(message.slice(i,i+33))
    }
    return lines.join("\n")
  },[message])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Typography variant="body2" sx={{
          fontWeight: 'bold'
        }}>
          To :
        </Typography>
        <Typography variant="body2" sx={{}}>
          <List
            sx={{
              p: 0,
              my:0.5
            }}
          >
            {receivers.map((receiver) => (
              <ListItem
                sx={{
                  m: 0,
                  p: 0
                }}
              >
                {receiver.name}
              </ListItem>
            ))}
          </List>
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          p: 0,
          my:0.5
        }}
      >
        <Typography variant="body2" sx={{
          fontWeight: 'bold'
        }}>
          Message :
        </Typography>
        <Typography
          variant="body2"
          sx={{
            m: 0,
            p: 0
          }}
        >
          {slicedMessage}
        </Typography>
      </Box>
    </Box>
  );
};
// TYPES
export const INJURY_LOG_TYPES = {
  CREATE_INJURY_REPORT: 'CREATE_INJURY_REPORT',
  CREATE_ACTION: 'CREATE_ACTION', // DETAILS ONLY
  UPDATE_ACTION: 'UPDATE_ACTION', // DETAILS ONLY
  COMPLETE_ACTION: 'COMPLETE_ACTION',
  REOPEN_ACTION: 'REOPEN_ACTION',
  DELETE_ACTION: 'DELETE_ACTION',
  ADD_ACTION_COMMENT: 'ADD_ACTION_COMMENT',
  ADD_COMMENT: 'ADD_COMMENT',
  ADD_MESSAGE: 'ADD_MESSAGE',
  UPDATE_SECTION_DETAILS: 'UPDATE_SECTION_DETAILS',
  UPDATE_CASE: 'UPDATE_CASE', // ASSIGNMENT, DUE_DATE OR APPROVED_SECTIONS
  REJECT_INJURY_REPORT: 'REJECT_INJURY_REPORT',
  REOPEN_INJURY_REPORT: 'REOPEN_INJURY_REPORT',
  ADD_OR_UPDATE_CLOSING_DETAILS: 'ADD_OR_UPDATE_CLOSING_DETAILS',
  ADD_OR_UPDATE_FORMS: 'ADD_OR_UPDATE_FORMS',
  CLOSE_INJURY_REPORT: 'CLOSE_INJURY_REPORT',
  ADD_EMAIL_MESSAGE: 'ADD_EMAIL_MESSAGE'
};

export const INJURY_LOG_TYPE_LABELS = {
  CREATE_ACTION: 'Created action', // DETAILS ONLY
  UPDATE_ACTION: 'Updated action', // DETAILS ONLY
  ADD_ACTION_COMMENT: 'Added action comment',
  COMPLETE_ACTION: 'Completed action',
  DELETE_ACTION: 'Deleted action',
  ADD_COMMENT: 'Added comment',
  ADD_MESSAGE: 'Sent message',
  UPDATE_SECTION_DETAILS: 'Updated section details',
  UPDATE_CASE: 'Updated case management', // ASSIGNMENT, DUE_DATE OR APPROVED_SECTIONS
  REJECT_REPORT: 'Rejected case',
  REOPEN_REPORT: 'Reopened case',
  ADD_OR_UPDATE_CLOSING_DETAILS: 'Added or updated closing details',
  ADD_OR_UPDATE_FORMS: 'Added or updatet forms',
  CLOSE_REPORT: 'Closed report',
  ADD_EMAIL_MESSAGE: 'Sent Emails'
};

const ACTION_LOG_ITEMS = [
  INJURY_LOG_TYPES.CREATE_ACTION,
  INJURY_LOG_TYPES.UPDATE_ACTION,
  INJURY_LOG_TYPES.COMPLETE_ACTION,
  INJURY_LOG_TYPES.DELETE_ACTION,
  INJURY_LOG_TYPES.ADD_ACTION_COMMENT
];

type InjuryHistoryItemProps = {
  log: InjuryLog;
};

export const InjuryHistoryItem = (props: InjuryHistoryItemProps) => {
  const { log } = props;
  const { t } = useTranslation();
  const report = useCurrentInjuryReport();

  const activeUser = getActiveUser();

  const actions = useAppSelector(selectActions);

  const users = useAppSelector(selectEmployees);

  const username = React.useMemo(() => {
    /* if (log?.user_id === activeUser?.id) {
      return 'You';
    } */
    const user = users[log?.user_id];
    if (user) {
      return user?.name || log?.user_id;
    }
    return log?.user_id || '';
  }, [log, users]);

  const logTypeText: any = {
    [INJURY_LOG_TYPES.CREATE_ACTION]: 'created an action',
    [INJURY_LOG_TYPES.UPDATE_ACTION]: 'updated an action',
    [INJURY_LOG_TYPES.ADD_ACTION_COMMENT]: 'added a comment to an action',
    [INJURY_LOG_TYPES.ADD_COMMENT]: 'added a comment',
    [INJURY_LOG_TYPES.ADD_MESSAGE]: 'sent a message',
    [INJURY_LOG_TYPES.UPDATE_SECTION_DETAILS]: 'updated section details',
    [INJURY_LOG_TYPES.UPDATE_CASE]: 'updated case handling',
    [INJURY_LOG_TYPES.REJECT_INJURY_REPORT]: 'rejected report',
    [INJURY_LOG_TYPES.REOPEN_INJURY_REPORT]: 'reopened report',
    [INJURY_LOG_TYPES.ADD_OR_UPDATE_CLOSING_DETAILS]: 'added or updated closing details',
    [INJURY_LOG_TYPES.ADD_OR_UPDATE_FORMS]: 'added or updated forms',
    [INJURY_LOG_TYPES.CLOSE_INJURY_REPORT]: 'closed report',
    [INJURY_LOG_TYPES.DELETE_ACTION]: 'deleted an action',
    [INJURY_LOG_TYPES.ADD_EMAIL_MESSAGE]: 'sent email messages'
  };

  const formattedLogTypeText = React.useMemo(() => {
    const logType = log?.type;
    if (ACTION_LOG_ITEMS.includes(logType)) {
      if (log?.action_id && actions?.[log?.action_id]?.text) {
        return t(`wif.injury.historyLogs.${log?.type}.messageWithActionName`, {
          actionName: actions[log?.action_id]?.text
        });
      }
    }
    if (logType === INJURY_LOG_TYPES.ADD_OR_UPDATE_FORMS) {
      let formName;
      if (log?.key && ['navForm', 'laborForm'].includes(log?.key)) {
        formName = t(`wif.injury.${log?.key}`);
      }
      if (formName) {
        return t(`wif.injury.historyLogs.${log?.type}.messageWithFormName`, { formName: formName });
      }
      return t(`wif.injury.historyLogs.${log?.type}.message`);
    }
    if (logType === INJURY_LOG_TYPES.UPDATE_SECTION_DETAILS) {
      let oSectionName = log?.updates?.[0]?.key?.split('.')?.[0];
      let sectionName = getReverseMappedSectionNameByCategory(oSectionName, report?.category);
      if (sectionName) {
        sectionName = t(`wif.injury.sectionNames.${sectionName}`);
        /* sectionName = AllSectionDetails[sectionName] ? t(AllSectionDetails[sectionName].title) : sectionName;
        if (sectionName) {
          
        } */
      }
      return t(`wif.injury.historyLogs.${log?.type}.messageWithSectionName`, { sectionName: sectionName });
    }
    return t(`wif.injury.historyLogs.${log?.type}.message`);
  }, [log, actions, t]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: 1.5,
        width: '100%',
        px: 2.5,
        py: 1.5
      }}
    >
      <AppUserAvatar username={username} size="medium" />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          flexGrow: 1
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 0.5
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: 600 }}>
            {`${log?.user_id === activeUser?.id ? t('wif.injury.you') : username} ${formattedLogTypeText}`}
            {/* <Typography
            sx={{ display: 'inline' }}
            component="span"
            variant="body2"
            color="text.primary"
          >
            {logTypeText[log.type]}
          </Typography> */}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            mt: -0.75
          }}
        >
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {formatDateTimeFromTimestamp(log?.time)}
          </Typography>
        </Box>
        {(log?.type === INJURY_LOG_TYPES.ADD_COMMENT ||
          log?.type === INJURY_LOG_TYPES.ADD_MESSAGE ||
          log?.type === INJURY_LOG_TYPES.ADD_ACTION_COMMENT) && (
          <MessageTypeLog message={log?.new?.message || ''} attachmentKeys={log?.new?.attachment_keys} />
        )}
        {log?.type === INJURY_LOG_TYPES.ADD_EMAIL_MESSAGE && (
          <MessageEmailTypeLog message={log?.new?.message || ''} receivers={log?.new?.usersReceived || ''} />
        )}
        {log?.type === INJURY_LOG_TYPES.UPDATE_CASE && <CaseUpdateTypeLog log={log} />}
        {(log?.type === INJURY_LOG_TYPES.CREATE_ACTION ||
          log?.type === INJURY_LOG_TYPES.UPDATE_ACTION ||
          log?.type === INJURY_LOG_TYPES.COMPLETE_ACTION ||
          log?.type === INJURY_LOG_TYPES.REOPEN_ACTION) && (
          <ActionTypeLog
            log={log}
            /* includeNonChanged={false} */
          />
        )}
      {log?.type === INJURY_LOG_TYPES.ADD_OR_UPDATE_CLOSING_DETAILS && (
        <UpdateCompletionTypeLogItem
          log={log}
        />
      )}
      {log?.type === INJURY_LOG_TYPES.UPDATE_SECTION_DETAILS && (
        <SectionUpdateTypeLogItem
          log={log}
        />
      )}
      {log?.type === INJURY_LOG_TYPES.REOPEN_INJURY_REPORT && (
        <SimpleMessageLogItem
          log={log}
        />
      )}
      </Box>
    </Box>
  );
};
