import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { WithChildren } from '../../../../types';

interface MapLocationsPanelProps {
  value: string;
  selectedPanel?: string;
  children?: React.ReactNode;
}

export const MapLocationsPanel = (props: MapLocationsPanelProps) => {
  const { value, selectedPanel, children } = props;

  const visible = value === selectedPanel;
  return (
    <div
      role="tabpanel"
      hidden={!visible}
      id={`MapLocationsPanel-${value}`}
      style={{
        flex: 1,
        display: visible ? 'flex' : 'none',
        flexDirection: 'column',
        overflow: 'hidden',
        minHeight: 0
      }}
    >
      {visible && children}
    </div>
  );
};
