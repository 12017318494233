import React from 'react';
import {
  useGridApiContext,
  useGridSelector,
  gridPageCountSelector,
  gridPageSelector,
  gridFilteredTopLevelRowCountSelector
} from '@mui/x-data-grid';
import Pagination from '@mui/material/Pagination';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TKB_INJURY } from '../../features/injuries/constants/common';

type RDataGridPaginationProps = {
  rowsCount?: number;
};

export const RDataGridPagination = (props: RDataGridPaginationProps) => {
  const { t } = useTranslation();
  const apiRef = useGridApiContext();
  const count = useGridSelector(apiRef, gridFilteredTopLevelRowCountSelector);
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  const from = 25 * page + 1;
  const to = Math.min(from + 24, count || 0);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: count < 25 ? 'flex-end' : 'start',
        width: '100%',
        ...(count < 1 && {
          display: 'none'
        })
        /* pr: 12, */
      }}
    >
      {count < 25 ? (
        <Typography sx={{ flex: 1 }}>Total: {count}</Typography>
      ) : (
        <>
          <Typography sx={{ flex: 1 }}>{`${from}-${to}` + ` ${t(`${TKB_INJURY}.of`)} ` + `${count}`}</Typography>
          <Pagination
            color="primary"
            /* shape='rounded' */
            count={pageCount}
            page={page + 1}
            onChange={(event, value) => apiRef.current.setPage(value - 1)}
          />
        </>
      )}
    </Box>
  );
};
