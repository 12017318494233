import React from 'react';
import Typography from '@mui/material/Typography';
import { WithChildren } from '../../types';

type FormSectionSubHeaderProps = {
  label: string;
};

export const FormSectionSubHeader = ({ children }: WithChildren<{}>) => {
  return (
    <Typography mt={2} variant="h4" className="FormSectionSubHeader">
      {children}
    </Typography>
  );
};
