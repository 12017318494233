import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PinCoordinateMap from './CreateAreaMap';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { selectAreaIds, selectSiteIds, selectSites, areaCreated } from '../../../locations/locationsSlice';
import { Autocomplete, FormControl, Paper, StepButton, Typography } from '@mui/material';
import Box from '@mui/system/Box';
import { Stack } from '@mui/system';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import type { MarkerDragEvent, LngLat } from 'react-map-gl';
import { snackAlertOpened } from '../../../global/controlsSlice';
import { useTranslation } from 'react-i18next';

const steps = [
  {
    label: 'Mark center of location',
    description: 'Click on the map to mark the center location of your site.'
  },
  {
    label: 'Draw outline of site (optional)',
    description: 'Use the draw controls in the upper right corner to draw a polygon for the outline of the site.'
  }
];

interface CreateAreaDialogProps {
  open?: boolean;
  handleClose: () => void;
}

export default function CreateAreaDialog(props: CreateAreaDialogProps) {
  const { open = true, handleClose } = props;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  
  const [basePath, setBasePath] = React.useState<string | null>('Grodem');
  const [baseInputValue, setBaseInputValue] = React.useState('');
  const [coordinates, setCoordinates] = React.useState<{ lat: number; lng: number } | null>(null);
  const [polygon, setPolygon] = React.useState<any>({});
  const [pinPlaced, setPinPlaced] = React.useState(false);
  const [name, setName] = React.useState('');
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setPolygon({});
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleResetPin = () => {
    setCoordinates(null);
  };

  const handleChangeCoordinates = (newCoordinates: { lat: number; lng: number }) => {
    setCoordinates(newCoordinates);
  };

  const handleMarkerDrag = React.useCallback((e: MarkerDragEvent) => {
    setCoordinates({
      lat: e.lngLat.lat,
      lng: e.lngLat.lng
    });
  }, []);

  React.useEffect(() => {
    if (coordinates) {
      setPinPlaced(true);
    } else {
      setPinPlaced(false);
    }
  }, [coordinates]);

  const siteIds = useAppSelector(selectSiteIds);
  const sites = useAppSelector(selectSites);
  const areaIds = useAppSelector(selectAreaIds);

  const base = React.useMemo(() => {
    if (basePath) {
      return sites[basePath];
    }
    return undefined;
  }, [basePath, sites]);

  React.useEffect(() => {
    setPinPlaced(false);
    setCoordinates(null);
  }, [base]);

  React.useEffect(() => {
    console.log(`polygon:\n${JSON.stringify(polygon, null, 2)}`);
  }, [polygon]);

  const [areaCoordinates, setAreaCoordinates] = React.useState([] as number[][]);

  const handleSubmit = () => {
    let site: any = {
      siteId: basePath,
      name,
      coordinates: {
        lat: +coordinates?.lat.toFixed(5),
        lng: +coordinates?.lng.toFixed(5)
      }
    };

    if (Object.keys(polygon).length > 0) {
      const id = Object.keys(polygon)[0];
      const coordinates = polygon[id].geometry.coordinates[0];
      site['areaCoordinates'] = coordinates;
    }
    if (!areaIds.includes(`${basePath}/${name}`)) {
      dispatch(areaCreated(site));
      dispatch(
        snackAlertOpened({
          message: `Area "${name}" created in "${sites[basePath]?.name || basePath}"`,
          severity: 'success'
        })
      );
      handleClose();
    }

    /* dispatch() */
  };

  const handleUpdatePolygon = React.useCallback((e: any) => {
    setPolygon((currFeatures: any) => {
      const newFeatures = { ...currFeatures };
      for (const f of e.features) {
        newFeatures[f.id] = f;
      }
      return newFeatures;
    });
  }, []);

  const handleDeletePolygon = React.useCallback((e: any) => {
    setPolygon((currFeatures: any) => {
      const newFeatures = { ...currFeatures };
      for (const f of e.features) {
        delete newFeatures[f.id];
      }
      return newFeatures;
    });
  }, []);

  return (
    <Dialog fullScreen open={open} onClose={handleClose} maxWidth="lg">
      <DialogTitle>Create site</DialogTitle>
      <DialogContent
        sx={{
          mt: 1.5,
          display: 'flex',
          flexDirection: 'column',
          gap: 2.5,
          px: {
            xs: 0,
            xl: 24
          }
        }}
      >
        <Autocomplete
          value={basePath}
          onChange={(event: any, newValue: string | null) => {
            setBasePath(newValue);
          }}
          inputValue={baseInputValue}
          onInputChange={(event, newInputValue) => {
            setBaseInputValue(newInputValue);
          }}
          id="base-path"
          options={siteIds}
          getOptionLabel={(option) => (sites[option]?.name as any) || option}
          renderInput={(params) => <TextField {...params} label="Base of site" sx={{ mt: 2 }} />}
        />
        <TextField fullWidth label="Name of site" value={name} onChange={(e) => setName(e.target.value)} />
        <Typography variant="body2" sx={{ mb: -1 }}>
          Click on the map to choose site location
        </Typography>
        {base && (
          <Box
            sx={{
              position: 'relative',
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              ...(activeStep !== 1 && {
                '& .mapboxgl-ctrl-group': {
                  display: 'none'
                }
              }),
              ...(Object.keys(polygon).length > 0 && {
                '& .mapbox-gl-draw_polygon': {
                  display: 'none'
                }
              })
            }}
          >
            <PinCoordinateMap
              lat={coordinates?.lat}
              lng={coordinates?.lng}
              handleChangeCoordinates={handleChangeCoordinates}
              base={base}
              pinPlaced={pinPlaced || activeStep === 1}
              onUpdatePolygon={handleUpdatePolygon}
              onDeletePolygon={handleDeletePolygon}
              onMarkerDrag={handleMarkerDrag}
            />
            <Paper
              sx={{
                position: 'absolute',
                top: 8,
                left: 8,
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 1.5,
                maxWidth: '560px'
              }}
            >
              <Stepper activeStep={activeStep} orientation="vertical" nonLinear>
                {steps.map((step, index) => (
                  <Step key={step.label}>
                    <StepButton color="inherit" onClick={() => setActiveStep(index)}>
                      <StepLabel>{step.label}</StepLabel>
                    </StepButton>

                    <StepContent>
                      <Typography>{step.description}</Typography>
                      <Box sx={{ mb: 2 }}>
                        {index === 0 && (
                          <Stack direction="row" gap={1} mt={1}>
                            <Button variant="contained" onClick={handleResetPin} disabled={!coordinates}>
                              Reset placement
                            </Button>
                            <Button variant="contained" color="primary" onClick={handleNext}>
                              Next
                            </Button>
                          </Stack>
                        )}
                        {index === 1 && (
                          <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                            Back
                          </Button>
                        )}
                        <div></div>
                      </Box>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </Paper>
          </Box>
        )}
        {/* {coordinates && (
            <Stack
              direction='row'
              spacing={1}
            >
              <TextField
                size='small'
                id='lat'
                label='Lat'
                value={coordinates.lat}
                sx={{
                  width: 120
                }}
              />
              <TextField
                size='small'
                id='Lng'
                label='Longitude'
                value={coordinates.lng}
                sx={{
                  width: 120
                }}
              />
            </Stack>
          )} */}
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: 'start'
        }}
      >
        <Button color="plain" variant="contained" onClick={handleClose}>
        {t('wif.injury.cancel')}
        </Button>
        <Button color="secondary" disabled={!coordinates} onClick={handleSubmit}>
          Create site
        </Button>
      </DialogActions>
    </Dialog>
  );
}
