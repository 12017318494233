export const asdf = 'asdf';
import React from 'react';
import { useTheme, alpha } from '@mui/material/styles';
import _ from 'lodash';
import { getChartColorByIndex } from '../../../components/Charts/helpers';
import DoughnutChart, { getDefaultDoughnutChartOptionsWithTitle } from '../../../components/Charts/DoughnutChart';
import { useTranslation } from 'react-i18next';

type SDoughnutProps = {
  title?: string;
  subtitle?: string; 
  label?: string; 
  arcs?: {
    key: string;
    values: any[];
    labels?: string[];
    colors?: string[];
  };
  dataSource?: any;
}

export const SDoughnut = (props: SDoughnutProps) => {
  const { title = '', subtitle = '', label = '', arcs, dataSource } = props;
  const theme = useTheme() as any;
  const { t } = useTranslation();
  
  const chartData = React.useMemo(() => {
    const options = getDefaultDoughnutChartOptionsWithTitle({ theme, title: title, subtitle: subtitle });
    let backgroundColor = [];

    let countByValue: any = {};

    for (let i = 0; i < arcs.values.length; i++) {
      const value = arcs.values[i];
      countByValue[value] = 0;
      if (arcs.colors) {
        backgroundColor.push(arcs.colors[i]);
      } else {
        backgroundColor.push(getChartColorByIndex(i));
      }
    }
    for (let value of arcs.values) {
      countByValue[value] = 0;
    }
    for (let o of dataSource) {
      const oKey = o[arcs.key];
      if (oKey) {
        if (arcs.values.includes(oKey)) {
          countByValue[oKey]++;
        }
      }
    }
    
    const chartData = {
      data: {
        labels: arcs?.labels || arcs.values,
        datasets: [
          {
            label: label,
            data: arcs.values.map(value => countByValue[value]), 
            backgroundColor: backgroundColor,
            borderColor: theme.palette.mode === 'light' ? theme.palette.background.paper : theme.palette.backgroundVariant,
            datalabels: {
              anchor: 'center',
              backgroundColor: null,
              borderWidth: 0
            }
          }
        ],
       
      },
      options
    };
    console.log(`chartData: ${JSON.stringify(chartData)}`);
    return chartData;
  }, [dataSource, theme, t]);

  return (
    <DoughnutChart
      data={chartData.data}
      options={chartData.options}
    />
  )
}