import React from 'react';

import {
  HashRouter as Router,
  Routes,
  Link as RouterLink,
  Route,
  Navigate
} from "react-router-dom";import { InjuryReportPage } from '../features/injuries/routes/InjuryReport';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material';
import Link, { LinkProps } from '@mui/material/Link';
import SideNavigation from '../components/Navigation/SideNavigation';
import { Locations } from '../features/locations/routes/Locations';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppDispatch, useAppSelector } from '../store';
import { paletteModeToggled, selectPaletteMode } from '../features/global/controlsSlice';
import { InjuryRegistrationsPage } from '../features/injuries/routes/InjuryRegistrationsPage/InjuryRegistrationsPage';
import { InjuryReportsHandlerPage } from '../features/injuries/routes/InjuryReportsHandlerPage';
import { InjuryReportReporterPage } from '../features/injuries/routes/InjuryReportReporterPage';
import {
  selectAllInjuryReportHandlers,
  selectAllInjuryReportRegistrations
} from '../features/injuries/store/injuryReportsSlice';
import { userRoles } from '../config/constants';
import { Auth } from 'aws-amplify';
import { redirectToCorrectSubDomain } from '../utils/functions';


const AccidentTypeOptions: string[] = [
  'A1 Impact/hit by object',
  'A2 Collision/collision',
  'A3 Overturned',
  'A4 Pinched/trapped',
  'A5 Fall',
  'A6 Stung/cut by a sharp/pointed object',
  'A7 Electrical voltage',
  'A8 High/low temperature',
  'A9 Chemicals',
  'A10 Explosion, explosion, fire',
  'A11 Threats of violence',
  'A12 Inflicted violent damage',
  'A13 Other'
];

const DamageNatureOptions: string[] = [
  'C1 Soft tissue injury without wound (crush injury)',
  'C2 Wound damage',
  'C3 Loss of body part',
  'C4 Sprain, contortion',
  'C5 Bone fracture',
  'C6 Heat damage',
  'C7 Cold injury',
  'C8 Etching',
  'C9 Acute poisoning',
  'C10 Psychological after-effects',
  'C11 Other'
];

const DamagedBodyPartOptions: string[] = [
  'D1 Head',
  'D2 Face',
  'D3 Eye, left',
  'D4 Eye, right',
  'D5 Ear, left',
  'D6 Ear, right',
  'D7 Teeth',
  'D8 Throat/neck',
  'D9 Back',
  'D10 Rib/shoulder blade',
  'D11 Chest/lungs/heart/esophagus',
  'D12 Stomach/pelvis/digestive organs/kidney/urinary tract',
  'D13 Shoulder, left',
  'D14 Shoulder, right',
  'D15 Arm/elbow, left',
  'D16 Arm/elbow, right',
  'D17 Wrist, left',
  'D18 Wrist, right',
  'D19 Hand, left',
  'D20 Hand, right',
  'D21 Fingers, left',
  'D22 Fingers, right',
  'D23 Hip, left',
  'D24 Right hip',
  'D25 Leg w/knee, left',
  'D26 Leg w/knee, right',
  'D27 Ankle, left',
  'D28 Ankle, right',
  'D29 Foot, left',
  'D30 Foot, right',
  'D31 Toe, left',
  'D32 Toe, right',
  'D33 Whole body (general poisoning, general cooling, etc.)'
];

const userClaimId = 'b4cb162c-2f5f-11ee-be56-0242ac120002';

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

type adminModuleType = {
  critical: boolean;
  documentHandling: boolean;
  fall: boolean;
  injuryEmployee: boolean;
  injuryStudent: boolean;
  manuals: boolean;
  nonconformity: boolean;
  privacypolicy: boolean;
  scheduler: boolean;
  weblink: boolean;
};

function LinkRouter(props: LinkRouterProps) {
  return <Link {...props} component={RouterLink as any} />;
}
export default function AppRouter() {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const paletteMode = useAppSelector(selectPaletteMode);
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [current, setCurrent] = React.useState<boolean>(false);
  const [adminModules, setAdminModules] = React.useState<adminModuleType>(undefined);
  const [userClaim, setUserClaim] = React.useState<boolean>(false);
  const [userRole, setUserRole] = React.useState<string>(userRoles.user);

  React.useEffect(() => {
    if (prefersDarkMode && paletteMode === 'light') {
      dispatch(paletteModeToggled());
    } else if (!prefersDarkMode && paletteMode === 'dark') {
      dispatch(paletteModeToggled());
    }
  }, [prefersDarkMode]);

 
  React.useEffect(() => {
    Auth.currentAuthenticatedUser().then(()=>{
      const ngStorageCurrent = localStorage.getItem('ngStorage-current');

      if(ngStorageCurrent){
        const current = JSON.parse(ngStorageCurrent);
        //set permitted modules by super admin
        if (current && current.organization) setAdminModules(current.organization.adminModules);
        if (
          current &&
          current.user &&
          current.user.permissions &&
          current.user.permissions.claims &&
          current.user.permissions.claims.length
        )
          current.user.permissions.claims.map((claim) => {
            if (claim.claim_id === userClaimId) setUserClaim(true);
          });
        if (current && current.user && current.user.role) setUserRole(current.user.role);
        setCurrent(true);
      }else{
        redirectToCorrectSubDomain();
      }
    }).catch((err)=>{
      window.location.href='/#/auth/login';
    })
  }, []);

  const injuryHandlerReports = useAppSelector(selectAllInjuryReportHandlers);

  const DefaultRoute: React.FC = () => {
    if (adminModules && adminModules.injuryEmployee && adminModules.injuryStudent)
      if (injuryHandlerReports.length) return <Navigate to="/injuries/all/handling" replace />;
      else return <InjuryRegistrationsPage />;
    else if (adminModules && !adminModules.injuryEmployee && adminModules.injuryStudent)
      if (injuryHandlerReports.length) return <Navigate to="/injuries/student/handling" replace />;
      else return <InjuryRegistrationsPage category="student" />;
    else if (adminModules && adminModules.injuryEmployee && !adminModules.injuryStudent)
      if (injuryHandlerReports.length) return <Navigate to="/injuries/employee/handling" replace />;
      else return <InjuryRegistrationsPage category="employee" />;
    else return <React.Fragment></React.Fragment>;
  };

  if (adminModules && !adminModules.injuryEmployee && !adminModules.injuryStudent)
    return (
      <Box
        sx={{
          width: '100%',
          flex: 1,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          bgcolor: 'background.default'
        }}
      >
        <Router basename="/fm">
          <SideNavigation>
            <Routes>
              <Route path="injuries">
                <Route index element={<InjuryReportsHandlerPage category="no_access" />} />
              </Route>
            </Routes>
          </SideNavigation>
        </Router>
      </Box>
    );
  return (
    <Box
      sx={{
        width: '100%',
        flex: 1,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        bgcolor: 'background.default'
      }}
    >
      <Router basename="/fm">
        {current && (
          <SideNavigation>
            <Routes>
              <Route path="injuries">
                {adminModules && adminModules.injuryEmployee && adminModules.injuryStudent && (
                  <Route path="all">
                    <Route path="handling">
                      <Route path=":injuryReportId" element={<InjuryReportPage />} />
                      <Route index element={<InjuryReportsHandlerPage />} />
                    </Route>
                    <Route path="registrations">
                      <Route path=":injuryReportId" element={<InjuryReportReporterPage />} />
                      <Route index element={<InjuryRegistrationsPage />} />
                    </Route>
                    <Route index element={<Navigate to="/injuries/all/handling" replace />} />
                  </Route>
                )}
                {adminModules && adminModules.injuryEmployee && (
                  <Route path="employee">
                    <Route path="handling">
                      <Route path=":injuryReportId" element={<InjuryReportPage category="employee" />} />
                      <Route index element={<InjuryReportsHandlerPage category="employee" />} />
                    </Route>
                    <Route path="registrations">
                      <Route path=":injuryReportId" element={<InjuryReportReporterPage category="employee" />} />
                      <Route index element={<InjuryRegistrationsPage category="employee" />} />
                    </Route>
                    <Route index element={<Navigate to="/injuries/employee/handling" replace />} />
                  </Route>
                )}
                {adminModules && adminModules.injuryStudent && (
                  <Route path="student">
                    <Route path="handling">
                      <Route path=":injuryReportId" element={<InjuryReportPage category="student" />} />
                      <Route index element={<InjuryReportsHandlerPage category="student" />} />
                    </Route>
                    <Route path="registrations">
                      <Route path=":injuryReportId" element={<InjuryReportReporterPage category="student" />} />
                      <Route index element={<InjuryRegistrationsPage category="student" />} />
                    </Route>
                    <Route index element={<Navigate to="/injuries/student/handling" replace />} />
                  </Route>
                )}
                {injuryHandlerReports.length &&
                  adminModules &&
                  adminModules.injuryEmployee &&
                  adminModules.injuryStudent && (
                    <Route index element={<Navigate to="/injuries/all/handling" replace />} />
                  )}
                {!injuryHandlerReports.length &&
                  adminModules &&
                  adminModules.injuryEmployee &&
                  adminModules.injuryStudent && (
                    <Route index element={<Navigate to="/injuries/all/registrations" replace />} />
                  )}
                {injuryHandlerReports.length && adminModules && adminModules.injuryStudent && (
                  <Route index element={<Navigate to="/injuries/student/handling" replace />} />
                )}
                {!injuryHandlerReports.length && adminModules && adminModules.injuryStudent && (
                  <Route index element={<Navigate to="/injuries/student/registrations" replace />} />
                )}
                {injuryHandlerReports.length && adminModules && adminModules.injuryEmployee && (
                  <Route index element={<Navigate to="/injuries/employee/handling" replace />} />
                )}
                {!injuryHandlerReports.length && adminModules && adminModules.injuryEmployee && (
                  <Route index element={<Navigate to="/injuries/employee/registrations" replace />} />
                )}
              </Route>
              <Route path="sites">
                <Route index element={<Locations />} />
              </Route>

              <Route path="*" element={<DefaultRoute />} />
            </Routes>
          </SideNavigation>
        )}
      </Router>
    </Box>
  );
}