import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Title,
  Tooltip,
  Legend,
);

export const getDefaultLineChartOptions = (theme: any) => {
  return {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        border: {
          display: false
        },
        grid: {
          display: false,
          color: theme.palette.divider,
          borderColor: theme.palette.divider,
        },
        ticks: {
          color: theme.palette.text.secondary,
          font: {
            family: theme.typography.body2.fontFamily,
            size: 13,
            lineHeight: theme.typography.body2.lineHeight,
          },
          precision: 0,
        },
      },
      y: {
        min: 0,
        grid: {
          color: theme.palette.divider,
          borderColor: theme.palette.divider,
          borderDash: [3, 3],
        },
        ticks: {
          color: theme.palette.text.secondary,
          maxTicksLimit: 6,
          font: {
            family: theme.typography.body2.fontFamily,
            size: 13,
            lineHeight: theme.typography.body2.lineHeight,
          },
          precision: 0,
        },
      }
    },
    plugins: {
      tooltip: {
        intersect: false
      },
      legend: {
        position: 'top' as const,
        align: 'end',
        labels: {
          boxWidth: 24,
          pointStyle: 'line',
          color: theme.palette.text.primary,
          font: {
            size: 13,
            family: 'Inter',
            opacity: 1
          }
        },
      }
    },
  } as any
}

export const defaultOptions = {
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Chart.js Line Chart',
    },
  },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

interface LineChartProps {
  options?: any;
  data?: any;
}

export default function LineChart(props: LineChartProps) {
  const { options = defaultOptions, data } = props;
  return <Line options={options} data={data} />;
}
