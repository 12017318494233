import { SamComment } from "../../types";
import { IActionCompletion } from "./components/CompleteActionDialog";



export type ActionsQueryFilters = {
  'overdue': boolean,
  'completed': boolean,
  'pending': boolean,
  'checklist': boolean,
  'fall': boolean,
  'nonconformity': boolean,
  'injury': boolean,
  'unassigned': boolean,
  'department': any[],
  'assigneeIds': any[],
  'selectedTemplate': any,
  'fromDate': any,
  'toDate': any
};

export const getDefaultActionsQueryFilters = () => {
  const to = new Date();
  const from = new Date();
  from.setFullYear(to.getFullYear() - 1);
  return {
    'overdue': false,
    'completed': false,
    'pending': false,
    'checklist': false,
    'fall': false,
    'injury': true,
    'nonconformity': false,
    'unassigned': false,
    'department': [],
    'assigneeIds': [],
    'selectedTemplate': null,
    'fromDate': null,
    'toDate': null,
  };
};

export type FetchActionsQuery = {
  startIndex: number;
  count: number;
  body: ActionsQueryFilters;
}

export type IActionCompletionAndEvaluation = {
  eva
};

export type IAction = {
  account_id: string;
  text?: string;
  members?: string[];
  assignee?: string[];
  type?: string;
  action_id?: string;
  is_completed?: boolean;
  created_at?: string;
  updated_at?: string;
  status?: string;
  actionDescription?: string;
  relatedCategory?: string;
  relatedCause?: string;
  measureType?: string;
  rep_id?: string;
  deadline?: any;
  comments?: SamComment[];
  completionAndEvaluation?: IActionCompletion;
  report_id: string;
};