import { useTranslation } from 'react-i18next';
import { Text, View, Svg, Path, G, Rect } from '@react-pdf/renderer';
import { DocColors } from './InjuryDocument';
import { formatDateFromTimestamp } from '../../../../utils/format';
import { DocSpace } from './DocSpacing';
import { DocListDescriptionItem } from './DocListDescriptionItem';
import { Box, alpha } from "@mui/system";
import { TKB_INJURY } from '../../constants';

type DocActionProps = {
  action?: any;
}

export const DocAction = (props: DocActionProps) => {
  const { action } = props;

  const { t } = useTranslation();

  return (
    <View 
      wrap={false}
      style={{
        backgroundColor: DocColors.background.level1,
        border: `1px solid ${DocColors.divider}`,
        borderRadius: 6,
        overflow: 'hidden',
        width: '100%',
        marginBottom: 12,
      }}
    >
      <View
        style={{
          paddingVertical: 8,
          paddingHorizontal: 12,
          display: 'flex', 
          flexDirection: 'row',
          alignItems: 'center', 
          justifyContent: 'flex-start',
          gap: 8,
          ...(action?.status === 'completed' && {
            backgroundColor: alpha(DocColors.success, 0.02),
          }),
          ...(action?.status === 'overdue' && {
            backgroundColor: alpha(DocColors.error, 0.02),
          }),
        }}
      >
        <Text
          style={{
            fontSize: 10,
            fontWeight: 400,
            flex: 1,
            textAlign: 'left',
            ...(action.status === 'overdue' && {
              color: DocColors.error,
            }),
            ...(action.status === 'completed' && {
              color: DocColors.success,
            }),
            
          }}
        >
          {action?.status === 'overdue' ? 'Overdue' : action?.status === 'completed' ? t(`${TKB_INJURY}.statuses.completed`) : t(`${TKB_INJURY}.statuses.pending`)}
        </Text>
       
        {action?.account && (
          <View
            style={{
              display: 'flex', 
              flexDirection: 'row', 
              alignItems: 'center', 
              gap: 4
            }}
          >
             <Svg
                height="18px" viewBox="0 0 20 20"
                width="18px"
              >
                <G>
                  <Rect height="20" width="20"/>
                </G>
                <G>
                  <G>
                    <Path
                      d="M10 5.5c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3zm0 4.5c-.83 0-1.5-.67-1.5-1.5S9.17 7 10 7s1.5.67 1.5 1.5S10.83 10 10 10z"
                      fill={DocColors.textVariant}
                    />
                    <Path 
                      d="M10 2c-4.42 0-8 3.58-8 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8zm0 14.5c-1.49 0-2.86-.51-3.96-1.36C7.19 14.42 8.55 14 10 14s2.81.42 3.96 1.14c-1.1.85-2.47 1.36-3.96 1.36zm5.07-2.44c-1.44-.99-3.19-1.56-5.07-1.56s-3.62.58-5.07 1.56C4.04 12.95 3.5 11.54 3.5 10c0-3.58 2.92-6.5 6.5-6.5s6.5 2.92 6.5 6.5c0 1.54-.54 2.95-1.43 4.06z"
                      fill={DocColors.textVariant}
                    />
                  </G>
                </G>
              </Svg>
            <Text
              style={{
                fontSize: 10,
                fontWeight: 400,
                textAlign: 'left'
              }}
            >
              {action?.account}
            </Text>

          </View>
        )}
        {action?.deadline && (
          <View
            style={{
              display: 'flex', 
              flexDirection: 'row', 
              alignItems: 'center', 
              gap: 4,
              ...(action?.status === 'overdue' && {
                color: DocColors.error
              })
            }}
          >
            <Svg
              height="18px"
              width="18px"
              viewBox="0 0 20 20"
            >
              <G>
                <Rect
                  height="20"
                  width="20"
                  x="0"
                />
              </G>
              <G>
                <Path
                  d="M15.5,4H14V2h-1.5v2h-5V2H6v2H4.5C3.67,4,3,4.68,3,5.5v11C3,17.32,3.67,18,4.5,18h11c0.83,0,1.5-0.68,1.5-1.5v-11 C17,4.68,16.33,4,15.5,4z M15.5,16.5h-11V9h11V16.5z M15.5,7.5h-11v-2h11V7.5z M7.5,12H6v-1.5h1.5V12z M10.75,12h-1.5v-1.5h1.5V12z M14,12h-1.5v-1.5H14V12z M7.5,15H6v-1.5h1.5V15z M10.75,15h-1.5v-1.5h1.5V15z M14,15h-1.5v-1.5H14V15z"
                  fill={action?.status === 'overdue' ? DocColors.error : DocColors.textVariant}
                />
              </G>
            </Svg>
            <Text
              style={{
                fontSize: 10,
                fontWeight: 400,
                textAlign: 'left'
              }}
            >
              {formatDateFromTimestamp(action.deadline)}
            </Text>
          </View>
          
        )}
      </View>
      <View 
        style={{
          paddingVertical: 13,
          paddingBottom: 14,
          paddingHorizontal: 12,
          display: 'flex',
          flexDirection: 'column',
          gap: 8,
          alignItems: 'flex-start', 
          justifyContent: 'center',
          borderTop: `1px solid ${DocColors.divider}`,
          /* borderBottom: `1px solid ${DocColors.divider}`, */
        }}
      >
        <Text
          style={{
            fontSize: 12,
            fontWeight: 600,
            color: DocColors.text.primary,
          }}
        >
          {action?.text}
        </Text>
        <Text
          style={{
            fontSize: 10,
            fontWeight: 400,
            color: DocColors.text.primary,
          }}
        >
          {action?.actionDescription}
        </Text>
        {action?.members && action?.members.length > 0 && (
          <Text
            style={{
              fontSize: 10, 
              fontWeight: 400,
            }}
          >
            {t(`${TKB_INJURY}.members`)}: {action?.members.join(', ')}
          </Text>
        )}
        
        {action?.completionAndEvaluation && (
            <View>
              <DocSpace spacing={1.5} />
              <Text
                style={{
                  fontSize: 11,
                  fontWeight: 400,
                  marginBottom: 8,
                  color: DocColors.text.secondary,
                }}
              >
                {t(`${TKB_INJURY}.completionDetails`)}
              </Text>
              {action?.completionAndEvaluation?.evaluation && (
              <DocListDescriptionItem
                disableHorizontalPadding
                disableVerticalPadding
                bottomDivider={false}
                field={t(`${TKB_INJURY}.evaluation`)}
                value={action?.completionAndEvaluation?.evaluation}
              />
            )}
            {action?.completionAndEvaluation?.workDone && (
              <DocListDescriptionItem
                disableHorizontalPadding
                disableVerticalPadding  
                field={t(`${TKB_INJURY}.workDone`)}
                bottomDivider={false}
                value={action?.completionAndEvaluation?.workDone}
              />
            )}
          </View>
        )}
      </View>
    </View>
  )
}

type DocGridContainerProps = {
  children: any;
};

export const DocGridRow = (props: DocGridContainerProps) => {
  const { children } = props;
  return (
    <View
      style={{
        display: 'flex', 
        flexDirection: 'row',
        alignItems: 'flex-start', 
        width: '100%',
        paddingVertical: 12
      }}
    >
      {children}
    </View>
  );
}

type DocGridDescriptionItemProps = {
  field: string; 
  value: string; 
}

export const DocGridDescriptionItem = (props: DocGridDescriptionItemProps) => {
  const { field, value } = props;
  return (
    <View 
      style={{
        display: 'flex', 
        flexDirection: 'column',
        gap: 3,
        flex: 1,
      }}
    >
      <Text
        style={{
          fontSize: 10, 
          color: DocColors.text.primary, 
          fontWeight: 500,
          maxWidth: '90%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {field}
      </Text>
      <Text
        style={{
          fontSize: 10,
          color: DocColors.text.secondary, 
          fontWeight: 400,
          maxWidth: '90%',
        }}
      >
        {value}
      </Text>
    </View>
  );
}