import React, { useEffect, useMemo } from 'react';
import { Divider, List, ListItem, ListItemIcon, ListItemText, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { AppIcon } from '../../../components/Elements';
import { REPORT_TYPES } from '../../injuries/constants';
import { ReportsCountStatusColors, ReportTypeIconNames } from '../../injuries/constants/mappedProps';

type RegistrationTypeItem = {
  count: number;
  status: number;
}

export type PopupCardProps = {
  label: string;
  reportsCount: {
    'total'?: number;
    [REPORT_TYPES.ACCIDENT]?: number;
    [REPORT_TYPES.ILLNESS]?: number;
    [REPORT_TYPES.MINOR_INJURY]?: number;
  };
  reportsCountStatus: {
    'total'?: number;
    [REPORT_TYPES.ACCIDENT]?: number;
    [REPORT_TYPES.ILLNESS]?: number;
    [REPORT_TYPES.MINOR_INJURY]?: number;
  };
}

export const PopupCard = (props: PopupCardProps) => {
  const { label, reportsCount, reportsCountStatus } = props;

  const reportsCountItems = React.useMemo(() => {
    return Object.keys(reportsCount).filter((type: any) => type !== 'total').map((type: any) => ({
      type: type,
      count: reportsCount[type as keyof typeof reportsCount] || 0,
      status: reportsCountStatus[type as keyof typeof reportsCount] || 0, 
    }));
  }, [reportsCount, reportsCountStatus]);
 
  return (
    <Paper
      elevation={1}
      sx={{
        pt: 1.5,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <List dense disablePadding
        sx={{ 
          /* bgcolor: theme.palette.background.default, */
          pb: 1.5, 
          px: 2,
        }}
      >

        {reportsCountItems.map((item, index) => (
          <ListItem
            disableGutters
            key={item.type}
          >
            <ListItemIcon sx={{ width: '28px', minWidth: 0 }}>
              <AppIcon sx={{ ml: -0.25 }} iconName={ReportTypeIconNames[item.type]} color='text.secondary' />
            </ListItemIcon>
            <Typography variant='body2' sx={{ color: 'text.secondary', flex: 1, mr: 3 }}>{item.type}</Typography>
            <Typography variant='body2' sx={{ color: ReportsCountStatusColors[item.status], mr: 1 }}>{item.count}</Typography>
          </ListItem>
        ))}
      </List>
      <Divider />
      <Typography variant='h6' sx={{ py: 2, px: 2, fontWeight: 600 }}>{label}</Typography>
    </Paper>
  );
}