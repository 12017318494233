import React from 'react';
import * as yup from 'yup';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { AppDialogTitle } from '../../../components/Display/AppDialogTitle';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { FormDatePicker, FormTextField } from '../../../components/FormInputs';
import { Box, CircularProgress, List, ListItem, ListItemText, Stack, Tab, Tabs } from '@mui/material';
import { RootState, useAppDispatch, useAppSelector } from '../../../store';
import { selectAllEmployees, selectEmployeeIds, selectEmployees } from '../../employees/employeesSlice';
import { FormAutocomplete } from '../../../components/FormInputs/FormAutocomplete';
import _, { set } from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import { snackAlertOpened } from '../../global/controlsSlice';
import { WithChildren } from '../../../types';
import { useCurrentInjuryReport } from '../../injuries/routes/CurrentInjuryReportContext';
import { makeSelectInjuryReportById, updateInjuryReportAction } from '../../injuries/store/injuryReportsSlice';
import { LoadingButtonContainer } from '../../../components/Elements/LoadingButtonContainer';
import { IAction } from '../actionTypes';
// import { AppMessage } from '../../injuries/components/ReportDetails/CasePanels/Comments';
import { BOX_SHADOWS } from '../../../config/theme/boxShadows';
import { TabsContainer } from '../../../components/Layout/AppTabs';
import { APP_COLORS } from '../../../config';
import { TKB_INJURY } from '../../injuries/constants';
import { useTranslation } from 'react-i18next';

interface ActionCompletionDialogProps {
  action?: IAction;
  onClose: () => void;
  open?: boolean;
}

export default function ActionCompletionDialog(props: ActionCompletionDialogProps) {
  const { action, onClose, open = false } = props;

  const selectInjuryReportById = React.useMemo(makeSelectInjuryReportById, [action.report_id]);
  const injuryReport = useAppSelector((state: RootState) => selectInjuryReportById(state, action.report_id));

  const dispatch = useAppDispatch();

  const [loading, setLoading] = React.useState(false);
  const [tab, setTab] = React.useState('completionDetails');

  const { t } = useTranslation();
  
  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <AppDialogTitle id="create-injury-action-dialog-title" onClose={onClose}>
        {`${action?.text ? action.text : action?.action_id}: ${t(`${TKB_INJURY}.completionEvaluation`)}`}
      </AppDialogTitle>
      {/* <TabsContainer 
        px={2.5}
        sx={{
          borderBottom: 0,
          bgcolor: 'primary.main'
        }}
      >
        <Tabs
          value={tab}
          onChange={handleChangeTab}
          sx={{
            '& .MuiTab-root': {
              color: (theme) => theme.palette.mode === 'light' ? APP_COLORS.darkTextSecondary : APP_COLORS.textSecondary,
              '&.Mui-selected': {
                color: (theme) => theme.palette.secondary.main,
                fontWeight: '600!important'
              }, 
            },
            '& .MuiTabs-indicator': {
              bgcolor: (theme) => theme.palette.secondary.main,
            },
          }}
        >
          <Tab 
            value={'completionDetails'}
            label={'Completion details'} 
          />
          <Tab 
            value={'history'}
            label={'History'} 
          />
        </Tabs>
      </TabsContainer> */}
      <DialogContent
        dividers
        sx={{
          width: 600
        }}
      >
        <List
          disablePadding
          sx={{
            p: 0,
            mb: 1.5
          }}
        >
          {action?.completionAndEvaluation?.evaluation && (
            <ListItem disablePadding>
              <ListItemText primary={t(`${TKB_INJURY}.evaluation`)} secondary={action?.completionAndEvaluation?.evaluation} />
            </ListItem>
          )}
          {action?.completionAndEvaluation?.workDone && (
            <ListItem disablePadding>
              <ListItemText primary={t(`${TKB_INJURY}.workDone`)} secondary={action?.completionAndEvaluation?.workDone} />
            </ListItem>
          )}
        </List>
      </DialogContent>
    </Dialog>
  );
}
