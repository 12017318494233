import * as yup from 'yup';
import { FormSelect, FormTextField } from '../../../../components/FormInputs';
import { WithFormMethods } from '../../../../components/Forms';
import { useAppSelector } from '../../../../store';
import { selectAllNonconformityReports, selectNonconformityReportEntities, selectNonconformityReportIds } from '../../../nonconformity/nonconformitySlice';
import { FormSingleAutocomplete } from '../../../../components/FormInputs/FormSingleAutocomplete';
import _, { filter } from 'lodash';
import { getTranslationKeyForSectionFieldLabel } from '../../helpers/dataMapping';
import { STUDENT_SECTION_NAMES } from '../../constants/student';
import { useTranslation } from 'react-i18next';

export const baseFurtherInformationSchema = yup.object({
  // handlingDepartment: yup.string().required(),
  description: yup.string().required('Should be up to 1275 characters long').max(1275, 'max 1275'),
  ncReportId: yup.string().optional(),
});

export type BaseFurtherInformationSection = yup.InferType<typeof baseFurtherInformationSchema>;

export const BaseFurtherInformationControls = (props: WithFormMethods<BaseFurtherInformationSection>) => {
  const { methods } = props;
  const { control, watch } = methods;
  const { t } = useTranslation();
  const ncReports = useAppSelector(selectAllNonconformityReports);
  const ncReportIds = useAppSelector(selectNonconformityReportIds);
  const ncReportEntities = useAppSelector(selectNonconformityReportEntities);
  let ncNames: any = _.mapValues(ncReportEntities, (ncr) => ncr.name);

  return (
    <>
      <FormTextField
        control={control}
        name='description'
        label={t(getTranslationKeyForSectionFieldLabel(STUDENT_SECTION_NAMES.FURTHER_INFORMATION, 'description'))}
        multiline
        minRows={3}
        maxRows={6}
      />
      <FormSingleAutocomplete
        control={control}
        name='ncReportId'
        label={t(getTranslationKeyForSectionFieldLabel(STUDENT_SECTION_NAMES.FURTHER_INFORMATION, 'ncReportId'))}
        options={ncReportIds as string[]}
        optionLabels={ncNames}
      />
    </>
  )
}