import mapboxgl from "mapbox-gl";
import { useRef } from 'react';
import MapGL, { Layer, Marker, Source } from 'react-map-gl';
import mapMarkerImg from '../../../../assets/mapmarker.svg'
import { useAppSelector } from "../../../../store";
import { NewSite, selectAllAreas, selectAllSites, Site } from "../../../locations/locationsSlice";
import { MAPBOX_TOKEN } from "../Map";
import React from 'react';
import { alpha } from '@mui/material/styles';
import centerOfMass from "@turf/center-of-mass";
import buffer from "@turf/buffer";
import bbox from "@turf/bbox";
import { point } from "@turf/helpers";
import '../map.css';
import { baseClusterCountLayer, baseClusterLayer, baseSiteLayer } from "../CreateAreaMap/siteLayers";
import { APP_COLORS } from "../../../../config";
import DrawControl from "./DrawControls";
import { Box } from "@mui/system";
import { Button, Paper } from "@mui/material";

const mapStyles: any = {
  width: '100%', 
  height: '100%', 
  position: 'relative',
  minHeight: '320px',
  minWidth: '480px',
  flex: 1,
}

interface PinCoordinateMapProps {
  lat?: number;
  lng?: number;
  handleChangeCoordinates: (newCoordinates: { lat: number, lng: number }) => void;
  handleChangeAreaCoordinates?: (newCoordinates: number[][]) => void;
  pinPlaced?: boolean;
  base: NewSite;
  onUpdatePolygon: any; 
  onDeletePolygon: any;
  onMarkerDrag: any;
}

export default function PinCoordinateMap(props: PinCoordinateMapProps) {
  const { lat, lng, handleChangeCoordinates, pinPlaced = false, base, onUpdatePolygon, onDeletePolygon, onMarkerDrag } = props;

  const areas = useAppSelector(selectAllAreas);

  const baseSiteFeatures = React.useMemo(() => { 
    return areas.filter((area) => area.site === base.id).map((site) => ({
      type: 'Feature',
      properties: {
        id: `${site.site}/${site.name}`,
        name: site.name,
        iconImage: site.areaCoordinates ? '' : 'pin-2',
        showIcon: site.areaCoordinates ? false : true,
      },
      geometry: {
        type: 'Point',
        coordinates: [site.coordinates.lng, site.coordinates.lat]
      },
      id: `${site.site}/${site.name}`,
    } as GeoJSON.Feature<GeoJSON.Point>))
  }, [base, areas]);

  const siteAreaFeatures = React.useMemo(() => {
    return areas.filter((site) => site.site === base.id && site.areaCoordinates).map((site) => ({
      type: 'Feature',
      properties: {
        id: `${site.site}/${site.name}-area`,
        siteId: `${site.site}/${site.name}`,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [site.areaCoordinates]
      },
      id: `${site.site}/${site.name}-area`,
    } as GeoJSON.Feature<GeoJSON.Polygon>))
  }, [base, areas]);

  const baseSiteFeatureCollection = React.useMemo(() => ({
    type: 'FeatureCollection', 
    features: [...baseSiteFeatures]
  }) as GeoJSON.FeatureCollection<GeoJSON.Point>, [baseSiteFeatures]);

  const siteAreaFeatureCollection = React.useMemo(() => ({
    type: 'FeatureCollection', 
    features: [...siteAreaFeatures]
  }) as GeoJSON.FeatureCollection<GeoJSON.Polygon>, [siteAreaFeatures]);

  const mapRef = useRef<any>(null);

  const onClick = (event: any) => {
    if (!pinPlaced) {
      if (event.lngLat) {
        let newCoordinates = { lat: event.lngLat.lat, lng: event.lngLat.lng };
        handleChangeCoordinates(newCoordinates);
      }
    }
  }

  React.useEffect(() => {
    console.log(`baseSiteFeatureCollection: ${JSON.stringify(baseSiteFeatureCollection)}`);
  }, [baseSiteFeatureCollection]);

  React.useEffect(() => {
    mapRef.current?.flyTo({ center: [base.coordinates.lng, base.coordinates.lat], zoom: 16, duration: 2000 });
  }, [base]);

  // POLY


  const [drawFeatures, setDrawFeatures] = React.useState<any>({});
  const [cursor, setCursor] = React.useState('auto');

  React.useEffect(() => {
    if (pinPlaced) {
      setCursor('auto');
    } else {
      setCursor(`url(${mapMarkerImg}) 20 20, auto`)
    }
  }, [pinPlaced]);

  const onCreate = React.useCallback((e: any) => {
    setDrawFeatures((e.features))
  }, []);

  const onUpdate = React.useCallback((e: any) => {
    setDrawFeatures((currFeatures: any) => {
      const newFeatures = {...currFeatures};
      for (const f of e.features) {
        newFeatures[f.id] = f;
      }
      return newFeatures;
    });
  }, []);

  const onDelete = React.useCallback((e: any) => {
    setDrawFeatures((currFeatures: any) => {
      const newFeatures = {...currFeatures};
      for (const f of e.features) {
        delete newFeatures[f.id];
      }
      return newFeatures;
    });
  }, []);

  

  React.useEffect(() => {
    if (drawFeatures) {
      console.log(`drawFeatures: ${JSON.stringify(drawFeatures)}`);
    }
  }, [drawFeatures]);

  const handleFitAreaBounds = () => {
    if (mapRef?.current && Object.keys(drawFeatures).length > 0) {
      const polygon = drawFeatures[Object.keys(drawFeatures)[0]].geometry.coordinates;
      const center = centerOfMass(drawFeatures[Object.keys(drawFeatures)[0]]);
      mapRef?.current?.flyTo({ center: center.geometry.coordinates, zoom: 16, duration: 2000 });
    }
  }

  const baseMaxBounds = React.useCallback(() => {
    const areaPoint = point([base.coordinates.lng, base.coordinates.lat]);
    const buffered = buffer(areaPoint, 1, { units: 'kilometers'});
    const baseArea = bbox(buffered);
    console.log(`baseArea: ${JSON.stringify(baseArea)}`);
    return baseArea as any;
  }, [base]);

  return (
    <Paper 
      variant='outlined'
      sx={{
        position: 'relative',
        flex: 1,
        overflow: 'hidden',
        '.mapbox-gl-draw_polygon': {
          display: Object.keys(drawFeatures).length > 0 ? 'none' : 'block',
        },
      }}
    >
      <MapGL
        maxBounds={baseMaxBounds()}
        initialViewState={{
          latitude: base.coordinates.lat,
          longitude: base.coordinates.lng,
          zoom: 17,
        }}
        style={mapStyles}
        ref={mapRef}
        cursor={cursor}
        maxPitch={85}
        onClick={onClick}
        mapStyle={'mapbox://styles/emkf/clf8kdbr8001901mwpja2vrdi'}
        mapboxAccessToken={MAPBOX_TOKEN}
      >
      <DrawControl
        position='top-right'
        displayControlsDefault={false}
        controls={{
          polygon: true,
          trash: true
        }}
        onCreate={onUpdatePolygon}
        onUpdate={onUpdatePolygon}
        onDelete={onDeletePolygon}
      />
      <Source
        id='main'
        type='geojson'
        data={baseSiteFeatureCollection}
        cluster={true}
      >
        <Layer 
          id='main-sites'
          type='symbol'
          layout={{
            'icon-size': 0.6,
            'icon-allow-overlap': true,
            'text-allow-overlap': true,
            'text-field': ['get', 'name'],  
            'text-font': ['Inter Tight Medium'],
            'text-max-width': 15,
            'text-size': 13,
            'icon-image': ['get', 'iconImage'], 
            'text-offset': [
              'case',
              ['==', ['get', 'showIcon'], 0],
              ['literal', [0, 0]],
              ['literal', [0, 1.7]],
            ]
          }}
          paint={{
            'text-color': '#333', 
          }}
        />
        <Layer {...baseClusterLayer} />
        <Layer {...baseClusterCountLayer} />
      </Source>
      <Source
        id='site-areas'
        type='geojson'
        data={siteAreaFeatureCollection}
      >
        <Layer 
          id='site-fill'
          beforeId='main-sites'
          type='fill'
          paint={{
            'fill-color': alpha(APP_COLORS.primary, 0.16),
          }}
        />
        <Layer 
          id='site-outlines'
          beforeId='site-fill'
          type='line'
          paint={{
            'line-color': APP_COLORS.primary,
            'line-width': 3,
          }}
          /* filter={['==', ['get', 'type'], 'outline']} */
        />
      </Source>
      {lat && lng && (
        <Marker
          longitude={lng}
          latitude={lat}
          draggable
          onDrag={onMarkerDrag}
        >
          <img src={mapMarkerImg} />
        </Marker>
      )}
    </MapGL>
    </Paper>
    
  )
}