import { Checkbox, FormControlLabel, FormHelperText } from '@mui/material';
import { useFormContext, type Control, type FieldPath, Controller } from 'react-hook-form';
import type { FieldValues } from 'react-hook-form/dist/types';

type CombinedCheckboxProps = {
    label?: any;
    direction?: 'top' | 'start' | 'bottom' | 'end';
    defaultValue: boolean;
    disabled?: boolean;
    size: any;
}

type Props<
    TFieldValues extends FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = CombinedCheckboxProps & {
    control?: Control<TFieldValues, any>;
    name: TName;
    controllerProps?: any;
};

export default function FormCheckbox<TFieldValues extends FieldValues>(props: Props<TFieldValues>) {
    const {
        control = useFormContext<TFieldValues>().control,
        name,
        controllerProps,
        defaultValue = false,
        label,
        disabled = false,
        direction = 'end',
        size
    } = props;

    return (
        <Controller
            control={control}
            name={name}
            {...controllerProps}
            render={({ field, fieldState: { error } }) => (
                <>
                    <FormControlLabel
                        id={`${name}-label`}
                        label={label}
                        disabled={disabled}
                        sx={{ '& .MuiSvgIcon-root': { fontSize: size } }}
                        labelPlacement={direction}
                        control={
                            <Checkbox
                                checked={field.value}
                                onChange={(e) => field.onChange(e.target.checked)}
                            />
                        }
                    />
                    <FormHelperText>{error?.message}</FormHelperText>
                </>
            )}
        />
    )
}
