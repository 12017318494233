import React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { AppIcon } from '../../../../components/Elements';
import {
  Card,
  CardContent,
  CardHeader,
  Drawer,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { APP_COLORS } from '../../../../config';
import { BOX_SHADOWS } from '../../../../config/theme/boxShadows';
import { Box } from '@mui/system';
import { LocationsPanelToggleGroup } from './LocationsPanelToggleGroup';
import { LocationsControllerToolbar } from './LocationsControllerToolbar';
import { MapLocationsPanel } from './MapLocationsPanel';
import { BaseLocationList } from '../BaseLocationList/BaseLocationList';
import { SiteList } from '../SiteList/SiteList';

export const MapLocationsControl = () => {
  const [selectedPanel, setSelectedPanel] = React.useState<undefined | string>();

  const handleClickPanel = (panel: string) => {
    setSelectedPanel(selectedPanel === panel ? undefined : panel);
  };

  return (
    <>
      <Paper
        elevation={1}
        sx={{
          visibility: selectedPanel ? 'visible' : 'hidden',
          position: 'absolute',
          left: 8,
          top: 8,
          border: 1,
          borderColor: 'backgroundVariant',
          ...(selectedPanel && {
            width: '360px'
          }),

          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Paper
          elevation={selectedPanel ? 0 : 1}
          sx={{
            visibility: 'visible',
            borderColor: '#e4e4e7',
            display: 'flex',
            flexDirection: 'column',
            bgcolor: 'background.paper'
          }}
        >
          <LocationsPanelToggleGroup value={selectedPanel} onItemClick={handleClickPanel} />
        </Paper>
        <MapLocationsPanel value="baseLocations" selectedPanel={selectedPanel}>
          <Box
            sx={{
              overflow: 'hidden',
              mb: '-2px'
            }}
          >
            <LocationsControllerToolbar>
              <TextField
                size="small"
                fullWidth
                variant="outlined"
                sx={{
                  bgcolor: 'background.paper'
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AppIcon iconName="search" />
                    </InputAdornment>
                  )
                }}
                placeholder="Search..."
              />
            </LocationsControllerToolbar>
          </Box>

          <Box
            sx={{
              width: '100%',
              flex: 1,
              overflowY: 'auto'
            }}
          >
            <BaseLocationList />
          </Box>
        </MapLocationsPanel>
        <MapLocationsPanel value="sites" selectedPanel={selectedPanel}>
          <LocationsControllerToolbar>
            <TextField
              size="small"
              fullWidth
              variant="outlined"
              sx={{
                bgcolor: 'background.paper'
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AppIcon iconName="search" />
                  </InputAdornment>
                )
              }}
              placeholder="Search..."
            />
          </LocationsControllerToolbar>
          <Box
            sx={{
              width: '100%',
              flex: 1,
              overflowY: 'auto'
            }}
          >
            <SiteList />
          </Box>
        </MapLocationsPanel>
      </Paper>
    </>
  );
};
