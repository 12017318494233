import React from 'react';
import '@fontsource-variable/material-symbols-outlined/full.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Box, CssBaseline } from '@mui/material';
import { APP_COLORS, getDesignTokens } from '../config';
import { WithChildren } from '../types';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useAppSelector } from '../store';
import { selectPaletteMode } from '../features/global/controlsSlice';

export const AppProvider = ({ children }: WithChildren<{}>) => {
  const paletteMode = useAppSelector(selectPaletteMode);

  const theme = React.useMemo(() => {
    return createTheme(getDesignTokens(paletteMode));
  }, [paletteMode]);

  return (
    <DndProvider backend={HTML5Backend}>
      <ThemeProvider theme={theme}>
        <CssBaseline enableColorScheme />
        <Box
          sx={{
            height: '100vh',
            width: '100%', 
            bgcolor: 'background.default', 
            '*::-webkit-scrollbar': {
              width: '14px!important',
            },
            '*::-webkit-scrollbar-track': {
              borderRadius: `100!important`, 
              ...(paletteMode === 'light' ? {
                background: `${APP_COLORS.light3}!important`,
              } : {
                background: `${APP_COLORS.dark1}!important`,
              }),
              /* background: (theme) => theme.palette.background.paper, */
              border: (theme) => `3px solid ${theme.palette.background.paper}!important`,
            },
            '*::-webkit-scrollbar-thumb': {
              
              borderRadius: 100, 
              ...(paletteMode === 'light' ? {
                backgroundColor: '#b9b8c4!important', 
              } : {
                backgroundColor: '#323144!important',
              }),
              border: '3px solid transparent!important',
              backgroundClip: 'content-box!important'
            },
            '*': {
              '*::-webkit-scrollbar': {
                width: '14px!important'
              },
              '*::-webkit-scrollbar-track': {
                borderRadius: `100!important`,
                ...(paletteMode === 'light'
                  ? {
                      background: `${APP_COLORS.light3}!important`
                    }
                  : {
                      background: `${APP_COLORS.dark1}!important`
                    }),
                /* background: (theme) => theme.palette.background.paper, */
                border: (theme) => `3px solid ${theme.palette.background.paper}!important`
              },
              '*::-webkit-scrollbar-thumb': {
                borderRadius: 100,
                ...(paletteMode === 'light'
                  ? {
                      backgroundColor: '#b9b8c4!important'
                    }
                  : {
                      backgroundColor: '#323144!important'
                    }),
                border: '3px solid transparent!important',
                backgroundClip: 'content-box!important'
              },
              scrollbarColor: `#d1d5db #f5f5f5!important`,
              '& .BackgroundDefault': {
                '::-webkit-scrollbar': {
                  width: '14px'
                },
                '::-webkit-scrollbar-track': {
                  borderRadius: 100,
                  ...(paletteMode === 'light'
                    ? {
                        background: `#dfdee6!important`
                      }
                    : {
                        background: APP_COLORS.dark2
                      }),
                  borderColor: (theme) => theme.palette.background.default,
                  borderWidth: `3px`,
                  borderStyle: 'solid'
                },
                '::-webkit-scrollbar-thumb': {
                  borderRadius: 100,
                  ...(paletteMode === 'light'
                    ? {
                        backgroundColor: '#a7a5b4'
                      }
                    : {
                        backgroundColor: '#323144'
                      }),
                  border: '3px solid transparent',
                  backgroundClip: 'content-box'
                },
                ...(paletteMode === 'light' ? {} : {}),
                scrollbarColor: `#d1d5db #f5f5f5`
              },
              '& .BackgroundVariant': {
                '::-webkit-scrollbar': {
                  width: '14px'
                },
                '::-webkit-scrollbar-track': {
                  borderRadius: 100,
                  ...(paletteMode === 'light' ? {
                    background: `#dfdee6!important`,
                    borderColor: `${APP_COLORS.light2}!important`,
                  } : {
                    background: APP_COLORS.dark2,
                    borderColor: APP_COLORS.dark2,
                  }),
                  borderWidth: `3px`,
                  borderStyle: 'solid'
                },
                '::-webkit-scrollbar-thumb': {
                  borderRadius: 100,
                  ...(paletteMode === 'light'
                    ? {
                        backgroundColor: '#a7a5b4'
                      }
                    : {
                        backgroundColor: '#323144'
                      }),
                  border: '3px solid transparent',
                  backgroundClip: 'content-box'
                },

                ...(paletteMode === 'light' ? {

                } : {

                }),
                scrollbarColor: `#d1d5db #f5f5f5`,
              },
              
            }
          }}
        >
          {children}
        </Box>
      </ThemeProvider>
    </DndProvider>
  );
};
