import InputAdornment from '@mui/material/InputAdornment';
import {
  useGridApiContext,
  useGridSelector,
  GridToolbarExport,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  gridQuickFilterValuesSelector,
  gridRowSelectionStateSelector,
  gridRowCountSelector
} from '@mui/x-data-grid';
import React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Button, IconButton, Slide, Stack, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import _, { filter } from 'lodash';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Chip from '@mui/material/Chip';
import Menu from '@mui/material/Menu';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { TransitionProps } from '@mui/material/transitions';
import { useTranslation } from 'react-i18next';
import { AppIcon } from '../Elements';
import { BOX_SHADOWS } from '../../config/theme/boxShadows';
import { useAppDispatch } from '../../store/hooks';
import { RDataGridUnsavedFilter, IActiveFilter, IFilterControl, IFilters } from './types';
import { RSelectedItemToolbar, RSelectedItemToolbarProps } from './RDataGridSelectedToolbar';

import useMediaQuery from '@mui/material/useMediaQuery';

type RFilterControlProps = {
  control: IFilterControl;
  appliedValue?: any;
  handleRemove: () => void;
  handleUpdateFilterValue: (newValue: string) => void;
};

const RFilterControl = (props: RFilterControlProps) => {
  const { control: { field: key, label, options }, appliedValue, handleRemove, handleUpdateFilterValue: handleUpdateFilter } = props;
  const { t } = useTranslation();
  const xlScreen = useMediaQuery('(min-width:1536px)');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const theme: any = useTheme();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickMenuItem = (value: string) => {
    handleUpdateFilter(value);
    handleClose();
  }

  const screenSizedLabel = React.useMemo(() => {
    if (xlScreen) {
      return label || key;
    }
    return (label || key).substring(0, 4).concat('...');
  }, [xlScreen, t]);

  const screenSizedAppliedLabel = React.useMemo(() => {
    if (appliedValue) {
      if (xlScreen) {
        return options.find((option) => option.value === appliedValue)?.label || options.find((option) => option.value === appliedValue).value;
      }
      return (options.find((option) => option.value === appliedValue)?.label || options.find((option) => option.value === appliedValue).value).substring(0, 4).concat('...');
    }
    return undefined;
  }, [xlScreen, appliedValue, t]);

  return (
    <>
      {appliedValue ? (
        <Tooltip
          title={`${label || key}: ${options.find((option) => option.value === appliedValue)?.label || options.find((option) => option.value === appliedValue).value}`}
          placement='bottom'
        >
        <Chip
        color={'secondary'}
        component='button'
        onClick={handleClick}
        label={screenSizedAppliedLabel}
        onDelete={handleRemove}
        deleteIcon={<CloseOutlinedIcon fontSize='small' sx={{ color: `${theme.palette.primary.main}!important`, opacity: 0.8, fontSize: '18px!important', marginLeft: '-4px!important' }} />}
        sx={{
          '& .MuiChip-label': {
            mt: -0.25
          },
          pl: '3px',
          height: '36px', pr: 0.75, fontSize: 'body2.fontSize', fontWeight: 500
        }}
      />
        </Tooltip>
        
      ) : (
        <Tooltip
          title={label || key}
          placement='bottom'
        >
          <Button
            sx={{ pl: 1.75, pr: 1.25, height: '36px', fontWeight: 500, borderRadius: 1.5 }}
            variant='outlined'
            endIcon={<AppIcon iconName='arrow_drop_down' color={'primary.main'} opticalSize={20} sx={{ ml: -0.25 }} />}
            onClick={handleClick}
          >
            {screenSizedLabel}
          </Button>
        </Tooltip>
        
      )}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {options.map((option: any, i: any) => (
          <MenuItem key={option} onClick={() => handleClickMenuItem(option.value)}>
            {option?.label || option.value}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

type RAddableFiltersMenuProps = {
  handleAddFilterControl: (control) => void;
  controls: IFilterControl[];
}

const RAddableFiltersMenu = (props: RAddableFiltersMenuProps) => {
  const { controls, handleAddFilterControl } = props;
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickedMenuItem = (control: IFilterControl) => {
    handleAddFilterControl(control);
    handleClose();
  }

  return (
    <>
      <Tooltip
        title={t('wif.injury.addFilter')}
        placement='bottom'
      >
      <Button
        variant='outlined'
        id="add-filter-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        startIcon={<AppIcon iconName='add' />}
        sx={{
          borderRadius: 1.5,
          height: '36px',
          width: '36px',
          minWidth: 0,
          '& .MuiButton-startIcon': {
            mx: 0,

          },
        }}
      >
        
      </Button>
      </Tooltip>
      <Menu
          id="addable-filters-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': "add-filter-button",
          }}
        >
          {controls.map((control: IFilterControl, i: any) => (
            <MenuItem onClick={() => handleClickedMenuItem(control)}>{control?.label || control.field}</MenuItem>
          ))}
        </Menu>
    </>
  )
};

type RDataGridToolbarProps = {
  filters?: IFilters;
  addableControls?: {
    controls: IFilterControl[];
    handleAddFilterControl: (control) => void;
  };
  selected?: RSelectedItemToolbarProps;
}

export const RDataGridToolbar = (props: RDataGridToolbarProps) => {
  const { filters, addableControls, selected } = props;
  const { controls, addedControls, active, handleSetFilter, handleRemoveFilter, handleResetFilterControls } = filters;


  
  const [quickFilterOpen, setQuickFilterOpen] = React.useState(false);
  const [filterChipsVisible, setFilterChipsVisible] = React.useState(true);
  const { t } = useTranslation();

  const apiRef = useGridApiContext();

  const quickFilterValues = useGridSelector(apiRef, gridQuickFilterValuesSelector);

  const quickFilterParser = (searchText: string) => searchText.split(' ').filter(word => word !== '');

  const updateSearchValue = React.useCallback((newSearchValue: any) => {
    apiRef.current.setQuickFilterValues(quickFilterParser(newSearchValue));
  }, [apiRef, quickFilterOpen]);
  
  const theme = useTheme();
  
  const rowsCount = useGridSelector(apiRef, gridRowCountSelector);

  const handleResetAllFilters = () => {
    apiRef.current.setQuickFilterValues([]);
    if (handleResetFilterControls) {
      handleResetFilterControls();
    }
    
  }

  const filteringDisabled = filters === undefined && addableControls === undefined; 

  return (
    <GridToolbarContainer
      sx={{
        pb: 2.5,
        pt: 0,
        ml: -0.5,
        mr: 0.5,
        position: 'relative'
      }}
    >
      {selected && selected?.count > 0 && (
        <RSelectedItemToolbar
          actions={selected.actions}
          itemName={selected.itemName}
        />
      )}
      <>
        <Stack
          direction={'column'}
          justifyContent={(!quickFilterOpen && !filterChipsVisible) ? 'flex-end' : 'space-between'}
          gap={1}
          sx={{ width: '100%' }}
        >
          <Stack
            direction={{
              xs: 'column', 
              xl: 'row'
            }}
            gap={{
              xs: 1.5, 
              xl: 1
            }}
            alignItems={{
              xs: 'start', 
              xl: 'center'
            }}
            display={'flex'}
            sx={{
              width: '100%'
            }}
          >
            <Stack
              direction='row'
              gap={1}
              alignItems='center'
              display={'flex'}
              sx={{
                width: {
                  xs: '100%', 
                  xl: 'auto',
                }
              }}
            >
              <GridToolbarQuickFilter
                InputProps={{
                  sx: { 
                    pl: 1.75,
                    borderRadius: 1.5,
                    borderColor: (theme) => `${theme.palette.primary.main}!important`,
                    '& input': {
                      lineHeight: 20,
                      ml: 0,
                      pl: 0.25,
                      '&::placeholder': {
                        color: 'textVariant',
                        opacity: 0.9
                      },

                    },
                    boxShadow: BOX_SHADOWS.elevation0,
                    height: 36, mb: -0.5
                  },
                  startAdornment: (<InputAdornment position='start' sx={{ minWidth: '0px' }}><AppIcon iconName='search' color='textVariant' weight={500} /></InputAdornment>),
                }}
                in
                variant='outlined'
                size='small'
                sx={{
                  width: { xs: '320px', xl: controls.length < 2 ? '640px' : '420px' }, borderColor: (theme) => `${theme.palette.primary.main}!important`, /* display: rowSelectionModel.length === 0 ? 'flex' : 'none' */
                }}
              />
              <Box sx={{ flexGrow: 1, display: { xs: 'flex', xl: 'none' } }} />
              <Tooltip 
                sx={{
                  display: {
                    xs: 'flex', 
                    xl: 'none'
                  }
                }}
                title={t('wif.injury.settings')} 
                placement='bottom'
              >
                <GridToolbarColumnsButton
                  variant='text'
                  startIcon={<AppIcon iconName='settings' color='text.secondary' />}
                  sx={{
                    color: 'text.primary',
                    '& .MuiButton-startIcon': {
                      mx: 0,

                    },
                    p: 'auto',
                    height: '36px',
                    width: '36px',
                    minWidth: 0,
                    display: {
                      xs: 'flex', 
                      xl: 'none'
                    }
                  }}
                />
              </Tooltip>
              <Tooltip 
                title={t('wif.injury.export')} 
                placement='bottom'
                sx={{
                  display: {
                    xs: 'flex', 
                    xl: 'none'
                  }
                }}
              >
                <GridToolbarExport
                  variant='text'
                  startIcon={<AppIcon iconName='download' color='text.secondary' weight={500} />}
                  sx={{
                    color: 'text.primary',
                    '& .MuiButton-startIcon': { mx: 0 },
                    p: 'auto',
                    minWidth: 0,
                    height: '36px',
                    width: '36px',
                    display: {
                      xs: 'flex', 
                      xl: 'none'
                    }
                  }}
                />
              </Tooltip>
            </Stack>
            
            <Stack
              direction='row'
              gap={1}
              alignItems='center'
              display={'flex'}
              sx={{
                flexGrow: 1,
                width: '100%',
              }}
            >
              {!filteringDisabled && (
              <>
              {filters && [...controls, ...addedControls].map((control, i) => (
                <RFilterControl
                  control={control}
                  appliedValue={active.find(filter => filter.field === control.field)?.value || undefined}
                  handleRemove={() => handleRemoveFilter(control.field)}
                  handleUpdateFilterValue={(newValue: any) => handleSetFilter({ field: control.field, value: newValue })}
                />
              ))}
              
              {(addableControls && addableControls?.controls?.length > 0 && rowsCount > 0) && (
                <RAddableFiltersMenu
                  controls={addableControls.controls}
                  handleAddFilterControl={addableControls.handleAddFilterControl}
                />
              )}
              {(addedControls?.length > 0 || active?.length > 0 || quickFilterValues?.length > 0) && (
                <Button
                  variant='text'
                  color='primary'
                  onClick={handleResetAllFilters}
                  sx={{ ml: 0.5 }}
                /*  endIcon={<AppIcon iconName='close' opticalSize={18} />} */
                >
                  {t('wif.injury.resetAll')}
                </Button>
              )}
              </>
            )}
            
            <Box sx={{ flexGrow: 1 }} />
            
            <Tooltip 
              sx={{
                display: {
                  xs: 'none', 
                  xl: 'flex'
                }
              }}
              title={t('wif.injury.settings')} 
              placement='bottom'
            >
              <GridToolbarColumnsButton
                variant='text'
                startIcon={<AppIcon iconName='settings' color='text.secondary' />}
                sx={{
                  color: 'text.primary',
                  '& .MuiButton-startIcon': {
                    mx: 0,

                  },
                  p: 'auto',
                  height: '36px',
                  width: '36px',
                  minWidth: 0,
                  display: {
                    xs: 'none', 
                    xl: 'flex'
                  }
                }}
              />
            </Tooltip>
            <Tooltip 
              title={t('wif.injury.export')} 
              placement='bottom'
              sx={{
                display: {
                  xs: 'none', 
                  xl: 'flex'
                }
              }}
            >
              <GridToolbarExport
                variant='text'
                startIcon={<AppIcon iconName='download' color='text.secondary' weight={500} />}
                sx={{
                  color: 'text.primary',
                  '& .MuiButton-startIcon': { mx: 0 },
                  p: 'auto',
                  minWidth: 0,
                  height: '36px',
                  width: '36px',
                  display: {
                    xs: 'none', 
                    xl: 'flex'
                  }
                }}
              />
            </Tooltip>
            </Stack>
            
          </Stack>
        </Stack>
      </>
    </GridToolbarContainer>
  )
}