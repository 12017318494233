import { Box, Button, Divider, Paper, Stack, Step, StepLabel, Stepper, ToggleButtonGroup, Typography } from '@mui/material';
import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';import { useAppDispatch, useAppSelector } from '../../../store';
import { useNavigate } from 'react-router-dom';
import { ContentLayout } from '../../../components/Layout';
import { Header } from '../../../components/Layout/Header';
import { TabsContainer } from '../../../components/Layout/AppTabs';
import { TabPanel } from '../../../components/Elements/TabPanel';
import { AreasDataGrid } from '../../sites/components/AreasDataGrid/AreasDataGrid';
import CreateAreaDialog from '../../sites/components/CreateAreaDialog/CreateAreaDialog';
import { initLocationTestData, selectAllSites } from '../locationsSlice';
import ContentWrapper from '../../../components/Layout/ContentWrapper';
import { SitesDataGrid } from '../../sites/components/SitesDataGrid.tsx/SitesDataGrid';
import CreateSiteDialog from '../../sites/components/CreateSiteDialog/CreateSiteDialog';
import { AppIcon } from '../../../components/Elements';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ToggleButton from '@mui/material/ToggleButton';

export const Locations = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [tab, setTab] = React.useState(0);
  const [view, setView] = React.useState<string>('list');

  const handleChangeView = (
    event: React.MouseEvent<HTMLElement>,
    newView: string | null,
  ) => {
    if (newView !== null) {
      setView(newView);
    }
  }

  const [addLocationMenuAnchorEl, setAddLocationMenuAnchorEl] = React.useState(null);
  const addLocationMenuOpen = Boolean(addLocationMenuAnchorEl);

  const handleAddLocation = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAddLocationMenuAnchorEl(event.currentTarget);
  };

  const handleCloseAddLocationMenu = () => {
    setAddLocationMenuAnchorEl(null);
  };

  const handleChangeTab = (event: React.SyntheticEvent, newTab: number) => {
    setTab(newTab);
  };
  
  const [openedDialog, setOpenedDialog] = React.useState<string|undefined>();

  const sites = useAppSelector(selectAllSites);

  React.useEffect(() => {
    if (sites.length < 1) {
      dispatch(initLocationTestData());
    }
  }, [sites]); 

  return (
    <ContentWrapper>
      <ContentLayout
      breadcrumbs={[
        { to: '/', name: 'Home' }, 
      ]}
      title='Locations'
    >
      <Stack 
        direction='column'
        spacing={1}
        sx={{
          pt: 1.5
        }}
      >
        <Header
          actions={
            <Stack direction='row' gap={1.5}>
             <ToggleButtonGroup
                value={view}
                exclusive
                onChange={handleChangeView}
                aria-label='View mode'
              >
                <ToggleButton value='list' aria-label='List'>
                  <AppIcon iconName='view_list' opticalSize={22} />
                </ToggleButton>
                <ToggleButton value='map' aria-label='Map'>
                  <AppIcon iconName='map' opticalSize={22} />
                </ToggleButton>
              </ToggleButtonGroup>
              <Button
                color='primary'
                variant='contained'
                onClick={handleAddLocation}
                /* startIcon={<AppIcon iconName='add_location' />} */
              >
                Add location
              </Button>
            </Stack>
          }
        >
          Locations
        </Header>
          <TabsContainer px={3}>
            <Tabs value={tab} onChange={handleChangeTab} aria-label="basic tabs example">
              <Tab label='Sites' />
              <Tab label='Areas' />
              <Tab label='Map' disabled />
            </Tabs>
          </TabsContainer>
          <Box sx={{ mt: 3 }} />
        </Stack>  
        <TabPanel
          value={tab}
          index={0}
        >
          <SitesDataGrid />
        </TabPanel>
        <TabPanel
          value={tab}
          index={1}
        >
          <AreasDataGrid />
        </TabPanel>
      <CreateSiteDialog open={openedDialog === 'site'} handleClose={() => setOpenedDialog(undefined)} />
      <CreateAreaDialog open={openedDialog === 'area'} handleClose={() => setOpenedDialog(undefined)} />
      <Menu
        id="basic-menu"
        anchorEl={addLocationMenuAnchorEl}
        open={addLocationMenuOpen}
        onClose={handleCloseAddLocationMenu}
        /* MenuListProps={{
          'aria-labelledby': 'basic-button',
        }} */
      >
        <MenuItem onClick={() => setOpenedDialog('site')}>
          <ListItemIcon><AppIcon iconName='home_work' /></ListItemIcon>
          <ListItemText>Site</ListItemText>
        </MenuItem>
        {sites.length > 0 && (
          <MenuItem onClick={() => setOpenedDialog('area')}>
            <ListItemIcon><AppIcon iconName='pin_drop' /></ListItemIcon>
            <ListItemText>Area</ListItemText>
          </MenuItem>
        )}
        
      </Menu>
    </ContentLayout>
    </ContentWrapper>
    
  );
}