import * as React from 'react';
import * as yup from "yup";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { AppDialogTitle } from '../../../components/Display/AppDialogTitle';
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { FormDatePicker, FormSelect, FormTextField } from '../../../components/FormInputs';
import { Box, CircularProgress, Stack } from '@mui/material';
import { RootState, useAppDispatch, useAppSelector } from '../../../store';
import { selectAllEmployees, selectEmployeeIds, selectEmployees } from '../../employees/employeesSlice';
import { FormAutocomplete } from '../../../components/FormInputs/FormAutocomplete';
import _, { set } from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import { snackAlertOpened } from '../../global/controlsSlice';
import { WithChildren } from '../../../types';
import { useCurrentInjuryReport } from '../../injuries/routes/CurrentInjuryReportContext';
import { makeSelectInjuryReportById, updateInjuryReportAction, updateInjuryReportActionReq } from '../../injuries/store/injuryReportsSlice';
import { LoadingButtonContainer } from '../../../components/Elements/LoadingButtonContainer';
import { IAction } from '../actionTypes';
import { useTranslation } from 'react-i18next';
import { CreateOrUpdateActionFormControls, actionReducesOptions } from './CreateOrUpdateActionFormControls';

export const updateActionSchema = yup.object().shape({
  text: yup.string().required().min(4, 'min 4').max(180, 'max 180'),
  actionDescription: yup.string().required().min(4, 'min 4').max(1000, 'max 1000'),
  deadline: yup.date().notRequired(), 
  measureType: yup.string().notRequired(),
  members: yup.array().notRequired(),
  assignee: yup.string().notRequired()
});

export type IActionUpdates = yup.InferType<typeof updateActionSchema>;

interface UpdateActionDialogProps {
  open?: boolean;
  action?: IAction;
  onClose: () => void;
}

export default function UpdateActionDialog(props: UpdateActionDialogProps) {
  const { onClose, action, open = false } = props;
  const { t } = useTranslation();

  const selectInjuryReportById = React.useMemo(makeSelectInjuryReportById, [action.report_id]);
  const injuryReport = useAppSelector((state: RootState) => selectInjuryReportById(state, action.report_id));

  const dispatch = useAppDispatch();

  const [loading, setLoading] = React.useState(false);

  const { handleSubmit, control, reset } = useForm<IActionUpdates>({
    resolver: yupResolver(updateActionSchema),
    defaultValues: React.useMemo(() => {
      return {
        text: action?.text,
        actionDescription: action?.actionDescription,
        deadline: action?.deadline,
        measureType: actionReducesOptions?.indexOf(action?.measureType) !== -1 ? action?.measureType : undefined,
        members: action?.members,
        assignee: action?.assignee?.[0],
      };
    }, [action]),
  });

  const handleSave = async (req: updateInjuryReportActionReq) => {
    setLoading(true);
    const resultAction = await dispatch(updateInjuryReportAction(req));
    if (updateInjuryReportAction.fulfilled.match(resultAction)) {
      dispatch(snackAlertOpened({ message: t('wif.injury.snackMessages.actionUpdated.success'), severity: 'success' }));
      setLoading(false);
      onClose();
    } else {
      if (resultAction.payload) {
        dispatch(snackAlertOpened({ message: t('wif.injury.snackMessages.genericError'), severity: 'error' }));
        setLoading(false);
      } else {
        dispatch(snackAlertOpened({ message: t('wif.injury.snackMessages.errorWithMessage', { message: resultAction.error }), severity: 'error' }));
        setLoading(false);
      }
    }
  };

  const onSubmit: SubmitHandler<IActionUpdates> = (data) => {
    const initialAction = Object.assign({}, {
      ...action
    });
    const payload: any = _.merge({ ...initialAction }, { ...data });
    if (payload?.assignee) {
      payload.assignee = [payload.assignee]; 
    }
    if (action.status === 'draft') {
      payload.status = 'pending';
    }
    const req = {
      reportId: injuryReport.id,
      action_id: action?.action_id,
      payload: payload
    };
    handleSave(req);
  };

  const onDraftSubmit: SubmitHandler<IActionUpdates> = (data) => {
    const initialAction = Object.assign({}, {
      ...action
    });
    const payload = _.merge({ ...initialAction }, { ...data });
    const req = {
      reportId: injuryReport.id,
      action_id: action?.action_id,
      payload: payload
    };
    handleSave(req);
  };

  const employees = useAppSelector(selectEmployees); // Replace with access controlled
  
  const employeeIds = useAppSelector(selectEmployeeIds); 
  let employeeNames: any = _.clone(employees);
  employeeNames = _.mapValues(employeeNames, (e) => e.name);

  return (
    <Dialog open={open} onClose={onClose}>
      <AppDialogTitle
        id='create-injury-action-dialog-title'
        onClose={onClose}
      >
        {t('wif.injury.editActionDialog.title', { actionName: action?.text || '' })}
      </AppDialogTitle>
      <DialogContent 
        dividers
        sx={{
          width: 600 
        }}
      >
        <form id='update-injury-action'>
          <Stack direction='column' gap={2.5} width='100%'>
            {control && (
              <CreateOrUpdateActionFormControls 
                control={control}
              />
            )}
            
          </Stack>
        </form>
      </DialogContent>
      <DialogActions>
        {action?.status === 'draft' && (
          <LoadingButtonContainer
            loading={loading}
          >
            <Button 
              /* type='submit'
              form='create-injury-action' */
              variant='contained'
              color='plain'
              disabled={loading}
              id='save-as-draft'
              onClick={handleSubmit(onDraftSubmit)}
            >
              {t('wif.injury.saveAsDraft')}
            </Button>
          </LoadingButtonContainer>
        )}
        <Box sx={{ flexGrow: 1 }} />
        <Button 
          color='primary'
          variant='text'
          onClick={onClose}
          sx={{ mr: 1 }}
        >
        {t('wif.injury.cancel')}
        </Button>
        
        <LoadingButtonContainer
          loading={loading}
        >
          <Button 
            /* type='submit'
            form='create-injury-action' */
            onClick={handleSubmit(onSubmit)}
            variant='contained'
            color='secondary'
            disabled={loading}
            id='save'
          >
            {action?.status === 'draft' ? t('wif.injury.publish') : t('wif.injury.save')}
          </Button>
        </LoadingButtonContainer>
        

        
      </DialogActions>
    </Dialog>
  );
}