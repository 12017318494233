import App from './App';
import './App.css';
import { Amplify, Auth } from 'aws-amplify';
import awsmobile from './aws-exports';
import CookieStorage from './utils/CookieStorage';
export default function Root(props) {
Amplify.configure(awsmobile);
Auth.configure({
  storage:CookieStorage
})
  return <App />
}
