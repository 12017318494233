import {
  EntityState,
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
  createEntityAdapter
} from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { CASE_STATUS, REPORT_CATEGORY, REPORT_TYPES } from '../constants';
import { EMPLOYEE_SECTION_NAMES } from '../constants/employee';
import { STUDENT_SECTION_NAMES } from '../constants/student';
import { InjuryReportCase, InjuryReportLog } from '../types';
import { FetchedStatus } from '../../../types';
import { formatDateFromTimestamp } from '../../../utils/format';
import {
  getReverseMappedSectionFieldName,
  getReverseMappedSectionName,
  getReverseMappedSectionNameByCategory,
  getTableValidSection
} from '../helpers/dataMapping';
import dayjs from 'dayjs';
import { CreateInjuryActionPayload } from '../components/CreateInjuryActionDialog';
import { IActionCompletion } from '../../actions/components/CompleteActionDialog';
import { selectDepartmentEntities } from '../../departments/departmentsSlice';
import { IAction } from '../../actions/actionTypes';
import { CreateMessagePayload } from '../components/ReportDetails/CasePanels/Comments';
import { IActiveFilter, IFilterControl, IFilters } from '../../../components/DataGrid/types';
import { GridColumnVisibilityModel, GridSortModel } from '@mui/x-data-grid';
import { getInitialInjuryControlsState } from './controlsHelpers';

export interface Controls {
  addedControls: IFilterControl[];
  activeFilters: IActiveFilter[];
  sortModel: GridSortModel;
  columnVisibilityModel: GridColumnVisibilityModel;
}

export interface ModuleControls {
  'student'?: Controls;
  'employee'?: Controls;
  'all'?: Controls;
  'shared'?: Controls;
}

export type InjuryModuleName = 'student'|'employee'|'all'|'shared';
export type InjuryViewName = 'reporter'|'handler';
export type ControlNames = 'addedControls'|'activeFilters'|'sortModel'|'columnVisibilityModel';

export interface InjuryControlsState {
  'reporter': ModuleControls;
  'handler': ModuleControls; 
  'initialized': boolean;
};

const initialState = getInitialInjuryControlsState();

type BaseInjuryControlPayload = {
  view: InjuryViewName;
  module: InjuryModuleName;
};

type UpdateQueriedControlPayload = BaseInjuryControlPayload & {
  controlName: ControlNames;
  controlValue: any;
}

type SortModelChangedPayload = BaseInjuryControlPayload & {
  newModel: GridSortModel;
};

type ColumnVisibilityModelChangedPayload = BaseInjuryControlPayload & {
  newModel: GridColumnVisibilityModel;
};

const injuryControlsSlice = createSlice({
  name: 'injuryControls', 
  initialState,
  reducers: {
    injuryControlChanged: (state, action: PayloadAction<UpdateQueriedControlPayload>) => {
      const { view, module, controlName, controlValue } = action.payload;
      state[view][module][controlName] = controlValue;
    },
    sortModelChanged: (state, action: PayloadAction<SortModelChangedPayload>) => {
      const { view, module, newModel } = action.payload;
      state[view][module].sortModel = newModel;
    },
    columnVisibilityModelChanged: (state, action: PayloadAction<ColumnVisibilityModelChangedPayload>) => {
      const { view, module, newModel } = action.payload;
      state[view][module].columnVisibilityModel = newModel;
    },
    activeFilterRemoved: (state, action: PayloadAction<BaseInjuryControlPayload & { field: string }>) => {
      const { view, module, field } = action.payload;
      const newActiveFilters = state[view][module].activeFilters.filter((filter) => filter.field !== action.payload.field);
      state[view][module].activeFilters = newActiveFilters;
    },
    activeFilterSet: (state, action: PayloadAction<BaseInjuryControlPayload & { newFilter: IActiveFilter }>) => {
      const { view, module, newFilter: newActiveFilter } = action.payload;
      const activeFilters = state[view][module].activeFilters;
      const indexOfNew = activeFilters.findIndex((filter) => filter.field === newActiveFilter.field);
      if (indexOfNew > -1) {
        activeFilters[indexOfNew] = newActiveFilter;
      } else {
        activeFilters.push(newActiveFilter);
      }
    },
    filterControlAdded: (state, action: PayloadAction<BaseInjuryControlPayload & { control: IFilterControl }>) => {
      const newAddedControls = state[action.payload.view][action.payload.module].addedControls;
      newAddedControls.push(action.payload.control);
    },
    filterControlsReset: (state, action: PayloadAction<BaseInjuryControlPayload>) => {
      state[action.payload.view][action.payload.module].activeFilters = [];
      state[action.payload.view][action.payload.module].addedControls = [];
    },
  }, 
  extraReducers: (builder) => {

  },
});

export const {
  sortModelChanged,
  columnVisibilityModelChanged,
  injuryControlChanged,
  activeFilterRemoved,
  activeFilterSet,
  filterControlAdded,
  filterControlsReset
} = injuryControlsSlice.actions;

export const selectInjuryControlsState = (state: RootState) => state.injuryControls;


// 'reporter'|'handler'
const getView = (_: any, view: InjuryViewName) => view;
// 'all'|'student'|'employee'|'shared'
const getModule = (_: any, module: InjuryModuleName) => module;
const getQuery = (_: any, query: { 'view': InjuryViewName, 'module': InjuryModuleName }) => query;

export const makeSelectInjuryControlsByQuery = () => createSelector(
  [selectInjuryControlsState, getQuery], 
  (controls, query) => controls[query.view][query.module]
);

export default injuryControlsSlice.reducer;