import * as yup from 'yup';
import { FormDatePicker, FormTimePicker } from '../../../../components/FormInputs';
import { FormAutocompleteWithOrder } from '../../../../components/FormInputs/FormAutocomplete';
import { FormRow } from '../../../../components/Forms';
import { GENERIC_VALIDATION_MSG } from '../../constants/mappedInputLabels';

export const ACCIDENT_TYPE_OPTIONS: string[] = [
  'A1',
  'A2',
  'A3',
  'A4',
  'A5',
  'A6',
  'A7',
  'A8',
  'A9',
  'A10',
  'A11',
  'A12',
  'A13',
];

export const ACCIDENT_TYPE_NAMES: any = {
  'A1': 'Impact/hit by object',
  'A10': 'Explosion, explosion, fire',
  'A11': 'Threats of violence',
  'A12': 'Inflicted violent damage',
  'A13': 'Other',
  'A2': 'Collision/collision',
  'A3': 'Overturned',
  'A4': 'Pinched/trapped',
  'A5': 'Fall',
  'A6': 'Stung/cut by a sharp/pointed object',
  'A7': 'Electrical voltage',
  'A8': 'High/low temperature',
  'A9': 'Chemicals',
}

export const DAMAGE_NATURE_OPTIONS: string[] = [
  'C1',
  'C2',
  'C3',
  'C4',
  'C5',
  'C6',
  'C7',
  'C8',
  'C9',
  'C10',
  'C11',
];

export const DAMAGE_NATURE_NAMES: any = {
  'C1': 'Soft tissue injury without wound (crush injury)',
  'C10': 'Psychological after-effects',
  'C11': 'Other',
  'C2': 'Wound damage',
  'C3': 'Loss of body part',
  'C4': 'Sprain, contortion',
  'C5': 'Bone fracture',
  'C6': 'Heat damage',
  'C7': 'Cold injury',
  'C8': 'Etching',
  'C9': 'Acute poisoning',
}

export const DAMAGED_BODY_PART_OPTIONS: string[] = [
  'D1',
  'D2',
  'D3',
  'D4',
  'D5',
  'D6',
  'D7',
  'D8',
  'D9',
  'D10',
  'D11',
  'D12',
  'D13',
  'D14',
  'D15',
  'D16',
  'D17',
  'D18',
  'D19',
  'D20',
  'D21',
  'D22',
  'D23',
  'D24',
  'D25',
  'D26',
  'D27',
  'D28',
  'D29',
  'D30',
  'D31',
  'D32',
  'D33',
];

export const DAMAGED_BODY_PART_NAMES: any = {
  'D1': 'Head',
  'D10': 'Rib/shoulder blade',
  'D11': 'Chest/lungs/heart/oesophagus',
  'D12': 'Stomach/pelvis/digestive organs/kidney/urinary tract',
  'D13': 'Shoulder, left',
  'D14': 'Shoulder, right',
  'D15': 'Arm/elbow, left',
  'D16': 'Arm/elbow, right',
  'D17': 'Wrist, left',
  'D18': 'Wrist, right',
  'D19': 'Hand, left',
  'D2': 'Face',
  'D20': 'Hand, right',
  'D21': 'Fingers, left',
  'D22': 'Fingers, right',
  'D23': 'Hip, left',
  'D24': 'Right hip',
  'D25': 'Leg w/knee, left',
  'D26': 'Leg w/knee, right',
  'D27': 'Ankle, left',
  'D28': 'Ankle, right',
  'D29': 'Foot, left',
  'D3': 'Eye, left',
  'D30': 'Foot, right',
  'D31': 'Toe, left',
  'D32': 'Toe, right',
  'D33': 'Whole body (general poisoning, general cooling, etc.)',
  'D4': 'Eye, right',
  'D5': 'Ear, left',
  'D6': 'Ear, right',
  'D7': 'Teeth',
  'D8': 'Throat/neck',
  'D9': 'Back',
};

export const baseAccidentSchema = yup.object().shape({
  date: yup.string().required(GENERIC_VALIDATION_MSG),
  time: yup.string().required(GENERIC_VALIDATION_MSG),

  accidentType: yup.array().of(yup.string().oneOf(ACCIDENT_TYPE_OPTIONS).required()).required(GENERIC_VALIDATION_MSG).min(1, 'min 1').max(13, 'max 13'),
  damageNature: yup.array().of(yup.string().oneOf(DAMAGE_NATURE_OPTIONS).required()).required(GENERIC_VALIDATION_MSG).min(1, 'min 1').max(11, 'max 11'),
  damagedBodyPart: yup.array().of(yup.string().oneOf(DAMAGED_BODY_PART_OPTIONS).required()).required(GENERIC_VALIDATION_MSG).min(1, 'min 1').max(33, 'max 33'),
});

export type BaseAccidentSection = yup.InferType<typeof baseAccidentSchema>;

export const BaseAccidentControls = ({ methods: { control } }: any) => (
  <>
    <FormRow>
      <FormDatePicker
        control={control}
        name='date'
        label='Date'
      />
      <FormTimePicker
        control={control}
        name='time'
        label='Time'
      />
    </FormRow>
    <FormAutocompleteWithOrder
      control={control}
      name='accidentType'
      options={ACCIDENT_TYPE_OPTIONS}
      label='Type of accident'
      placeholder='Accident type'
      /* helperText='The top 4 choices are transferred to the NAV form in the selected order. The rest will be shown in the web report. You can change the order once you have selected the items.' */
    />
    <FormAutocompleteWithOrder
      control={control}
      name='damageNature'
      options={DAMAGE_NATURE_OPTIONS}
      label='Damage nature'
      placeholder='Damage nature'
    />
    <FormAutocompleteWithOrder
      control={control}
      name='damagedBodyPart'
      options={DAMAGED_BODY_PART_OPTIONS}
      label='Damaged body parts'
      placeholder='Damaged body parts'
    />
    
    {/* <FormSelect
      control={control}
      name='accidentOccured'
      label='Accident occured'
      optionValues={ACCIDENT_OCCURED_OPTION_VALUES}
      optionLabels={ACCIDENT_OCCURED_OPTION_LABELS}
    />
    <FormSelect
      control={control}
      name='accidentType'
      label='Accident occured'
      optionValues={ACCIDENT_OCCURED_OPTION_VALUES}
      optionLabels={ACCIDENT_OCCURED_OPTION_LABELS}
    /> */}
  </>
)