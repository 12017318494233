import { Button, Chip, Divider, InputAdornment, List, ListItemButton, ListItemText, Paper, Stack, TextField, Typography, useScrollTrigger } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import { useTheme } from '@mui/material/styles';
import { InjuryModuleName, InjuryViewName } from '../store/injuryControlsSlice';
import { createInjuryReportMessage, makeSelectInjuryReportById, makeSelectInjuryReportsByModule, selectInjuryHandlerReportIds, selectInjuryRegistrationReportIds } from '../store/injuryReportsSlice';
import { RootState, useAppDispatch, useAppSelector } from '../../../store';
import * as React from 'react';
import { selectEmployees } from '../../employees/employeesSlice';
import { getActiveUser } from '../../../utils/user';
import { formatDateTimeFromTimestamp, getLastUpdatedStr, getTranslateFriendlyUpdatedStr } from '../../../utils/format';
import { AppIcon } from '../../../components/Elements';
import { BOX_SHADOWS } from '../../../config/theme/boxShadows';
import { SIKRI_COLORS } from '../../../config';
import { useForm, Controller, SubmitHandler, useFieldArray, useWatch } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { AppMessage, useChatScroll } from '../../../components/Messages/AppMessage';
import { SendMessage } from '../../../components/Messages/SendMessage';
import { CreateMessagePayload, createMessageSchema } from './ReportDetails/CasePanels/Comments';
import { snackAlertOpened } from '../../global/controlsSlice';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import _, { filter } from 'lodash';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import { getTranslatedInjuryReportName } from '../helpers';


type InjuryChatItemProps = {
  injuryReport: any;
  onClick?: () => void;
  selected?: boolean;
}

const InjuryChatItem = (props: InjuryChatItemProps) => {
  const { injuryReport, onClick, selected } = props;
  const { t } = useTranslation();

  const users = useAppSelector(selectEmployees);

  const lastMessage = React.useMemo(() => {
    return injuryReport.messages[injuryReport.messages.length - 1];
  }, [injuryReport]);

  const lastMessageUsername = React.useMemo(() => {
    const activeUser = getActiveUser();
    if (lastMessage.user_id === activeUser.id) {
      return t('wif.injury.you');
    }
    return _.capitalize(users[lastMessage.user_id]?.name) || lastMessage.user_id;
  }, [lastMessage, users, t]);

  const name = getTranslatedInjuryReportName(injuryReport, t);
  return (
    <ListItemButton 
      /* sx={{ 
        py: 2, 
        px: 3, 
        ...(selected && {
          bgcolor: (theme) => theme.palette.mode === 'light' ? SIKRI_COLORS.galaxyGreenSelected : SIKRI_COLORS.galaxyGreenSelectedDark,
        }) 
      }} */
      sx={{
        px: 3,
        '&.Mui-selected': {
          bgcolor: (theme) => theme.palette.mode === 'light' ? SIKRI_COLORS.galaxyGreenSelected : SIKRI_COLORS.galaxyGreenSelectedDark,
        }
      }}
      onClick={() => onClick && onClick()}
      selected={selected}
    >
      <ListItemText
        sx={{
          '& .MuiListItemText-primary': {
            fontWeight: 500,
            color: 'text.primary',
            fontSize: 'body2.fontSize'
          },
          '& .MuiListItemText-secondary': {
            color: 'text.secondary',
            fontSize: 'body2.fontSize'
          }
        }}
        /* primary={
          <Typography variant='body2' sx={{ fontWeight: 500 }}>
            {injuryReport.name}
            <Typography
              sx={{ display: 'inline', fontWeight: 400, color: 'text.secondary' }}
              component="span"
              variant="body2"
              color="text.primary"
            >
              {` • ${getLastUpdatedStr(new Date(lastMessage.created_at).getTime())}`}
            </Typography>
          </Typography>
        } */
        primary={name}
        secondary={`${lastMessageUsername}: ${lastMessage.message} • ` + t.apply(null, getTranslateFriendlyUpdatedStr(new Date(lastMessage.created_at).getTime()))}
      />
      {/* <Typography variant='body1'>{injuryReport.name}</Typography>
      <Typography variant='body2'>{`${lastMessageUsername}: ${lastMessage.message} • ${formatDateTimeFromTimestamp(lastMessage.created_at)}`}</Typography> */}
    </ListItemButton>
  )
}

type InjuryChatWindowProps = {
  injuryReportId: string;
}

const InjuryChatWindow = (props: InjuryChatWindowProps) => {
  const { injuryReportId } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const selectInjuryReportById = React.useMemo(makeSelectInjuryReportById, [injuryReportId]);
  const injuryReport = useAppSelector((state: RootState) => selectInjuryReportById(state, injuryReportId));

  const messages = injuryReport?.messages || [];
  const chatRef = useChatScroll(messages);

  const employees = useAppSelector(selectEmployees);
  const [loading, setLoading] = React.useState(false);
  const dispatch = useAppDispatch();

  const { handleSubmit, control, reset, setValue, watch } = useForm<CreateMessagePayload>({
    resolver: yupResolver(createMessageSchema),
    defaultValues: {
      attachments: []
    }
  });

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control,
      name: 'attachments'
    }
  );
   
  const attachments = useWatch({ name: 'attachments', control: control });

  const canAddAttachment = React.useMemo(() => {
    let canAdd = true;
    if (attachments?.length < 2) {
      return true;
    }
    if (attachments.filter((attachment) => attachment?.file)?.length < attachments?.length) {
      return false;
    }
    for (let i = 0; i < attachments.length; i++) {
      const attachment = attachments[i];
      console.log(`attachment.${i}: ${JSON.stringify(attachment)}`);
      const file = attachment?.file;
      if (file) {
        console.log(`file.${i}: ${file}`);
      }
    }
    return canAdd;
  }, [attachments]);

  const handleSave = async (req: any) => {
    setLoading(true);
    const resultAction = await dispatch(createInjuryReportMessage(req));
    if (createInjuryReportMessage.fulfilled.match(resultAction)) {
      dispatch(snackAlertOpened({ message: t('wif.injury.snackMessages.messageSaved.success'), severity: 'success' }));
      setLoading(false);
      reset();
    } else {
      if (resultAction.payload) {
        dispatch(snackAlertOpened({ message: t('wif.injury.snackMessages.genericError'), severity: 'error' }));
        setLoading(false);
      } else {
        dispatch(snackAlertOpened({ message: t('wif.injury.snackMessages.errorWithMessage', { message: resultAction.error }), severity: 'error' }));
        setLoading(false);
      }
    }
  }

  const getBase64 = (file: any) => {
    return new Promise((res, rej) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => res({ file: reader.result, file_name: file.name, file_type: file.type, file_size: file.size });
      reader.onerror = error => rej(error);
    });
  }
  
  const getAttachmentFiles = async (attachments: any[]) => {
    const promises = attachments.filter((attachment) => attachment?.file).map((attachment) => getBase64(attachment.file));
    const attachment_files = await Promise.all(promises);
  
    return attachment_files; 
  }

  const onSubmit: SubmitHandler<CreateMessagePayload> = (data) => {
    setLoading(true);
    getAttachmentFiles(data.attachments).then((attachment_files: any[]) => {
        const req: any = {
          reportId: injuryReport.id,
          payload: {
            message: data.message, 
            attachments: attachment_files
          }
        };
        handleSave(req);
      }
    );
  };

  const sendingEnabled = React.useMemo(() => {
    let enabled = true;
    if (injuryReport?.status !== 'ongoing') {
      enabled = false;
    }
    /* const userId = getActiveUser()?.id;
    const assigneeId = _.get(injuryReport, 'assignee_id', null);
    const reporterId = (_.get(injuryReport, 'created_by.id', null) || _.get(injuryReport, 'created_by', null));
    if (userId !== assigneeId && userId !== reporterId) {
      enabled = false;
    } */
    return enabled;
  }, [injuryReport]);

  const handleOpenInjuryReport = () => {
    navigate(injuryReport.id);
  }
  const name = getTranslatedInjuryReportName(injuryReport, t);

  const [scrollTarget, setScrollTarget] = React.useState<any>(undefined);

  const scrollRef = React.useRef<HTMLDivElement>();

  React.useEffect(() => {
    if (scrollRef && scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef?.current?.scrollHeight;
    }
  }, [messages]);

  /* React.useEffect(() => {
    if (scrollRef && scrollRef.current) {
      setScrollTarget(scrollRef.current);
    }
  }, [scrollRef]); */

  const scrollTrigger = useScrollTrigger({ 
    target: scrollRef?.current || undefined,
    disableHysteresis: true,
    threshold: 0 
  });

  return (
    <Box 
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        height: '100%',
        width: '100%',
        overflow: 'hidden'
      }}
    >
      <Stack
        direction='row'
        alignItems='center'
        gap={1.5}
        justifyContent='space-between'
        sx={{
          width: '100%',
          py: 2,
          px: 3,
          
          ...(scrollTrigger && {
            borderBottom: 1,
            borderBottomColor: 'divider'
          }) 
          
        }}
      >
        <Typography variant='h3' sx={{ mb: -0.25 }}>{name}</Typography>
        <Button
          onClick={handleOpenInjuryReport}
        >
          {t('wif.injury.openReport')}
        </Button>
      </Stack>
      {messages.length > 0 ? (
        <Box
          ref={scrollRef}
          /* className='BackgroundDefault' */
          sx={{ 
            flexGrow: 1,
            zIndex: 0,
            borderRadius: 1,
            overflowY: 'auto',
            gap: 2.5,
            py: 2,
            px: 3,
            pt: 2.5,
            display: 'flex', 
            flexDirection: 'column',
            maxHeight: 'calc(100vh - 229px - 187px)',
            width: '100%', 
          /*  p: 2, */
            /* bgcolor: 'background.default' */
          }}
        >
        {messages.map((comment: any) => (
          <AppMessage
            key={comment.comment_id}
            timestamp={comment.created_at}
            sender={_.capitalize(employees[comment?.user_id]?.name) || 'Unknown'}
            senderRole={(injuryReport.assignee_id === comment?.user_id && injuryReport.reporter_id === comment?.user_id) ? t('wif.injury.reporterAndAssignee') : injuryReport.assignee_id === comment?.user_id ? t('wif.injury.assignedHandler') : injuryReport.reporter_id === comment?.user_id ? t('wif.injury.reporter') : undefined}
            message={comment.message}
            attachmentKeys={comment?.attachment_keys}
          />
        ))}
      </Box>
      ) : (
        <Box sx={{ pt: 3.25, pb: 2.5 }}>
          <Typography color='textVariant'>{t('wif.injury.noMessages')}</Typography>
        </Box>
      )}
      <Box
        sx={{
          width: '100%', 
          px: 3
        }}
      >
        <SendMessage
          formId='add-injury-comment'
          onSubmit={handleSubmit(onSubmit)}
          watch={watch}
          control={control}
          append={append}
          remove={remove}
          fields={fields}
          attachments={attachments}
          loading={loading}
          sendButtonLabel='send'
        />    
      </Box>
      
    </Box>
  )
}

type InjuryInboxProps = {
  module?: InjuryModuleName;
  view?: InjuryViewName;
}

export const InjuryInbox = (props: InjuryInboxProps) => {
  const { module, view = 'handler' } = props;
  const { t } = useTranslation();
  const [query, setQuery] = React.useState<string>('');
  const [selectedChat, setSelectedChat] = React.useState<string|undefined>();
  const [firstLoadSet, setFirstLoadSet] = React.useState<boolean>(false);

  const registrationIds = useAppSelector(selectInjuryRegistrationReportIds);
  const handlingIds = useAppSelector(selectInjuryHandlerReportIds);

  const selectInjuryReports = React.useMemo(makeSelectInjuryReportsByModule, [module]);

  const handleClickChat = (injuryReportId: string) => {
    /* if (selectedChat === injuryReportId) {
      setSelectedChat(undefined);
    } else {
      setSelectedChat(injuryReportId);
    } */
    setSelectedChat(injuryReportId);
  }

  const injuryReports = useAppSelector((state: RootState) =>
    selectInjuryReports(state, module)
  );

  const injuryReportsWithMessages = React.useMemo(() => {
    return injuryReports.filter((injuryReport) => {
      if (view === 'reporter') {
        return injuryReport.messages.length > 0 && registrationIds.indexOf(injuryReport.id) > -1;
      } else if (view === 'handler') {
        return injuryReport.messages.length > 0 && handlingIds.indexOf(injuryReport.id) > -1;
      }
      return injuryReport.messages.length > 0;
    });
  }, [injuryReports]);

  

  /* React.useEffect(() => {
    if (selectedChat) {
      if (!queryFilteredInjuryReports.find((injuryReport) => injuryReport.id === selectedChat)) {
        setSelectedChat(undefined);
      }
    } 
  }, [queryFilteredInjuryReports]); */
  const [sort, setSort] = React.useState('newest');
  const [sortAnchorEl, setSortAnchorEl] = React.useState<null | HTMLElement>(null);
  const sortOpen = Boolean(sortAnchorEl);
  
  const handleClickSort = (event: React.MouseEvent<HTMLButtonElement>) => {
    setSortAnchorEl(event.currentTarget);
  };

  const handleSort = (newSort: string) => {
    setSort(newSort);
    setSortAnchorEl(null);
  };

  const handleSortClose = () => {
    setSortAnchorEl(null);
  };

  const filteredAndSortedInjuryReports = React.useMemo(() => {
    let filtered = [...injuryReportsWithMessages];
    if (query.length > 0) {
      filtered = injuryReportsWithMessages.filter((injuryReport) => {

        return injuryReport.name.toLowerCase().includes(query.toLowerCase()) || (selectedChat && selectedChat === injuryReport.id);
      });
    }
    if (sort === 'newest') {
      filtered = filtered.sort((a, b) => {
        const aLastMessage = a.messages[a.messages.length - 1];
        const bLastMessage = b.messages[b.messages.length - 1];
        return new Date(bLastMessage?.created_at).getTime() - new Date(aLastMessage?.created_at).getTime();
      });
    } else if (sort === 'oldest') {
      filtered = filtered.sort((a, b) => {
        const aLastMessage = a.messages[a.messages.length - 1];
        const bLastMessage = b.messages[b.messages.length - 1];
        return new Date(aLastMessage?.created_at).getTime() - new Date(bLastMessage?.created_at).getTime();
      });
    }
    return filtered;
  }, [query, injuryReportsWithMessages, sort]);

  React.useEffect(() => {
    if (filteredAndSortedInjuryReports.length > 0 && !firstLoadSet) {
      setSelectedChat(filteredAndSortedInjuryReports[0].id)
      setFirstLoadSet(true);
    }
  }, [filteredAndSortedInjuryReports]);

  return (
    <Grid
      container
      spacing={0}
      sx={{
        flexGrow: 1,
        minHeight: 0,
        overflow: 'hidden',
      }}
    >
      <Grid
        xs={12}
        lg={5}
        sx={{ height: '100%' }}
      >
        <Stack
          direction='column'
          sx={{
            display: 'flex', 
            borderRight: 1, 
            borderRightColor: 'divider',
            height: '100%',
          }}
        >
          <Box
            sx={{
              px: 3, 
              pt: 2.5,
              pb: 1,
              borderBottom: 1, 
              display: 'flex', 
              gap: 1,
              flexDirection: 'column', 
              borderBottomColor: 'divider',
              width: '100%'
            }}
          >
            {/* <Stack
              direction='row'
              gap={1}
              alignItems='center'
            >
              <Typography variant='h4'>Messages</Typography>
              <Chip
                size='small'
                label={injuryReportsWithMessages.length}
                sx={{
                  fontWeight: 500
                }}
              />
            </Stack> */}
            
            <TextField
              value={query}
              size='small'
              fullWidth
              onChange={(e) => setQuery(e.target.value)}
              placeholder={t('wif.injury.searchPlaceholder')}
              InputProps={{
                sx: { 
                  pl: 1.75,
                  borderRadius: 1.5,
                  borderColor: (theme) => `${theme.palette.primary.main}!important`,
                  '& input': {
                    lineHeight: 20,
                    ml: 0,
                    pl: 0.25,
                    '&::placeholder': {
                      color: 'textVariant',
                      opacity: 0.9
                    },

                  },
                  boxShadow: BOX_SHADOWS.elevation0,
                  height: 36, 
                },
                
                startAdornment: (<InputAdornment position='start' sx={{ minWidth: '0px' }}><AppIcon iconName='search' color='textVariant' weight={500} /></InputAdornment>),
              }}
              sx={{
                borderColor: (theme) => `${theme.palette.primary.main}!important`,
              }}
            />
            <Stack
              direction='row'
              gap={1}
              alignItems='center'
              justifyContent='space-between'
              sx={{ mx: -0.5 }}
            >
               <Button
                variant='text'
                color='primary'
                disabled
                startIcon={<AppIcon iconName='tune' /* color='primary.main' */ />}
                sx={{
                  pl: 1
                }}
              >
                {t('wif.injury.filter')}
              </Button>
              <Button
                variant='text'
                endIcon={<AppIcon iconName='arrow_drop_down' color='primary.main' />}
                onClick={handleClickSort}
                sx={{
                  '& .MuiButton-endIcon': {
                    ml: 0.5,
                    
                  },
                  pr: 1
                }}
              >
                {t('wif.injury.sortBy') + ": " + t(`wif.injury.${sort}`)}
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={sortAnchorEl}
                open={sortOpen}
                onClose={handleSortClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem onClick={() => handleSort('newest')}>{t('wif.injury.newest')}</MenuItem>
                <MenuItem onClick={() => handleSort('oldest')}>{t('wif.injury.oldest')}</MenuItem>
              </Menu>
            </Stack>
            
            
          </Box>
          
          <List
            disablePadding
            sx={{
              overflowY: 'auto', 
              flexGrow: 1,
              maxHeight: 'calc(100vh - 229px - 109px)',
            }}
          >
            {filteredAndSortedInjuryReports.length > 0 && [...filteredAndSortedInjuryReports].map((injuryReport, i) => (
              <>
                <InjuryChatItem 
                  key={`InjuryChatItem-${i}`}
                  injuryReport={injuryReport}
                  selected={injuryReport.id === selectedChat}
                  onClick={() => handleClickChat(injuryReport.id)}
                />
                {filteredAndSortedInjuryReports.indexOf(injuryReport) !== filteredAndSortedInjuryReports.length - 1 && <Divider />}
              </>
              
            ))}
            
          </List>
        </Stack>
        
        
      </Grid>
      <Grid
        xs={12}
        lg={7}
        sx={{ height: '100%' }}
      > 
        {!selectedChat && (
          <Box
            sx={{
              width: '100%', 
              height: '100%',
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'center'
            }}
          >
            <Typography variant='body1'>Select a chat from the injury reports in the side panel to view messages.</Typography>
          </Box>
        )}
        {selectedChat && (
          <InjuryChatWindow injuryReportId={selectedChat} />
        )}
      </Grid>
    </Grid>
  )
}