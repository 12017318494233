import { Autocomplete, Checkbox, TextFieldProps } from '@mui/material';
import { TextField } from '@mui/material';
import React from 'react';
import type { Control, FieldPath } from 'react-hook-form';
import { Controller, useFormContext } from 'react-hook-form';
import type { FieldValues } from 'react-hook-form/dist/types';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useTranslation } from 'react-i18next';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = TextFieldProps & {
  control?: Control<TFieldValues, any>;
  name: TName;
  controllerProps?: any;
  options: {
    id: string;
    name?: string;
  }[];
  label?: string;
  selectionLimit?: number;
  multiple?: boolean;
};

export function NewFormAutocomplete<TFieldValues extends FieldValues>(props: Props<TFieldValues>) {
  const { 
    control = useFormContext<TFieldValues>().control, 
    name, 
    controllerProps, 
    options,
    label,
    selectionLimit,
    multiple = true,
    ...rest 
  } = props;
  const { t } = useTranslation();
  const getTranslation = React.useCallback((key: string) => {
    let keyWithValues = key.split(' ');
    const translationKey = keyWithValues[0];
    if (translationKey === 'max' || translationKey === 'min' || translationKey === 'minmax') {
      return t(`wif.injury.errors.${translationKey}`, { i: keyWithValues[1] });
    } else if (translationKey === 'between') {
      return t(`wif.injury.errors.${translationKey}`, { min: keyWithValues[1], max: keyWithValues[2] });
    } 
    return t(`wif.injury.errors.${key}`);
  }, [t]);
  const [orderDialogOpen, setOrderDialogOpen] = React.useState(false);

  return (
    <Controller
      control={control}
      name={name}
      {...controllerProps}
      render={({ field: { value, ...field }, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          multiple
          disableCloseOnSelect
          limitTags={4}
          getOptionDisabled={selectionLimit ? (options) => value ? value.length >= selectionLimit : false : undefined}
          id="tags-standard"
          value={value ? value : []}
          onChange={(event, newValue) => {
            field.onChange(newValue)
          }}
          options={options.map((option) => option.id)}
          getOptionLabel={(option) => options.find((o) => o.id === option)?.name || option}
          renderOption={multiple ? (props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 4, marginLeft: -8 }}
                checked={selected}
              />
              {options.find((o) => o.id === option)?.name || option.id}
            </li>
          ) : undefined}
          /* options={ACCIDENT_TYPE_KEYS.map((key) => `${key} ${ACCIDENT_TYPE_VALUES[key]}`)} */
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              required={!!error}
              error={!!error}
              helperText={error?.message ? getTranslation(error.message) : null}
              variant='outlined'
              {...rest}
            />
          )}
        />
      )}
    />
  );
}
