import { Avatar, Button, IconButton, Paper, Stack, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from 'react';
import { BOX_SHADOWS } from "../../../../../config/theme/boxShadows";
import { formatBytes, formatDateTimeFromTimestamp } from "../../../../../utils/format";
import { APP_COLORS } from "../../../../../config";
import { useCurrentInjuryReport } from "../../../routes/CurrentInjuryReportContext";
import { AppComment, createInjuryReportComment, createInjuryReportMessage } from "../../../store/injuryReportsSlice";
import { RootState, useAppDispatch, useAppSelector } from "../../../../../store";
import { selectEmployees } from "../../../../employees/employeesSlice";
import * as yup from "yup";
import { useForm, Controller, SubmitHandler, useFieldArray, useWatch } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { snackAlertOpened } from "../../../../global/controlsSlice";
import { FormTextField } from "../../../../../components/FormInputs/FormTextField";
import { LoadingButtonContainer } from "../../../../../components/Elements/LoadingButtonContainer";
import { AppIcon } from "../../../../../components/Elements";
import { FormFileInput } from "../../../../../components/FormInputs/FormFileInput";
import { useTheme, alpha } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import { AppMessage, useChatScroll } from "../../../../../components/Messages/AppMessage";
import { CreateMessagePayload, createMessageSchema } from "./Comments";
import { getActiveUser} from "../../../../../utils/user";
import _, { filter } from 'lodash';
import { SendMessage, SendMessageAttachmentItem, SendMessageAttachmentsContainer, SendMessageContainer, SendMessageInputsContainer, SendMessagePaper } from "../../../../../components/Messages/SendMessage";
import { useTranslation } from 'react-i18next';

export function ReportMessages() {
  const injuryReport = useCurrentInjuryReport();
  const messages = injuryReport?.messages || [];
  const chatRef = useChatScroll(messages);
  const { t } = useTranslation();
  const employees = useAppSelector(selectEmployees);
  const [loading, setLoading] = React.useState(false);
  const dispatch = useAppDispatch();

  const { handleSubmit, control, reset, setValue, watch } = useForm<CreateMessagePayload>({
    resolver: yupResolver(createMessageSchema),
    defaultValues: {
      attachments: []
    }
  });

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control,
      name: 'attachments'
    }
  );
   
  const attachments = useWatch({ name: 'attachments', control: control });

  const canAddAttachment = React.useMemo(() => {
    let canAdd = true;
    if (attachments?.length < 2) {
      return true;
    }
    if (attachments.filter((attachment) => attachment?.file)?.length < attachments?.length) {
      return false;
    }
    for (let i = 0; i < attachments.length; i++) {
      const attachment = attachments[i];
      console.log(`attachment.${i}: ${JSON.stringify(attachment)}`);
      const file = attachment?.file;
      if (file) {
        console.log(`file.${i}: ${file}`);
      }
    }
    return canAdd;
  }, [attachments]);

  const handleSave = async (req: any) => {
    setLoading(true);
    const resultAction = await dispatch(createInjuryReportMessage(req));
    if (createInjuryReportMessage.fulfilled.match(resultAction)) {
      dispatch(snackAlertOpened({ message: t('wif.injury.snackMessages.messageSaved.success'), severity: 'success' }));
      setLoading(false);
      reset();
    } else {
      if (resultAction.payload) {
        dispatch(snackAlertOpened({ message: t('wif.injury.snackMessages.genericError'), severity: 'error' }));
        setLoading(false);
      } else {
        dispatch(snackAlertOpened({ message: t('wif.injury.snackMessages.errorWithMessage', { message: resultAction.error }), severity: 'error' }));
        setLoading(false);
      }
    }
  }

  const getBase64 = (file: any) => {
    return new Promise((res, rej) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => res({ file: reader.result, file_name: file.name, file_type: file.type, file_size: file.size });
      reader.onerror = error => rej(error);
    });
  }
  
  const getAttachmentFiles = async (attachments: any[]) => {
    const promises = attachments.filter((attachment) => attachment?.file).map((attachment) => getBase64(attachment.file));
    const attachment_files = await Promise.all(promises);
  
    return attachment_files; 
  }

  const onSubmit: SubmitHandler<CreateMessagePayload> = (data) => {
    setLoading(true);
    getAttachmentFiles(data.attachments).then((attachment_files: any[]) => {
        const req: any = {
          reportId: injuryReport.id,
          payload: {
            message: data.message, 
            attachments: attachment_files
          }
        };
        handleSave(req);
      }
    );
  };

  const sendingEnabled = React.useMemo(() => {
    let enabled = true;
    if (injuryReport?.status !== 'ongoing') {
      enabled = false;
    }
    /* const userId = getActiveUser()?.id;
    const assigneeId = _.get(injuryReport, 'assignee_id', null);
    const reporterId = (_.get(injuryReport, 'created_by.id', null) || _.get(injuryReport, 'created_by', null));
    if (userId !== assigneeId && userId !== reporterId) {
      enabled = false;
    } */
    return enabled;
  }, [injuryReport]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        flex: 1,
        
      }}
    >
      {messages.length > 0 ? (
        <Box
        ref={chatRef}
        /* className='BackgroundDefault' */
        sx={{ 
          flexGrow: 1,
          zIndex: 0,
          borderRadius: 1,
          maxHeight: '400px',
          overflowY: 'auto',
          gap: 2.5,
          py: 2,
          pt: 2.5,
          display: 'flex', 
          flexDirection: 'column',
        /*  p: 2, */
          /* bgcolor: 'background.default' */
        }}
      >
        {messages.map((comment: any) => (
          <AppMessage
            key={comment.comment_id}
            timestamp={comment.created_at}
            sender={_.capitalize(employees[comment?.user_id]?.name) || 'Unknown'}
            senderRole={(injuryReport.assignee_id === comment?.user_id && injuryReport.reporter_id === comment?.user_id) ? 'Reporter/Assignee' : injuryReport.assignee_id === comment?.user_id ? 'Assignee' : injuryReport.reporter_id === comment?.user_id ? 'Reporter' : undefined}
            message={comment.message}
            attachmentKeys={comment?.attachment_keys}
          />
        ))}
      </Box>
      ) : (
        <Box sx={{ pt: 3.25, pb: 2.5 }}>
          <Typography color='textVariant'>{t('wif.injury.noMessages')}</Typography>
        </Box>
      )}
      <SendMessage
        formId='add-injury-comment'
        onSubmit={handleSubmit(onSubmit)}
        sendButtonLabel='send'
        watch={watch}
        control={control}
        append={append}
        remove={remove}
        fields={fields}
        attachments={attachments}
        loading={loading}
        /* sendButtonIconName='send' */
      />    
    </Box>
  )
}