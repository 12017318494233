import {
  Autocomplete,
  Box,
  Checkbox,
  Dialog,
  Paper,
  TextFieldProps,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  CircularProgress
} from '@mui/material';
import { TextField } from '@mui/material';
import React from 'react';
import type { Control, FieldPath } from 'react-hook-form';
import { Controller, useFormContext } from 'react-hook-form';
import type { FieldValues } from 'react-hook-form/dist/types';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { SortableContainer } from '../Sortable/SortableContainer';
import { mappedInputLabels } from '../../features/injuries/constants/mappedInputLabels';
import { useTranslation } from 'react-i18next';

type FormSingleAutocompleteOption = {
  label: any; 
  text?: string;
  depth?: number;
  disabled?: boolean;
}

type Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = TextFieldProps & {
  control?: Control<TFieldValues, any>;
  name: TName;
  controllerProps?: any;
  options: string[];
  optionLabels?: {
    [key: string]: string;
  };
  label?: string;
  loading?: boolean;
};

export function FormSingleAutocomplete<TFieldValues extends FieldValues>(props: Props<TFieldValues>) {
  const { 
    control = useFormContext<TFieldValues>().control, 
    name, 
    controllerProps, 
    options,
    optionLabels,
    label,
    loading = false,
    ...rest
  } = props;

  const [orderDialogOpen, setOrderDialogOpen] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');
  const { t } = useTranslation();
  const getTranslation = React.useCallback((key: string) => {
    let keyWithValues = key.split(' ');
    const translationKey = keyWithValues[0];
    if (translationKey === 'max' || translationKey === 'min' || translationKey === 'minmax') {
      return t(`wif.injury.errors.${translationKey}`, { i: keyWithValues[1] });
    } else if (translationKey === 'between') {
      return t(`wif.injury.errors.${translationKey}`, { min: keyWithValues[1], max: keyWithValues[2] });
    } 
    return t(`wif.injury.errors.${key}`);
  }, [t]);
  return (
    <Controller
      control={control}
      name={name}
      {...controllerProps}
      render={({ field: { value, ...field }, fieldState: { error } }) => (
        <Autocomplete
        {...field}
        id={`${name}-autocomplete`}
        value={!loading ? value : null}
        onChange={(event, newValue) => {
          field.onChange(newValue)
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        loading={loading}
        getOptionLabel={(option) => optionLabels[option] || option}
        options={options}
        renderOption={(props, option) => (
          <li {...props}>
            <Box
              sx={{
                pl: option.depth
              }}
            >
              {optionLabels ? optionLabels[option] : option}
            </Box>
            
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            required={!!error}
            error={!!error}
            helperText={error?.message ? getTranslation(error.message) : null}
            variant='outlined'
            {...rest}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
      )}
    />
  );
}