import { GridColumnVisibilityModel, GridSortModel } from "@mui/x-data-grid";
import { IActiveFilter, IFilterControl } from "../../../components/DataGrid/types";
import { getActiveUser } from "../../../utils/user";
import { InjuryModuleName, InjuryViewName } from "./injuryControlsSlice";
import { CASE_STATUS, REPORT_CATEGORY, REPORT_TYPES, TKB_INJURY } from "../constants";



const getInitialCols = (view: string, module: string): GridColumnVisibilityModel => {
  let initialCols = {
    'ncReportName': false,
    'unit': false, 
    'type': false,
    'comments': false,
    'updated_at': false
  } as any;
  if (view === 'handler') {
    initialCols = {
      ...initialCols,
      'reporter_id': false,
      'progress': false,
      'messages': false,
    };
  }
  if (view === 'reporter') {
    initialCols = {
      ...initialCols, 
      'departmentId': false,
    }
  }
  if (module === 'all') {
    initialCols = {
      ...initialCols,
      'category': false,
    };
  }
  return {
    ...initialCols,
  }
};

const getInitialAddedControls = (view: InjuryViewName, module: InjuryModuleName): IFilterControl[] => {
  let controls: IFilterControl[] = [
    /* {
      field: 'status',
      label: `${TKB_INJURY}.status`, // translation key
      options: [
        { value: CASE_STATUS.ONGOING, label: `${TKB_INJURY}.ongoing` },
        { value: CASE_STATUS.CLOSED, label: `${TKB_INJURY}.closed` },
        { value: CASE_STATUS.REJECTED, label: `${TKB_INJURY}.rejected` }
      ]
    },
    {
      field: 'type',
      label: `${TKB_INJURY}.type`,
      options: [
        { value: REPORT_TYPES.ACCIDENT, label: `${TKB_INJURY}.reportType.accident` },
        { value: REPORT_TYPES.ILLNESS, label: `${TKB_INJURY}.reportType.illness` },
        { value: REPORT_TYPES.MINOR_INJURY, label: `${TKB_INJURY}.reportType.minorInjury` },
      ]
    }, */
  ];
  /* if (module === 'all') {
    controls.unshift({
      field: 'category',
      label: `${TKB_INJURY}.category`,
      options: [
        { value: REPORT_CATEGORY.STUDENT, label: `${TKB_INJURY}.reportCategory.student` },
        { value: REPORT_CATEGORY.EMPLOYEE, label: `${TKB_INJURY}.reportCategory.employee` },
      ]
    });
  }; */

  return controls;
};

const getInitialAddedFilters = (view: string, module: string): IActiveFilter[] => {
  let initialFilters = [];

  if (view === 'handler') {
    initialFilters.push({
      field: 'status', 
      value: CASE_STATUS.ONGOING,
    });
  }
  /* const activeUser = getActiveUser();

  if (view === 'reporter') {
    if (activeUser && activeUser?.id) {
      initialFilters.push({
        field: 'reporter_id', 
        value: activeUser.id,
      });
    }
  }  */

  return initialFilters;
}
// view: handler, reporter 
// if handler, sort by due date
// if reporter, sort by date created
const getInitialSortModel = (view: string): GridSortModel => {
  if (view === 'handler') {
    return [
      {
        field: 'due_date',
        sort: 'desc',
      }
    ];
  } else if (view === 'reporter') {
    return [
      {
        field: 'reportedTimestamp',
        sort: 'desc'
      }
    ];
  }
  return [
    {
      field: 'reportedTimestamp',
      sort: 'desc'
    }
  ];
  
};

const getInitialControlsStateByViewAndQuery = (view: InjuryViewName, module: InjuryModuleName) => {
  return {
    addedControls: getInitialAddedControls(view, module),
    activeFilters: getInitialAddedFilters(view, module),
    sortModel: getInitialSortModel(view),
    columnVisibilityModel: getInitialCols(view, module),
  }
};

export const getInitialInjuryControlsState = () => {
  let reporter = {
    student: getInitialControlsStateByViewAndQuery('reporter', 'student'),
    employee: getInitialControlsStateByViewAndQuery('reporter', 'employee'),
    all: getInitialControlsStateByViewAndQuery('reporter', 'all'),
    shared: getInitialControlsStateByViewAndQuery('reporter', 'shared'),
  };
  let handler = {
    student: getInitialControlsStateByViewAndQuery('handler', 'student'),
    employee: getInitialControlsStateByViewAndQuery('handler', 'employee'),
    all: getInitialControlsStateByViewAndQuery('handler', 'all'),
    shared: getInitialControlsStateByViewAndQuery('handler', 'shared'),
  };
  return {
    reporter, 
    handler
  };
};